import React, { FC, useCallback } from 'react'

import { ConnectedProps, connect } from 'react-redux'
import { AppDispatch, RootState } from 'App'
import { bindActionCreators } from 'redux'

import { getAdvisorsListSelector, getBusinesses } from 'store/selectors/userManagement'

import { getAdvisorsAction, getBusinessesAction } from 'store/actions/userManagement'

import { Columns } from 'types'

import { TableActionsContainer } from 'pages/Common/container'
import UserTableHeader from 'components/Common/UserTableHeader'
import UserManagementLayout from '../UserManagementLayout'
import { useSelector } from 'react-redux'
import { replaceTerm } from 'utils/helper'

const connector = connect(
  (state: RootState) => ({
    loading: state.loadingStatus.isOwnerDataLoading,
    businesses: getBusinesses(state),
    advisors: getAdvisorsListSelector(state),
  }),
  (dispatch: AppDispatch) =>
    bindActionCreators(
      {
        getBusinessesAction: getBusinessesAction.STARTED,
        getAdvisorsAction: getAdvisorsAction.STARTED,
      },
      dispatch
    )
)

interface IBusinessesProps extends ConnectedProps<typeof connector> {}

const sortFields = [
  { label: 'Name', value: 'name' },
  { label: 'Business Name', value: 'businessName' },
  { label: 'Date Created', value: 'createdAt', fieldType: 'date' },
  { label: 'Status', value: 'status' },
]

const Businesses = connector<FC<IBusinessesProps>>(
  ({ getBusinessesAction, getAdvisorsAction, businesses, loading }) => {
    const getData = useCallback(() => {
      getAdvisorsAction({ isPaginated: false })
      getBusinessesAction({ allStatuses: true })
    }, [getAdvisorsAction, getBusinessesAction])

    const bsoProfile = useSelector((state: RootState) => state.bso.bsoProfile)
    columns[2].colFields[0].headerName = replaceTerm('advisor', bsoProfile.terminology)

    return (
      <UserManagementLayout
        searchInputPlaceholder="Search by name or business"
        createAccountUrlPath={'create-business-owner'}
        searchByProperties={['name', 'businessName']}
        userAccountType={'Business'}
        userAccounts={businesses}
        sortFields={sortFields}
        tableColumns={columns}
        getData={getData}
        loading={loading}
        showAdvisorFilter
      />
    )
  }
)

export default Businesses

const columns: Columns = [
  {
    text: 'records',
    tableType: 'user',
    width: 260,
    textDirection: 'left',
    colSpan: 'col-span-3',
    direction: 'items-center',
    colFields: [
      {
        field: 'name',
        type: 'string',
        headerName: 'Name',
        sortable: true,
      },
      {
        field: 'businessName',
        type: 'string',
        headerName: 'Business Name',
        circle: true,
        sortable: true,
      },
    ],
    renderCell: (params) => {
      return (
        <UserTableHeader className="col-span-3">
          <p className="font-semibold"> {params?.name || '(No name provided)'}</p>
          <div className="break-words">{params?.businessName || '(No business name provided)'}</div>
        </UserTableHeader>
      )
    },
  },
  {
    width: 160,
    textDirection: 'left',
    colSpan: 'col-span-3',
    direction: 'items-center',
    colFields: [
      {
        field: 'created',
        type: 'date',
        headerName: 'Created',
        sortable: true,
      },
      {
        field: 'activated',
        type: 'string',
        headerName: 'Activated',
        circle: true,
        sortable: true,
      },
    ],
    renderCell: (params) => {
      return (
        <UserTableHeader className="col-span-3">
          <div>{params?.created}</div>
          <div className="capitalize">
            {params?.activated === 'active' ? params?.updated : params?.activated}
          </div>
        </UserTableHeader>
      )
    },
  },
  {
    width: 200,
    textDirection: 'left',
    colSpan: 'col-span-2',
    direction: 'items-center',
    colFields: [
      {
        field: 'advisors',
        type: 'string',
        headerName: 'Advisor',
        paddingTop: 'pt-4',
      },
    ],
    renderCell: (params) => {
      return (
        <UserTableHeader className={'!whitespace-pre-wrap col-span-2'}>
          {params?.activated === 'inactive' ? '' : params?.advisors}
        </UserTableHeader>
      )
    },
  },
  {
    textDirection: 'right',
    colSpan: 'col-span-4',
    direction: 'justify-end',
    colFields: [
      {
        field: 'action',
        headerName: 'Actions',
        paddingTop: 'justify-end pt-4',
      },
    ],
    renderCell: (params) => {
      return (
        <td className="p-3 text-sm text-grey whitespace-nowrap self-center text-right col-span-4">
          <TableActionsContainer userData={params} />
        </td>
      )
    },
  },
]
