import { DISABLE_PLAY, GET_ASSESSMENT_PLAY_LISTING, GET_DASHBOARD_LISTING } from 'store/types'

export const getDashboardListingAction = {
  STARTED: () => ({
    type: GET_DASHBOARD_LISTING.STARTED,
  }),
  FULLFILLED: (payload) => ({ type: GET_DASHBOARD_LISTING.FULLFILLED, payload }),
  REJECTED: (error: any) => ({ type: GET_DASHBOARD_LISTING.REJECTED, payload: error }),
}

export const getAssessmentPlayListingAction = {
  STARTED: () => ({
    type: GET_ASSESSMENT_PLAY_LISTING.STARTED,
  }),
  FULLFILLED: (payload) => ({ type: GET_ASSESSMENT_PLAY_LISTING.FULLFILLED, payload }),
  REJECTED: (error: any) => ({ type: GET_ASSESSMENT_PLAY_LISTING.REJECTED, payload: error }),
}

export const disablePlayAction = (payload) => ({
  type: DISABLE_PLAY,
  payload,
})
