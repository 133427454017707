import React from 'react'
import styled from 'styled-components'

const StyledLabel = styled.label``

const Label = ({ title, className, children }) => {
  return (
    <StyledLabel
      title={title}
      className={`${className} text-primary-text font-primary xs:text-base sm:text-xl font-medium	`}
    >
      {children}
    </StyledLabel>
  )
}

Label.defaultProps = {
  className: '',
  title: '',
}

export default Label
