import { BSOProfile } from 'store/reducers/bso'
import { GET_BSO_PROFILE } from 'store/types'

export const getBSOProfileAction = {
  STARTED: (payload) => ({
    type: GET_BSO_PROFILE.STARTED,
    payload,
  }),
  FULLFILLED: (bsoProfile: BSOProfile) => ({
    type: GET_BSO_PROFILE.FULLFILLED,
    payload: bsoProfile,
  }),
  REJECTED: () => ({
    type: GET_BSO_PROFILE.REJECTED,
  }),
}
