import React from 'react'

function AccountIcon({ className }) {
  return (
    <svg
      width="25"
      className={className}
      height="27"
      viewBox="0 0 25 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1876_5623)">
        <path d="M12.15 0C8.31516 0 5.20714 3.02168 5.20714 6.75C5.20714 10.4783 8.31516 13.5 12.15 13.5C15.9848 13.5 19.0929 10.4783 19.0929 6.75C19.0929 3.02168 15.9848 0 12.15 0ZM11.4286 21.7266L7.6046 16.1525C3.28701 16.8486 0 20.4979 0 24.8906V25.3125C0 26.2459 0.775647 27 1.73571 27H22.5643C23.5244 27 24.3 26.2459 24.3 25.3125V24.8906C24.3 20.4979 21.013 16.8486 16.6954 16.1525L12.8714 21.7266C12.5297 22.2275 11.7703 22.2275 11.4286 21.7266Z" />
      </g>
      <defs>
        <clipPath id="clip0_1876_5623">
          <rect width="24.3" height="27" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
AccountIcon.defaultProps = {
  className: '',
}

export default AccountIcon
