import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import Progressbar from 'components/Common/ProgressBar'
import InputField from 'components/Common/InputField'
import {
  totalStepsProgressbarAction,
  updateProgressbarAction,
} from 'store/actions/common/progressbar'
import { forgotPasswordAction, logoutAction } from 'store/actions/auth'
import { changePasswordAction, saveUserFileAction } from 'store/actions/common'
import SettingPanel from 'layout/AppLayout/SettingPanel'
import IdleTracking from 'components/Common/IdleTracking'
import ChangePassword from 'components/Common/ChangePassword'
import NotificationPanel from 'components/NotificationPanel'
import TableActions from 'components/Admin/UserManagement/TableActions'
import ConfirmationModal from 'components/Admin/UserManagement/ConfirmationModal'
import ResetPasswordConfirmationModal from 'components/Admin/UserManagement/ResetPasswordConfirmationModal'
import Chat from 'components/Chat'
import { getUsersAssessmentResponseByQuestionAction } from 'store/actions/owner/initialAssessment'
import {
  chatUnreadMessageCountAction,
  getChatUsersAction,
  getUnreadNotificationsAction,
  getUserNoticationsAction,
  updateUserNotificationsAction,
  deleteUserNotificationsAction,
  getClientsSummaryAction,
  resendUserInviteAction,
  openConfirmationModalAction,
  updateUserProfileAction,
  setShowSubscriptionPromptAction,
} from 'store/actions/user'
import NavigationTabs from 'layout/AppLayout/AppNavbar/NavigationTabs'
import NavbarActionIconButtons from 'layout/AppLayout/AppNavbar/NavbarActionIconButtons'
import Dashboard from 'components/Dashboard'
import { RootState } from 'App'
import { getClientsAction } from 'store/actions/clients'
import { getUserCalendarEventsAction } from 'store/actions/calendar'
import { getCalendarEventsSelector } from 'store/selectors/calendar'
import { getParsedNotificationSelector } from 'store/selectors/notification'
import ChangeTimezone from 'components/Common/ChangeTimezone'

/* --------------------------- Dashboard --------------------------- */
const dashboardProps = (state: RootState) => {
  const { showSubscriptionPrompt } = state.user
  return {
    isLoading: state.loadingStatus.clientsSummary,
    isUpdateProfileLoading: state?.user?.isUpdateProfileLoading,
    summary: state.user?.summary,
    user: state.user?.user,
    chatUnreadMessageCount: state?.user?.chatUnreadMessageCount,
    events: getCalendarEventsSelector(state).futureMeetings,
    showSubscriptionPrompt,
  }
}

function dashboardDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getClientsSummaryAction: getClientsSummaryAction.STARTED,
      getUserCalendarEventsAction: getUserCalendarEventsAction.STARTED,
      getClientsAction: getClientsAction.STARTED,
      setShowSubscriptionPromptAction: setShowSubscriptionPromptAction,
      updateUserProfileAction: updateUserProfileAction.STARTED,
    },
    dispatch
  )
}
export const DashboardContainer = connect(dashboardProps, dashboardDispatch)(Dashboard)

/* --------------------------- Progressbar (Index) --------------------------- */

const progressbarProps = (state: RootState) => {
  const { width } = state.progressbar
  return {
    width: width,
  }
}

function progressbarDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      updateProgressbarAction: updateProgressbarAction,
      totalStepsProgressbarAction: totalStepsProgressbarAction,
    },
    dispatch
  )
}
export const ProgressbarContainer = connect(progressbarProps, progressbarDispatch)(Progressbar)

/* --------------------------- InputField (Index) --------------------------- */

const inputFieldProps = (state: RootState) => {
  const { inputFieldBgColor } = state.common
  return {
    inputFieldBgColor,
  }
}

export const InputFieldContainer = connect(inputFieldProps, null)(InputField)

/* --------------------------- Idle Tracking (Index) --------------------------- */

const idleTrackingProps = (state: RootState) => {
  return {}
}

function idleTrackingDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      logoutAction: logoutAction,
    },
    dispatch
  )
}

export const IdleTrackingContainer = connect(idleTrackingProps, idleTrackingDispatch)(IdleTracking)

/* --------------------------- App layout  --------------------------- */
/* --------------------------- Setting Panel --------------------------- */

const settingPanelProps = (state: RootState) => {
  return {}
}

function settingPanelDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      logoutAction: logoutAction,
    },
    dispatch
  )
}

export const SettingPanelContainer = connect(settingPanelProps, settingPanelDispatch)(SettingPanel)

/* --------------------------- Change Password (Index) --------------------------- */

const changePasswordProps = (state: RootState) => {
  const { error } = state.auth
  const { status } = state.changePassword
  return {
    errorMessage: error,
    status,
  }
}

function changePasswordDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      changePasswordAction: changePasswordAction.STARTED,
    },
    dispatch
  )
}
export const ChangePasswordContainer = connect(
  changePasswordProps,
  changePasswordDispatch
)(ChangePassword)

/* --------------------------- Chat --------------------------- */

const chatProps = (state: RootState) => {
  let { user, chatUsers } = state.user
  let { usersAssessmentResponseByQuestion } = state.owner
  return {
    user,
    chatUsers,
    token: user?.streamAccessToken,
    usersAssessmentResponseByQuestion,
    chatUnreadMessageCount: state.user.chatUnreadMessageCount,
  }
}

function chatDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      saveUserFileAction: saveUserFileAction.STARTED,
      getUsersAssessmentResponseByQuestionAction:
        getUsersAssessmentResponseByQuestionAction.STARTED,
      getChatUsersAction: getChatUsersAction.STARTED,
      chatUnreadMessageCountAction: chatUnreadMessageCountAction.STARTED,
    },
    dispatch
  )
}
export const ChatContainer = connect(chatProps, chatDispatch)(Chat)

/* ------------------- NAVBAR ----------------------- */

const navigationTabsProps = (state: RootState) => {
  let { user } = state.user
  return {
    user: user,
  }
}

function navigationTabsDispatch(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch)
}
export const NavigationTabsContainer = connect(
  navigationTabsProps,
  navigationTabsDispatch
)(NavigationTabs)

/* ------------------- HEADER ----------------------- */

const navbarActionIconButtonsProps = (state: RootState) => {
  let { user } = state
  return {
    user: user.user,
    chatUnreadMessageCount: user.chatUnreadMessageCount,
    chatUnreadNotificationsCount: user.notifications.totalUnread,
  }
}

function navbarActionIconButtonsDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getUnreadNotificationsAction: getUnreadNotificationsAction.STARTED,
      chatUnreadMessageCountAction: chatUnreadMessageCountAction.STARTED,
    },
    dispatch
  )
}
export const NavbarActionIconButtonsContainer = connect(
  navbarActionIconButtonsProps,
  navbarActionIconButtonsDispatch
)(NavbarActionIconButtons)

/* ------------------- Notifications ----------------------- */

const notificationProps = (state: RootState) => {
  let { user } = state
  return {
    notifications: getParsedNotificationSelector(state),
    totalCount: user.notifications.totalCount,
    isLoading: user.notifications.isLoading,
    lastEvaluatedKey: user.notifications?.lastEvaluatedKey,
  }
}

const notificationDispatch = (dispatch: Dispatch) => {
  return bindActionCreators(
    {
      getUnreadNotificationsAction: getUnreadNotificationsAction.STARTED,
      getUserNoticationsAction: getUserNoticationsAction.STARTED,
      resetUserNotifications: getUserNoticationsAction.RESET,
      updateUserNoticationsAction: updateUserNotificationsAction.STARTED,
      deleteUserNoticationsAction: deleteUserNotificationsAction.STARTED,
    },
    dispatch
  )
}
export const NotificationPanelContainer = connect(
  notificationProps,
  notificationDispatch
)(NotificationPanel)

/* --------------------------- ResetPasswordConfirmationModal --------------------------- */

const resetPasswordConfirmationProps = (state: RootState) => {
  const { disableLoginSubmit, error, status } = state.auth
  return {
    status,
    errorMessage: error,
    disableLoginSubmit: disableLoginSubmit,
  }
}

function resetPasswordConfirmationDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      forgotPasswordAction: forgotPasswordAction.STARTED,
    },
    dispatch
  )
}

const ResetPasswordConfirmationModalContainer = connect(
  resetPasswordConfirmationProps,
  resetPasswordConfirmationDispatch
)(ResetPasswordConfirmationModal)

export default ResetPasswordConfirmationModalContainer

/* --------------------------- ConfirmationModal --------------------------- */

const confirmationProps = (state: RootState) => {
  const { error, status, isSuccess } = state.user
  return {
    status,
    isSuccess,
    errorMessage: error,
  }
}

function confirmationDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      resendUserInviteAction: resendUserInviteAction.STARTED,
    },
    dispatch
  )
}

export const ConfirmationModalContainer = connect(
  confirmationProps,
  confirmationDispatch
)(ConfirmationModal)

/* --------------------------- TableActions --------------------------- */

const tableActionsProps = (state: RootState) => {
  const { error, status, isSuccess, openConfirmationModal, email } = state.user
  return {
    email,
    status,
    isSuccess,
    errorMessage: error,
    openConfirmationModal,
  }
}

function tableActionsDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      resendUserInviteAction: resendUserInviteAction.STARTED,
      openConfirmationModalAction: openConfirmationModalAction,
    },
    dispatch
  )
}

export const TableActionsContainer = connect(tableActionsProps, tableActionsDispatch)(TableActions)

/* --------------------------- Change Time Zone (Index) --------------------------- */

const changeTimezoneProps = (state: RootState) => {
  const { user } = state.user
  return {
    user,
  }
}

function changeTimezoneDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      updateUserProfileAction: updateUserProfileAction.STARTED,
    },
    dispatch
  )
}
export const ChangeTimezoneContainer = connect(
  changeTimezoneProps,
  changeTimezoneDispatch
)(ChangeTimezone)
