import API from 'api'

import * as api from '__generated__/api-types-and-hooks'

class Calendar_API {
  getUserConnectors = (connectorName): Promise<any> => {
    return API.fetch(api['GetUserConnectorsDocument'], { connectorName })
  }
  getUserCalendarEvents = (calendarName, timeMax, timeMin, tenantId): Promise<any> => {
    return API.fetch(api['GetUserCalendarEventsDocument'], {
      calendarName,
      timeMax,
      timeMin,
      tenantId,
    })
  }
  getOAuthUrl = (connectorName) => {
    return API.fetch(api['GetOAuthUrlDocument'], { connectorName })
  }
  getCalendlyUser = () => {
    return API.fetch(api['GetCalendlyUserDocument'], null)
  }
  deleteCalendarEvent = (calendarEvent) => {
    return API.fetch(api['DeleteCalendarEventDocument'], { input: calendarEvent })
  }
  subscribeCalendlyWebhook = ({ tenantId = undefined, email = undefined }): Promise<any> => {
    return API.fetch(api['SubscribeCalendlyWebhookDocument'], { tenantId, email })
  }
  subscribeGoogleCalendarWebhook = (tenantId): Promise<any> => {
    return API.fetch(api['SubscribeGoogleCalendarWebhookDocument'], { tenantId })
  }
  getBoAdvisors = (tenantId = undefined, userId = undefined): Promise<any> => {
    return API.fetch(api['GetBoAdvisorsDocument'], { tenantId, userId })
  }
  getUserMeeting = (advisorId): Promise<any> => {
    return API.fetch(api['GetUserMeetingDocument'], { advisorId })
  }
  deleteGoogleCalendarEvent = (payload): Promise<any> => {
    return API.fetch(api['DeleteGoogleCalendarEventDocument'], { input: payload })
  }
  updateGoogleCalendarEvent = (payload): Promise<any> => {
    return API.fetch(api['UpdateGoogleCalendarEventDocument'], { input: payload })
  }
  cancelOutlookEvent = (payload): Promise<any> => {
    return API.fetch(api['CancelOutlookEventDocument'], { input: payload })
  }
  getMeetingTypes = ({ tenantId }) => {
    return API.fetch(api['GetMeetingTypesDocument'], { tenantId })
  }
  setUserAvailability = (payload) => {
    return API.fetch(api['SetUserAvailabilityDocument'], { input: payload })
  }
  getUserAvailability = (payload) => {
    return API.fetch(api['GetUserAvailabilityDocument'], { input: payload })
  }

  setUserMeeting = (payload) => {
    return API.fetch(api['SetUserMeetingDocument'], { input: payload })
  }
  updateUserCalendarEvent = (payload) => {
    return API.fetch(api['UpdateUserCalendarEventDocument'], { input: payload })
  }
  cancelUserCalendarEvent = (payload) => {
    return API.fetch(api['CancelUserCalendarEventDocument'], { input: payload })
  }
  getUserAvailabilityHours = () => {
    return API.fetch(api['GetUserAvailabilityHoursDocument'], null)
  }
  updateUserAvailability = (payload) => {
    return API.fetch(api['UpdateUserAvailabilityDocument'], { input: payload })
  }
  getAdvisorProfile = (payload: { advisorId: string; tenantId: string }) => {
    return API.fetch(api['GetAdvisorProfileDocument'], { input: payload })
  }
  getUserMeetingById = (payload) => {
    return API.fetch(api['GetUserMeetingByIdDocument'], { input: payload })
  }
}
// eslint-disable-next-line
export default new Calendar_API()
