import React from 'react'
import history from 'utils/history'
import Button from 'components/Common/Button'

export enum IDiaLogueType {
  SaveChanges,
  UpdatedIndustry,
}

const Dialogue = ({
  path,
  dialogueType,
  onSubmit,
  showModal,
  setActiveTab,
  setShowModal,
  disableSaveButton,
  setIsFormDataChanged,
}) => {
  const redirectToPath = () => {
    if (IDiaLogueType.UpdatedIndustry === dialogueType) {
      setShowModal(false)
      return
    }
    const splitLocation = path.split('/')
    setActiveTab(splitLocation[5])
    setIsFormDataChanged(false)
    history.push(`${path}`)
  }
  return (
    <div>
      <div className="flex flex-row justify-between items-center px-5 pt-8 pb-10">
        <label className="font-primary text-primary-text text-lg font-normal">
          {IDiaLogueType.UpdatedIndustry === dialogueType ? (
            <>
              Industry value has been changed, which will remove the assessments data.
              <br />
              Are you sure, you want to save the changes?
            </>
          ) : (
            <>Save any changes you made before leaving?</>
          )}
        </label>
      </div>
      <div className="mt-10 pb-4 px-5">
        <div className="grid grid-cols-12">
          <div className="xs:hidden sm:inline-grid sm:col-span-7"></div>
          <div className="xs:col-span-6 sm:col-span-3 pr-1">
            {/* check-button -- clean up custom styles */}
            <Button
              label="Don’t Save"
              variant="secondary"
              className="!outline-0 active:border-0 focus:border-0 w-full"
              onClick={() => redirectToPath()}
            />
          </div>
          <div className="xs:col-span-6 sm:col-span-2">
            <Button
              disabled={disableSaveButton}
              label="Save"
              variant="primary"
              className="w-full"
              onClick={onSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

Dialogue.defaultProps = {
  path: '',
  setActiveTab: '',
  disableSaveButton: '',
  setIsFormDataChanged: () => {},
}

export default Dialogue
