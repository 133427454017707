import React, { useEffect, useState } from 'react'

import useFormContainer from 'hooks/useFormContainer'
import { useForm } from 'react-hook-form'
import { orderBy } from 'lodash'
import { FORM } from 'config'

import OwnerAssignment from 'components/Admin/UserManagement/OwnerAssignment'
import InputTextButton from 'components/Common/InputTextButton'
import InputField from 'components/Common/InputField'
import Loader from 'components/Loaders'

const AssignOwnerToAdvisor = ({
  height,
  owners,
  padding,
  onSubmit,
  firstName,
  isUserCreation,
  assignedOwnerList,
  isUserProfileFormSet,
}) => {
  const [allFilteredOwners, setAllFilteredOwners]: any = useState([])
  const [filteredAssignedOwners, setFilteredAssignedOwners]: any = useState([])
  const [assignedOwners, setAssignedOwners]: any = useState()
  const [allOwners, setAllOwners]: any = useState()

  const methods = useForm({
    mode: 'all',
  })

  const { register, handleSubmit, setValue } = methods

  useEffect(() => {
    register('owners')
  }, [register])

  useEffect(() => {
    setValue(
      'owners',
      filteredAssignedOwners?.map((owner) => owner.value)
    )
  }, [filteredAssignedOwners, setValue])

  useEffect(() => {
    setFilteredAssignedOwners(assignedOwnerList || [])
    setAssignedOwners(assignedOwnerList || [])
    setAllFilteredOwners(owners)
    setAllOwners(owners)

    return () => {
      setFilteredAssignedOwners([])
      setAllFilteredOwners([])
      setAssignedOwners([])
      setAllOwners([])
    }
  }, [assignedOwnerList, owners])

  const addOwner = (owner) => {
    const isAlreadyAdded = filteredAssignedOwners?.find((user) => user?.value === owner.value)
    if (isAlreadyAdded) return
    owner.isNew = true
    let sortedClient = [owner, ...filteredAssignedOwners]
    sortedClient = orderBy(sortedClient, ['lastName'], ['asc'])

    setFilteredAssignedOwners(sortedClient)
    setAssignedOwners(sortedClient)
  }

  const removeOwner = (owner) => {
    const owners = filteredAssignedOwners?.filter((item) => item.value !== owner.value)
    setAssignedOwners(owners)
    setFilteredAssignedOwners(owners)
  }

  const getAllFilteredOwner = (e) => {
    const searchValue = e.target.value.toLowerCase()
    if (searchValue) {
      let data = []
      data = allOwners.filter(
        (item: any) =>
          item.label.toLowerCase().includes(searchValue) ||
          item.email.toLowerCase().includes(searchValue)
      )
      setAllFilteredOwners(data)
    } else setAllFilteredOwners(owners)
  }

  const getAssignedFilterOwner = (e) => {
    const searchValue = e.target.value.toLowerCase()
    if (searchValue) {
      let data = []
      data = assignedOwners?.filter(
        (item: any) =>
          item.label.toLowerCase().includes(searchValue) ||
          item.email.toLowerCase().includes(searchValue)
      )
      setFilteredAssignedOwners(data)
    } else setFilteredAssignedOwners(assignedOwners)
  }

  useFormContainer(FORM.OWNERS, methods)

  return (
    <>
      {!isUserProfileFormSet && !isUserCreation ? (
        <Loader loader="ClientAssignment" />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={`${padding} bg-white inline-flex w-full px-7`}>
            <label className="text-2xl font-primary text-primary-text w-full">
              Client Assignment
            </label>

            <div className="inline-flex justify-end font-primary text-base font-semibold text-silver-darker w-full items-center">
              <span className="">
                <label>Assigned: </label>
                <label className="text-lg font-primary text-black-light font-normal">
                  {assignedOwners?.length}
                </label>
              </span>
              <label className="ml-10">
                <label>Remaining: </label>
                <label className="text-lg font-primary text-black-light font-normal">
                  {allOwners?.length - assignedOwners?.length}
                </label>
              </label>
            </div>
          </div>

          <div className={`grid grid-cols-12 gap-3 bg-white px-7 ${padding ? ' pb-2' : 'pb-5'}`}>
            <div className={`col-span-6 left ${isUserCreation ? '' : 'flex flex-col	flex-1'}`}>
              <div className="grid-cols-12 relative mb-3 mt-2">
                <div className="inline-flex items-center">
                  <label className="font-primary text-primary-text font-semibold text-lg">
                    All Clients
                  </label>
                </div>
                <span className="absolute top-[-20px] right-[5%] w-full !mt-[-2px] ">
                  <InputTextButton
                    title={'Add All'}
                    fontSize="text-lg"
                    fontWeight="font-semibold"
                    action={() => {
                      allFilteredOwners.map((owner) => (owner.isNew = true))
                      setFilteredAssignedOwners([...allFilteredOwners])
                      setAssignedOwners([...owners])
                    }}
                  />
                </span>
              </div>
              <div className={`mt-1 ${isUserCreation ? '' : 'flex flex-col	flex-1'}`}>
                <div>
                  <InputField
                    name="search"
                    type={'search'}
                    onChange={getAllFilteredOwner}
                    placeholder="Filter by Client Name or Email"
                  />
                </div>
                <div
                  className={`border-[1px] border-primary-outline mt-2 pt-2 pl-2   ${height} ${
                    isUserCreation ? ' overflow-y-scroll' : 'flex flex-col	flex-1 overflow-y-auto'
                  }`}
                >
                  {allFilteredOwners?.map((owner, index) => {
                    return (
                      <OwnerAssignment
                        allFilteredOwners={allFilteredOwners}
                        filteredAssignedOwners={filteredAssignedOwners}
                        assignedOwners={assignedOwners}
                        removeOwner={removeOwner}
                        owner={owner}
                        index={index}
                        isAssignedOwner={false}
                        addOwner={addOwner}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
            <div className={`col-span-6 right ${isUserCreation ? '' : 'flex flex-col	flex-1'}`}>
              <div className="grid-cols-12 relative mb-3 mt-2">
                <div className="inline-flex items-center">
                  <label className="font-primary text-primary-text font-semibold text-lg">
                    {firstName ? `${firstName}’s Clients` : 'Assigned Clients'}
                  </label>
                </div>
                <span className="absolute top-[-20px] right-[5%] w-full font-semibold font-primary text-lg !mt-[-2px]">
                  <InputTextButton
                    title={'Remove All'}
                    fontSize="text-lg"
                    fontWeight="font-semibold"
                    action={() => {
                      setFilteredAssignedOwners([])
                      setAssignedOwners([])
                    }}
                  />
                </span>
              </div>
              <div className={`mt-1 ${isUserCreation ? '' : 'flex flex-col	flex-1'}`}>
                <div>
                  <InputField
                    name="search"
                    type={'search'}
                    onChange={getAssignedFilterOwner}
                    placeholder="Filter by Client Name or Email"
                  />
                </div>
                <div
                  className={`border-[1px] border-primary-outline mt-2 pt-2 pl-2 overflow-y-auto ${height} ${
                    isUserCreation ? 'overflow-y-scroll' : 'flex flex-col	flex-1 overflow-y-auto'
                  }`}
                >
                  {filteredAssignedOwners?.map((owner, index) => {
                    return (
                      <OwnerAssignment
                        allFilteredOwners={allFilteredOwners}
                        filteredAssignedOwners={filteredAssignedOwners}
                        assignedOwners={assignedOwners}
                        removeOwner={removeOwner}
                        owner={owner}
                        index={index}
                        isAssignedOwner={true}
                        addOwner={addOwner}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  )
}

AssignOwnerToAdvisor.defaultProps = {
  assignment: '',
  padding: 'pt-2',
  height: 'min-h-[68px] max-h-[50vh]',
  isUserCreation: false,
}

export default AssignOwnerToAdvisor
