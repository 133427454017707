import {
  GET_CLIENTS,
  GET_CLIENT_PROFILE,
  GET_ADVISORS_DASHBOARD,
  GET_TOP_ACTIVE_CLIENTS,
  GET_CLIENT_ACTIVITY_LOGS,
  GET_CLIENT_ACTIVITY_SCORE,
  GET_CLIENT_PROFILE_DETAILS,
  UPDATE_CLIENT_PROFILE_DETAIL,
  GET_CLIENT_EDIT_PROFILE_DETAIL,
  OPEN_CLIENT_DETAIL,
  GET_CLIENT_ASSESSMENTS,
  GET_CLIENT_ASSESSMENT_LIST,
  GET_CLIENT_CONNECTED_TOOLS,
  GET_ALL_TOOLS,
  GET_TACKLE_MEETING_EVENTS,
  SHOW_COMMENTS,
  DE_AUTHENTICATE_TOOLS,
  GET_MY_TOOLS,
  SET_AUTH_TOOLS_SUCCESS,
  GET_CLIENT_ATTESTATION_REPORT,
  RESET_CLIENTS,
  GET_TOOLS_BY_TYPE,
} from 'store/types'

export const getAdvisorDashboardAction = {
  STARTED: (payload: any) => ({ type: GET_ADVISORS_DASHBOARD.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: GET_ADVISORS_DASHBOARD.FULLFILLED, payload }),
  REJECTED: (payload: any) => ({ type: GET_ADVISORS_DASHBOARD.REJECTED, payload }),
}
export const getClientsAction = {
  STARTED: (payload: any) => ({ type: GET_CLIENTS.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: GET_CLIENTS.FULLFILLED, payload }),
  REJECTED: (payload: any) => ({ type: GET_CLIENTS.REJECTED, payload }),
}
export const getTopActiveClientsAction = {
  STARTED: (payload: any) => ({ type: GET_TOP_ACTIVE_CLIENTS.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: GET_TOP_ACTIVE_CLIENTS.FULLFILLED, payload }),
  REJECTED: (payload: any) => ({ type: GET_TOP_ACTIVE_CLIENTS.REJECTED, payload }),
}

//
export const getClientProfileAction = {
  STARTED: (payload: any) => ({ type: GET_CLIENT_PROFILE.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: GET_CLIENT_PROFILE.FULLFILLED, payload }),
  REJECTED: (payload: any) => ({ type: GET_CLIENT_PROFILE.REJECTED, payload }),
}

export const getClientActivityLogsAction = {
  STARTED: (payload: any) => ({ type: GET_CLIENT_ACTIVITY_LOGS.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: GET_CLIENT_ACTIVITY_LOGS.FULLFILLED, payload }),
  REJECTED: (payload: any) => ({ type: GET_CLIENT_ACTIVITY_LOGS.REJECTED, payload }),
}

export const getClientActivityScoreAction = {
  STARTED: (payload: any) => ({ type: GET_CLIENT_ACTIVITY_SCORE.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: GET_CLIENT_ACTIVITY_SCORE.FULLFILLED, payload }),
  REJECTED: (payload: any) => ({ type: GET_CLIENT_ACTIVITY_SCORE.REJECTED, payload }),
}

// GET_CLIENT_PROFILE_DETAILS
export const getClientProfileDetailsAction = {
  STARTED: (payload: any) => ({ type: GET_CLIENT_PROFILE_DETAILS.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: GET_CLIENT_PROFILE_DETAILS.FULLFILLED, payload }),
  REJECTED: (payload: any) => ({ type: GET_CLIENT_PROFILE_DETAILS.REJECTED, payload }),
}

export const updateClientProfileDetailAction = {
  STARTED: (payload: any) => ({ type: UPDATE_CLIENT_PROFILE_DETAIL.STARTED, payload }),
  FULLFILLED: () => ({ type: UPDATE_CLIENT_PROFILE_DETAIL.FULLFILLED }),
  REJECTED: () => ({ type: UPDATE_CLIENT_PROFILE_DETAIL.REJECTED }),
}

export const getClientEditProfileDetailAction = {
  STARTED: (payload: any) => ({ type: GET_CLIENT_EDIT_PROFILE_DETAIL.STARTED, payload }),
  FULLFILLED: (payload: any) => ({ type: GET_CLIENT_EDIT_PROFILE_DETAIL.FULLFILLED, payload }),
  REJECTED: (payload: any) => ({ type: GET_CLIENT_EDIT_PROFILE_DETAIL.REJECTED, payload }),
}

export const openClientDetailAction = (payload) => ({
  type: OPEN_CLIENT_DETAIL,
  payload,
})
/**
 * Fetch assessements for particular owner
 */
export const getAssessmentAction = {
  STARTED: (payload: any) => ({ type: GET_CLIENT_ASSESSMENTS.STARTED, payload }),
  FULLFILLED: (questions: any) => ({
    type: GET_CLIENT_ASSESSMENTS.FULLFILLED,
    payload: questions,
  }),
  REJECTED: () => ({ type: GET_CLIENT_ASSESSMENTS.REJECTED }),
}

/**
 * Fetch assessements list
 */
export const getAssessmentListAction = {
  STARTED: (payload: any) => ({ type: GET_CLIENT_ASSESSMENT_LIST.STARTED, payload }),
  FULLFILLED: (questions: any) => ({
    type: GET_CLIENT_ASSESSMENT_LIST.FULLFILLED,
    payload: questions,
  }),
  REJECTED: () => ({ type: GET_CLIENT_ASSESSMENT_LIST.REJECTED }),
}

/**
 * Fetch Connected Tools list
 */
export const getConnectedToolsAction = {
  STARTED: (payload: any) => ({ type: GET_CLIENT_CONNECTED_TOOLS.STARTED, payload }),
  FULLFILLED: (questions: any) => ({
    type: GET_CLIENT_CONNECTED_TOOLS.FULLFILLED,
    payload: questions,
  }),
  REJECTED: () => ({ type: GET_CLIENT_CONNECTED_TOOLS.REJECTED }),
}

export const getAllToolsAction = {
  STARTED: (payload: any) => ({ type: GET_ALL_TOOLS.STARTED, payload }),
  FULLFILLED: (payload: any) => ({
    type: GET_ALL_TOOLS.FULLFILLED,
    payload,
  }),
  REJECTED: () => ({ type: GET_ALL_TOOLS.REJECTED }),
}

export const getToolsByTypeAction = {
  STARTED: (payload: any) => ({ type: GET_TOOLS_BY_TYPE.STARTED, payload }),
  FULLFILLED: (payload: any) => ({
    type: GET_TOOLS_BY_TYPE.FULLFILLED,
    payload,
  }),
  REJECTED: () => ({ type: GET_TOOLS_BY_TYPE.REJECTED }),
}

export const getTackleMeetingEventsAction = {
  STARTED: (payload: any) => ({ type: GET_TACKLE_MEETING_EVENTS.STARTED, payload }),
  FULLFILLED: (payload: any) => ({
    type: GET_TACKLE_MEETING_EVENTS.FULLFILLED,
    payload,
  }),
  REJECTED: () => ({ type: GET_TACKLE_MEETING_EVENTS.REJECTED }),
}
export const showCommentsAction = (payload: any) => ({
  type: SHOW_COMMENTS,
  payload,
})
export const deauthenticateConnectorAction = {
  STARTED: (payload: any) => ({ type: DE_AUTHENTICATE_TOOLS.STARTED, payload }),
  FULLFILLED: (payload: any) => ({
    type: DE_AUTHENTICATE_TOOLS.FULLFILLED,
    payload,
  }),
  REJECTED: () => ({ type: DE_AUTHENTICATE_TOOLS.REJECTED }),
}

export const getMyToolsAction = {
  STARTED: (payload: any) => ({ type: GET_MY_TOOLS.STARTED, payload }),
  FULLFILLED: (payload: any) => ({
    type: GET_MY_TOOLS.FULLFILLED,
    payload,
  }),
  REJECTED: () => ({ type: GET_MY_TOOLS.REJECTED }),
}

export const toolConnectionSuccessAction = {
  STARTED: (payload: any) => ({ type: SET_AUTH_TOOLS_SUCCESS.STARTED, payload }),
  FULLFILLED: () => ({
    type: SET_AUTH_TOOLS_SUCCESS.FULLFILLED,
  }),
  REJECTED: () => ({ type: SET_AUTH_TOOLS_SUCCESS.REJECTED }),
}
export const getClientAttestationReportAction = {
  STARTED: (payload) => ({ type: GET_CLIENT_ATTESTATION_REPORT.STARTED, payload }),
  FULLFILLED: (payload) => ({
    type: GET_CLIENT_ATTESTATION_REPORT.FULLFILLED,
    payload,
  }),
  REJECTED: () => ({ type: GET_CLIENT_ATTESTATION_REPORT.REJECTED }),
  RESET: () => ({ type: GET_CLIENT_ATTESTATION_REPORT.RESET }),
}

export const resetClientsAction = () => ({
  type: RESET_CLIENTS,
})
