import moment from 'moment'
import sortBy from 'lodash/sortBy'
import pick from 'lodash/pick'

import history from 'utils/history'

import { call, put, takeLatest, select, all } from 'redux-saga/effects'
import {
  GET_ADVISORS_DASHBOARD,
  GET_CLIENTS,
  GET_CLIENT_ACTIVITY_LOGS,
  GET_CLIENT_ACTIVITY_SCORE,
  GET_CLIENT_PROFILE,
  GET_CLIENT_PROFILE_DETAILS,
  GET_CLIENT_EDIT_PROFILE_DETAIL,
  GET_TOP_ACTIVE_CLIENTS,
  UPDATE_CLIENT_PROFILE_DETAIL,
  GET_CLIENT_ASSESSMENTS,
  GET_CLIENT_ASSESSMENT_LIST,
  GET_CLIENT_CONNECTED_TOOLS,
  GET_ALL_TOOLS,
  GET_TACKLE_MEETING_EVENTS,
  DE_AUTHENTICATE_TOOLS,
  UPDATE_MY_TOOLS,
  GET_MY_TOOLS,
  SET_AUTH_TOOLS_SUCCESS,
  UPDATE_USER_CALENDAR_EVENT,
  CANCEL_USER_CALENDAR_EVENT,
  GET_CLIENT_ATTESTATION_REPORT,
  GET_TOOLS_BY_TYPE,
} from 'store/types'
import CalendarApi from 'api/calendar'
import ClientsApi from 'api/clients'
import {
  getClientActivityLogsAction,
  getClientActivityScoreAction,
  getClientProfileAction,
  getClientProfileDetailsAction,
  getClientEditProfileDetailAction,
  getAdvisorDashboardAction,
  getTopActiveClientsAction,
  getClientsAction,
  updateClientProfileDetailAction,
  getAssessmentListAction,
  getAssessmentAction,
  getAllToolsAction,
  getTackleMeetingEventsAction,
  getMyToolsAction,
  toolConnectionSuccessAction,
  deauthenticateConnectorAction,
  getClientAttestationReportAction,
  getToolsByTypeAction,
} from 'store/actions/clients'
import UserApi from 'api/user'
import AssessmentApi from 'api/assessment'
import { get, isEmpty, pickBy } from 'lodash'
import hookForms from 'utils/hookForms'
import { FORM, formKeys } from 'config'
import goalAPi from 'api/goal'
import _ from 'lodash'
import {
  getBusinessAndAssessmentResponseAction,
  getUserProfileAndAdvisorsAction,
} from 'store/actions/userManagement'
import { getUserGoalAction } from 'store/actions/owner/playbooks'
import { getAssessmentResponse } from 'utils/helper'
import { getGoalsSelector } from 'store/selectors/clients'
import { getConnectedToolsAction } from 'store/actions/clients'
import { getUserAssessments } from 'store/sagas/owner'
import { setFormData } from 'store/actions/form'
import {
  getUserCalendarEventsAction,
  getUserConnectorsAction,
  updateUserCalendarEventAction,
} from 'store/actions/calendar'
import { isSameObject } from 'utils/helper'
import { getUserProfileAction, updateMyToolsAction } from 'store/actions/user'
import { ampli } from 'ampli'
import { Tool, ToolType } from '__generated__/api-types-and-hooks'

function* getAdvisorDashboard(action) {
  try {
    const tenantId = yield select((state) => state.user?.tenantId)
    const payload = { ...action.payload, tenantId }

    let res = yield call(ClientsApi.getAdvisorDashboard, payload)
    res = JSON.parse(res?.getAdvisorDashboard.data)
    yield put(getAdvisorDashboardAction.FULLFILLED(res))
  } catch (error) {
    yield put(getAdvisorDashboardAction.REJECTED(error))
    console.log(error)
  }
}

function* getClients(action) {
  const tenantId = yield select((state) => state.user?.tenantId)

  try {
    const payload = { ...action.payload, tenantId }
    yield put(getClientsAction.FULLFILLED([]))
    let res = yield call(ClientsApi.getClients, payload)
    res = get(res, 'getClients.data')
    yield put(getClientsAction.FULLFILLED(res))
  } catch (error) {
    yield put(getClientsAction.REJECTED(error))
    console.log(error)
  }
}
function* getTopActiveClients(a) {
  try {
    const tenantId = yield select((state) => state.user?.tenantId)

    let res = yield call(ClientsApi.getTopActiveClients, { tenantId })
    const data = get(res.getTopActiveClients, 'data')
    yield put(getTopActiveClientsAction.FULLFILLED(data))
  } catch (error) {
    yield put(getTopActiveClientsAction.REJECTED(error))
    console.log(error)
  }
}

function* getClientProfile(action) {
  try {
    if (!action?.payload) return

    const res = yield call(UserApi.getUserProfile, action?.payload?.clientId)
    const userProfile = get(res, 'getMyProfile', {})

    const currentDate = moment().startOf('day')
    const totalDays = userProfile?.firstSigninDate
      ? currentDate.diff(moment(userProfile?.firstSigninDate).startOf('day'), 'days')
      : 0

    const clientProfile = {
      ...userProfile,
      totalDays,
    }

    yield put(getClientProfileAction.FULLFILLED(clientProfile))
  } catch (error) {
    console.log(error)
  }
}

function* getClientActivityLogs(action) {
  try {
    const { clientId } = action.payload
    const tenantId = yield select((state) => state.user?.tenantId)

    let activityLogs = yield call(ClientsApi.getActivityLogs, clientId, tenantId)
    activityLogs = get(activityLogs, 'getActivityLogs.data', [])

    if (activityLogs) {
      activityLogs = JSON.parse(activityLogs)
      yield put(getClientActivityLogsAction.FULLFILLED(activityLogs))
    }
  } catch (error) {
    console.log(error)
  }
}

function* getClientActivityScore(action) {
  try {
    const { clientId } = action?.payload

    const tenantId = yield select((state) => state.user?.tenantId)

    const res = yield call(ClientsApi.getOwnerActivityDetails, clientId, tenantId)
    let activityDetail = res?.getOwnerActivityDetails?.data
    if (activityDetail) {
      activityDetail = JSON.parse(activityDetail)
      yield put(getClientActivityScoreAction.FULLFILLED(activityDetail))
    }
  } catch (error) {
    console.log(error)
  }
}

function* getClientProfileDetails(action) {
  try {
    const type: any = 'initial'
    const tenantId = yield select((state) => state.user?.tenantId)
    const clientId = action?.payload?.clientId
    const userGoals = yield call(goalAPi.getUserGoals)
    yield put(getUserGoalAction.FULLFILLED(userGoals?.getUserGoals))

    const [userProfile, businessProfile, assessmentResponse] = yield all([
      call(getClientProfile, { payload: { clientId, tenantId } }),
      call(UserApi.getBusinessProfile, clientId),
      call(AssessmentApi.getUserAssessmentResponse, type, clientId),
    ])

    let userAssessmentsResponse: any = get(
      assessmentResponse,
      'getUserAssessmentResponse.data.assessmentResponse',
      ''
    )
    userAssessmentsResponse = userAssessmentsResponse ? JSON.parse(userAssessmentsResponse) : []

    const payload = {
      userProfile,
      businessProfile: businessProfile?.getBusinessProfile
        ? businessProfile?.getBusinessProfile
        : {},
      assessmentResponse: userAssessmentsResponse,
    }
    yield put(getClientProfileDetailsAction.FULLFILLED(payload))
  } catch (error) {
    console.log(error)
  }
}

function* getClientProfileDetail(action) {
  const { userId } = action.payload
  const tenantId = yield select((state) => state.user?.tenantId)

  try {
    yield all([
      put(
        getUserProfileAndAdvisorsAction.STARTED({
          tenantId,
          userId,
          setForms: [FORM.USER_PROFILE_FORM],
        })
      ),
      put(
        getBusinessAndAssessmentResponseAction.STARTED({
          setForms: [FORM.USER_MANAGEMENT_BUSINESS_FORM],
          userId,
          tenantId,
          type: 'initial',
        })
      ),
    ])

    const res = yield call(UserApi.getUserProfile, userId)

    let otherBusinessDetails = yield select((state) => state.user.businessProfile)

    const assessment = yield select((state) => state?.owner?.questions)
    let questions = yield select((state) => state?.owner?.assessmentResponse)

    const assessmentResponse = {}
    questions.assessmentResponse = questions?.assessmentResponse ? questions.assessmentResponse : []

    questions.assessmentResponse?.forEach((answer) => {
      assessmentResponse[answer.id] = answer?.value
    })

    const businessAssessment = {}
    const businessQuestions = assessment?.filter(
      (question) => question?.meta?.showToClientOtherDetails
    )

    for (let i = 0; i < businessQuestions.length; i++) {
      businessAssessment[businessQuestions[i].id] = assessmentResponse[businessQuestions[i]?.id]
    }

    otherBusinessDetails = {
      ...otherBusinessDetails,
      otherGoals: JSON.parse(otherBusinessDetails.otherGoals),
    }
    const otherBusinessDetail = { ...otherBusinessDetails, ...res.getMyProfile }
    if (action.payload?.setForms?.length > 0) {
      const { setForms } = action.payload
      // const form = hookForms.getForm(setForms[0])
      let businessOtherDetail = pickBy(otherBusinessDetail, (value, key) =>
        formKeys[setForms[0]].includes(key)
      )

      const goals = yield select(getGoalsSelector)

      goals?.forEach((goal) => {
        if (goal.label === businessOtherDetail.topGoal) {
          businessOtherDetail.topGoal = goal.value
        }
        return businessOtherDetail
      })

      yield put(
        setFormData({ form: setForms[0], data: { ...businessOtherDetail, ...businessAssessment } })
      )
      // form.reset({ ...businessOtherDetail })
    }
    yield put(getClientEditProfileDetailAction.FULLFILLED({ update: true }))
  } catch (error) {
    console.log(error)
  }
}

function* updateClientProfileDetail(action) {
  const { userId } = action.payload
  const form = yield select((state) => state.form)
  const tenantId = yield select((state) => state.user?.tenantId)

  try {
    const userProfileForm = hookForms.getForm(FORM.USER_PROFILE_FORM)
    const businessOtherDetail = hookForms.getForm(FORM.BUSINESS_OTHER_DETAILS)
    const userManagementBusinessForm = hookForms.getForm(FORM.USER_MANAGEMENT_BUSINESS_FORM)

    let userProfileFormValues = userProfileForm.getValues()
    const businessOtherDetailValues = businessOtherDetail.getValues()
    const businessFormValues = userManagementBusinessForm.getValues()

    const businessProfileFormValues = pickBy(businessFormValues, (value, key) =>
      formKeys[FORM.USER_MANAGEMENT_BUSINESS_FORM].includes(key)
    )

    const businessOtherDetailsFormValue = pickBy(businessOtherDetailValues, (value, key) =>
      formKeys[FORM.BUSINESS_OTHER_DETAILS].includes(key)
    )
    userProfileFormValues = pickBy(userProfileFormValues, (value, key) =>
      formKeys[FORM.PROFILE_FORM].includes(key)
    )
    let topGoal = pickBy(businessOtherDetailValues, (value, key) =>
      formKeys[FORM.TOPGOAL].includes(key)
    )
    const businessOtherDetails = pickBy(businessOtherDetailValues, (value, key) =>
      formKeys[FORM.BUSINESS_DETAILS].includes(key)
    )
    const otherGoals = pickBy(businessOtherDetailValues, (value, key) =>
      formKeys[FORM.OTHER_GOALS].includes(key)
    )

    let initialAssessmentOtherDetail = _.omit(
      businessOtherDetailValues,
      Object.keys(businessOtherDetailsFormValue)
    )

    let initialAssessmentFormValues = _.omit(
      businessFormValues,
      Object.keys(businessProfileFormValues)
    )

    initialAssessmentFormValues = {
      ...initialAssessmentFormValues,
      ...initialAssessmentOtherDetail,
    }

    let getAssessments = yield call(AssessmentApi.getAssessments, 'initial')

    const assessmentList = getUserAssessments(getAssessments, false)
    const questionId = assessmentList?.find((res) => res?.meta?.isTopGoal)?.id
    const topGoalData = { [questionId]: topGoal?.topGoal }

    initialAssessmentFormValues = { ...initialAssessmentFormValues, ...topGoalData }
    const businessProfile = {
      ...businessProfileFormValues,
      ...businessOtherDetails,
      otherGoals: JSON.stringify(otherGoals.otherGoals),
    }

    let assessmentResponse: any = []

    assessmentResponse = getAssessmentResponse({
      keys: initialAssessmentFormValues,
      assessment_questions: assessmentList,
    })
    userProfileFormValues.mobileContactNumber = userProfileFormValues.mobileContactNumber.replace(
      /\D/g,
      ''
    )
    const apiCalls: any = []
    if (
      !isSameObject(
        userProfileFormValues,
        pick(form.USER_PROFILE_FORM, ['firstName', 'lastName', 'mobileContactNumber'])
      )
    )
      apiCalls.push(
        call(UserApi.updateUserProfile, {
          ...userProfileFormValues,
          userId,
          tenantId,
        })
      )

    const otherData = { ...businessOtherDetails, ...otherGoals }
    if (
      !isSameObject(businessFormValues, form.USER_MANAGEMENT_BUSINESS_FORM) ||
      !isSameObject(otherData, form.BUSINESS_OTHER_DETAILS)
    )
      apiCalls.push(
        call(UserApi.updateBusinessProfile, {
          ...otherData,
          ...businessProfile,
          id: userId,
          tenantId,
        })
      )
    if (apiCalls.length > 0) yield all(apiCalls)
    yield call(AssessmentApi.saveAssessmentResponse, {
      userId,
      type: 'initial',
      tenantId: tenantId,
      assessmentResponse: JSON.stringify(assessmentResponse),
    })

    const payload = { clientId: userId }
    yield call(getClientProfileDetails, { payload })
    yield put(updateClientProfileDetailAction.FULLFILLED())
  } catch (error) {
    yield put(updateClientProfileDetailAction.REJECTED())
    console.log(error)
  }
}

function* getAssessmentList() {
  try {
    const user = yield select((state) => ({
      userId: state.clients.client.profile.id,
      tenantId: state.user?.tenantId,
    }))
    let getAssessments = yield call(AssessmentApi.getAssessmentList, user.userId, user.tenantId)
    yield put(getAssessmentListAction.FULLFILLED(getAssessments.listAssessments.data))
  } catch (error) {
    console.log('get assessment question error : ', error)
  }
}

function* getAssessment(action) {
  try {
    const { type, userId } = action.payload
    let getAssessments = yield call(AssessmentApi.getAssessments, type)
    getAssessments = getUserAssessments(getAssessments, false)
    getAssessments = sortBy(getAssessments, (q) => q.previous)
    let questions: any = { assessmentResponse: [] }
    const res = yield call(AssessmentApi.getUserAssessmentResponse, type, userId)
    questions = get(res, 'getUserAssessmentResponse.data', '')
    const assessmentResponse = {}
    questions.assessmentResponse = get(questions, 'assessmentResponse')
      ? JSON.parse(questions.assessmentResponse)
      : []
    questions.assessmentResponse.forEach((answer) => {
      assessmentResponse[answer.id] = answer.value
    })

    yield put(
      getAssessmentAction.FULLFILLED({
        [type]: {
          questions: getAssessments,
          assessmentResponse: questions,
        },
      })
    )
  } catch (error) {
    console.log('get assessment question error : ', error)
  }
}

function* getConnectedTools() {
  try {
    const user = yield select((state) => ({
      userId: get(state?.clients, 'client.profile.id', '') || get(state.user, 'user.id', ''),
      tenantId: state.user?.tenantId,
    }))
    let getConnectedTools = yield call(
      ClientsApi.getClientConnectedTools,
      user.userId,
      user.tenantId
    )
    let data = get(getConnectedTools, 'getUserIntegrations.data')
    if (data) data = JSON.parse(data)
    yield put(getConnectedToolsAction.FULLFILLED(data))
  } catch (error) {
    console.log('get connected tools error : ', error)
  }
}

function* getAllTools(action) {
  try {
    const tenantId = yield select((state) => state.user?.tenantId)

    const payload = { ...action.payload, tenantId }
    const res = yield call(ClientsApi.getAllTools, payload)
    let data = get(res, 'getAllTools.data')
    if (data) data = data as Tool[]
    yield put(getAllToolsAction.FULLFILLED(data))
  } catch (error) {
    console.log('get connected tools error : ', error)
  }
}

function* getTackleMeeting(payloadData?: any) {
  try {
    const tenantId = yield select((state) => state.user?.tenantId)

    const payload = {
      tenantId,
      filter: payloadData?.payload?.filter.trim(),
      isPaginated: false,
      ownerId: payloadData?.payload?.ownerId,
    }

    let data = yield call(ClientsApi.getTackleMeetings, payload)

    yield put(
      getTackleMeetingEventsAction.FULLFILLED({
        data: get(data, 'getTackleMeetings.data', []),
        filter: payloadData?.payload?.filter.trim(),
      })
    )
  } catch (error) {
    console.log('get GoTackle meetings error : ', error)
  }
}

function* updateUserCalendarEvent(action) {
  try {
    const tenantId = yield select((state) => state.user.tenantId)
    const { updateCalendarMeetingPayload, getCalendarEventPayload, filter, updateCommentPayload } =
      action.payload

    let res = yield call(
      CalendarApi.updateUserCalendarEvent,
      updateCalendarMeetingPayload ? updateCalendarMeetingPayload : updateCommentPayload
    )

    if (action?.payload?.filter) {
      yield put(getTackleMeetingEventsAction.STARTED({ filter }))
    }
    if (getCalendarEventPayload) {
      yield put(getUserCalendarEventsAction.STARTED({ ...getCalendarEventPayload, tenantId }))
    }
    if (updateCommentPayload) {
      yield put(updateUserCalendarEventAction.FULLFILLED(res?.updateUserCalendarEvent?.data))
    }
  } catch (error) {
    console.log('comment updating error : ', error)
  }
}

function* deauthenticateConnector(action) {
  const tenantId = yield select((state) => state.user?.tenantId)

  yield call(ClientsApi.deauthenticateConnector, action.payload, tenantId)
  yield put(deauthenticateConnectorAction.FULLFILLED(action))
  ampli.toolDisconnected({ name: action.payload })
  yield call(getMyTools)
  yield call(getToolsByType, { payload: { tenantId, toolType: ToolType.Calendar } })
  yield call(getAllTools, { payload: { tenantId, includeDetails: true } })
  yield put(getUserConnectorsAction.STARTED(undefined))
}

function* cancelUserCalendarEvent(action) {
  const tenantId = yield select((state) => state.user?.tenantId)
  try {
    yield call(CalendarApi.cancelUserCalendarEvent, { ...action.payload, tenantId })
    const payload = {
      filter: 'upcoming',
    }
    yield call(getTackleMeeting, { payload })
  } catch (error) {
    console.log('cannot cancel meeting error : ', error)
  }
}
function* updateMyTools(action) {
  try {
    const tenantId = yield select((state) => state.user?.tenantId)
    const payload = { ...action.payload, tenantId }
    const res = yield call(ClientsApi.updateMyTools, payload)
    yield put(updateMyToolsAction.FULLFILLED(res))
    if (action.payload.ownerExperience) {
      const response = yield call(UserApi.getUserProfile)

      const user = response?.getMyProfile
      yield put(getUserProfileAction.FULLFILLED({ user }))
    } else {
      yield call(getMyTools)
    }
  } catch (error) {
    console.log('error while updating: ', error)
  }
}
function* getMyTools() {
  try {
    const payload = yield select((state) => ({
      tenantId: state.user.tenantId,
      userId: state.user.user.id,
    }))
    const res = yield call(ClientsApi.getMyTools, payload.userId, payload.tenantId)
    let data = get(res, 'getUserTools.data')
    if (data) data = data as Tool[]
    yield put(getMyToolsAction.FULLFILLED(data))
  } catch (error) {
    console.log('error while updating: ', error)
  }
}

function* toolConnectionSuccess(action) {
  try {
    const play = yield select((state) => state.play.play)
    const tenantId = yield history.location.pathname.split('/')[2]
    yield call(
      ClientsApi.toolConnectionSuccess,
      action.payload.connectorName,
      tenantId,
      action.payload.playId
    )
    const sessionToken = localStorage.getItem('sessionToken')

    yield call(UserApi.logActivity, {
      action: `Integrated ${action.payload.connectorName}`,
      logStatus: '',
      accessToken: sessionToken,
      tenantId,
      showClientActivity: true,
      type: get(play, 'id', ''),
    })
    ampli.toolConnected({ name: action.payload.connectorName, playId: action.payload.playId })
    yield put(toolConnectionSuccessAction.FULLFILLED())
  } catch (error) {
    console.log('error while updating: ', error)
  }
}
function* getToolsByType(action) {
  try {
    const tenantId = yield select((state) => state.user?.tenantId)

    const payload = { ...action.payload, tenantId }
    const res = yield call(ClientsApi.getToolsByType, payload)
    let data = get(res, 'getToolsByType.data')
    if (data) data = data as Tool[]
    yield put(getToolsByTypeAction.FULLFILLED(data))
  } catch (error) {
    console.log('get connected tools error : ', error)
  }
}

function* getClientAttestationReport(action) {
  try {
    const clientReport = yield select((state) => state.clients.clientReport)

    const tenantId = yield select((state) => state.user?.user?.tenantId?.[0])
    let lastEvaluatedKey = undefined

    if (action?.payload?.viewMore) {
      lastEvaluatedKey = get(clientReport, 'lastEvaluatedKey')
      if (isEmpty(lastEvaluatedKey)) return
    }

    const payload = {
      tenantId,
      pageSize: 10,
      filter: action?.payload?.filter,
      lastEvaluatedKey: lastEvaluatedKey,
      month: moment().format('MM'),
      year: moment().format('YYYY'),
    }
    const response = yield call(ClientsApi.getAttestationReport, payload)
    yield put(getClientAttestationReportAction.FULLFILLED(response?.getAttestationReports?.data))
  } catch (error) {
    console.log('error while updating: ', error)
  }
}

/// /////////// Watchers ///////////////////////
export function* watcherClients() {
  yield takeLatest(GET_ADVISORS_DASHBOARD.STARTED, getAdvisorDashboard)
  yield takeLatest(GET_TOP_ACTIVE_CLIENTS.STARTED, getTopActiveClients)
  yield takeLatest(GET_CLIENT_PROFILE.STARTED, getClientProfile)
  yield takeLatest(GET_CLIENT_ACTIVITY_LOGS.STARTED, getClientActivityLogs)
  yield takeLatest(GET_CLIENT_ACTIVITY_SCORE.STARTED, getClientActivityScore)
  yield takeLatest(GET_CLIENT_PROFILE_DETAILS.STARTED, getClientProfileDetails)
  yield takeLatest(GET_CLIENTS.STARTED, getClients)
  yield takeLatest(GET_CLIENT_EDIT_PROFILE_DETAIL.STARTED, getClientProfileDetail)
  yield takeLatest(UPDATE_CLIENT_PROFILE_DETAIL.STARTED, updateClientProfileDetail)
  yield takeLatest(GET_CLIENT_ASSESSMENTS.STARTED, getAssessment)
  yield takeLatest(GET_CLIENT_ASSESSMENT_LIST.STARTED, getAssessmentList)
  yield takeLatest(GET_CLIENT_CONNECTED_TOOLS.STARTED, getConnectedTools)
  yield takeLatest(GET_ALL_TOOLS.STARTED, getAllTools)
  yield takeLatest(GET_TACKLE_MEETING_EVENTS.STARTED, getTackleMeeting)
  yield takeLatest(UPDATE_USER_CALENDAR_EVENT.STARTED, updateUserCalendarEvent)
  yield takeLatest(DE_AUTHENTICATE_TOOLS.STARTED, deauthenticateConnector)
  yield takeLatest(CANCEL_USER_CALENDAR_EVENT.STARTED, cancelUserCalendarEvent)
  yield takeLatest(UPDATE_MY_TOOLS.STARTED, updateMyTools)
  yield takeLatest(GET_MY_TOOLS.STARTED, getMyTools)
  yield takeLatest(SET_AUTH_TOOLS_SUCCESS.STARTED, toolConnectionSuccess)
  yield takeLatest(GET_TOOLS_BY_TYPE.STARTED, getToolsByType)
  yield takeLatest(GET_CLIENT_ATTESTATION_REPORT.STARTED, getClientAttestationReport)
}
