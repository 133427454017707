import React, { useState } from 'react'
import { ChannelList } from 'stream-chat-react'
import { ChatIcon } from 'components/Common/SvgIcons'
import InputField from 'components/Common/InputField'
import SearchIcon from 'components/Common/SvgIcons/SearchIcon'
import { MessagingChannelList, MessagingChannelPreview } from 'components/Chat/components'
import { useUnreadContext } from 'components/Chat/contexts/UnreadContext'
import { Channel } from 'stream-chat'
import { DefaultStreamChatGenerics } from 'components/Chat/types'
import { useTenantId } from 'utils/useTenantId'

import { Tooltip } from 'react-tooltip'
import { ToolTipWrapper } from 'components/Calendar/EventForm/style'
export const getTotalChatUnreadCount = (channels: Channel<DefaultStreamChatGenerics>[]) => {
  return channels.map((channel) => channel.countUnread()).reduce((total, count) => total + count, 0)
}

const ChannelListHeader = ({
  userId,
  usersAssessmentResponseByQuestion,
  setShowChannelCreate,
  showChannelCreate,
  setShowChannelPreview,
  showChannelPreview,
  chatUnreadMessageCountAction,
  chatUnreadMessageCount,
}) => {
  const tenantId = useTenantId()

  const { setChatsUnreadCount, channelMessageCount, setChannelMessageCount } = useUnreadContext()
  const [channelFilters]: any = useState({
    type: 'messaging',
    $and: [{ team: { $in: [tenantId] } }, { members: { $in: [userId] } }],
  })

  const [searchChannel, setSearchChannel] = useState('')
  const [triggerSearch, setTriggerSearch] = useState(false)

  const customRenderFilter = (channels) => {
    try {
      setChatsUnreadCount(getTotalChatUnreadCount(channels))

      channels.forEach((channel) => (channelMessageCount[channel.cid] = channel.countUnread()))
      setChannelMessageCount(channelMessageCount)

      return channels
    } catch (err) {
      return channels
    }
  }

  const searchQueryHandler = (e) => {
    if (e.key === 'Enter') {
      setSearchChannel(searchChannel)
    }
  }

  return (
    <div
      className={`messaging__sidebar xs:
    ${showChannelCreate ? 'hidden' : 'col-span-12'}
    mdl:col-span-4 mdl:block xl:col-span-3 `}
    >
      <div className="pr-5">
        <div className="flex items-center justify-between mb-3">
          <span className="flex items-center justify-between">
            <label className="text-2xl font-primary text-primary-text mr-3">Messages</label>
            <span className="mt-[1px] inline-flex justify-center items-center bg-primary-error text-white w-[26px] h-[22px] rounded-3xl text-xs font-bold">
              {chatUnreadMessageCount}
            </span>
          </span>

          <span
            onClick={() => setShowChannelCreate(!showChannelCreate)}
            className="cursor-pointer mdl:flex mdl:relative mdl:items-center mdl:justify-between"
            data-tooltip-id="tooltip-new-message"
            data-tooltip-content="Compose New Message"
          >
            <ToolTipWrapper>
              <Tooltip id="tooltip-new-message" place="left" />
            </ToolTipWrapper>
            <ChatIcon
              className={`${showChannelCreate ? 'fill-primary-disabled' : 'fill-primary'}`}
            />
          </span>
        </div>
        <div className={` flex justify-between my-5 items-center xs:pb-3`}>
          <InputField
            type={'search'}
            placeholder="Filter"
            className={`${showChannelCreate ? '' : 'xs:bg-white'}  xs:mt-0 rounded-full w-full`}
            name="search"
            value={searchChannel}
            onChange={(e) => {
              setSearchChannel(e.target.value)
              if (e.target.value === '') {
                setSearchChannel('')
              }
            }}
            disabled={showChannelCreate}
            onKeyPress={searchQueryHandler}
          />
          <div
            onClick={() => {
              setTriggerSearch(!triggerSearch)
              setSearchChannel(searchChannel)
            }}
          >
            <SearchIcon
              className={` ml-2 ${showChannelCreate ? 'fill-primary-disabled' : 'fill-primary'}`}
            />
          </div>
        </div>
      </div>

      <span
        className={`xs:${
          !showChannelPreview ? 'col-span-12' : 'hidden'
        } mdl:block mdl:col-span-12 ${showChannelCreate ? 'pointer-events-none' : ''} `}
        onClick={() => setShowChannelPreview(!showChannelPreview)}
        aria-disabled={showChannelCreate}
      >
        <ChannelList
          channelRenderFilterFn={customRenderFilter}
          filters={channelFilters}
          List={MessagingChannelList}
          Preview={(props) => (
            <MessagingChannelPreview
              {...props}
              searchChannel={searchChannel}
              showChannelCreate={showChannelCreate}
              setShowChannelCreate={setShowChannelCreate}
              setShowChannelPreview={setShowChannelPreview}
              usersAssessmentResponseByQuestion={usersAssessmentResponseByQuestion}
              chatUnreadMessageCountAction={chatUnreadMessageCountAction}
            />
          )}
        />
      </span>
    </div>
  )
}

export default ChannelListHeader
