import React from 'react'
import { AppFC } from 'types'

import GoTackleLogoIconOnly from 'assets/svgs/GoTackleLogoIconOnly.svg'

interface IProfilePictureProps {
  src?: string
}

const ProfilePicture: AppFC<IProfilePictureProps> = ({ src }) => {
  return (
    <div className="w-[122px] h-[122px] flex-shrink-0 rounded-full bg-white flex items-center justify-center">
      {src ? (
        <img
          src={src}
          className="object-contain bg-center w-full h-full rounded-full"
          alt="profile-pic"
        />
      ) : (
        <div className="h-full w-full flex justify-center items-center p-5">
          <img src={GoTackleLogoIconOnly} alt="go-tackle-logo-icon-only" className="w-full" />
        </div>
      )}
    </div>
  )
}

export default ProfilePicture
