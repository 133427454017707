import moment from 'moment'
import { createSelector } from 'reselect'
import { compact, get, join, orderBy } from 'lodash'
import { sortList } from 'utils/helper'

const getBusinessDetails = (state) => state.userManagement.businesses
const getAdvisorsDetails = (state) => state.userManagement.advisorsAccountDetails
const getAdvisors = (state) => state.userManagement.advisors
const getBoAdvisors = (state) => state.calendar.boAdvisors
const getSelectedClient = (state) => state.clients.assignedOwnerList
export const getAssessmentQuestion = (state) => state.owner.questions

export const getBusinesses = createSelector([getBusinessDetails], (businesses) => {
  try {
    let list: any = []

    if (businesses) {
      businesses = businesses.list

      if (businesses && businesses?.length === 0) {
        return []
      }

      businesses?.forEach((obj) => {
        let advisors = ''
        if (obj?.advisors && obj?.advisors?.length > 0) {
          advisors = obj.advisors.map((advisor) => advisor.name).join(', ')
        }

        let row = {
          name: obj.name || '',
          businessName: obj.businessName || '',
          createdAt: obj?.createdAt ? new Date(obj.createdAt) : null,
          created: obj.createdAt ? moment(obj.createdAt).format('LL') : '',
          activated: obj?.status || '',
          updated: obj.updatedAt ? moment(obj.updatedAt).format('LL') : '',
          advisors: advisors,
          userId: obj.id || '',
          email: obj.email,
          status: obj?.status || '',
        }
        list.push(row)
      })

      return list
    } else {
      return []
    }
  } catch (err: any) {
    console.log(err.message)
    return []
  }
})

export const getAdvisorsAccountDetails = createSelector(
  [getAdvisorsDetails],
  (advisorsAccountDetails) => {
    try {
      let list: any = []

      if (advisorsAccountDetails) {
        advisorsAccountDetails = advisorsAccountDetails.list

        if (advisorsAccountDetails && advisorsAccountDetails?.length === 0) {
          return []
        }
        advisorsAccountDetails?.forEach((obj) => {
          let row = {
            name: obj.firstName + ' ' + obj.lastName || '',
            businessName: obj.businessName || '',
            createdAt: obj?.createdAt ? new Date(obj.createdAt) : null,
            created: obj.createdAt ? moment(obj.createdAt).format('LL') : '',
            activated: obj?.status || '',
            email: obj?.email || '',
            role: obj?.role || '',
            userId: obj?.id,
            updated: obj.updatedAt ? moment(obj.updatedAt).format('LL') : '',
            status: obj?.status || '',
          }
          list.push(row)
        })
        return list
      } else {
        return []
      }
    } catch (err: any) {
      console.log(err.message)
      return []
    }
  }
)

export const getAdvisorsListSelector = createSelector([getAdvisors], (advisors) => {
  try {
    if (advisors && advisors?.list) {
      let list: any = []

      if (advisors && advisors?.length === 0) {
        return []
      }

      list.push({
        label: 'All',
        value: 'all',
      })

      advisors?.list?.forEach((obj) => {
        let row = {
          label: get(obj, 'firstName', '') + ' ' + get(obj, 'lastName', '') || '',
          value: get(obj, 'firstName', '') + ' ' + get(obj, 'lastName', '') || '',
        }
        list.push(row)
      })
      sortList(list)

      return list
    } else {
      return []
    }
  } catch (err: any) {
    console.log(err.message)
    return []
  }
})

export const getBoAdvisorsList = createSelector([getBoAdvisors], (boAdvisors) => {
  try {
    if (boAdvisors && boAdvisors.length) {
      const advisorList = boAdvisors?.map((advisor) => ({
        label: advisor.firstName + ' ' + advisor.lastName || '',
        value: advisor.id || '',
      }))
      return advisorList
    }
    return
  } catch (err: any) {
    console.log(err.message)
    return []
  }
})

export const getAssessmentsSelector = createSelector([getAssessmentQuestion], (assessment) => {
  const lastItem = assessment.pop()
  assessment.unshift(lastItem)
  return assessment
    .filter((question) => question?.meta.showToBusinessProfile)
    .sort((a, b) => a?.meta?.sequenceOrder - b?.meta?.sequenceOrder)
})

export const getAllClient = createSelector([getBusinessDetails], (businesses) => {
  const sortedClients = orderBy(get(businesses, 'list', []), ['lastName'], ['asc'])

  let clients = sortedClients.map((client) => ({
    label: join(compact([client.firstName, client.lastName]), ' '),
    lastName: get(client, 'lastName', ''),
    value: client.id,
    email: get(client, 'email', ''),
  }))

  return clients
})

export const getSelectedClients = createSelector([getSelectedClient], (assignedOwnerList) => {
  try {
    if (assignedOwnerList && assignedOwnerList.length) {
      const sortedClients = orderBy(assignedOwnerList, ['lastName'], ['asc'])

      const clients = sortedClients.map((client) => ({
        label: join(compact([client.firstName, client.lastName]), ' '),
        lastName: get(client, 'lastName', ''),
        value: client.id || '',
        email: get(client, 'email', ''),
      }))
      return clients
    }
    return
  } catch (err: any) {
    console.log(err.message)
    return []
  }
})

export const getRemainingClients = createSelector(
  [getBusinessDetails, getSelectedClient],
  (businesses, assignedOwnerList) => {
    try {
      const remainingClients = get(businesses, 'list', []).filter(
        (client) => !assignedOwnerList.some((assignedOwner) => assignedOwner.id === client.id)
      )
      const sortedClients = orderBy(remainingClients, ['lastName'], ['asc'])
      let clients = sortedClients.map((client) => ({
        label: join(compact([client.firstName, client.lastName]), ' '),
        lastName: get(client, 'lastName', ''),
        value: client.id,
        email: get(client, 'email', ''),
      }))

      return clients
    } catch (err: any) {
      console.log(err.message)
      return []
    }
  }
)
