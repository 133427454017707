import { useMemo } from 'react'

const useGetChatCountLabel = (chatUnreadMessageCount) => {
  const messageLabel = useMemo(
    () => `${chatUnreadMessageCount} Unread Message${chatUnreadMessageCount > 1 ? 's' : ''}`,
    [chatUnreadMessageCount]
  )
  return messageLabel
}

export default useGetChatCountLabel
