import React from 'react'
import { GoTackleBrandIcon } from 'components/Common/SvgIcons'

const TackleAvatarLogo = ({ className, padding }) => {
  return (
    <div>
      <span className={`rounded-full inline-flex justify-center items-center ${className}`}>
        <GoTackleBrandIcon className={`${padding} fill-white w-full h-full`} />
      </span>
    </div>
  )
}

TackleAvatarLogo.defaultProps = {
  padding: 'p-[6px]',
  className: 'bg-tertiary h-[50px] w-[50px]',
}
export default TackleAvatarLogo
