import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import type { ChannelFilters, ChannelOptions, ChannelSort } from 'stream-chat'
import { Chat, Channel } from 'stream-chat-react'
import { useChatContext } from 'stream-chat-react'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import uniq from 'lodash/uniq'
import { isMobileOnly, isTablet } from 'mobile-device-detect'
import { useUpdateAppHeightOnResize } from './hooks/useUpdateAppHeightOnResize'
import { useConnectUser } from 'components/Chat/hooks/useConnectUser'
import UserProfile from 'components/Chat/components/UserProfile'
import {
  CreateChannel,
  CustomMessage,
  MessagingInput,
  MessagingThreadHeader,
} from 'components/Chat/components'
import { ChannelInner } from 'components/Chat/components/ChannelInner/ChannelInner'
import ChannelListHeader from 'components/Chat/components/ChannelListHeader'
import { GiphyContextProvider } from './Giphy'
import { APP_STREAM_KEY } from 'config'
import { StreamChatGenerics } from 'components/Chat/types'
import '@stream-io/stream-chat-css/dist/css/index.css'
import './App.css'
import { ChatWrapper } from './style'
import { IChatAttachment } from '.'
import { replaceState } from 'utils/helper'

type AppProps = {
  token: string
  userId: string
  apiKey: string
  chatUsers: any
  usersAssessmentResponseByQuestion: any
  chatUnreadMessageCountAction: any
  chatUnreadMessageCount: number
  getUsersAssessmentResponseByQuestionAction: (data: any) => {}
  userToConnect: { id: string; name?: string; image?: string }
  saveUserFileAction: (streamMsgId: string, attachment: IChatAttachment) => void
  targetOrigin: string
  channelListOptions: {
    options: ChannelOptions
    filters: ChannelFilters
    sort: ChannelSort
  }
}

const ChatApplication = (props: AppProps) => {
  const { userId, token, chatUsers } = props
  const { activeMemberId } = useParams()
  const [chatUser] = useState({ id: userId })
  const theme = 'light'
  const width = window.screen.width
  const chatClient = useConnectUser<StreamChatGenerics>(APP_STREAM_KEY, chatUser, token)

  useUpdateAppHeightOnResize()

  if (!chatClient) {
    return null // render nothing until connection to the backend is established
  }

  return (
    <ChatWrapper>
      <div>
        {/* <div> TOTAL: {chatClient?.user?.total_unread_count}</div> */}

        <Chat client={chatClient} theme={`messaging ${theme}`}>
          <MyChat
            theme={theme}
            activeMemberId={activeMemberId}
            width={width}
            usersAssessmentResponseByQuestion={props.usersAssessmentResponseByQuestion}
            getUsersAssessmentResponseByQuestionAction={
              props.getUsersAssessmentResponseByQuestionAction
            }
            userId={userId}
            chatUsers={chatUsers}
            chatUnreadMessageCountAction={props.chatUnreadMessageCountAction}
            chatUnreadMessageCount={props.chatUnreadMessageCount}
            saveUserFileAction={props.saveUserFileAction}
          />
        </Chat>
      </div>
    </ChatWrapper>
  )
}

export default ChatApplication

const MyChat = ({
  usersAssessmentResponseByQuestion,
  getUsersAssessmentResponseByQuestionAction,
  theme,
  width,
  activeMemberId,
  userId,
  chatUsers,
  chatUnreadMessageCountAction,
  chatUnreadMessageCount,
  saveUserFileAction,
}) => {
  const context = useChatContext<StreamChatGenerics>()
  const [membersList, setMembersList] = useState([])
  const [isSetActiveMember, setActiveMember] = useState(false)
  const [showChannelCreate, setShowChannelCreate] = useState(false)
  const [showChannelPreview, setShowChannelPreview] = useState(false)
  const [showDesktopUserProfile, setShowDesktopUserProfile] = useState(false)
  const [activeMemberChatChannelExists, setActiveMemberChannelExists] = useState<boolean>(false)

  useEffect(() => {
    if (!isEmpty(context.client.activeChannels)) {
      const members: any = []
      const channels = Object.values(context.client.activeChannels)

      for (let i = 0; i < channels.length; i++) {
        const channelMember: any = Object.values(get(channels[i], 'state.members', {})).filter(
          (f: any) => f.user.id !== context.client.userID
        )
        if (channelMember.length === 1) {
          members.push(channelMember[0].user_id)
        }
      }
      uniq(members)

      if (JSON.stringify(membersList.sort()) !== JSON.stringify(members.sort())) {
        setMembersList(members)
      }
    }

    // eslint-disable-next-line
  }, [context])

  useEffect(() => {
    if (membersList.length > 0) {
      getUsersAssessmentResponseByQuestionAction({
        question: 'isIndustry',
        type: 'initial',
        users: membersList,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [membersList])

  useEffect(() => {
    if (!isSetActiveMember && membersList.length > 0 && activeMemberId) {
      const filter = { type: 'messaging', members: { $eq: [activeMemberId, userId] } }
      context.client.queryChannels(filter).then((channels) => {
        if (channels?.length > 0) {
          setActiveMemberChannelExists(true)
          context.setActiveChannel(channels[0])
          setShowChannelPreview(true)
        } else {
          setActiveMemberChannelExists(false)
          setShowChannelCreate(true)
        }
      })
      setActiveMember(activeMemberId)
      replaceState(document.title)
    }
  }, [membersList, activeMemberId, context, isSetActiveMember, setActiveMember, userId])

  return (
    <div className="grid grid-cols-12 h-[calc(100vh-250px)] xs:overflow-auto xs:h-[calc(100vh-95px)] sm:h-[calc(100vh-149px)] md:h-full">
      <ChannelListHeader
        userId={userId}
        showChannelCreate={showChannelCreate}
        setShowChannelPreview={setShowChannelPreview}
        showChannelPreview={showChannelPreview}
        setShowChannelCreate={setShowChannelCreate}
        usersAssessmentResponseByQuestion={usersAssessmentResponseByQuestion}
        chatUnreadMessageCountAction={chatUnreadMessageCountAction}
        chatUnreadMessageCount={chatUnreadMessageCount}
      />

      <div
        className={`${showDesktopUserProfile ? 'xl:col-span-6' : 'xl:col-span-9'}
      xs:${(showChannelCreate || showChannelPreview) && isMobileOnly ? 'col-span-12 ' : 'hidden'}
      sm:${showChannelCreate || showChannelPreview ? 'col-span-12 block' : 'hidden'}
      md:${showChannelCreate || showChannelPreview ? 'col-span-12 !block' : 'hidden'}
      mdl:col-span-8  mdl:block lg:col-span-8  xl:pr-0 border-x-[1px] border-primary-outline xl:mt-20 mdl:mt-20 xs:top-[55px] xs:h-full sm:top-0 md:h-auto xs:w-full xs:left-0 mdl:left-auto sm:w-full xs:fixed sm:absolute mdl:static`}
      >
        <Channel
          Input={MessagingInput}
          maxNumberOfFiles={10}
          Message={CustomMessage}
          multipleUploads={true}
          ThreadHeader={MessagingThreadHeader}
          TypingIndicator={() => null}
        >
          {showChannelCreate && (
            <GiphyContextProvider>
              <CreateChannel
                chatUsers={chatUsers}
                onClose={() => setShowChannelCreate(false)}
                setShowChannelPreview={setShowChannelPreview}
                showChannelPreview={showChannelPreview}
                setShowChannelCreate={setShowChannelCreate}
                showChannelCreate={showChannelCreate}
                initialUserIds={
                  activeMemberId && !activeMemberChatChannelExists ? [activeMemberId] : []
                }
              />
            </GiphyContextProvider>
          )}
          <GiphyContextProvider>
            <ChannelInner
              setShowDesktopUserProfile={setShowDesktopUserProfile}
              showDesktopUserProfile={showDesktopUserProfile}
              theme={theme}
              setShowChannelPreview={setShowChannelPreview}
              showChannelPreview={showChannelPreview}
              usersAssessmentResponseByQuestion={usersAssessmentResponseByQuestion}
              chatUnreadMessageCountAction={chatUnreadMessageCountAction}
              saveUserFileAction={saveUserFileAction}
            />
          </GiphyContextProvider>
        </Channel>
      </div>
      {!isMobileOnly && !isTablet && showDesktopUserProfile && (
        <UserProfile usersAssessmentResponseByQuestion={usersAssessmentResponseByQuestion} />
      )}
    </div>
  )
}
