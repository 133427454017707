let currentUserId: string

export const initializeUsetiful = (userId: string) => {
  window.usetifulTags = {
    userId,
  }
  const url = 'https://www.usetiful.com/dist/usetiful.js'

  let script = document.querySelector(`script[src='${url}']`) as HTMLScriptElement

  if (script && userId === currentUserId) return

  if (script && userId !== currentUserId) {
    window.USETIFUL = {}
    document.body.removeChild(script)
  }
  script = document.createElement('script')
  script.type = 'application/javascript'
  script.src = url
  script.async = true

  script.setAttribute('id', 'usetifulScript')
  script.dataset.token = 'fc0b72b7e2fc31548d4f087ad6b0bb85'

  currentUserId = userId
  document.body.appendChild(script)
}
