import { XButtonBackground } from 'components/Chat/assets'
import { MessagingInput } from 'components/Chat/components'
import { getUserName } from 'components/Chat/helper'
import type { StreamChatGenerics } from 'components/Chat/types'
import SelectField from 'components/Common/SelectField'
import { CrossIcon } from 'components/Common/SvgIcons'
import PlusIcon from 'components/Common/SvgIcons/PlusIcon'
import { USER_ROLES } from 'config'
import { isEmpty } from 'lodash'
import _debounce from 'lodash.debounce'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import type { Channel, ChannelData, ExtendableGenerics, Message, UserResponse } from 'stream-chat'
import { MessageInput, MessageToSend, Thread, useChatContext, Window } from 'stream-chat-react'
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types'
// import redirectTo from 'utils/redirectTo'
import './CreateChannel.css'
import { useTenantId } from 'utils/useTenantId'

const filterChatUsersByRole = ({ allUsers, chatUsers }) => {
  try {
    let chatUsersList: any[] = []
    let { glRole } = chatUsers

    // skip those users in which glRoles key is not available
    allUsers = allUsers.filter((user) => {
      if (user?.glRoles) {
        return user
      } else {
        return false
      }
    })

    if (glRole === USER_ROLES.BUSINESS_OWNER) {
      if (chatUsers?.users && chatUsers?.users.length > 0) {
        chatUsers?.users.forEach((chatUser) => {
          allUsers.find((user) => {
            if (user.id === chatUser.id) {
              chatUsersList.push(user)
              return true
            } else {
              return false
            }
          })
        })
      }
    } else if (glRole === USER_ROLES.BSO_ADMIN) {
      allUsers.forEach((user) => {
        if (user?.glRoles) {
          chatUsersList.push(user)
        }
      })
    } else if (glRole === USER_ROLES.BSO_ADVISOR) {
      if (chatUsers?.users && chatUsers?.users.length > 0) {
        chatUsers?.users.forEach((chatUser) => {
          allUsers.some((user: any) => {
            if (user.id === chatUser.id) {
              chatUsersList.push(user)
              return true
            }
            return false
          })
        })
      }
      // add advisors & admins in array
      allUsers.forEach((user: any) => {
        if (
          // user.glRoles.includes(USER_ROLES.BSO_ADMIN) ||
          user.glRoles.includes(USER_ROLES.BSO_ADVISOR)
        ) {
          chatUsersList.push(user)
        }
      })
    }

    return chatUsersList
  } catch (err: any) {
    console.log('error: filterChatUsersByRole: ', err.message)
    return []
  }
}

export type ChannelInnerProps = {
  toggleMobile: () => void
  theme: string
  showChannelPreview: any
  showChannelCreate: any
  setShowDesktopUserProfile: (showDesktopUserProfile) => void
  setShowChannelPreview: (showChannelPreview) => void
  showDesktopUserProfile
}

export type IStreamChatUserContext = DefaultStreamChatGenerics &
  ExtendableGenerics & {
    users: {
      firstName: string
      lastName: string
    }
  }

export type IStreamChannelContext = DefaultStreamChatGenerics &
  Channel<IStreamChatUserContext> & {
    members: (string | undefined)[]
    team: string | undefined
  }

export interface ICreateChannelProps {
  onClose: () => void
  setShowChannelPreview: (showChannelPreview: boolean) => void
  showChannelPreview: boolean
  setShowChannelCreate: (showChannelCreate: boolean) => void
  showChannelCreate: boolean
  chatUsers: any
  initialUserIds?: string[]
}

const CreateChannel: FC<ICreateChannelProps> = ({
  onClose,
  setShowChannelPreview,
  showChannelPreview,
  setShowChannelCreate,
  showChannelCreate,
  chatUsers,
  initialUserIds,
}) => {
  const { client, setActiveChannel } = useChatContext<IStreamChatUserContext>()

  const [searching, setSearching] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState<UserResponse<StreamChatGenerics>[]>([])
  const [users, setUsers] = useState<UserResponse<IStreamChatUserContext>[]>([])
  const [showSelect, setShowSelect] = useState<boolean>(false)
  const [loadedInitialUsers, setLoadedInitialUsers] = useState<boolean>(false)

  const inputRef = useRef<HTMLInputElement>(null)

  const tenantId = useTenantId()

  useEffect(() => {
    if (chatUsers) {
      findUsersDebounce()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatUsers, users])

  const getAllUsers = async () => {
    const limit = 100
    let allFound = false
    let offset = 0
    let result: UserResponse<IStreamChatUserContext>[] = []

    while (!allFound) {
      const response = await client.queryUsers(
        {
          id: { $ne: client.userID as string },
          $and: [{ teams: { $contains: tenantId } }],
        },
        { firstName: 1 },
        { limit, offset }
      )
      if (response.users.length === 0) {
        allFound = true
      } else {
        result = result.concat(response.users)
        offset += limit
      }
    }
    return result
  }

  const findUsers = async () => {
    if (searching) return
    setSearching(true)

    try {
      const users = await getAllUsers()

      let filterUser = users.filter((user) => user.firstName || user.lastName)

      let chatUsersList = filterChatUsersByRole({
        allUsers: filterUser,
        chatUsers,
      })

      chatUsersList.forEach((user) => {
        user.name = {
          firstName: user?.firstName || user?.name?.firstName,
          lastName: user?.lastName || user?.name?.lastName,
        }
      })

      if (chatUsersList.length) {
        chatUsersList = chatUsersList.sort((a, b) =>
          (a.firstName || '').localeCompare(b.firstName || '')
        )

        setUsers(chatUsersList)
      }
    } catch (error) {
      console.log({ error })
    }

    setSearching(false)
  }

  const findUsersDebounce = _debounce(findUsers, 100, {
    trailing: true,
  })

  const removeUser = (user: UserResponse<StreamChatGenerics>) => {
    const newUsers = selectedUsers.filter((item) => item.id !== user.id)
    setSelectedUsers(newUsers)
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const addUser = useCallback(
    (addedUser: UserResponse<StreamChatGenerics>) => {
      const isAlreadyAdded = selectedUsers.find((user) => user.id === addedUser.id)
      if (isAlreadyAdded) return

      setSelectedUsers([...selectedUsers, addedUser])
      if (inputRef.current) {
        inputRef.current.focus()
      }
    },
    [selectedUsers]
  )

  useEffect(() => {
    if (!loadedInitialUsers && users.length > 0) {
      for (const userId of initialUserIds || []) {
        const user = users.find((user) => user.id === userId) as UserResponse<StreamChatGenerics>
        if (user) addUser(user)

        setLoadedInitialUsers(true)
      }
    }
  }, [initialUserIds, addUser, users, tenantId, loadedInitialUsers])

  // const isNewConversation = (conversation: Channel<IStreamChatUserContext>) => {
  //   return conversation.state.last_message_at === null
  // }

  const createChannelHandler = async (message: MessageToSend<StreamChatGenerics>) => {
    try {
      const selectedUsersIds = selectedUsers.map((u) => u.id)

      const customData: ChannelData<IStreamChannelContext> = {
        members: [...selectedUsersIds, client.userID || 'unknown'],
        team: tenantId,
      }
      //create channel
      const conversation = client.channel('messaging', customData)
      await conversation.create()

      //  send message
      let newMessage: Message<IStreamChatUserContext> = {}
      if (!isEmpty(message.text)) {
        newMessage.text = message.text
      }
      if (message.attachments) {
        newMessage.attachments = []
      }
      for (const attachment of Object.values(message.attachments || {})) {
        let fileSize: number = 0
        if (attachment.file_size) {
          if (typeof attachment.file_size === 'string') {
            fileSize = parseInt((attachment.file_size as string) || '0')
          }
        }
        newMessage?.attachments?.push({
          ...attachment,
          file_size: fileSize,
        })
      }
      if (!isEmpty(newMessage)) {
        await conversation.sendMessage(newMessage)
      }

      const channel = await client.queryChannels(
        { type: 'messaging', id: conversation.id },
        [{ last_message_at: -1 }],
        {
          watch: true, // this is the default
          state: true,
        }
      )
      // if (isNewConversation(conversation)) {
      //   if (selectedUsersIds.length > 0) {
      //     redirectTo(`${window.location.pathname}/${selectedUsersIds[0]}`) // Workaround for Stream bug where subsequent messages after the first don't show.
      //   } else {
      //     redirectTo(window.location.pathname) // Workaround for Stream bug where subsequent messages after the first don't show.
      //   }
      // }
      await conversation.watch()

      setActiveChannel(channel[0])
      setShowChannelPreview(true)
      setSelectedUsers([])
      setUsers([])
      onClose()
    } catch (err: any) {
      console.log('error: ', err.message)
    }
  }

  return (
    <div className="messaging-create-channel">
      <Window>
        <div className="relative h-full">
          <div className="pt-5 pl-5 xs:pr-3">
            {/* <header> */}
            <div className="flex justify-between">
              <div className="text-xl font-bold">New Message</div>
              <div
                onClick={() => {
                  setShowChannelPreview(false)
                  setShowChannelCreate(!showChannelCreate)
                }}
                className="cursor-pointer"
              >
                <XButtonBackground />
              </div>
            </div>
            <div className="messaging-create-channel__left !items-center	">
              <div className="text-sm font-normal uppercase mt-5 mr-3">To: </div>
              <div className="users-input-container inline-flex">
                {
                  <div className="messaging-create-channel__users max-h-[39px]">
                    {!!selectedUsers?.length &&
                      selectedUsers.map((user) => (
                        <div
                          className="messaging-create-channel__user !bg-sky max-h-[39px] mt-2"
                          onClick={() => removeUser(user)}
                          key={user.id}
                        >
                          <div className="messaging-create-channel__user-text !text-black-light !text-base !font-semibold !font-primary">
                            {getUserName(user.name)}
                          </div>
                          <CrossIcon className={'fill-black-light h-[15px] w-[15px]'} />
                        </div>
                      ))}

                    {showSelect || selectedUsers.length === 0 ? (
                      <div style={{ width: '300px' }}>
                        <SelectField
                          placeholder="Select"
                          name={''}
                          options={users.map((user) => ({
                            label: getUserName(user?.name),
                            value: user.id,
                          }))}
                          onChange={(value) => {
                            addUser(
                              users.find(
                                (user) => user.id === value
                              ) as UserResponse<StreamChatGenerics>
                            )
                            setShowSelect(false)
                          }}
                          isSearchable={true}
                        />
                      </div>
                    ) : (
                      <div className="mt-4 ml-5" onClick={() => setShowSelect(true)}>
                        <PlusIcon className="fill-primary h-[26px] w-[26px]" />
                      </div>
                    )}
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 w-full">
            <MessageInput focus overrideSubmitHandler={createChannelHandler} />
          </div>
        </div>
      </Window>
      <Thread Input={MessagingInput} />
    </div>
  )
}

export default CreateChannel
