import { GetAttestationSummaryInput, AttestationSummary } from '__generated__/api-types-and-hooks'
import { GET_ATTESTATION_SUMMARY } from 'store/types'

type GetAttestationSummaryInputWithoutTenant = Omit<GetAttestationSummaryInput, 'tenantId'>

export const getAttestationSummaryAction = {
  STARTED: (payload: GetAttestationSummaryInputWithoutTenant) => ({
    type: GET_ATTESTATION_SUMMARY.STARTED,
    payload,
  }),
  FULLFILLED: (payload: AttestationSummary) => ({
    type: GET_ATTESTATION_SUMMARY.FULLFILLED,
    payload,
  }),
  REJECTED: (error) => ({ type: GET_ATTESTATION_SUMMARY.REJECTED, error }),
}

/** Types describing the actions returned from each getAttestationSummaryAction callbacks */
export namespace GetAttestationSummaryAction {
  export type STARTED = ReturnType<typeof getAttestationSummaryAction['STARTED']>
  export type FULLFILLED = ReturnType<typeof getAttestationSummaryAction['FULLFILLED']>
  export type REJECTED = ReturnType<typeof getAttestationSummaryAction['REJECTED']>
}
