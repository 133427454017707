import { get, pickBy } from 'lodash'
import UserApi from 'api/user'
import { SET_MONTHLY_ATTESTATION_FORM, SAVE_ATTESTATION } from 'store/types'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getUserBusinessProfileAction } from 'store/actions/user'
import { setFormData } from 'store/actions/form'
import { REGISTER_BUSINESS, formKeys } from 'config'
import history from 'utils/history'
import { saveAttestationAction } from 'store/actions/monthlyAttestaion'
export interface SetMonthlyAttestationFormPayload {
  setForms?: string[]
}
export interface ISetMonthlyAttestationFormAction {
  type: string
  payload: SetMonthlyAttestationFormPayload
}
function* setMonthlyAttestationForm(action: ISetMonthlyAttestationFormAction) {
  try {
    const user = yield select((state) => state.user.user)

    const res = yield call(UserApi.getBusinessProfile, get(user, 'id', ''))
    yield put(getUserBusinessProfileAction.FULLFILLED(res?.getBusinessProfile))

    const setForms = action?.payload?.setForms || []
    if (setForms?.length > 0) {
      let businessProfile = get(res, 'getBusinessProfile', '')
      businessProfile = {
        ...businessProfile,
        businessBankAccount: businessProfile?.businessBankAccount ? '1' : '0',
      }

      if (businessProfile?.revenue?.includes(',')) {
        businessProfile.revenue = businessProfile.revenue.replaceAll(',', '')
      }
      if (
        businessProfile &&
        businessProfile?.registerBusinessStructure === REGISTER_BUSINESS.TELL_ME_MORE
      ) {
        businessProfile.registerBusinessStructure = REGISTER_BUSINESS.DONT_HAVE_YET
      }
      yield put(
        setFormData({
          form: setForms[0],
          data: pickBy(businessProfile, (value, key) => formKeys[setForms[0]].includes(key)),
        })
      )
      if (businessProfile?.revenue?.includes('$')) {
        businessProfile.revenue = businessProfile.revenue.replace('$', '')
      }
      yield put(getUserBusinessProfileAction.FULLFILLED(businessProfile))
    }
  } catch (error) {
    console.log('Could not set form:', error)
  }
}
function* updateMonthlyAttestation(action) {
  try {
    const tenantId = yield history.location.pathname.split('/')[2]
    let redirectUrl = window.location.href
    redirectUrl = redirectUrl.replace('attestation', 'dashboard')
    const businessName = yield select((state) => state.user?.businessProfile?.businessName || '')

    let { revenue } = action.payload.formData
    let revenueValue = ''
    if (revenue) {
      revenueValue = parseInt(revenue, 10).toLocaleString()
      if (revenue?.includes('.')) {
        let splittedData = revenue.split('.')
        revenueValue = revenueValue + '.' + splittedData[1]
      }
      if (revenueValue?.includes('$')) {
        revenueValue = revenueValue.replace('$', '')
      }

      if (revenueValue?.includes('-')) {
        revenueValue = revenueValue.slice(0, 1) + '$' + revenueValue.slice(1)
      } else {
        revenueValue = `$${revenueValue}`
      }
    }

    let data = {
      tenantId,
      ...action.payload?.formData,
      redirectUrl,
      businessName,
      revenue: revenueValue,
      generateDocuSignUrl: action.payload?.generateUrl,
    }
    const response = yield call(UserApi.saveAttestation, { ...data })

    if (action.payload?.generateUrl) {
      yield put(saveAttestationAction.FULLFILLED(response?.saveAttestation?.data?.url))
    }
  } catch (error) {
    console.log('Could not update monthly attestation', error)
    yield put(saveAttestationAction.REJECTED())
  }
}
/// /////////// Watchers ///////////////////////
export function* watcherMonthlyAttestation() {
  yield takeLatest(SET_MONTHLY_ATTESTATION_FORM.STARTED, setMonthlyAttestationForm)
  yield takeLatest(SAVE_ATTESTATION.STARTED, updateMonthlyAttestation)
}
