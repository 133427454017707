import { Maybe, UserNotificationData } from '__generated__/api-types-and-hooks'
import { STATUS, USER_ROLES } from 'config'
import { get } from 'lodash'
import pick from 'lodash/pick'
import {
  GET_USER_BUSINESS_PROFILE,
  GET_USER_PROFILE,
  UPDATE_USER_BUSINESS_PROFILE,
  UPDATE_USER_PROFILE,
  CHAT_UNREAD_MESSAGE_COUNT,
  GET_USER_NOTIFICATIONS,
  DELETE_USER_NOTIFICATIONS,
  UPDATE_MY_TOOLS,
  CLIENTS_SUMMARY,
  RESEND_USER_INVITE,
  OPEN_CONFIRMATION_MODAL,
  SAVE_ATTESTATION,
  RESET_ATTESTATION_URL,
  SET_TENANT_ID,
  SET_MONTHLY_ATTESTATION_FORM,
  SET_SHOW_SUBSCRIPTION_PROMPT,
} from 'store/types'

export interface UserState {
  user: any
  businessProfile: any
  notifications: {
    data: Maybe<UserNotificationData>[]
    lastEvaluatedKey: string
    totalCount: number
    totalUnread: number
  }
  error: string
  disableButtonsButtons: boolean

  userProfile: any
  chatUsers: {
    glRole: USER_ROLES
    users: any[]
  }
  chatUnreadMessageCount: number
  updateOwner: string
  summary: any
  email: string
  isSuccess: boolean
  attestationUrl: string
  isLoading: boolean
  tenantId: string
  openTenantIdModal: boolean
  attestationData: {}
  isUpdateProfileLoading: boolean
  isLoadingBusinessProfile: boolean
  showSubscriptionPrompt: boolean
}

const initialState: UserState = {
  user: {},
  businessProfile: {},
  notifications: { data: [], lastEvaluatedKey: '', totalCount: 0, totalUnread: 0 },
  error: '',
  disableButtonsButtons: false,

  userProfile: {},
  chatUsers: {
    glRole: USER_ROLES.BUSINESS_OWNER,
    users: [],
  },
  chatUnreadMessageCount: 0,
  updateOwner: '',
  summary: {},
  email: '',
  isSuccess: false,
  attestationUrl: '',
  isLoading: false,
  tenantId: '',
  openTenantIdModal: false,
  attestationData: {},
  isUpdateProfileLoading: false,
  isLoadingBusinessProfile: false,
  showSubscriptionPrompt: false,
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case 'GET_CHAT_USERS':
      return {
        ...state,
        chatUsers: action.payload,
      }

    case GET_USER_PROFILE.FULLFILLED:
      return {
        ...state,
        ...action.payload,
      }
    case SET_MONTHLY_ATTESTATION_FORM.STARTED:
      return {
        ...state,
        isLoadingBusinessProfile: true,
      }

    case GET_USER_BUSINESS_PROFILE.FULLFILLED:
      return {
        ...state,
        businessProfile: action?.payload,
        isLoadingBusinessProfile: false,
      }
    case UPDATE_USER_PROFILE.STARTED:
      return {
        ...state,
        disableButtons: true,
        isUpdateProfileLoading: true,
      }
    case UPDATE_USER_PROFILE.FULLFILLED:
      return {
        ...state,
        user: action?.payload,
        disableButtons: false,
        isUpdateProfileLoading: false,
      }
    case UPDATE_USER_PROFILE.REJECTED:
      return {
        ...state,
        error: action.payload,
        disableButtons: false,
      }
    case UPDATE_USER_BUSINESS_PROFILE.STARTED:
      return {
        ...state,
        disableButtons: true,
      }
    case UPDATE_USER_BUSINESS_PROFILE.REJECTED:
      return {
        ...state,
        error: action.payload,
        disableButtons: false,
      }
    case UPDATE_USER_BUSINESS_PROFILE.FULLFILLED:
      return {
        ...state,
        disableButtons: false,
        businessProfile: action?.paylaod,
      }

    case CHAT_UNREAD_MESSAGE_COUNT.FULLFILLED:
      return {
        ...state,
        chatUnreadMessageCount: action.payload,
      }
    case DELETE_USER_NOTIFICATIONS.STARTED:
    case GET_USER_NOTIFICATIONS.STARTED:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isLoading: true,
        },
      }
    case DELETE_USER_NOTIFICATIONS.FULLFILLED:
    case GET_USER_NOTIFICATIONS.FULLFILLED:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          isLoading: false,
          ...pick(action.payload, ['data', 'lastEvaluatedKey', 'totalCount', 'totalUnread']),
        },
      }
    case GET_USER_NOTIFICATIONS.RESET:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          lastEvaluatedKey: '',
          data: [],
          totalCount: 0,
        },
      }
    case UPDATE_MY_TOOLS.FULLFILLED:
      return {
        ...state,
        updateOwner: action.payload,
      }
    case CLIENTS_SUMMARY.FULLFILLED:
      return {
        ...state,
        summary: action.payload,
      }
    case RESEND_USER_INVITE.STARTED:
      return {
        ...state,
        status: STATUS.LOADING,
        isSuccess: false,
      }
    case RESEND_USER_INVITE.REJECTED:
      return {
        ...state,
        error: get(action.payload, 'error.message', ''),
        status: STATUS.COMPLETED,
        isSuccess: false,
      }
    case RESEND_USER_INVITE.FULLFILLED:
      return {
        ...state,
        status: STATUS.COMPLETED,
        isSuccess: true,
      }
    case OPEN_CONFIRMATION_MODAL:
      return {
        ...state,
        openConfirmationModal: action.payload.openConfirmationModal,
        email: action.payload.email,
        isSuccess: false,
      }
    case SAVE_ATTESTATION.STARTED:
      return {
        ...state,
        isLoading: action.payload?.generateUrl ? true : false,
      }
    case SAVE_ATTESTATION.FULLFILLED:
      return {
        ...state,
        attestationUrl: action.payload,
      }
    case SAVE_ATTESTATION.REJECTED:
      return {
        ...state,
        isLoading: false,
      }
    case RESET_ATTESTATION_URL:
      return {
        ...state,
        attestationUrl: '',
      }
    case SET_TENANT_ID:
      return {
        ...state,
        tenantId: action.payload,
      }
    case SET_SHOW_SUBSCRIPTION_PROMPT:
      return {
        ...state,
        showSubscriptionPrompt: action.payload,
      }
  }

  return state
}

export default reducer
