import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetFormData } from 'store/actions/form'

import hookForms from 'utils/hookForms'

export default function useFormContainer(name, methods) {
  const formData = useSelector((state: any) => state.form[name])
  const dispatch = useDispatch()

  methods.watch()

  hookForms.addForm(name, methods)

  useEffect(() => {
    methods.reset(formData)
  }, [formData, methods])

  useEffect(() => {
    return () => {
      dispatch(resetFormData({ form: name }))
    }
  }, [dispatch, name])
}
