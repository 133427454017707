import moment from 'moment'
import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import UserApi from 'api/user'
import GoalApi from 'api/goal'
import OwnerApi from 'api/ownerData'
import history from 'utils/history'

import { getUserGoalAction, updateUserGoalAction } from 'store/actions/owner/playbooks'
import {
  getSalesChartDataAction,
  getProductDataAction,
  getFinanceDataAction,
  getMarketingChartDataAction,
} from 'store/actions/owner/ownerData'
import {
  GET_ASSESSMENT_QUESTION,
  GET_USER_ASSESSMENT_RESPONSE,
  SAVE_ASSESSMENT_RESPONSE,
  GET_USER_GOAL,
  UPDATE_USER_GOAL,
  ADD_EDIT_ASSESSMENT,
  HANDLE_GOAL_ACTION,
  GET_ASSESSMENT_RESPONSE_BY_QUESTION,
  GET_RECOMMENDED_PLAYS_ASSSESSMENTS,
  GET_USER_ASSESSMENT_RESPONSE_BY_QUESTION,
  PREVIOUS_QUESTION,
  GET_BIG_QUERY,
  OWNER_ONBOARDING_COMPLETED,
  GET_SALES_DATA,
  GET_PRODUCT_DATA,
  GET_FINANCE_DATA,
  GET_MARKETING_DATA,
} from 'store/types'
import AssessmentApi from 'api/assessment'
import {
  getAssessmentQuestionAction,
  saveAssessmentResponseAction,
  getUserAssessmentResponseAction,
  addEditAssessmentAction,
  setActiveQuestion,
  setActiveStepAction,
  getUsersAssessmentResponseByQuestionAction,
  getAssessmentResponseByQuestionAction,
  initializeOwnerStepperAction,
} from 'store/actions/owner/initialAssessment'

import {
  getBigQueryAction,
  getRecommendedPlaysAssessments,
  showMyProfileAction,
} from 'store/actions/owner/account'
import { LOG_STATUS, ASSESSMENTS, FORM } from 'config'
import hookForms from 'utils/hookForms'
import { get, isNil, reverse, cloneDeep, find, includes } from 'lodash'
import { ASSESSMENT_USER_PRIVACY } from 'config/enums'
import { getRoleFromPath } from 'utils/helper'
import { sortBy } from 'lodash'
import { getAssessmentQuestionSelector, getCurrentGoalSelector } from 'store/selectors/owner'
import { GOAL_ACTION } from 'config'
import { resetProgressbarAction } from 'store/actions/common/progressbar'
import { updateUserBusinessProfileAction, updateUserProfileAction } from 'store/actions/user'
import { setFormData } from 'store/actions/form'
import { getAssessmentPlayListingAction, getDashboardListingAction } from 'store/actions/PlayBook'
import TopGrossSalesByChannel from 'assets/fakeData/top_gross_sales_by_channel.json'
import { checkBusinessProfileAction } from 'store/actions/userManagement'

export const getUserAssessments = (assessments, filter = true) => {
  let userAssessments

  userAssessments = assessments = get(assessments, 'getAssessments', [])
  userAssessments = userAssessments.map((assessment) => ({
    ...assessment,
    meta: assessment?.meta ? JSON.parse(assessment.meta) : null,
    content: assessment?.content ? JSON.parse(assessment.content) : null,
    options: assessment?.options ? JSON.parse(assessment.options) : null,
    previous: assessment?.previous ? JSON.parse(assessment.previous) : [],
  }))

  if (filter) {
    const role = getRoleFromPath()
    if (role) {
      userAssessments = userAssessments.filter(
        (assessment) => assessment?.meta?.[ASSESSMENT_USER_PRIVACY[role]]
      )
    }
  }

  return userAssessments
}

function* getAssessments(action) {
  try {
    const activeQuestion = yield select((state) => state.owner.activeQuestion)

    let getAssessments = yield call(AssessmentApi.getAssessments, action.payload.type)
    getAssessments = getUserAssessments(getAssessments, action.payload?.filter)

    getAssessments = sortBy(getAssessments, (q) => q.previous)
    yield put(getAssessmentQuestionAction.FULLFILLED(getAssessments))
    if (!activeQuestion.id) yield put(setActiveQuestion(getAssessments[0]?.id))
  } catch (error) {
    console.log('get assessment question error : ', error)
  }
}

function* getUserAssessmentResponse(action) {
  const assessment = yield select((state) => state.owner.questions)
  try {
    const { type, setForms, checkForAssessment } = action.payload
    yield put(checkBusinessProfileAction(false))
    const res = yield call(AssessmentApi.getUserAssessmentResponse, type, action?.payload?.userId)
    const questions = get(res, 'getUserAssessmentResponse.data', '')
    const assessmentResponse = {}
    questions.assessmentResponse = questions.assessmentResponse
      ? JSON.parse(questions.assessmentResponse)
      : []
    questions.assessmentResponse.forEach((answer) => {
      assessmentResponse[answer.id] = answer.value
    })
    if (setForms?.length > 0) {
      if (checkForAssessment) {
        const businessAssessment = {}
        const businessQuestions = assessment.filter(
          (question) => question?.meta?.showToBusinessProfile
        )

        for (let i = 0; i < businessQuestions.length; i++) {
          businessAssessment[businessQuestions[i].id] = assessmentResponse[businessQuestions[i].id]
        }
        yield put(setFormData({ form: setForms[0], data: businessAssessment }))
      } else {
        yield put(setFormData({ form: setForms[0], data: assessmentResponse }))
      }
    }
    yield put(getUserAssessmentResponseAction.FULLFILLED(questions))
    yield put(checkBusinessProfileAction(true))
    // if (questions.currentStage) {
    //   yield put(setActiveStepAction('Questions'))
    //   yield put(setActiveQuestion(questions.currentStage))
    // }
  } catch (error) {
    console.log('get assessment question error : ', error)
  }
}

function* addEditAssessment(action) {
  const steps = yield select((state) => state.owner.steps)
  try {
    const { type, userId } = action.payload
    let getAssessments = yield call(AssessmentApi.getAssessments, type)
    getAssessments = getUserAssessments(getAssessments)
    getAssessments = sortBy(getAssessments, (q) => q.previous)

    let questions: any = { assessmentResponse: [] }

    const res = yield call(AssessmentApi.getUserAssessmentResponse, type, userId)
    questions = get(res, 'getUserAssessmentResponse.data', {})
      ? get(res, 'getUserAssessmentResponse.data', {})
      : {}
    const assessmentResponse = {}
    questions.assessmentResponse = questions.assessmentResponse
      ? JSON.parse(questions.assessmentResponse)
      : []
    questions.assessmentResponse.forEach((answer) => {
      assessmentResponse[answer.id] = answer.value
    })

    yield put(
      addEditAssessmentAction.FULLFILLED({
        questions: getAssessments,
        assessmentResponse: questions,
      })
    )

    if (questions?.currentStage && isNil(questions.completedAt)) {
      yield put(setActiveStepAction('Questions'))
      yield put(setActiveQuestion(questions.currentStage))
    } else {
      yield put(setActiveStepAction(steps[0].title))
      let firstQuestion = getAssessments.find((assessment) => assessment?.previous.length === 0)
      if (firstQuestion) {
        yield put(setActiveQuestion(firstQuestion.id))
      }
    }
  } catch (error) {
    console.log('get assessment question error : ', error)
  }
}

function* saveAssessmentResponse(action) {
  try {
    let actionValue = ''
    const { type, isLastQuestion } = action.payload

    const sessionToken = localStorage.getItem('sessionToken')
    const tenantId = yield select((state) => state.user?.tenantId)

    const question = yield select((state) => state.owner.questions)
    let firstQuestion = question.find((assessment) => assessment?.previous.length === 0)
    const assessmentResponse = yield select((state) => state.owner.assessmentResponse)

    if (isLastQuestion) {
      action.payload.isCompleted = true
    }

    const currentQuestion = JSON.parse(action.payload.assessmentResponse)
    if (firstQuestion && currentQuestion.find((d) => d.id === firstQuestion.id)) {
      if (!assessmentResponse.assessmentResponse.find((res) => res.id === firstQuestion.id)) {
        if (
          action?.payload?.type !== 'initial' &&
          ASSESSMENTS[firstQuestion?.assessmentType]?.title
        ) {
          actionValue = `${LOG_STATUS.STARTED} ${ASSESSMENTS[firstQuestion?.assessmentType]?.title}`
          yield call(UserApi.logActivity, {
            action: actionValue,
            logStatus: LOG_STATUS.STARTED,
            accessToken: sessionToken,
            tenantId,
            showClientActivity: true,
            type,
          })
        }
      }
    }
    let widget = action.payload.widget
    delete action.payload.isLastQuestion
    delete action.payload.widget
    action.payload.tenantId = tenantId

    const assessmentResp = yield call(AssessmentApi.saveAssessmentResponse, action.payload)
    if (widget && assessmentResp) {
      yield put(getAssessmentPlayListingAction.STARTED())

      if (isLastQuestion) {
        yield put(getDashboardListingAction.STARTED())
      }
    }

    yield put(getUserAssessmentResponseAction.STARTED({ type }))

    // fetch recommended assessments & plays
    if (isLastQuestion) {
      yield put(getRecommendedPlaysAssessments.STARTED([]))
      const recommendation = yield call(AssessmentApi.getUserRecommendations)
      let record = JSON.parse(recommendation.getUserRecommendations.data)
      yield put(getRecommendedPlaysAssessments.FULLFILLED(record?.recommendations))

      // logActivity
      const sessionToken = localStorage.getItem('sessionToken')

      const tenantId = yield select((state) => state.user?.tenantId)

      if (ASSESSMENTS[type]?.title) {
        yield call(UserApi.logActivity, {
          action: `${LOG_STATUS.COMPLETED} ${ASSESSMENTS[type]?.title}`,
          logStatus: LOG_STATUS.COMPLETED,
          accessToken: sessionToken,
          tenantId,
          showClientActivity: true,
          type,
        })
      }
    }
    yield put(saveAssessmentResponseAction.FULLFILLED(assessmentResp))
  } catch (error) {
    console.log('get assessment question error : ', error)
  }
}

function* getUserGoal() {
  try {
    const goals = yield call(GoalApi.getUserGoals)
    yield put(getUserGoalAction.FULLFILLED(goals))
  } catch (error) {
    console.log('get goals error : ', error)
  }
}

function* updateUserGoal(action) {
  try {
    yield call(GoalApi.updateUserGoal, action.payload)
    yield put(updateUserGoalAction.FULLFILLED({ disable: false }))
    yield call(getUserGoal)
  } catch (error) {
    console.log('update owner goals error : ', error)
  }
}
function* getAssessmentResponseByQuestion(action) {
  const user = yield select((state) => state.user.user)

  const { type, question } = action.payload
  try {
    const res = yield call(AssessmentApi.getAssessmentResponseByQuestion, question, type, user.id)
    yield put(
      getAssessmentResponseByQuestionAction.FULLFILLED(res?.getAssessmentResponseByQuestion?.data)
    )
  } catch (error) {
    console.log('assessment response by question error ', error)
  }
}

function* getUsersAssessmentResponseByQuestion(action) {
  const { type, question, users } = action.payload
  const data = {}
  try {
    const res = yield all(
      users.map((x) => call(AssessmentApi.getAssessmentResponseByQuestion, question, type, x))
    )

    for (let i = 0; i < res.length; i++) {
      if (res[i].getAssessmentResponseByQuestion.success) {
        const assessmentQuestion = res[i].getAssessmentResponseByQuestion.data
        const value = assessmentQuestion?.value

        let parsedQuestion = JSON.parse(assessmentQuestion?.question || '[]')
        const selectedOption = parsedQuestion?.options?.find((opt) => opt.id === value)
        data[users[i]] = { icon: selectedOption?.icon, value: selectedOption?.value }
      }
    }
    yield put(getUsersAssessmentResponseByQuestionAction.FULLFILLED(data))
  } catch (error) {
    console.log('user assessment response by question error ', error)
  }
}

/* ---------------------------- Component Helpers ---------------------------- */

function* handleUserGoalAction(action) {
  try {
    const { type } = action.payload

    const goals = yield select((state) => state.owner.goals.getUserGoals)
    const assessment = yield select(getAssessmentQuestionSelector)
    const currentGoal = yield select(getCurrentGoalSelector)

    let dataToUpdate = {}
    if ([GOAL_ACTION.ACTIVE, GOAL_ACTION.INACTIVE].includes(type)) {
      const remainingActiveGoals = goals
        .filter(
          (res) =>
            (type === GOAL_ACTION.ACTIVE && (res.isActiveGoal || res.id === currentGoal.id)) ||
            (type === GOAL_ACTION.INACTIVE && res.isActiveGoal && res.id !== currentGoal.id)
        )
        .map((d) => d.id)
      dataToUpdate = { id: 'activeGoal', isActiveGoal: true, value: remainingActiveGoals }
    }

    if (type === GOAL_ACTION.TOP_GOAL) {
      const questionId = assessment?.questions?.find((res) => res?.meta?.isTopGoal)?.id
      dataToUpdate = { id: questionId, isTopGoal: true, value: currentGoal.id }
    }
    const assessmentResponseData: any = {
      assessmentResponse: JSON.stringify([
        {
          ...dataToUpdate,
        },
      ]),
      type: 'initial',
    }
    yield call(updateUserGoal, { payload: assessmentResponseData })
  } catch (error) {
    console.log('update owner goals error : ', error)
  }
}

function* getRecommendedPlaysAssessment(action) {
  try {
    const res = yield call(AssessmentApi.getUserRecommendations)
    let record = JSON.parse(res.getUserRecommendations?.data || '{}')

    yield put(getRecommendedPlaysAssessments.FULLFILLED(record?.recommendations))
  } catch (error) {
    console.log('recommended play assessment error ', error)
  }
}

function* previousQuestion(action) {
  try {
    // notes: (alihamza): save errors for troubleshooting

    let { previous, activeQuestionId } = action.payload
    const questions = yield select((state) => state.owner.questions)
    const assessmentResponse = yield select(
      (state) => state.owner.assessmentResponse?.assessmentResponse
    )

    if (previous.length === 1) {
      const previousQuestion = questions.find((obj) => previous[0] === obj.id)
      yield put(setActiveQuestion(previousQuestion.id))
    } else {
      let answers: any = [] //those questions who answer is saved
      previous.forEach((question_id) => {
        assessmentResponse.forEach((obj) => {
          if (obj.value && question_id.trim() === obj.id.trim()) {
            answers.push(obj)
          }
        })
      })

      if (answers.length === 1) {
        yield put(setActiveQuestion(answers[0].id))
      } else {
        let previous_question_id = ''
        let previous_question

        // iterate answers (answer saved in DB)
        answers.some((answer) => {
          let question = questions.find((obj) => answer.id.trim() === obj.id.trim())
          let selectedNextQuestionId
          assessmentResponse.forEach((response) => {
            if (response.id === question.id) {
              question.options.forEach((option) => {
                if (question?.optionType !== 'input' && option.id === response?.value) {
                  previous_question = question
                  selectedNextQuestionId = option?.action?.next?.default
                }
              })
            }
          })
          if (!previous_question) return ''

          let nextQuestionId: any = ''

          // find next questionId
          if (question) {
            if (question?.optionType === 'input') {
              nextQuestionId = question?.options[0]?.action?.next?.default
            }
            if (previous_question && selectedNextQuestionId === activeQuestionId) {
              nextQuestionId = selectedNextQuestionId
              previous_question_id = previous_question.id
            }
          } else {
            question.options.forEach((option) => {
              if (option.id === answer.value) {
                nextQuestionId = option?.action?.next?.default
                return option?.action?.next?.default
              }
            })
          }

          // check next questionID is equal to activeQuestionId
          if (nextQuestionId.trim() === activeQuestionId.trim()) {
            previous_question_id = answer.id
            return true
          } else {
            return false
          }
        })
        if (previous_question_id === '')
          previous_question_id = get(
            find(reverse(cloneDeep(assessmentResponse)), ({ id }) => includes(previous, id)),
            'id'
          )

        yield put(setActiveQuestion(previous_question_id))
      }
    }
  } catch (error) {
    console.log('previous question error ', error)
  }
}

function* getBigQuery(action) {
  try {
    const res = yield call(UserApi.getBigQuery, action?.payload)
    const bigQueryData = get(res, 'getBigQuery.data', '')
    if (bigQueryData) {
      yield put(getBigQueryAction.FULLFILLED(JSON.parse(bigQueryData)))
    } else {
      yield put(getBigQueryAction.REJECTED([]))
    }
  } catch (error) {
    console.log('get big query error ', error)
  }
}

function* ownerOnBoardingCompletion(action) {
  try {
    const user = yield select((state) => state.user.user)
    const tenantId = yield select((state) => state.user?.tenantId)
    const profileForm = hookForms.getForm(FORM.PROFILE_FORM)
    const profileFormValues = profileForm.getValues()
    yield put(
      updateUserProfileAction.STARTED({
        ...profileFormValues,
        isNewUser: false,
        tenantId,
      })
    )
    const businessForm = hookForms.getForm(FORM.BUSINESS_PROFILE)
    const businessFormValue = businessForm.getValues()
    yield put(showMyProfileAction(false))
    const role = getRoleFromPath()
    history.push(`/${role}/${tenantId}/dashboard`)
    yield put(
      updateUserBusinessProfileAction.STARTED({
        ...businessFormValue,
        id: user.id,
        yearsInBusiness: businessFormValue?.yearsInBusiness?.toString(),
      })
    )

    yield put(initializeOwnerStepperAction([]))
    yield put(resetProgressbarAction())
    yield put(setActiveStepAction(''))

    // log onBoarding completion
    const sessionToken = localStorage.getItem('sessionToken')
    yield call(UserApi.logActivity, {
      action: `Onboarding ${LOG_STATUS.COMPLETED}`,
      logStatus: LOG_STATUS.COMPLETED,
      accessToken: sessionToken,
      tenantId,
      showClientActivity: true,
    })
  } catch (error) {
    console.log('owner onBoarding completion action error ', error)
  }
}

function* getSalesChartData() {
  try {
    const businessId = 'f65ea3af-a7b4-41ac-9c97-197bba03e230'
    const chartName = 'GROSS_SALES_SUMMARY'
    const res = yield call(OwnerApi.getSalesChartData, businessId, chartName)
    const data = JSON.parse(res.getChartData.data)
    yield put(getSalesChartDataAction.FULLFILLED(data))
  } catch (error) {
    console.log('get sales chart data error ', error)
  }
}

function* getProductData(action) {
  try {
    const businessId = 'f65ea3af-a7b4-41ac-9c97-197bba03e230'
    const resp = yield all([
      call(OwnerApi.getSalesChartData, businessId, 'TOP_SELLING_PRODUCTS'),
      call(OwnerApi.getSalesChartData, businessId, 'SALES_GROWTH_RATE'),
    ])
    const topSelling = JSON.parse(get(resp, '0.getChartData.data'))
    const salesGrowth = JSON.parse(get(resp, '1.getChartData.data'))
    yield put(
      getProductDataAction.FULLFILLED({
        topGrossSalesChannel: TopGrossSalesByChannel,
        topSelling,
        salesGrowth,
      })
    )
  } catch (error) {
    console.log('get product data error ', error)
  }
}

function* getFinanceData(action) {
  try {
    const businessId = 'f65ea3af-a7b4-41ac-9c97-197bba03e230'
    const resp = yield all([
      call(OwnerApi.getSalesChartData, businessId, 'QUICKBOOKS_CF'),
      call(OwnerApi.getSalesChartData, businessId, 'QUICKBOOKS_PNL'),
    ])
    const cashFlowData = JSON.parse(get(resp, '0.getChartData.data'))
    const pnlData = JSON.parse(get(resp, '1.getChartData.data'))
    yield put(
      getFinanceDataAction.FULLFILLED({
        cashFlowData,
        pnlData,
      })
    )
  } catch (error) {
    console.log('get finance data error ', error)
  }
}

function* getMarketingChartData(action) {
  try {
    const res = yield call(OwnerApi.getOwnerBusinessData, action.payload)
    const data = JSON.parse(res.getOwnerBusinessData.data)
    yield put(
      getMarketingChartDataAction.FULLFILLED({
        ...data,
        updatedDate: moment().format('YYYY-MM-DD'),
      })
    )
  } catch (error) {
    console.log('get marketing chart data error ', error)
  }
}

/* -------------------------------- Watchers -------------------------------- */
export function* watcherAssessment() {
  yield takeLatest(GET_ASSESSMENT_QUESTION.STARTED, getAssessments)
  yield takeLatest(ADD_EDIT_ASSESSMENT.STARTED, addEditAssessment)
  yield takeLatest(GET_USER_GOAL.STARTED, getUserGoal)
  yield takeLatest(UPDATE_USER_GOAL.STARTED, updateUserGoal)
  yield takeLatest(SAVE_ASSESSMENT_RESPONSE.STARTED, saveAssessmentResponse)
  yield takeLatest(GET_USER_ASSESSMENT_RESPONSE.STARTED, getUserAssessmentResponse)

  yield takeLatest(HANDLE_GOAL_ACTION, handleUserGoalAction)
  yield takeLatest(GET_ASSESSMENT_RESPONSE_BY_QUESTION.STARTED, getAssessmentResponseByQuestion)
  yield takeLatest(
    GET_USER_ASSESSMENT_RESPONSE_BY_QUESTION.STARTED,
    getUsersAssessmentResponseByQuestion
  )
  yield takeLatest(GET_RECOMMENDED_PLAYS_ASSSESSMENTS.STARTED, getRecommendedPlaysAssessment)
  yield takeLatest(GET_BIG_QUERY.STARTED, getBigQuery)
  yield takeLatest(PREVIOUS_QUESTION, previousQuestion)
  yield takeLatest(OWNER_ONBOARDING_COMPLETED, ownerOnBoardingCompletion)
  yield takeLatest(GET_SALES_DATA.STARTED, getSalesChartData)
  yield takeLatest(GET_PRODUCT_DATA.STARTED, getProductData)
  yield takeLatest(GET_FINANCE_DATA.STARTED, getFinanceData)
  yield takeLatest(GET_MARKETING_DATA.STARTED, getMarketingChartData)
}
