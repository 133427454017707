import React, { ElementType, Fragment } from 'react'

import { Dialog, Transition } from '@headlessui/react'
import CrossIcon from 'components/Common/SvgIcons/CrossIcon'
import BackButtonArrowIcon from '../SvgIcons/BackButtonArrowIcon'

interface IModalBoxProps {
  title?: any
  children?: any
  onClose?: (e: any) => void
  width?: string
  classes?: string
  wrapperClass?: string
  backgroundColor: string
  childClass: string
  showCancelButton: boolean
  margin: string
  height: string
  isOnBoarding: boolean
  onBack?: () => {}
  Icon?: ElementType
  padding: string
  id?: string
  bgColor: string
  position: 'top' | 'center'
}

const ModalBox = ({
  title,
  children,
  onClose,
  width,
  classes,
  wrapperClass,
  backgroundColor,
  childClass,
  showCancelButton,
  margin,
  height,
  isOnBoarding,
  onBack,
  Icon,
  padding,
  bgColor,
  id = '',
  position,
}: IModalBoxProps) => {
  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog
        id={id}
        as="div"
        className={`relative z-50 ${wrapperClass} `}
        onClose={() => undefined}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className={`fixed inset-0 ${bgColor ? bgColor : 'bg-zinc'}`} />
        </Transition.Child>

        <div className={`fixed inset-0 overflow-y-auto ${margin}`}>
          <div className={`h-full flex items-center`}>
            <div
              className={`sm:rounded-xl xs:rounded-none modal-container w-full ${height} xs:overflow-auto  
    ${
      width
        ? `${width} mx-auto`
        : `max-w-[800px] mx-auto ${
            position === 'top' ? 'sm:mt-12 sm:mb-auto' : position === 'center' ? 'my-auto' : ''
          }`
    }
    ${backgroundColor ? backgroundColor : 'bg-white'}
    shadow-xl
  `}
            >
              {(title || onClose) && (
                <div
                  className={`flex flex-row justify-end items-center ${padding}  top-0 ${
                    title ? 'border-primary-outline border-b' : ''
                  }`}
                >
                  {onBack && (
                    <span onClick={onBack}>
                      <BackButtonArrowIcon className={'cursor-pointer mr-2'} />
                    </span>
                  )}
                  {title && (
                    <div className="text-primary-text w-full text-2xl font-secondary">{title}</div>
                  )}
                  {onClose && (
                    <div onClick={onClose} className="cursor-pointer">
                      {Icon ? (
                        <Icon />
                      ) : (
                        <CrossIcon className={'text-primary-text  h-[25px] w-[25px]'} />
                      )}
                    </div>
                  )}
                </div>
              )}
              {showCancelButton && (
                <span onClick={onClose}>
                  <CrossIcon
                    className={`xs:hidden sm:block h-[25px] w-[25px] cursor-pointer absolute z-10 ${
                      isOnBoarding
                        ? 'relative fill-black !inline top-5 left-5'
                        : 'fill-grey-lighter z-10 top-10 left-10'
                    } `}
                  />
                </span>
              )}
              <div
                className={`modalBlock rounded-b-xl overflow-y-auto overflow-x-hidden ${
                  backgroundColor ? backgroundColor : 'bg-white'
                }`}
              >
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel
                    className={`${
                      width ? `${width}` : ' max-w-[799px]'
                    }  ${classes} transform overflow-hidden text-left  transition-all`}
                  >
                    <div className={childClass}>{children}</div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
ModalBox.defaultProps = {
  title: '',
  backgroundColor: 'bg-white',
  onClose: undefined,
  childClass: '',
  showCancelButton: false,
  margin: '',
  height: 'h-auto',
  isOnBoarding: false,
  padding: 'p-7',
  position: 'my-auto',
  bgColor: '',
}

export default ModalBox
