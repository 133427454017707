import { ampli } from 'ampli'
import { FORM } from 'config'
import { isEmpty } from 'lodash'
import { copy } from 'utils/helper'
import hookForms from 'utils/hookForms'

interface IOption {
  label: string
  value: string
}
interface IRadioOption {
  label: string
  value: string
  id: number
}

export enum DEMOGRAPHIC_DETAILS {
  REGISTER_GENDER = 'gender',
  REGISTER_RACE = 'race',
  REGISTER_EDUCATION = 'education',
  GENDER = 'Gender',
  RACE = 'Race',
  EDUCATION = 'Highest Level of Education',
}

export enum EMPLOYEE {
  FULL_TIME = 'Number of full-time employees',
  PART_TIME = 'Number of part-time employees',
  CONTRACT = 'Number of contractor employees',
  VOLUNTEERS = 'Number of volunteers',
  REGISTER_FULL_TIME = 'fullTimeEmployees',
  REGISTER_PART_TIME = 'partTimeEmployees',
  REGISTER_CONTRACT = 'contractors',
  REGISTER_VOLUNTEERS = 'volunteers',
}
export enum JOBS {
  REGISTER_WAGES = 'wages',
  JOBS_CREATED = 'Number of jobs created',
  REGISTER_JOBS_CREATED = 'jobsCreated',
  WAGES = 'Wages per hour (average across all jobs)',
}
export enum FINANCIAL {
  REVENUE = 'Profit and Loss last month',
  DEBT = 'Debt financing last month',
  BANK = 'Business bank accounts opened (total)',
  REGISTER_REVENUE = 'revenue',
  REGISTER_DEBT = 'debtFinancing',
  REGISTER_BANK = 'businessBankAccount',
  REGISTER_GROWTH = 'financialGrowth',
}

export enum SALES {
  CUSTOMER = 'New customers last month',
  PRODUCTS = 'Number of products for sale (total)',
  SERVICES = 'Number of services for sale (total)',
  REGISTER_CUSTOMER = 'newCustomer',
  REGISTER_PRODUCTS = 'productSale',
  REGISTER_SERVICES = 'serviceSale',
}
export enum MARKETING {
  AUDIENCE = 'Target audience size',
  REGISTER_AUDIENCE = 'targetAudience',
}
export enum OPERATIONS {
  LOCATION = 'Number of physical locations (include mobile)',
  REGISTER_LOCATION = 'physicalLocation',
}
export enum ADDITIONAL_INFO {
  INFO = 'Additional Information',
  REGISTER_INFO = 'additionalInfo',
}
export enum BUSINESS_STRUCTURE {
  YEAR = 'Year',
  MONTH = 'Month',
  REGISTER_YEAR = 'year',
  REGISTER_MONTH = 'month',
  REGISTER_BUSINESS = 'Registered Business Structure',
  REGISTER_BUSINESS_NAME = 'registerBusinessStructure',
}

export enum REVENUE {
  LOSS = 'Loss',
  PROFIT = 'Profit',
}

export const radioOptions: IRadioOption[] = [
  {
    label: REVENUE.PROFIT,
    value: REVENUE.PROFIT,
    id: 1,
  },
  {
    label: REVENUE.LOSS,
    value: REVENUE.LOSS,
    id: 2,
  },
]

export const genderOptions: IOption[] = [
  { label: 'Male', value: 'Male' },
  { label: 'Female', value: 'Female' },
  { label: 'Transgender male', value: 'Transgender male' },
  { label: 'Transgender female', value: 'Transgender female' },
  { label: 'Other', value: 'Other' },
  { label: 'Prefer not to say', value: 'Prefer not to say' },
]

export const raceOptions: IOption[] = [
  {
    label: 'African American or Black',
    value: 'African American or Black',
  },
  { label: 'American Indian or Alaskan Native', value: 'American Indian or Alaskan Native' },
  { label: 'Asian', value: 'Asian' },
  {
    label: 'Caucasian or White',
    value: 'Caucasian or White',
  },
  {
    label: 'Hispanic or Latino',
    value: 'Hispanic or Latino',
  },
  {
    label: 'Other or Prefer not to say',
    value: 'Other or Prefer not to say',
  },
]
export const educationOptions: IOption[] = [
  { label: 'Elementary / Middle School', value: 'Elementary / Middle School' },
  { label: 'High School', value: 'High School' },
  { label: 'Graduate / GED', value: 'Graduate / GED' },
  { label: 'Some College', value: 'Some College' },
  { label: 'Associate Degree', value: 'Associate Degree' },
  { label: 'Bachelor’s Degree', value: 'Bachelor’s Degree' },
  {
    label: 'Professional Certificate or Apprenticeship',
    value: 'Professional Certificate or Apprenticeship',
  },
  { label: 'Master’s Degree', value: 'Master’s Degree' },
  { label: 'Doctorate / Ph.D.', value: 'Doctorate / Ph.D.' },
]

export const registeredBusinessOptions: IOption[] = [
  { label: 'I don’t have one yet', value: 'I don’t have one yet' },
  { label: 'Sole Proprietorship', value: 'Sole Proprietorship' },
  { label: 'Partnership', value: 'Partnership' },
  { label: 'LLC (Limited Liability Corporation)', value: 'LLC (Limited Liability Corporation)' },
  { label: 'S-Corp', value: 'S-Corp' },
  { label: 'C-Corp', value: 'C-Corp' },
  { label: 'Benefit Corporation', value: 'Benefit Corporation' },
  { label: 'Nonprofit or Not-for Profit', value: 'Nonprofit or Not-for Profit' },
]
export const monthOptions: IOption[] = [
  { label: 'January', value: 'January' },
  { label: 'February', value: 'February' },
  { label: 'March', value: 'March' },
  { label: 'April', value: 'April' },
  { label: 'May', value: 'May' },
  { label: 'June', value: 'June' },
  { label: 'July', value: 'July' },
  { label: 'August', value: 'August' },
  { label: 'September', value: 'September' },
  { label: 'October', value: 'October' },
  { label: 'November', value: 'November' },
  { label: 'December', value: 'December' },
]

export const yearOptions = () => {
  const currentYear = new Date().getFullYear()
  const years: IOption[] = []
  let startYear = 1999
  for (let i = startYear; i < currentYear; i++) {
    startYear++
    years.push({ label: startYear.toString(), value: startYear.toString() })
  }
  return years
}
export const registeredBusiness = 'I don’t have one yet'

export const checkFormValid = (errors) => {
  const form = hookForms?.getForm(FORM.MONTHLY_ATTESTATION)
  let formValues = form?.getValues()
  let isDataValid: boolean = false
  formValues = { ...formValues, education: formValues?.education ? formValues?.education : '' }
  if (formValues) {
    let formData = copy(formValues)
    delete formData[ADDITIONAL_INFO.REGISTER_INFO]
    const { registerBusinessStructure, month, year, ...otherValues } = formData

    let dataValues = []
    if (registerBusinessStructure !== registeredBusiness) {
      dataValues = Object.values(formData)
    } else {
      dataValues = Object.values(otherValues)
    }
    isDataValid = dataValues?.some((value) => isEmpty(value))
  }

  return isDataValid || !isEmpty(errors)
}

export interface IFieldError {
  [FINANCIAL.REGISTER_REVENUE]: IError
}
export interface IError {
  message: string
  type: string
}

export const validateRevenueField = (value: string, financialGrowthCheck: string) => {
  let error: IFieldError | '' = ''
  let registeredValue: string = value
  if (value) {
    const number = Number(value.replace(/[$,.]/g, ''))

    if (number > 0) {
      if (financialGrowthCheck === REVENUE.LOSS) {
        error = {
          [FINANCIAL.REGISTER_REVENUE]: {
            type: 'required',
            message: 'If you had a profit last month, please select “Profit”',
          },
        }
      }
    } else if (number < 0) {
      if (financialGrowthCheck === REVENUE.PROFIT) {
        error = {
          [FINANCIAL.REGISTER_REVENUE]: {
            type: 'required',
            message: 'If you had a loss last month, please select “Loss”',
          },
        }
      }
    }
  }

  return { returnedValue: registeredValue, error }
}

export const validateField = (value: string, financialGrowthCheck: string) => {
  let registeredValue: string = ''

  registeredValue = value.replace(/[$,\\-]/g, '')

  if (financialGrowthCheck === REVENUE.LOSS && value !== '0') {
    registeredValue = `-${registeredValue}`
  } else {
    registeredValue = `${registeredValue}`
  }

  return { value: registeredValue, error: '' }
}

export const getError = (errors, revenueError) => {
  return errors.revenue
    ? errors.revenue
    : !isEmpty(revenueError?.[FINANCIAL.REGISTER_REVENUE]?.message)
    ? revenueError?.[FINANCIAL.REGISTER_REVENUE]
    : ''
}

export const resetFields = (setValue) => {
  setValue('year', null)
  setValue('month', null)
}

export const monthlyImpactReportFormSubmittedLogs = (
  formValues,
  businessProfile,
  organizationName
) => {
  ampli.monthlyImpactReportFormSubmitted({
    boID: businessProfile?.id,
    impactReportCompleted: new Date().toLocaleString(),
    additionalInfo: formValues.additionalInfo,
    businessBankAccount: formValues.businessBankAccount,
    employeesContractors: formValues.contractors,
    debtFinancing: formValues.debtFinancing,
    educationLevel: formValues.education,
    profitLoss: formValues.financialGrowth,
    employeesFullTime: formValues.fullTimeEmployees,
    gender: formValues.gender,
    jobsCreated: formValues.jobsCreated,
    businessStructureMonth: formValues.month,
    newCustomers: formValues.newCustomer,
    employeesPartTime: formValues.partTimeEmployees,
    locations: formValues.physicalLocation,
    salesProducts: formValues.productSale,
    race: formValues.race,
    businessStructure: formValues.registerBusinessStructure,
    profitLossAmount: formValues.revenue,
    salesServices: formValues.serviceSale,
    targetAudience: formValues.targetAudience,
    employeesVolunteers: formValues.volunteers,
    wagesAveragePerHour: formValues.wages,
    businessStructureYear: formValues.year,
    organizationName: organizationName,
    businessName: businessProfile?.businessName || '',
  })
}
