import React, { useState, useEffect } from 'react'

import { ampli } from 'ampli'
import { FORM } from 'config'
import { USER_STATUS } from 'config'
import { isMobileOnly } from 'mobile-device-detect'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import { profileSchema } from 'pages/Owner/validation'
import useFormContainer from 'hooks/useFormContainer'

import {
  AssignOwnerToAdvisorContainer,
  AssignOwnerToAdvisorMobileViewContainer,
  EditBusinessProfileContainer,
} from 'pages/Admin/container/UserManagement'
import Button from 'components/Common/Button'
import Dialogue, { IDiaLogueType } from 'components/Clients/Client/Details/EditClient/Dialogue'
import EditUserProfile from 'components/Admin/UserManagement/EditUserProfile'
import ModalBox from 'components/Common/ModalBox'
import Loader from 'components/Loaders'
import SelectField from 'components/Common/SelectField'
import { SelectFieldWrapper } from 'components/Common/StyledComponents/SelectFieldWrapper'
import { useSelector } from 'react-redux'
import { RootState } from 'App'
import { replaceTerm } from 'utils/helper'

const EditAccount = ({
  userId,
  updateOwnerProfileAction,
  isBusiness,
  disableButton,
  userProfile,
  enableDisableUserAction,
  disableUserButton,
  getUserProfileAndAdvisorsAction,
  advisors,
  getClientsAction,
  updateAdvisorProfileAction,
  openUserManagementModal,
  openUserManagementEditModal,
  setOpenUserManagementModal,
  isUserProfileFormSet,
}) => {
  const methods = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(profileSchema),
  })

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
  } = methods

  const [errorsCount, setErrorsCount] = useState(Number)
  const [isSubIndustrySelected, setIsSubIndustrySelected] = useState(false)
  const [isIndustryChanged, setIndustryChanged] = useState(false)
  const [showIndustryModal, setShowIndustryModal] = useState(false)
  const [hasSubIndustry, setHasSubIndustry] = useState(false)
  const [formChanged, setFormChanged] = useState(false)
  const submitData = () => {
    setShowIndustryModal(false)
    isBusiness ? updateOwnerProfileAction({ userId }) : updateAdvisorProfileAction({ userId })
  }

  const onSubmit = () => {
    if (isIndustryChanged) {
      setShowIndustryModal(true)
      return
    }
    submitData()
  }

  const onToggleUserAccess = () => {
    const isCurrentlyEnabled = userProfile?.status === 'active'
    if (isCurrentlyEnabled) {
      ampli.accountDeactivated({ id: userId, email: userProfile?.email || 'No email found' })
    } else {
      ampli.accountActivated({ id: userId, email: userProfile?.email || 'No email found' })
    }

    enableDisableUserAction({
      status: isCurrentlyEnabled ? USER_STATUS.DISABLED : USER_STATUS.ACTIVE,
      userId,
      isBusiness,
    })
  }

  const checkErrors = (errors) => {
    setErrorsCount(Object.keys(errors).length)
  }

  useEffect(() => {
    getUserProfileAndAdvisorsAction({
      userId,
      setForms: [FORM.USER_PROFILE_FORM],
    })
  }, [getUserProfileAndAdvisorsAction, userId])

  useEffect(() => {
    getClientsAction({
      advisorId: userId,
      includeInvitedClients: true,
    })
  }, [getClientsAction, userId])

  useEffect(() => {
    if (!openUserManagementEditModal) {
      setOpenUserManagementModal(false)
    }
  }, [openUserManagementEditModal, setOpenUserManagementModal])

  useFormContainer(FORM.USER_PROFILE_FORM, methods)

  const bsoProfile = useSelector((state: RootState) => state.bso.bsoProfile)
  const replacedTerm = replaceTerm('advisor', bsoProfile.terminology).toUpperCase()

  const disabledButton = !isBusiness
    ? disableButton || errorsCount
    : !isValid ||
      errorsCount ||
      disableButton ||
      (hasSubIndustry && !isSubIndustrySelected) ||
      (!isDirty && !formChanged && !isIndustryChanged)

  return (
    <>
      <div className="h-min w-full">
        <div className={`${isBusiness ? 'bg-white' : 'bg-cream-dark'} `}>
          <div className="flex justify-between pt-5 px-7">
            <div className="pb-2">
              <label className="text-2xl font-primary text-primary-text">User Profile</label>
            </div>
            <div className="">
              <label className="text-silver-darker mr-2 font-primary font-semibold text-base">
                STATUS:
              </label>
              <label className="text-black-light font-primary italic capitalize text-lg	">
                {userProfile?.status}
              </label>
            </div>
          </div>
          <>
            <div>
              {!isUserProfileFormSet ? (
                <Loader loader={isBusiness ? 'EditUserProfile' : 'EditAdvisorUserProfile'} />
              ) : (
                <div className="xs:mb-7 sm:mb-0">
                  <SelectFieldWrapper>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div
                        className={`${
                          !isBusiness ? 'xs:pb-0 sm:pb-8' : ''
                        } grid xs:grid-cols-12 sm:grid-cols-12 mx-7 gap-3`}
                      >
                        <EditUserProfile
                          checkErrors={checkErrors}
                          register={register}
                          errors={errors}
                        />
                        {isBusiness && (
                          <div className=" grid grid-cols-1 col-span-12 gap-3 sm:pt-3 sm:pb-7">
                            <Controller
                              name={'advisors'}
                              control={control}
                              render={({ field }) => (
                                <SelectField
                                  {...field}
                                  isMulti={true}
                                  label={`${replacedTerm} ASSIGNMENT`}
                                  options={advisors}
                                  onChange={(value) => {
                                    field.onChange(value)
                                  }}
                                />
                              )}
                            />
                          </div>
                        )}
                      </div>
                    </form>
                  </SelectFieldWrapper>
                </div>
              )}
              {isBusiness && (
                <EditBusinessProfileContainer
                  setIsSubIndustrySelected={setIsSubIndustrySelected}
                  userId={userId}
                  onSubmit={onSubmit}
                  checkErrors={checkErrors}
                  isIndustryChanged={isIndustryChanged}
                  setIndustryChanged={setIndustryChanged}
                  setHasSubIndustry={setHasSubIndustry}
                  setFormChanged={setFormChanged}
                />
              )}
              {!isBusiness && (
                <>
                  <div className="xs:hidden sm:block">
                    <AssignOwnerToAdvisorContainer onSubmit={onSubmit} />
                  </div>
                  <div className="xs:block sm:hidden">
                    <AssignOwnerToAdvisorMobileViewContainer onSubmit={onSubmit} />
                  </div>
                </>
              )}
            </div>
          </>

          <div className="grid grid-cols-12 gap-1 px-7 pt-6 pb-6 bg-white">
            <div
              className={`xs:col-span-12 sm:col-span-3 md:col-span-3	mdl:col-span-3 col-start-1 ${
                isMobileOnly ? 'pb-[25px]' : ''
              } ${userProfile?.status === 'active' ? '' : 'px-[2.9px]'} `}
            >
              <Button
                label={
                  userProfile && userProfile?.status === 'active' ? 'Disable User' : '  Enable User'
                }
                variant="secondary"
                type="button"
                className={`${
                  userProfile?.status === USER_STATUS.ACTIVE ||
                  userProfile?.status === USER_STATUS.DISABLED
                    ? 'visible'
                    : 'invisible'
                } px-3 bg-secondary w-full`}
                onClick={onToggleUserAccess}
                disabled={disableUserButton}
              />
            </div>
            {isMobileOnly && (
              <hr className="border-t-[1px] border-primary-outline col-span-12 pb-[23px]"></hr>
            )}
            {isMobileOnly && (
              <div className="xs:col-span-12 sm:col-span-2	sm:col-start-11 xs:px-0 px-3">
                <Button
                  label="Save"
                  variant="primary"
                  className="w-full"
                  disabled={disabledButton}
                  type="submit"
                  onClick={onSubmit}
                />
              </div>
            )}

            <div className="xs:col-span-12 sm:col-span-2	sm:col-start-9	">
              <Button
                label="Cancel"
                type="button"
                variant={'secondary'}
                className={`${
                  userProfile?.status === USER_STATUS.ACTIVE ||
                  userProfile?.status === USER_STATUS.DISABLED
                    ? 'visible'
                    : 'invisible'
                } !outline-0 active:outline-0 w-full`}
                onClick={() => setOpenUserManagementModal(false)}
              />
            </div>
            {!isMobileOnly && (
              <div className="xs:col-span-12 sm:col-span-2	sm:col-start-11 xs:px-0 px-3">
                <Button
                  label="Save"
                  variant="primary"
                  disabled={disabledButton}
                  type="submit"
                  onClick={onSubmit}
                  className="w-full"
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {showIndustryModal && (
        <ModalBox onClose={() => setShowIndustryModal(false)} title="Save Changes">
          <Dialogue
            dialogueType={IDiaLogueType.UpdatedIndustry}
            onSubmit={(e) => submitData()}
            showModal={showIndustryModal}
            setShowModal={setShowIndustryModal}
          />
        </ModalBox>
      )}
    </>
  )
}

export default EditAccount
