import React, { useState } from 'react'

import { getRoleFromPath, navigateTo } from 'utils/helper'
import { AppFC } from 'types'

import RightDirectionArrowIcon from 'components/Common/SvgIcons/RightDirectionArrowIcon'
import Button from 'components/Common/Button'
import ModalBox from 'components/Common/ModalBox'
import { TermsAndConditionsContainer } from 'pages/TermsAndConditions/containers'
import { PrivacyPolicyContainer } from 'pages/PrivacyAndPolicy/containers'

interface ISettingItemProps {
  heading: string
  subHeading: string
  Icon: any
  clickAction: (...args: any[]) => any
}

const SettingItem: AppFC<ISettingItemProps> = ({ heading, subHeading, Icon, clickAction }) => {
  return (
    <div
      className="group max-w-full h-auto rounded-[20px] p-2 bg-white cursor-pointer shadow-md border border-white
                    hover:border hover:border-primary-brand active:border-none active:shadow focus:border-primary-focus"
    >
      <div className="grid grid-cols-5 " onClick={clickAction}>
        <div className="col-span-4 p-2">
          <p className="text-base font-bold text-primary group-hover:text-primary-brand group-focus:text-primary-brand group-active:text-primary-active ">
            {heading}
          </p>
          <p className="font-normal text-xs text-silver-darker">{subHeading}</p>
        </div>
        <div className="grid place-items-center">
          <Icon className="fill-primary group-hover:fill-primary-brand group-active:fill-primary-active group-focus:fill-primary-brand" />
        </div>
      </div>
    </div>
  )
}

export default function SettingPanel({ logoutAction }) {
  const [showPrivacy, setShowPrivacy] = useState(false)
  const [showTerms, setShowTerms] = useState(false)

  return (
    <div className="w-full">
      <div className="h-full right-0">
        <div className="grid grid-cols-1 gap-4 lg:max-w-full md:max-w-full font-primary h-[fit-content] xs:w-full sm:w-full md:w-full">
          <p className="max-w-[355px] h-[32px] font-normal mb-4 text-2xl text-purple-dark">
            Settings
          </p>
          {/* Use this icon */}
          {/* <RightDirectionArrowIcon /> */}
          <SettingItem
            Icon={RightDirectionArrowIcon}
            heading={'Change Password'}
            subHeading="It’s a good idea to use a strong password that you’re not using elsewhere."
            clickAction={() => navigateTo(getRoleFromPath(), `change-password`)}
          />

          <SettingItem
            Icon={RightDirectionArrowIcon}
            heading={'Change Default Time Zone'}
            subHeading="Here is where you can modify your default time zone."
            clickAction={() => navigateTo(getRoleFromPath(), `change-timezone`)}
          />
          {/** To be restored when a click action is available */}
          {/* <SettingItem
            Icon={RightDirectionArrowIcon}
            heading={'Notification Preferences'}
            subHeading={`${PRODUCT_NAME} may still send you important notifications about your account outside of your preferred notification settings.`}
          /> */}

          <SettingItem
            Icon={RightDirectionArrowIcon}
            heading={'View Data Privacy Policy'}
            subHeading="Information about how we protect your data."
            clickAction={() => setShowPrivacy(!showPrivacy)}
          />

          <SettingItem
            Icon={RightDirectionArrowIcon}
            heading={'View Terms & Conditions'}
            subHeading="Information about your participation on the dashboard."
            clickAction={() => setShowTerms(!showTerms)}
          />
          <div className="mb-2">
            <Button
              label="Sign Out"
              variant="secondary"
              onClick={() => logoutAction()}
              className="w-full"
            />
          </div>
        </div>
        {showTerms && (
          <ModalBox width={'w-full'} height="h-max" showCancelButton={false}>
            <TermsAndConditionsContainer setShow={setShowTerms} />
          </ModalBox>
        )}
        {showPrivacy && (
          <ModalBox width={'w-full'} height="h-max" showCancelButton={false}>
            <PrivacyPolicyContainer setShow={setShowPrivacy} />
          </ModalBox>
        )}
      </div>
    </div>
  )
}
