import * as yup from 'yup'
import { UPPER_CASE_REGEX, NUMBER_REGEX, SPECIAL_CHARACTER_REGEX } from 'config'
import ValidationSchemaBuilder from 'utils/ValidationSchemaBuilder'

const schemaBuilder = new ValidationSchemaBuilder()

export const schema = schemaBuilder.email().getSchema()

export const forgotPasswordSchema = schemaBuilder.email().getSchema()

export const resetPasswordSchema = schemaBuilder
  .custom({
    password: yup
      .string()
      .required('Password not complete')
      .min(8, 'Password rules not met')
      .matches(RegExp(UPPER_CASE_REGEX), 'Password rules not met')
      .matches(RegExp(NUMBER_REGEX), 'Password rules not met')
      .matches(RegExp(SPECIAL_CHARACTER_REGEX), 'Password rules not met'),
    confirmPassword: yup
      .string()
      .required('Password not complete')
      .oneOf([yup.ref('password'), null], 'Passwords do not match'),
  })
  .getSchema()
