import { getIsAllowCookies, saveUser } from 'utils/user'
import {
  ROLES,
  PLAY_STEPS,
  PLAY_INTEGRATION_QUERY_PARAMS,
  FORM,
  MEDIA_BUCKET,
  customLabels,
} from 'config'
import { scroller } from 'react-scroll'
import history from 'utils/history'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import isEqual from 'lodash/isEqual'
import find from 'lodash/find'
import moment from 'moment'
import hookForms from 'utils/hookForms'
import set from 'lodash/set'
import { getMultipleRecommendations } from 'utils/assessments'
import { filter, includes, isEmpty, omit } from 'lodash'
import { ampli } from 'ampli'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import { round } from 'javascript-time-ago/steps'
import redirectTo from './redirectTo'
import { getExt } from 'components/Common/Dropzone/utils'
import { store } from 'App'
import { ZendeskSettings, ZendeskWidget } from 'zendesk-types'
import { REVENUE } from 'components/MonthlyAttestation/utils'
import { AttestationMinusIcon, AttestationPlusIcon } from 'components/Common/SvgIcons'
declare global {
  interface Window {
    zESettings?: ZendeskSettings
    zE?: ZendeskWidget
  }
}

export const API_TYPE = (action: string) => ({
  STARTED: `${action}_STARTED`,
  FULLFILLED: `${action}_FULLFILLED`,
  REJECTED: `${action}_REJECTED`,
})

/** Get the last piece of a given URL path */
export const getFinalPathExtension = (path) => path?.split('/').reverse()?.[0] || ''

export const userInitialRouting = (userProfile, tenantId: string, next?: string) => {
  const isAllow = getIsAllowCookies()
  if (isAllow) {
    saveUser(userProfile)
  }
  if (get(userProfile, 'getMyProfile.roles[0]', '')) {
    const role = userProfile.getMyProfile.roles[0].toUpperCase()
    if (role === 'BUSINESS_OWNER' && get(userProfile, 'getMyProfile.isNewUser', false)) {
      redirectTo(`/owner/${tenantId}/assessment?show-subscription-modal`)
    } else if (
      (role === 'BSO_ADVISOR' || role === 'BSO_ADMIN') &&
      get(userProfile, 'getMyProfile.isNewUser', false)
    ) {
      redirectTo(
        `/${ROLES[`${role}`]}/${tenantId}/onboarding/welcome-advisor?show-subscription-modal`
      )
    } else {
      if (next?.includes(ROLES[role])) {
        const separator = next.includes('?') ? '&' : '?'
        redirectTo(next + separator + 'show-subscription-modal')
      } else {
        redirectTo(`/${ROLES[`${role}`]}/${tenantId}/dashboard?show-subscription-modal`)
      }
    }
  } else {
    if (next) {
      const separator = next.includes('?') ? '&' : '?'
      redirectTo(next + separator + 'show-subscription-modal')
    } else {
      redirectTo('/auth/login')
    }
  }
}

// A function to replace keyword with it's replacement
export const replaceTerm = (
  inputTerm: string,
  terminology: { term: string; replacement: string }[]
): string => {
  const bsoTerms = terminology || []
  const termInfo = bsoTerms?.find(
    (termObj) => termObj.term.toLowerCase() === inputTerm.toLowerCase()
  )
  const outputTerm = termInfo ? termInfo.replacement : inputTerm
  return outputTerm.charAt(0).toUpperCase() + outputTerm.slice(1)
}

export const isActiveStep = (activeStep, title) => {
  return activeStep === title ? 'block' : 'hidden'
}

export const getRoleFromPath = (): string | undefined => {
  const pathname = history.location.pathname.split('/')
  return pathname?.[1]
}

export const getCurrentRoute = () => {
  return `${window.location.pathname}${window.location.search}`
}

export const scrollToTop = () => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
}

export const handleSelectedTab = (
  tabName,
  userRole,
  path,
  tenantId = '',
  isSelectedTab = '',
  openNotifications = false
) => {
  if (openNotifications && tabName.toLowerCase() === 'bell') return true
  if (isSelectedTab) {
    path = path.replace(`/${isSelectedTab}`, '')
  }
  return path === `/${userRole}/${tenantId}/${tabName}`
}

export const copy = (data) => {
  return JSON.parse(JSON.stringify(data))
}

export const getAssessmentResponse = ({ keys, assessment_questions }) => {
  const assessmentResponse: any = []
  Object.keys(keys).forEach((key, index) => {
    if (keys[key]) {
      // find recommendation object is available in selected option of assestion question
      let activeQuestion = assessment_questions.find((question) => question.id === key)
      let answer
      answer = activeQuestion?.options.find((option_id) => option_id.id === keys[key])

      if (activeQuestion?.meta?.allowMultiSelect) {
        answer = filter(activeQuestion?.options, (op) => includes(keys[key], op.id))
      }

      assessmentResponse.push({
        id: key,
        value: keys[key],
        isTopGoal: activeQuestion?.meta.isTopGoal || undefined,
        recommendations: activeQuestion?.meta?.allowMultiSelect
          ? getMultipleRecommendations(answer)
          : answer?.action?.recommendations || undefined,
        isIndustry: activeQuestion?.meta?.isIndustry || undefined,
        isSubIndustry: activeQuestion?.meta?.isSubIndustry || undefined,
      })
    }
  })

  return assessmentResponse
}

export const getActiveStepAndNavigationLink = ({ playStage, stepName, id }) => {
  let activeStep = ''
  let link = ''
  let activeStepIndex = 0
  if (!playStage?.guide?.isCompleted) {
    activeStep = PLAY_STEPS.GUIDE
    link = getLink(id, PLAY_STEPS.GUIDE)
  } else if (!playStage?.setUp?.isCompleted) {
    activeStep = PLAY_STEPS.SETUP
    link = getLink(id, PLAY_STEPS.SETUP)

    playStage?.setUp?.steps?.every((step, index) => {
      if (!step.isCompleted) {
        activeStepIndex = index
        return false
      }
      return true
    })
  } else if (!playStage?.use?.isCompleted) {
    activeStep = PLAY_STEPS.USE
    link = getLink(id, PLAY_STEPS.USE)
  } else if (
    (playStage?.use?.isCompleted && !playStage.isCompleted) ||
    (playStage && playStage.isCompleted)
  ) {
    activeStep = PLAY_STEPS.GUIDE
    link = getLink(id, PLAY_STEPS.GUIDE)
  }

  if (history.location.search.includes(PLAY_INTEGRATION_QUERY_PARAMS.SUCCESS)) {
    link = link + PLAY_INTEGRATION_QUERY_PARAMS.SUCCESS
  }

  return {
    activeStep,
    link,
    activeStepIndex,
  }
}

export const getPlayStageAndAssessmentResponse = ({ assessments, play }) => {
  let playStage
  if (assessments) {
    playStage = JSON.parse(assessments?.playStage)

    assessments.assessmentResponse = assessments.assessmentResponse
      ? JSON.parse(assessments.assessmentResponse)
      : []
  }

  if (!playStage && !play.playTabs) {
    // get play steps
    let getPlaySteps = play?.setup?.steps?.map((step) => {
      return {
        isCompleted: false,
        id: step?.id,
        icon: step?.icon,
        title: step?.title,
      }
    })

    // create play-state
    playStage = {
      isCompleted: false,
      guide: {
        isCompleted: false,
      },
      setUp: {
        isCompleted: false,
        steps: getPlaySteps,
      },
      use: {
        isCompleted: false,
      },
    }
  } else if (!playStage && play.playTabs) {
    playStage = []
    for (let i = 0; i < play.playTabs.length; i++) {
      for (let j = 0; j < play.playTabs[i].tabData.steps.length; j++) {
        set(playStage, `[${i}].steps[${j}].isCompleted`, false)
      }
    }
  }
  return { playStage, assessmentResponse: assessments }
}

export const sortHandlerHelper = ({ sortField, sortOrder, fieldType, data }) => {
  try {
    if (data?.length === 0) {
      return []
    }

    let sortedData = [...data]

    if (sortField !== null) {
      sortedData.sort((a, b) => {
        const fieldA = a[sortField]
        const fieldB = b[sortField]

        const fieldASortable = fieldA !== undefined && fieldA !== ''
        const fieldBSortable = fieldB !== undefined && fieldB !== ''

        // this logic forces undefined values or empty strings to the bottom of a sorted list when sort order is "DESC" - this prevents their default placement at the top in some situations
        if (fieldASortable && !fieldBSortable) return sortOrder === 'DESC' ? 1 : -1
        if (fieldBSortable && !fieldASortable) return sortOrder === 'DESC' ? -1 : 1
        if (!fieldBSortable && !fieldASortable) return 0

        if (fieldType === 'date') {
          let dateA = new Date(fieldA)
          let dateB = new Date(fieldB)
          // @ts-ignore - JS knows how to compare dates despite TS warnings
          return sortOrder === 'ASC' ? dateA - dateB : dateB - dateA
        } else {
          if (fieldA.toLowerCase() < fieldB.toLowerCase()) {
            return sortOrder === 'ASC' ? -1 : 1
          }
          if (fieldA > fieldB) {
            return sortOrder === 'ASC' ? 1 : -1
          }
        }

        return 0
      })
    }

    return sortedData
  } catch (err: any) {
    console.log('error: sortHandlerHelper: ', err.message)
    return []
  }
}

export const dateFormatterHelper = (date) => {
  try {
    let updatedDate = ''

    if (!date) {
      return ''
    }

    const currentDate: number = new Date(moment().format('YYYY-MM-DD')).getTime()
    const comingData: number = new Date(moment(date).format('YYYY-MM-DD')).getTime()

    const diffTime = Math.abs(currentDate - comingData)
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

    if (diffDays === 0) {
      updatedDate = 'Today'
    } else if (diffDays === 1) {
      updatedDate = 'Yesterday'
    } else {
      updatedDate = moment(date).format('LL')
    }

    return updatedDate
  } catch (err) {
    console.log(err)
    return ''
  }
}

export const isImgLink = (url: string) => {
  if (typeof url !== 'string') return false
  return url.match(/^https?:\/\/.+\.(jpg|jpeg|png|webp|avif|gif|svg)$/) != null
}

export const getFormData = () => {
  const userProfileForm = hookForms.getForm(FORM.USER_PROFILE_FORM)
  const BusinessForm = hookForms.getForm(FORM.USER_MANAGEMENT_BUSINESS_FORM)

  const otherBusinessForm = hookForms.getForm(FORM.BUSINESS_OTHER_DETAILS)

  const userProfileFormData = userProfileForm?.getValues()

  const BusinessFormData = BusinessForm?.getValues()

  const otherBusinessFormData = otherBusinessForm?.getValues()
  const data = { ...userProfileFormData, ...BusinessFormData, ...otherBusinessFormData }
  return data
}

/** Takes a raw number and returns a string version formatted with commas every 3 digis (in U.S. fashion) */
export const formatNumber = (val: number) => {
  if (val) return val.toLocaleString('en-US')
  return val
}

export const timeDuration = (data: string) => {
  const customStyle = {
    steps: round,
    labels: 'custom',
  }
  TimeAgo.addLabels('en', 'custom', customLabels)

  TimeAgo.addLocale(en)

  const timeAgo = new TimeAgo('en-US')

  let formattedDate = moment(data).format('MMMM DD YYYY, h:mm:ss a')

  let date: Date = new Date(formattedDate)

  let timeInMilliseconds: number = date.getTime()

  let time = timeAgo.format(timeInMilliseconds - 1 * 1000, customStyle)

  return time
}

export const isSameObject = (data, orgData) => {
  const diffVal = find(data, (value, key) => {
    const refValue = get(orgData, key)
    if ((value || refValue) && !isEqual(value, refValue)) {
      return true
    }
    return false
  })
  return isNil(diffVal)
}

export const openNewWindow = (url, isLaunchType) => {
  const h = isLaunchType ? window.outerWidth : window.outerWidth / 2
  const w = isLaunchType ? window.outerHeight : window.outerHeight / 2
  const y = window.outerHeight / 2 + window.screenY - h / 2
  const x = window.outerWidth / 2 + window.screenX - w / 2

  return window.open(
    url,
    'newWindow',
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyHistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
  )
}

export const onLaunchTool = (tool: any) => {
  ampli.toolLaunched({ id: tool.id, name: tool.toolName })
  const toolWindowHandle = openNewWindow(tool.toolUrl, true)
  if (!toolWindowHandle) return
  var timer = setInterval(function () {
    if (toolWindowHandle.closed) {
      clearInterval(timer)
      ampli.toolClosed({ id: tool.id, name: tool.toolName })
    }
  }, 1000)
  return timer
}

export const getImageUrl = (url) => {
  return `${MEDIA_BUCKET}/${url}`
}

export const checkPlayCompletion = ({ play, playStage }) => {
  let playCompleted = false
  if (play.isCompleted) {
    for (let i = 0; i < play.playTabs.length; i++) {
      for (let j = 0; j < play.playTabs[i].tabData.steps.length; j++) {
        set(playStage, `[${i}].isCompleted`, false)
      }
    }
  }
  playCompleted = play.isCompleted
  return { updatedPlayStage: playStage, playCompleted }
}

export const scrollToSpecificAssessment = (list, openAssessment, tenantId) => {
  scroller.scrollTo(`assessment-${list.assessmentType}`, {
    duration: 1500,
    delay: 100,
    smooth: true,
    containerId: 'containerElement',
    offset: -80,
  })
  if (openAssessment) openAssessment()
  if (get(list, 'type', '') === 'play') history.push(`/owner/${tenantId}/plays/${list.id}/guide`)
}

export const checkIndustry = (
  question,
  profileFormValues,
  subIndustryQuestion,
  foodIndustryQuestionOption,
  industryQuestion,
  servicesSubIndustryQuestion,
  serviceIndustryQuestionOption
) => {
  if (
    (profileFormValues &&
      subIndustryQuestion &&
      question?.id === subIndustryQuestion?.id &&
      profileFormValues[industryQuestion.id] === foodIndustryQuestionOption?.id) ||
    (profileFormValues &&
      servicesSubIndustryQuestion &&
      question?.id === servicesSubIndustryQuestion?.id &&
      profileFormValues[industryQuestion.id] === serviceIndustryQuestionOption?.id)
  ) {
    return true
  } else if (
    (servicesSubIndustryQuestion && question?.id === servicesSubIndustryQuestion?.id) ||
    (subIndustryQuestion && question?.id === subIndustryQuestion?.id)
  ) {
    return false
  } else {
    return true
  }
}
export const isQuestionHaveSubIndustry = (
  profileFormValues,
  foodIndustryQuestionOption,
  serviceIndustryQuestionOption,
  industryQuestion
) => {
  if (
    profileFormValues[industryQuestion?.id] === foodIndustryQuestionOption?.id ||
    profileFormValues[industryQuestion?.id] === serviceIndustryQuestionOption?.id
  ) {
    return true
  } else {
    return false
  }
}

export const checkFormData = (timeSlots) => {
  let isFormDataValid = true

  for (let i = 0; i < timeSlots?.length; i++) {
    if (timeSlots[i].checked) {
      for (let j = 0; j < timeSlots[i]?.slots.length; j++) {
        if (
          isEmpty(timeSlots[i]?.slots[j]?.startTime) ||
          isEmpty(timeSlots[i]?.slots[j]?.endTime)
        ) {
          isFormDataValid = false
          break
        } else {
          isFormDataValid = true
        }
      }
    }
    if (!isFormDataValid) break
  }
  let isValid = timeSlots?.every((slot) => !slot.checked)
  if (isValid) {
    isFormDataValid = false
  }

  return isFormDataValid
}

export const createTimeSlot = (startingTime: string, endingTime: string, timeIncrement: number) => {
  let startTimeMoment = moment(startingTime, 'hh:mm A')
  let startingTimeTwelveHour: any = startingTime

  const endTimeMoment = moment(endingTime, 'hh:mm A')

  if (endTimeMoment.isSameOrBefore(startTimeMoment)) {
    endTimeMoment.add(1, 'day')
  }

  let startTimeTwentyFourHour = moment(startTimeMoment).format('HH:mm')
  const timeSlotsArray: { label: string; value: string }[] = []
  while (startTimeMoment.isSameOrBefore(endTimeMoment)) {
    timeSlotsArray.push({ label: startingTimeTwelveHour, value: startTimeTwentyFourHour })
    startingTimeTwelveHour = startingTime
    startTimeMoment = startTimeMoment.add(timeIncrement, 'm')
    startTimeTwentyFourHour = moment(startTimeMoment).format('HH:mm')

    startingTimeTwelveHour = startTimeMoment.format('hh:mm A')
  }
  return timeSlotsArray
}
export const removeEmptySlots = (timeSlots) => {
  let updatedTimeSlots = timeSlots

  updatedTimeSlots.forEach((obj) => {
    obj.slots = obj.slots.filter((item) => item.startTime !== '')
  })

  updatedTimeSlots = updatedTimeSlots.map((slot) => {
    return omit(slot, ['checked'])
  })
  return updatedTimeSlots
}

export const createResponse = (data, formData, playData) => {
  let response = data.map((item, index) => {
    return {
      [item.questionType]: true,
      value: formData[Object.keys(formData)[index]],
      id: playData?.recommendedBy?.id,
    }
  })
  return response
}
export const formatFileNameWithExtension = (fileName: string) => {
  if (fileName?.length <= 16) {
    return fileName
  } else {
    return fileName?.substring(0, 16) + '...' + getExt(fileName)
  }
}
export const checkError = (regex, selectedOption, isSubmitted) => {
  return (
    (regex && selectedOption && !selectedOption.match(regex)) ||
    (isSubmitted && isEmpty(selectedOption))
  )
}
export const getErrorMessage = (label) => `Please enter a valid ${label?.toLowerCase()}`

export const checkTimeSlots = (parentSlots, timeSlotsCreation, childIndex) => {
  let isFound = false
  for (let i = 1; i < timeSlotsCreation.length - 1; i++) {
    const valueToCheck = timeSlotsCreation[i].value

    for (let j = 0; j < parentSlots.length; j++) {
      if (j !== childIndex) {
        if (valueToCheck === parentSlots[j].startTime || valueToCheck === parentSlots[j].endTime) {
          isFound = true
          break
        }
      }
    }
  }

  return isFound
}
export const formatSlotTime = (time) => {
  const formattedTime = moment(time, 'HH:mm').format('h:mm A')
  return formattedTime
}

export const isDisabled = (level, assessmentsListing, type) => {
  if (assessmentsListing.length > 0) {
    if (level === 'Essentials') {
      const assessment = assessmentsListing.find(
        (a) =>
          a.assessmentType.startsWith(type) && a.assessmentType[a.assessmentType.length - 1] === '1'
      )

      if (assessment && !assessment?.isLocked) {
        return false
      } else {
        return true
      }
    }
    // TODO: Uncomment this once we have a clear set of rules around when intermediate plays should be shown.
    // } else if (level === 'Intermediate') {
    //   const assessment = assessmentsListing.find(
    //     (a) =>
    //       a.assessmentType.startsWith(type) && a.assessmentType[a.assessmentType.length - 1] === '2'
    //   )
    //   if (assessment && !assessment?.isLocked) {
    //     return false
    //   } else {
    //     return true
    //   }
    // } else if (level === 'Advanced') {
    //   const assessment = assessmentsListing.find(
    //     (a) =>
    //       a.assessmentType.startsWith(type) && a.assessmentType[a.assessmentType.length - 1] === '3'
    //   )
    //   if (assessment && !assessment?.isLocked) {
    //     return false
    //   } else {
    //     return true
    //   }
    // }
    // return false
  }
  return true
}
export const showToast = (toastData, currentUser, closeToast, boAdvisor) => {
  return (
    toastData?.variant &&
    currentUser?.attestationStatus === 'UNSIGNED' &&
    !closeToast &&
    !isEmpty(boAdvisor) &&
    !window.location.href.includes('?event=signing_complete')
  )
}
export const checkAvailability = (date, userAvailabilityList) => {
  const dateString = moment(date).format('YYYY-MM-DD')
  const checkDate = userAvailabilityList.availability.find((item) => item.date === dateString)
  return checkDate ? true : false
}

export const formatValue = (value) => {
  const sanitizedValue = value.replace(/,/g, '')
  const isValid = /^\d*$/.test(sanitizedValue)
  let formattedValue = ''

  if (isValid && sanitizedValue !== '') {
    formattedValue = parseInt(sanitizedValue, 10).toLocaleString()
  } else {
    formattedValue = value.slice(0, -1)
  }

  return { value: formattedValue }
}

export const getTenantId = () => {
  const state = store.getState()
  const tenantId = state.user?.tenantId
  return tenantId
}
export const navigateTo = (
  role: string | undefined,
  path: string,
  isHistoryReplaced?: boolean,
  step?: string
) => {
  if (isHistoryReplaced) {
    history.replace(`/${role}/${getTenantId()}/${path}/${step}`)
  } else {
    history.push(`/${role}/${getTenantId()}/${path}`)
  }
}

//this is function for toggle help desk
export const helpDesk = () => {
  if (window.zE) window.zE('messenger', 'open')
}
export const convertStringToNumber = (value) => {
  return value?.replace('$', '').replace(',', '')
}

export const getIcon = (financialGrowthCheck: string) => {
  if (financialGrowthCheck === REVENUE.PROFIT) {
    return AttestationPlusIcon
  } else if (financialGrowthCheck === REVENUE.LOSS) {
    return AttestationMinusIcon
  }
  return null
}
export const getLink = (id: string, playStep: string) =>
  `/${ROLES.BUSINESS_OWNER}/${getTenantId()}/plays/${id}/${playStep}`

export const replaceState = (title: string) => {
  window.history.replaceState(null, title, `/${getRoleFromPath()}/${getTenantId()}/chat`)
}

export const goToNextPath = (item) => {
  const next = item?.path?.replace?.(':tenantId', getTenantId()) || ''
  if (next) history.push(next)
}

export const formatCurrency = (value, currencyType = 'USD') => {
  const numericValue = parseFloat(value)
  if (isNaN(numericValue)) return
  return numericValue.toLocaleString('en-US', {
    style: 'currency',
    currency: currencyType,
    minimumFractionDigits: 0,
  })
}
export const viewNotificationDetails = (notification, handleClose) => {
  if (notification.url) {
    history.push(`/${notification?.url}`)
    handleClose()
  }
}
export const sortList = (list) => {
  list.sort((a, b) =>
    (a.label.trim().toLowerCase() || '').localeCompare(b.label.trim().toLowerCase() || '')
  )
}

export function replaceAll(str: string, find: RegExp, replace: string) {
  return str.replace(new RegExp(find), replace)
}
export const cleanSubscriptionModalQueryParams = () => {
  let updatedPathName = window.location.href
    .replace(/[?&]show-subscription-modal\b/g, '')
    .replace(/[?&]/, '?')

  const splitUrl = updatedPathName.split('/')
  updatedPathName = '/' + splitUrl.slice(3).join('/')

  return updatedPathName
}
