import styled from 'styled-components'
import tw from 'twin.macro'

export const CalendlyWrapper = styled.div`
  .calendly-inline-widget {
    height: 630px !important;
  }
`
export const CalendarToolWrapper = styled.div`
  ${tw`flex xs:flex-col sm:flex-row xs:gap-8 sm:gap-10 pt-9 pb-20 xs:px-5 sm:pr-10 sm:pl-6`}
`
export const ConnectCalendarWrapper = styled.div`
  ${tw`grid-cols-12 w-full h-auto`}
`
export const MobileTitleWrapper = styled.div`
  ${tw`flex items-center justify-start sm:hidden my-4`}
`
export const MobileTitle = styled.div`
  ${tw`font-secondary text-4xl`}
`
export const ConnectButtonWrapper = styled.div`
  ${tw`flex items-center xs:justify-between sm:justify-end`}
`
export const TextButtonWrapper = styled.main.attrs({
  className: 'flex xs:gap-4 sm:px-4 ',
})`
  & {
    .connectMeetingButtons {
      ${tw`xs:!py-4 xs:!px-0`}
    }
  }
`

export const CalanderWrapper = styled.div`
  .rbc-month-header .rbc-header {
    font-family: 'Futura Std' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 19px !important;
    background-color: #ffffff !important;
    color: #888888 !important;
    border-top: 0 !important;
    border-bottom: 1px solid #e8e8e8 !important;
    text-transform: uppercase;
    padding: 10px;
    border-right: 0px !important;
    height: 100% !important;

    @media only screen and (min-width: 992px) {
      font-size: 16px !important;
      padding: 15px;
    }
  }
  .rbc-time-view {
    border: 1px solid #e8e8e8 !important;
    border-radius: 10px !important;
    color: #969696 !important;
    font-family: 'Futura Std';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    overflow: hidden;
  }

  .rbc-time-content {
    border-top: 0 solid #ddd;
  }
  .rbc-time-header.rbc-overflowing {
    border-right: 0 solid #ddd;
  }
  .rbc-timeslot-group:first-child {
    border-top: 1px solid #ddd;
  }
  .rbc-label {
    paddin: 0 12px;
  }
  .rbc-row.rbc-time-header-cell .rbc-header {
    font-family: 'Futura Std' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    background-color: transparent !important;
    color: #888888 !important;
    border-top: 0 !important;
    border-bottom: 0 solid #e8e8e8 !important;
    text-transform: uppercase;
    padding: 15px;
    border-right: 0px !important;
  }
  .rbc-month-header .rbc-header:first-child {
    border-radius: 10px 0 0 0;
    border-left: 0 !important;
  }
  .rbc-month-header .rbc-header:nth-child(5) {
    border-radius: 0 10px 0 0;
    border-right: 0 !important;
  }
  .rbc-month-row {
    overflow: visible;
  }
  /* .rbc-month-header .rbc-header:nth-child(6) {
  display: none !important;
}
.rbc-month-header .rbc-header:nth-child(7) {
  display: none !important;
}
.rbc-month-row .rbc-row-bg .rbc-day-bg:nth-child(6) {
  display: none !important;
}
.rbc-month-row .rbc-row-bg .rbc-day-bg:nth-child(7) {
  display: none !important;
}
.rbc-row-content {

  z-index: 0 !important;
}
.rbc-month-row .rbc-row-content .rbc-row .rbc-date-cell:nth-child(6) {
  display: none !important;
}
.rbc-month-row .rbc-row-content .rbc-row .rbc-date-cell:nth-child(7) {
  display: none !important;
} */
  /* .rbc-event {
  min-width: 50px;
  min-height: 25px !important;
  background-color: #d0f1fc;
  color: #0085ff;
  border-radius: 5px;
  padding: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
} */

  .rbc-event.rbc-selected {
    min-width: 50px;
    min-height: 25px !important;
    background-color: #e8e8e8;
    color: #585757;
    border-radius: 5px;
    padding: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  .rbc-event-label {
    display: none !important;
  }

  .rbc-row.rbc-time-header-cell {
    height: 40px !important;
  }

  .rbc-header {
    height: 40px !important;
    background-color: #e6ebee !important;
    color: #7c92a9 !important;
    border: 1px solid #e6ebee !important;
    border-bottom: 3px solid #e6ebee !important;
    text-align: left;
    display: flex;
    align-items: center;
    pointer-events: none;
  }

  .rbc-header a {
    pointer-events: none;
  }

  .rbc-header.rbc-today {
    color: #12b8f1 !important;
    border-bottom: 3px solid #12b8f1 !important;
  }

  .rbc-today {
    background-color: #fff;
  }

  .rbc-current-time-indicator {
    background-color: red !important;
    height: 2px !important;
    border-radius: 2px;
    z-index: 0;
  }

  .rbc-current-time-indicator::before {
    display: block;
    content: '';
    height: 8px;
    width: 8px;
    background-color: red !important;
    border-radius: 50%;
    transform: translateY(-3px);
  }

  .rbc-allday-cell {
    display: none;
  }

  // .rbc-day-bg.rbc-today {
  //   background-color: #d0f1fc66 !important;
  // }
  .rbc-day-bg {
    background-color: #ffffff !important;
  }
  .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid #e8e8e8;
  }

  .rbc-month-view {
    border: 1px solid #e8e8e8 !important;
    border-radius: 10px !important;
    color: #969696 !important;
    font-family: 'Futura Std';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    /* overflow: hidden; */
  }
  .rbc-date-cell {
    padding: 10px;
    text-align: left;
    @media only screen and (min-width: 992px) {
      padding: 12px;
    }
  }
  .rbc-date-cell.rbc-now.rbc-current a {
    color: #259aed !important;
    font-weight: bolder;
  }
  .rbc-button-link {
    font-size: 14px;
    color: #888888;
    @media only screen and (min-width: 992px) {
      font-size: 18px;
    }
  }
  .rbc-row-segment {
    padding: 2px 5px;
  }
  .rbc-date-cell.rbc-now .rbc-button-link {
    color: #ffffff;
    background-color: #52b4f9;
    width: 30px;
    height: 30px;
    border-radius: 50px;
  }
  .rbc-off-range-bg {
    background-color: #f4f4f5 !important;
  }
`

export const CalanderField = styled.div`
  /* .select__single-value {
    color: #5bbab9;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-tranform: capitalize;
  }
  .select__control {
    border: 1px solid #5bbab9;
    background: #fafafa;
  }
  .select__indicator {
    color: #3f3f46;
  } */
`
export const CalanderCardWrapper = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 347px;
`
export const EventWrapper = styled.div`
  &.close {
    .withoutExpendWrap {
    }
  }
  &.open {
    border: 1px solid #5bbab9;
    border-radius: 10px;
    .withoutExpendWrap {
      .arrowIcon {
        SVG {
          transform: rotate(90deg);
        }
      }
    }
  }
`

export const ExpendArea = styled.div`
  border-top: 1px solid rgba(37, 99, 235, 0.5);
  margin-top: 20px;
  padding-top: 20px;
`
export const RequestMeeting = styled.div`
  button {
    padding: 15px 25px;
  }
`

export const RequestMeetingMobile = styled.div`
  button {
    padding: 10px 12px;
    font-size: 16px;
  }
  @media screen and (min-width: 1200px) {
    padding: 15px 25px;
    font-size: 16px;
  }
`
export const CalanderTopBarMobile = styled.div`
  display: flex;
  align-item: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid #d4d4d8;
  flex-direction: column-reverse;
  gap: 10px;

  svg {
    &.open {
      transform: rotate(180deg);
    }
  }
  @media screen and (min-width: 868px) {
    fle-direction: row;
  }

  @media screen and (min-width: 1200px) {
    display: none;
  }
`

export const CalendarCardWrapper = styled.main.attrs({
  className: 'xs:mb-6 md:mb-0 md:col-span-6 lg:col-span-3 ',
})`
  & {
    .card {
      ${tw`shadow-lg rounded-xl h-full flex flex-col  max-w-[347px] bg-white`}
    }
    .imgCard {
      ${tw` rounded-t-[10px] flex items-center justify-center p-6 min-h-[130px]`}
    }
    .imgCardContent {
      ${tw`w-full max-w-[180px]`}
    }
    .cardBody {
      ${tw`py-5 px-4 flex flex-col h-full`}
    }
    .cardBodyHeading {
      ${tw`text-primary-text font-bold text-lg my-6 text-center`}
    }
    .cardBodyContent {
      ${tw`mt-auto flex justify-center`}
    }
    .cardBodyButton {
      ${tw`flex justify-center sm:w-[227.83px] xs:w-[183px]`}
    }
  }
`
export const BoAdvisorWrapper = styled.div`
  ${tw`p-6 min-h-[200px] xs:h-full sm:h-auto xs:w-full sm:w-auto`}
`

export const MeetingTime = styled.div`
  ${tw`flex flex-col gap-1 py-9`}
`

export const MeetingTimeTitle = styled.label`
  ${tw`text-base block`}
`

export const MeetingTimeDuration = styled.label`
  ${tw`font-bold text-base text-primary block`}
`

export const MeetingScheduleWrapper = styled.div``

export const AdvisorWrapper = styled.div`
  ${tw`flex flex-col gap-1`}
`

export const AdvisorTitle = styled.label`
  ${tw`text-base`}
`

export const AdvisorHeading = styled.label`
  ${tw`font-bold text-base`}
`

export const InputFieldsWrapper = styled.div`
  ${tw`mb-8 w-full`}
`
export const ButtonsFieldWrapper = styled.div`
  ${tw`my-9`}
`

export const Divider = styled.hr`
  ${tw`col-span-12 border-primary-outline border-t-2 mt-4`}
`

export const MeetingWrapper = styled.div`
  ${tw`px-6 grid grid-cols-12`}
`
export const FormWrapper = styled.form`
  ${tw``}
`
export const CalendarTitle = styled.div`
  ${tw`text-base mt-7 col-span-12 font-primary text-primary-text`}
`

export const SelectDateWrapper = styled.div`
  ${tw`col-span-12`}
`

export const RequestMeetingWrapper = styled.div`
  ${tw`col-span-12 py-7`}
`

export const SelectedDate = styled.label`
  ${tw`font-medium text-xl mb-4`}
`

export const TimeDuration = styled.div`
  ${tw`mb-5`}
`

export const StartTimeLabel = styled.div`
  ${tw`mt-10 mb-4`}
`

export const IconWithLabel = styled.label`
  ${tw`xs:col-span-12 text-lg my-2 flex items-center`}
`
export const UpcomingEventsWrapper = styled.div`
  ${tw`xs:col-span-12 lg:col-span-4 xs:mt-9 lg:mt-12 pb-1`}
`
export const EventsWrapper = styled.div`
  ${tw`text-primary-text lg:mt-0 xs:text-xl lg:text-3xl font-normal leading-9 font-secondary`}
`
export const DateWrapper = styled.div`
  ${tw`text-silver-light text-base font-normal leading-6 font-primary`}
`

export const NoSlotLabel = styled.label`
  ${tw`p-12 col-span-12 text-center sm:text-lg text-primary font-primary font-bold`}
`

export const DescriptionLabel = styled.label`
  ${tw`xs:text-base sm:text-lg font-medium`}
`

export const TimeSlotsPanel = styled.div`
  ${tw`overflow-y-auto px-3 xs:h-[60vh]`}
`

export const CalendarWrapper = styled.div.attrs({
  className: 'col-span-12 py-7',
})`
  & {
    .react-calendar {
      border: 0;
    }
    .ab {
    }
    .react-calendar .highlight abbr {
      font-weight: bold;
      background-color: rgba(94, 6, 185, 0.2);
    }
    .react-calendar .highlight {
      background-color: transparent;
    }
    .react-calendar__month-view__weekdays__weekday abbr {
      font-weight: 600;
      text-decoration: none !important;
    }
    .react-calendar__tile--active abbr {
      background-color: rgba(94, 6, 185, 0.2);
    }
    .react-calendar__tile--active {
      background-color: transparent !important;
    }
    .react-calendar .react-calendar__tile abbr {
      color: #3f3f46;
    }
    .react-calendar__month-view__days {
      justify-content: space-between;
    }
    .react-calendar__tile {
      display: flex;
      justify-content: center;
    }
    .react-calendar__tile--active::after {
      transform: translate(0, 500%);
      border-radius: 100%;
      position: absolute;
      background: #5e06b9;
      content: '';
      height: 6px;
      width: 6px;
    }
    .react-calendar__tile abbr {
      display: flex;
      border-radius: 50%;
      padding: 16px;
      width: 42px;
      height: 42px;
      justify-content: center;
      align-items: center;
    }
    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
      background-color: transparent;
    }
    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus {
      background-color: transparent !important;
    }
    .react-calendar__tile--now {
      background-color: transparent;
    }
    .react-calendar__tile--now abbr {
      background-color: transparent;
    }
    .react-calendar__tile--active:enabled:hover abbr,
    .react-calendar__tile--active:enabled:focus abbr {
      background-color: rgba(94, 6, 185, 0.2);
    }
    .react-calendar__tile:hover abbr,
    .react-calendar__tile:focus abbr {
      background-color: rgba(94, 6, 185, 0.3);
    }
    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
      background-color: transparent;
    }
    span.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
      font-weight: bold;
    }
    .react-calendar__navigation__label {
      text-align: left;
    }
    .react-calendar__navigation {
      display: flex;
      column-gap: 51px;
      align-items: end;
      height: 30px;
    }
    .react-calendar__navigation__prev-button {
      order: 2;
      color: rgba(71, 12, 132, 1);
      font-size: 40px;
      align-self: center;
    }

    .react-calendar__navigation__next-button {
      order: 3;
      color: rgba(71, 12, 132, 1);
      font-size: 40px;
      align-self: center;
    }
    .react-calendar__navigation__prev2-button,
    .react-calendar__navigation__next2-button {
      display: none;
    }
  }
`
