import { useParams } from 'react-router-dom'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { isMobileOnly } from 'mobile-device-detect'

import { tabsOptions } from 'config/data'

import GrowingContainer from 'components/Common/GrowingContainer'
import ButtonTabs from 'components/Common/ButtonTabs'
import Advisors from './Advisors'
import Businesses from './Businesses'
import { ROLES } from 'config'
import { navigateTo, replaceTerm } from 'utils/helper'
import { RootState } from 'App'

const UserManagement = () => {
  let { isSelectedTab } = useParams()

  const [selectedTab, setSelectedTab] = useState(tabsOptions[0])
  const bsoProfile = useSelector((state: RootState) => state.bso.bsoProfile)
  const replacedTerm = replaceTerm('advisor', bsoProfile?.terminology)

  // Replace the label in tabsOptions[1] with bso replacement term
  tabsOptions[1].label = `${replacedTerm}s`

  useEffect(() => {
    if (isSelectedTab === tabsOptions[0].link) {
      setSelectedTab(tabsOptions[0])
    } else {
      setSelectedTab(tabsOptions[1])
    }
  }, [isSelectedTab])

  const onHandleTabChange = (value) => {
    setSelectedTab(value)

    if (value.label === tabsOptions[0].label) {
      navigateTo(ROLES.BSO_ADMIN, `user-management/${tabsOptions[0].link}`)
    } else if (value.label === tabsOptions[1].label) {
      navigateTo(ROLES.BSO_ADMIN, `user-management/${tabsOptions[1].link}`)
    }
  }

  const firstTabSelected = useMemo(() => {
    return selectedTab === tabsOptions[0]
  }, [selectedTab])

  return (
    <GrowingContainer id="user-management">
      <div className={`${isMobileOnly ? 'p-[8px]' : ''} w-full h-full`}>
        <div id="tab-container" className="w-full flex">
          <ButtonTabs
            options={tabsOptions}
            setSelectedTab={onHandleTabChange}
            selectedTab={selectedTab}
          />
        </div>
        <div className="w-full relative z-[5]">
          <div
            className={`bg-white grid-cols-12 p-4 sm:p-8 rounded-2xl mb-8 gap-12 shadow-lg mdl:pb-6 lg:pb-3 xl:pb-10 ${
              firstTabSelected && 'rounded-tl-none'
            }`}
          >
            {isSelectedTab === 'businesses' && <Businesses />}
            {isSelectedTab === 'advisors' && <Advisors />}
          </div>
        </div>
      </div>
    </GrowingContainer>
  )
}

export default UserManagement
