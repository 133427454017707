import React, { FC, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { ConnectedProps, connect } from 'react-redux'

import { actionOption } from 'config'

import { AdminRightsContainer, EditAccountContainer } from 'pages/Admin/container/UserManagement'
import SelectField from 'components/Common/SelectField'
import InfiniteScroll from 'react-infinite-scroll-component'
import ModalBox from 'components/Common/ModalBox'
import { AppDispatch, RootState } from 'App'
import { openUserManagementEditModalAction } from 'store/actions/userManagement'
import { capitalize } from 'lodash'

const connector = connect(
  (state: RootState) => ({
    openUserManagementEditModal: state.userManagement.openUserManagementEditModal,
  }),
  (dispatch: AppDispatch) =>
    bindActionCreators(
      {
        openUserManagementEditModalAction: openUserManagementEditModalAction,
      },
      dispatch
    )
)

interface IUserManagementListProps extends ConnectedProps<typeof connector> {
  userAccounts: {
    name?: string
    businessName?: string
    created?: string
    activated?: string
    updated?: string
    advisors?: unknown[]
  }[]
  showAdminRightsToggle?: boolean
  nextPaginatedDataHandler: (...args: unknown[]) => unknown
  pageSize: number
}

const UserManagementList = connector<FC<IUserManagementListProps>>(
  ({ userAccounts, showAdminRightsToggle = false, nextPaginatedDataHandler, pageSize }) => {
    const [selectedUser, setSelectedUser]: any = useState()
    let { isSelectedTab } = useParams()
    const [openUserManagementModal, setOpenUserManagementModal] = useState(false)

    const showAssociatedAdvisors = useMemo(() => {
      return userAccounts?.some((user) => Boolean(user?.advisors))
    }, [userAccounts])

    return (
      <div data-testid="user-management-list" className="border-primary pb-4">
        {userAccounts && (
          <InfiniteScroll
            // next={() => nextPaginatedDataHandler({ pageSize: pageSize })}
            // this currently fetches an entire table over again for no reason. Since no pagination is implemented and prop is required, just pass empty function for now.
            next={() => {}}
            dataLength={userAccounts?.length}
            hasMore={true}
            loader=""
          >
            {userAccounts.map((user, index) => {
              return (
                <div key={index} className="border-b-[1px] border-primary-outline py-5">
                  <div className="flex flex-col gap-8">
                    <div className="flex flex-col smd:flex-row smd:justify-between gap-4">
                      <div>
                        <p className="font-bold">{user?.name || ''}</p>
                        <p className="text-zinc">{user?.businessName || ''} </p>
                      </div>
                      <div>
                        <p className="smd:text-right">
                          <InlineLabel>Created: </InlineLabel>
                          {user?.created}
                        </p>
                        <p className="smd:text-right">
                          <InlineLabel>
                            {`${capitalize(user.activated)}: `}
                            <span>{user?.updated}</span>
                          </InlineLabel>
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col smd:flex-row smd:justify-between gap-4">
                      <div className="smd:w-1/2">
                        {user.activated !== 'inactive' && showAssociatedAdvisors && (
                          <p>
                            <InlineLabel>Advisors: </InlineLabel>
                            {user?.advisors || ''}
                          </p>
                        )}
                      </div>
                      <div className="flex items-center justify-between gap-4">
                        <div className="">
                          <SelectField
                            name={'action'}
                            placeholder="Actions"
                            options={actionOption}
                          />
                        </div>
                        {showAdminRightsToggle && <AdminRightsContainer params={user} />}
                        <div>
                          <label
                            className="font-primary font-bold text-primary text-sm"
                            onClick={() => {
                              setOpenUserManagementModal(true)
                              setSelectedUser(user)
                            }}
                          >
                            Edit
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </InfiniteScroll>
        )}
        {openUserManagementModal && (
          <div className="flex justify-between pt-4 w-[100%]">
            <ModalBox
              onClose={() => setOpenUserManagementModal(false)}
              title="Edit Account"
              width="xs:w-full sm:w-auto rounded-[10px]"
              height="h-full"
            >
              <EditAccountContainer
                userId={selectedUser?.userId}
                isBusiness={isSelectedTab === 'businesses' ? true : false}
                openUserManagementModal={openUserManagementModal}
                setOpenUserManagementModal={setOpenUserManagementModal}
              />
            </ModalBox>
          </div>
        )}
      </div>
    )
  }
)

const InlineLabel: FC = ({ children }) => {
  return <span className="text-silver-darker">{children}</span>
}

export default React.memo(UserManagementList)
