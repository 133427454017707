import React, { Fragment, useEffect, useState } from 'react'
import { IDataTableProps } from '../DataTable'
import Loader from 'components/Loaders'

interface ITableBodyProps {
  columns: IDataTableProps['columns']
  rows: IDataTableProps['rows']
  ExpandedComponent?: IDataTableProps['ExpandedComponent']
  gridColumns?: IDataTableProps['gridColumns']
  isLoading?: boolean
}

const TableBody = ({
  columns,
  rows,
  ExpandedComponent = null,
  gridColumns,
  isLoading,
}: ITableBodyProps) => {
  const [tableColumns, setTableColumns] = useState<any[]>([])
  const [tableRows, setTableRows] = useState<any[]>([])

  useEffect(() => {
    setTableColumns(columns)
    setTableRows(rows)
  }, [columns, rows])
  return isLoading ? (
    <Loader loader="DataTableLoader" />
  ) : tableRows.length === 0 ? (
    <tbody className="mt-6">
      <tr>
        <td className="font-primary text-black-light italic text-lg">No {columns[0].text}</td>
      </tr>
    </tbody>
  ) : (
    <tbody className="w-full">
      {tableRows &&
        tableRows.map((row, index) => {
          return (
            <Fragment key={index}>
              <tr
                className={
                  gridColumns
                    ? `grid grid-cols-${gridColumns}`
                    : `${
                        tableColumns[0]?.display || 'grid grid-cols-12'
                      } border-b-[1px] border-grey-lighter`
                }
                key={index}
              >
                {tableColumns?.map((col, colIndex) => {
                  return !col.hide && <Fragment key={colIndex}>{col?.renderCell(row)}</Fragment>
                })}
              </tr>
              {ExpandedComponent && <ExpandedComponent key={`exp-${index}`} row={row} />}
            </Fragment>
          )
        })}
    </tbody>
  )
}

export default TableBody
