import React, { useMemo } from 'react'
import Card from 'components/Common/Card'
import { AppFC } from 'types'
import useNavigateTo from 'hooks/useNavigateTo'
import useNavItem from 'hooks/useNavItem'

interface INavigationCardProps {
  Icon: any
  /** The nav item's id attribute */
  destination: string
  label?: string
}

const NavigationCard: AppFC<INavigationCardProps> = ({
  Icon,
  destination,
  className = '',
  label,
}) => {
  const item = useNavItem((i) => i.id === destination)
  const navigate = useNavigateTo(destination)

  const text = useMemo(() => {
    if (label) return label
    return item?.label || ''
  }, [item, label])

  return (
    <Card
      className={`hover:cursor-pointer max-w-[345px] flex-grow-1 border-b-4 border-b-transparent hover:border-b-primary hover:border-b-4 ${className}`}
      onClick={navigate}
    >
      <div className="w-full h-full flex flex-col items-center justify-center md:flex-row md:justify-start gap-2 md:gap-[13px]">
        <div className="rounded-full h-[58px] w-[58px] min-h-[58px] min-w-[58px] flex-shrink-0 bg-primary flex justify-center items-center">
          <Icon className="!w-4/5 fill-white" />
        </div>
        <p className="font-semibold text-center lg:text-xl">{text}</p>
      </div>
    </Card>
  )
}

export default NavigationCard
