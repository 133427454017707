import history from 'utils/history'
import { useParams } from 'react-router-dom'
import { copy, getFinalPathExtension, getRoleFromPath } from 'utils/helper'
import { useMemo } from 'react'
import NAVIGATION from 'routes/navigation'

const useNavigationItems = () => {
  const params = useParams()
  const tenantId = useMemo(() => params?.tenantId, [params])
  const path = history.location.pathname

  const items: any[] = useMemo(() => {
    const role = getRoleFromPath()
    if (!role || !(role in NAVIGATION)) return []
    return NAVIGATION[role].map((it) => {
      const item = copy(it)
      if (item.path && tenantId) {
        item.path = item.path.replace(':tenantId', tenantId)
      }

      const itemPathExtension = getFinalPathExtension(item.path)
      const currentPathExtension = getFinalPathExtension(path)

      return {
        ...item,
        selected: itemPathExtension === currentPathExtension && itemPathExtension !== '',
      }
    })
  }, [path, tenantId])
  return items
}

export default useNavigationItems
