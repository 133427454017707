import { Views } from 'react-big-calendar'
import { ROLES, FORM } from 'config'
import {
  FoodIcon,
  RetailIcon,
  ServicesIcon,
  AccountsCreatedIcon,
  SignInIcon,
  MeetingScheduleIcon,
  MeetingRescheduleIcon,
} from 'components/Common/SvgIcons'
import MsPowerPointIcon from 'components/Common/SvgIcons/MsPowerPointIcon'
import MsExcelIcon from 'components/Common/SvgIcons/MsExcelIcon'
import MsWordIcon from 'components/Common/SvgIcons/MsWordIcon'
import CsvIcon from 'components/Common/SvgIcons/CsvIcon'
import VideoIcon from 'components/Common/SvgIcons/VideoIcon'
import ImageIcon from 'components/Common/SvgIcons/ImageIcon'
import GenericDocumentIcon from 'components/Common/SvgIcons/GenericDocumentIcon'
import EcommerceIcon from 'components/Common/SvgIcons/EcommerceIcon'
import ChildCareIcon from 'components/Common/SvgIcons/ChildCareIcon'
import QuickBookIcon from 'components/Common/SvgIcons/QuickBookIcon'
import SquareIcon from 'components/Common/SvgIcons/SquareIcon'
import CalendlyIcon from 'components/Common/SvgIcons/CalendlyIcon'
import PdfIcon from 'components/Common/SvgIcons/PdfIcon'
import OutLookIcon from 'components/Common/SvgIcons/OutLookIcon'
import GoogleCalendarIcon from 'components/Common/SvgIcons/GoogleCalendarIcon'

export const routes = {
  [ROLES.BSO_ADMIN]: {
    Dashboard: [{ label: 'DASHBOARD', path: '/admin/dashboard', icon: 'bx bx-home-circle' }],
  },
  [ROLES.BSO_ADVISOR]: {
    Dashboard: [{ label: 'Home', path: '/advisor/dashboard', icon: 'bx bx-home-circle' }],
  },
  [ROLES.BUSINESS_OWNER]: {
    Dashboard: [{ label: 'Home', path: '/owner/assessment', icon: 'bx bx-home-circle' }],
  },
}
export const advisorStepper = [
  {
    title: 'Welcome Advisor',
    value: 'welcome-advisor',
  },
  {
    title: 'Set up Calendar',
    value: 'set-up-calendar',
  },
  {
    title: 'Calendar Link',
    value: 'calendar-link',
  },
  {
    title: 'Success',
    value: 'success',
  },
]

export const initialAssessmentStepper = [
  {
    title: 'Welcome Owner',
  },
  {
    title: 'Create Profile',
  },
  {
    title: 'Questions',
  },
  {
    title: 'Terrific',
  },
]

export const assessmentsStepper = [
  {
    title: 'Questions',
  },
  {
    title: 'Terrific',
  },
]

export const formKeys = {
  [FORM.PROFILE_FORM]: ['firstName', 'lastName', 'mobileContactNumber'],
  [FORM.ADVISOR_PROFILE_FORM]: ['firstName', 'lastName', 'email', 'mobileContactNumber'],
  [FORM.USER_PROFILE]: ['email', 'firstName', 'mobileContactNumber'],
  [FORM.BUSINESS_PROFILE]: ['businessName', 'yearsInBusiness', 'mobileContactNumber'],
  [FORM.USER_PROFILE_FORM]: ['firstName', 'lastName', 'email', 'mobileContactNumber', 'advisors'],
  [FORM.USER_MANAGEMENT_BUSINESS_FORM]: ['businessName', 'yearsInBusiness'],
  [FORM.OWNERS]: ['owners'],
  [FORM.TOPGOAL]: ['topGoal'],
  [FORM.OTHER_GOALS]: ['otherGoals'],
  [FORM.BUSINESS_DETAILS]: ['businessVision', 'challenges'],
  [FORM.BUSINESS_OTHER_DETAILS]: ['businessVision', 'challenges', 'otherGoals', 'topGoal'],
  [FORM.CALENDAR]: ['description', 'attendees'],
  [FORM.USER_AVAILABILITY]: ['duration', 'timeSlots', 'timeZone'],
  [FORM.MEETING_SCHEDULED]: ['meetingTitle', 'meetingLocation'],
  [FORM.MONTHLY_ATTESTATION]: [
    'registerBusinessStructure',
    'year',
    'businessBankAccount',
    'revenue',
    'contractors',
    'volunteers',
    'fullTimeEmployees',
    'jobsCreated',
    'wages',
    'month',
    'partTimeEmployees',
    'volunteers',
    'gender',
    'race',
    'debtFinancing',
    'education',
    'financialGrowth',
    'newCustomer',
    'productSale',
    'serviceSale',
    'physicalLocation',
    'targetAudience',
    'additionalInfo',
  ],
}

export const ASSESSMENTS = {
  sales_level_retail_1: {
    tag: 'Sales',
    title: 'Sales Level 1',
    bgColor: 'bg-yellow-darker',
    category: 'Sales',
  },
  sales_level_ecommerce_1: {
    tag: 'Sales',
    title: 'Sales Level 1',
    bgColor: 'bg-yellow-darker',
    category: 'Sales',
  },
  business_essentials_retail_1: {
    tag: 'Business Essentials',
    title: 'Business Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
  },
  business_essentials_retail_2: {
    tag: 'Business Essentials',
    title: 'Business Essentials 2',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
  },
  business_essentials_retail_3: {
    tag: 'Business Essentials',
    title: 'Business Essentials 3',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
  },
  business_essentials_ecommerce_1: {
    tag: 'Business Essentials',
    title: 'Business Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
  },
  business_essentials_ecommerce_2: {
    tag: 'Business Essentials',
    title: 'Business Essentials 2',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
  },
  business_essentials_ecommerce_3: {
    tag: 'Business Essentials',
    title: 'Business Essentials 3',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
  },
  sales_assessment: {
    tag: 'Sales Assessment',
    title: 'Sales Assessment',
    bgColor: 'bg-tertiary',
    category: 'Sales',
  },
  initial: {
    tag: 'Initial Assessment',
    title: 'Initial Assessment',
    bgColor: 'bg-tertiary',
    category: 'Initial',
  },
  baseline_assessment: {
    tag: 'Business Baseline',
    title: 'Business Baseline',
    bgColor: 'bg-yellow-darker',
    category: 'Baseline',
  },
  business_essentials_food_1: {
    tag: 'Business Essentials',
    title: 'Business Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
  },
  business_essentials_restaurant_1: {
    tag: 'Business Essentials',
    title: 'Business Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
  },
  business_essentials_restaurant_2: {
    tag: 'Business Essentials',
    title: 'Business Essentials 2',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
  },
  business_essentials_mobile_1: {
    tag: 'Business Essentials',
    title: 'Business Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
  },
  business_essentials_mobile_2: {
    tag: 'Business Essentials',
    title: 'Business Essentials 2',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
  },
  business_essentials_kitchen_1: {
    tag: 'Business Essentials',
    title: 'Business Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
  },
  business_essentials_kitchen_2: {
    tag: 'Business Essentials',
    title: 'Business Essentials 2',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
  },
  business_essentials_services_1: {
    tag: 'Business Essentials',
    title: 'Business Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
  },
  business_essentials_services_2: {
    tag: 'Business Essentials',
    title: 'Business Essentials 2',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
  },
  business_essentials_childcare_1: {
    tag: 'Business Essentials',
    title: 'Business Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
  },
  business_essentials_childcare_2: {
    tag: 'Business Essentials',
    title: 'Business Essentials 1',
    bgColor: 'bg-yellow-darker',
    category: 'Business Essentials',
  },
  finance_lvl_retail_1: {
    tag: 'Finance Assessment',
    title: 'Finance Essentials',
    bgColor: 'bg-yellow-darker',
    category: 'Finance',
  },
  finance_lvl_retail_2: {
    tag: 'Finance Assessment',
    title: 'Finance Essentials',
    bgColor: 'bg-yellow-darker',
    category: 'Finance',
  },
  finance_lvl_restaurant_1: {
    tag: 'Finance Assessment',
    title: 'Finance Essentials',
    bgColor: 'bg-yellow-darker',
    category: 'Finance',
  },
  finance_lvl_kitchen_1: {
    tag: 'Finance Assessment',
    title: 'Finance Essentials',
    bgColor: 'bg-yellow-darker',
    category: 'Finance',
  },
  finance_lvl_kitchen_2: {
    tag: 'Finance Assessment',
    title: 'Finance Essentials',
    bgColor: 'bg-yellow-darker',
    category: 'Finance',
  },
  finance_lvl_mobile_1: {
    tag: 'Finance Assessment',
    title: 'Finance Essentials',
    bgColor: 'bg-yellow-darker',
    category: 'Finance',
  },
  finance_lvl_mobile_2: {
    tag: 'Finance Assessment',
    title: 'Finance Essentials',
    bgColor: 'bg-yellow-darker',
    category: 'Finance',
  },
  finance_lvl_services_1: {
    tag: 'Finance Assessment',
    title: 'Finance Essentials',
    bgColor: 'bg-yellow-darker',
    category: 'Finance',
  },
  finance_lvl_services_2: {
    tag: 'Finance Assessment',
    title: 'Finance Essentials',
    bgColor: 'bg-yellow-darker',
    category: 'Finance',
  },
  finance_lvl_childcare_1: {
    tag: 'Finance Assessment',
    title: 'Finance Essentials',
    bgColor: 'bg-yellow-darker',
    category: 'Finance',
  },
  finance_lvl_childcare_2: {
    tag: 'Finance Assessment',
    title: 'Finance Essentials',
    bgColor: 'bg-yellow-darker',
    category: 'Finance',
  },
  finance_lvl_ecommerce_1: {
    tag: 'Finance Assessment',
    title: 'Finance Essentials',
    bgColor: 'bg-yellow-darker',
    category: 'Finance',
  },
  finance_lvl_ecommerce_2: {
    tag: 'Finance Assessment',
    title: 'Finance Essentials',
    bgColor: 'bg-yellow-darker',
    category: 'Finance',
  },
  sales_lvl_retail_1: {
    tag: 'Sales Assessment',
    title: 'Sales Assessment 1',
    bgColor: 'bg-yellow-darker',
    category: 'Sale',
  },
  sales_lvl_restaurant_1: {
    tag: 'Sales Assessment',
    title: 'Sales Assessment 1',
    bgColor: 'bg-yellow-darker',
    category: 'Sales',
  },
  sales_lvl_kitchen_1: {
    tag: 'Sales Assessment',
    title: 'Sales Assessment 1',
    bgColor: 'bg-yellow-darker',
    category: 'Sales',
  },
  sales_lvl_mobile_1: {
    tag: 'Sales Assessment',
    title: 'Sales Assessment 1',
    bgColor: 'bg-yellow-darker',
    category: 'Sales',
  },
  sales_lvl_childcare_1: {
    tag: 'Sales Assessment',
    title: 'Sales Assessment 1',
    bgColor: 'bg-yellow-darker',
    category: 'Sales',
  },
  sales_lvl_ecommerce_1: {
    tag: 'Sales Assessment',
    title: 'Sales Assessment 1',
    bgColor: 'bg-yellow-darker',
    category: 'Sales',
  },
  sales_lvl_services_1: {
    tag: 'Sales Assessment',
    title: 'Sales Assessment 1',
    bgColor: 'bg-yellow-darker',
    category: 'Sales',
  },
  marketing_lvl_retail_1: {
    tag: 'Marketing Assessment',
    title: 'Marketing Assessment 1',
    bgColor: 'bg-yellow-darker',
    category: 'Marketing',
  },
  marketing_lvl_restaurant_1: {
    tag: 'Marketing Assessment',
    title: 'Marketing Assessment 1',
    bgColor: 'bg-yellow-darker',
    category: 'Marketing',
  },
  marketing_lvl_kitchen_1: {
    tag: 'Marketing Assessment',
    title: 'Marketing Assessment 1',
    bgColor: 'bg-yellow-darker',
    category: 'Marketing',
  },
  marketing_lvl_mobile_1: {
    tag: 'Marketing Assessment',
    title: 'Marketing Assessment 1',
    bgColor: 'bg-yellow-darker',
    category: 'Marketing',
  },
  marketing_lvl_ecommerce_1: {
    tag: 'Marketing Assessment',
    title: 'Marketing Assessment 1',
    bgColor: 'bg-yellow-darker',
    category: 'Marketing',
  },
  marketing_lvl_childcare_1: {
    tag: 'Marketing Assessment',
    title: 'Marketing Assessment 1',
    bgColor: 'bg-yellow-darker',
    category: 'Marketing',
  },
  marketing_lvl_services_1: {
    tag: 'Marketing Assessment',
    title: 'Marketing Assessment 1',
    bgColor: 'bg-yellow-darker',
    category: 'Marketing',
  },
  marketing_lvl_services_1_1: {
    tag: 'Marketing Assessment',
    title: 'Marketing Assessment 1',
    bgColor: 'bg-yellow-darker',
    category: 'Marketing',
  },
  marketing_lvl_childcare_1_1: {
    tag: 'Marketing Assessment',
    title: 'Marketing Assessment 1',
    bgColor: 'bg-yellow-darker',
    category: 'Marketing',
  },
  marketing_lvl_retail_1_1: {
    tag: 'Marketing Assessment',
    title: 'Marketing Assessment 1',
    bgColor: 'bg-yellow-darker',
    category: 'Marketing',
  },
  marketing_lvl_restaurant_1_1: {
    tag: 'Marketing Assessment',
    title: 'Marketing Assessment 1',
    bgColor: 'bg-yellow-darker',
    category: 'Marketing',
  },
  marketing_lvl_kitchen_1_1: {
    tag: 'Marketing Assessment',
    title: 'Marketing Assessment 1',
    bgColor: 'bg-yellow-darker',
    category: 'Marketing',
  },
  marketing_lvl_mobile_1_1: {
    tag: 'Marketing Assessment',
    title: 'Marketing Assessment 1',
    bgColor: 'bg-yellow-darker',
    category: 'Marketing',
  },
  marketing_lvl_ecommerce_1_1: {
    tag: 'Marketing Assessment',
    title: 'Marketing Assessment 1',
    bgColor: 'bg-yellow-darker',
    category: 'Marketing',
  },
}

export const assessmentIcons = [
  { iconLabel: 'Retail', value: RetailIcon },
  { iconLabel: 'ECommerce', value: EcommerceIcon },
  { iconLabel: 'Food', value: FoodIcon },
  { iconLabel: 'Childcare', value: ChildCareIcon },
  { iconLabel: 'Services', value: ServicesIcon },
]

export const activeQuestion = {
  id: 'abdd2775-ad45-4fa4-b01f-629201c4e6fbb',
  tenantId: null,
  previous: 'abdd2775-ad45-4fa4-b01f-629201c4e6faa',
  assessmentType: 'initial',
  meta: {
    showToBusinessProfile: true,
    showToAdmin: true,
    showToOwner: true,
  },
  content: {
    owner: {
      description: 'Where is your business located?',
      title: '',
    },
    businessProfile: {
      description: 'Business Locations',
      title: '',
    },
    admin: {
      description: 'Where is the business located?',
      title: '',
    },
  },
  optionType: 'select',
  options: [
    {
      action: {
        next: {
          default: 'abdd2775-ad45-4fa4-b01f-629201c4e7o1',
        },
      },
      id: 'a83c31b3-86d7-46e8-bc26-25dda701ff20',
      value: 'Home-Based',
    },
    {
      action: {
        next: {
          default: 'abdd2775-ad45-4fa4-b01f-629201c4e7o2',
        },
      },
      id: 'a83c31b3-86d7-46e8-bc26-25dda701ff21',
      value: 'Retail Location',
    },
    {
      action: {
        next: {
          default: 'abdd2775-ad45-4fa4-b01f-629201c4e7o3',
        },
      },
      id: 'a83c31b3-86d7-46e8-bc26-25dda701ff22',
      value: 'Commercial Space',
    },
    {
      action: {
        next: {
          default: 'abdd2775-ad45-4fa4-b01f-629201c4e7o4',
        },
      },
      id: 'a83c31b3-86d7-46e8-bc26-25dda701ff23',
      value: 'Incubator / Innovation Center',
    },
    {
      action: {
        next: {
          default: 'abdd2775-ad45-4fa4-b01f-629201c4e7o5',
        },
      },
      id: 'a83c31b3-86d7-46e8-bc26-25dda701ff24',
      value: 'Other',
    },
  ],
  createdAt: null,
  updatedAt: null,
}

export const filterByTimeOption: any = [
  {
    label: 'This Week (Mon-Sun)',
    value: 'this week',
  },
  {
    label: 'This Month',
    value: 'this month',
  },
  {
    label: 'This Year',
    value: 'this year',
  },
  {
    label: 'All Time',
    value: 'all time',
  },
]

export const clientActivityIcons = [
  { iconLabel: 'Accounts', value: AccountsCreatedIcon },
  { iconLabel: 'Signin', value: SignInIcon },
  { iconLabel: 'MeetingSchedule', value: MeetingScheduleIcon },
  { iconLabel: 'MeetingReschedule', value: MeetingRescheduleIcon },
]

export const stepNames = [
  { name: 'guide', title: 'Guide' },
  { name: 'setup', title: 'Set Up' },
  { name: 'use', title: 'Use' },
]

export const sortOptions = [
  { label: 'Sort By name', value: 'name' },
  { label: 'Sort By Business name', value: 'businessName' },
  { label: 'Sort By Created', value: 'created' },
  { label: 'Sort By Activated', value: 'activated' },
]

export const statusOptions = [
  { label: 'All', value: 'all' },
  { label: 'Invited', value: 'invited' },
  { label: 'Active', value: 'active' },
  { label: 'Pending', value: 'pending' },
  { label: 'Inactive', value: 'inactive' },
  { label: 'Disabled', value: 'disabled' },
]

export const MEDIA_BUCKET = `https://${process.env.REACT_APP_MEDIA_BUCKET}`

export const tabsOptions = [
  {
    label: 'Businesses',
    link: 'businesses',
  },
  {
    label: 'Advisors',
    link: 'advisors',
  },
]

export const actionOption = [
  { action: 'active', label: 'Reset Password', value: 'resetpassword' },
  { action: 'active', label: 'Chat', value: 'chat' },
  { action: 'inactive', label: 'Send Invitation', value: 'sendinvitation' },
  { action: 'pending', label: 'Resend Invitation', value: 'resendinvitation' },
]

export const clientTabs = [
  { name: 'details', title: 'Details' },
  { name: 'activity', title: 'Activity' },
  { name: 'assessmentTools', title: 'Assessments & Tools' },
  { name: 'businessData', title: 'Business Data' },
  { name: 'meetingRecord', title: 'Meeting Record' },
]

export const userStatus = {
  active: 'Active',
  inactive: 'InActive',
  pending: 'Pending',
  disabled: 'Disabled',
}

export const integrationLogActions = {
  Squareup: 'Integrated Square POS',
  QuickBooks: 'Integrated QuickBooks',
}

export const fileExtension = {
  powerpoint: ['pps', 'ppt', 'pptx'],
  excel: ['ods', 'xls', 'xlsm', 'xlsx'],
  word: ['doc', 'docx', 'odt', 'msword'],
  csv: ['csv'],
  pdf: ['pdf'],
  video: [
    '3g2',
    '3gp',
    'avi',
    'flv',
    'h264',
    'm4v',
    'mkv',
    'mov',
    'mp4',
    'mpg',
    'mpeg',
    'rm',
    'swf',
    'vob',
    'wmv',
  ],
  image: ['bmp', 'gif', 'ico', 'jpeg', 'jpg', 'png', 'ps', 'psd', 'svg', 'tif', 'tiff'],
}

export const fileIcons = [
  { iconLabel: 'presentation', value: MsPowerPointIcon },
  { iconLabel: 'excel', value: MsExcelIcon },
  { iconLabel: 'word', value: MsWordIcon },
  { iconLabel: 'csv', value: CsvIcon },
  { iconLabel: 'video', value: VideoIcon },
  { iconLabel: 'image', value: ImageIcon },
  { iconLabel: 'document', value: GenericDocumentIcon },
  { iconLabel: 'pdf', value: PdfIcon },
]

export const defaultFileIcon = { iconLabel: 'document', value: GenericDocumentIcon }

export const ADVISOR_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DISABLED: 'disabled',
  INVITED: 'invited',
}

export const playBookTabsOptions = [
  { name: 'inProgress', title: 'In-Progress' },
  { name: 'completed', title: 'Completed' },
]

export const playTabsOptions = [
  { name: 'inProgress', title: 'In-Progress' },
  { name: 'notStarted', title: 'Not Started' },
  { name: 'completed', title: 'Completed' },
]

export const playColors = [
  {
    label: 'All',
    value: 'All',
    color: 'bg-secondary',
    name: 'business essentials',
    colorCode: '#5F5F64',
    backgroundColor: 'bg-cream-dark',
    textColor: 'text-primary-text',
    borderColor: 'border-primary-outline',
    completedCardBackgroundColor: 'bg-secondary',
  },
  {
    label: 'Sales',
    value: 'Sales',
    color: 'bg-sky-light',
    name: 'sales',
    colorCode: '#77FADB',
    backgroundColor: 'bg-sky-light',
    textColor: 'text-green-light',
    borderColor: 'border-aqua',
    completedCardBackgroundColor: 'bg-white',
  },
  {
    label: 'Marketing',
    value: 'Marketing',
    color: 'bg-yellow',
  },
  {
    label: 'Financing',
    value: 'Financing',
    color: 'bg-sky',
  },
  {
    label: 'Operations',
    value: 'Operations',
    color: 'bg-sky-dark',
  },
  {
    label: 'Team',
    value: 'Team',
    color: 'bg-sky-darker',
  },
]

export const toolsTabs = [
  {
    label: 'My Tools',
  },
  {
    label: 'All Tools',
  },
]

export enum IDataTabs {
  OVERVIEW = 'overview',
  CUSTOMERS = 'customers',
  MARKETING = 'marketing',
  FINANCE = 'finance',
  PRODUCT = 'product',
}

export const dataTabs = [
  { name: IDataTabs.OVERVIEW, title: 'Overview' },
  { name: IDataTabs.CUSTOMERS, title: 'Customers', isDisabled: true },
  { name: IDataTabs.MARKETING, title: 'Marketing' },
  { name: IDataTabs.FINANCE, title: 'Finance' },
  { name: IDataTabs.PRODUCT, title: 'Product' },
]

export const allTools = {
  calendly: [{ name: 'Calendly', url: 'https://calendly.com/event_types/user/me' }],
  sales: [
    {
      name: 'QuickBooks',
      url: 'https://app.qbo.intuit.com',
    },
    {
      name: 'Squareup',
      url: 'https://squareup.com/dashboard',
    },
  ],
}

export const toolsImage = [
  { iconLabel: 'QuickBooks', value: QuickBookIcon },
  { iconLabel: 'Squareup', value: SquareIcon },
  { iconLabel: 'Calendly', value: CalendlyIcon },
  { iconLabel: 'Google Calendar', value: GoogleCalendarIcon },
  { iconLabel: 'Outlook', value: OutLookIcon },
]
export const meetingTabOptions = [
  {
    label: 'Upcoming',
  },
  {
    label: 'Previous',
  },
]

export const AccountTabsOptions = [
  {
    label: 'My Profile',
  },
  {
    label: 'Business Profile',
  },
]
export const AccountTabOptions = [
  {
    label: 'My Profile',
  },
]

export const meetingCheckBoxOption = [
  {
    label: 'As soon as possible',
    value: 'as soon as possible',
    id: 1,
    isChecked: false,
  },
  {
    label: 'I‘m flexible',
    value: 'i‘m flexible',
    id: 2,
    isChecked: false,
  },
]

export const calendarOptions = [
  {
    label: 'Day',
    value: Views.DAY,
  },
  {
    label: 'Week',
    value: Views.WEEK,
  },
  {
    label: 'Month',
    value: Views.MONTH,
  },
]

export const slots = {
  defaultStartTime: '08:00',
  defaultEndTime: '17:00',
}

export const defaultValues = {
  timeSlots: [
    {
      label: 'Sunday',
      value: 'Sunday',
      checked: false,
      slots: [],
    },
    {
      label: 'Monday',
      value: 'Monday',
      checked: true,
      slots: [
        {
          startTime: slots.defaultStartTime,
          endTime: slots.defaultEndTime,
        },
      ],
    },
    {
      label: 'Tuesday',
      value: 'Tuesday',
      checked: true,
      slots: [
        {
          startTime: slots.defaultStartTime,
          endTime: slots.defaultEndTime,
        },
      ],
    },
    {
      label: 'Wednesday',
      value: 'Wednesday',
      checked: true,
      slots: [
        {
          startTime: slots.defaultStartTime,
          endTime: slots.defaultEndTime,
        },
      ],
    },
    {
      label: 'Thursday',
      value: 'Thursday',
      checked: true,
      slots: [
        {
          startTime: slots.defaultStartTime,
          endTime: slots.defaultEndTime,
        },
      ],
    },
    {
      label: 'Friday',
      value: 'Friday',
      checked: true,
      slots: [
        {
          startTime: slots.defaultStartTime,
          endTime: slots.defaultEndTime,
        },
      ],
    },
    {
      label: 'Saturday',
      value: 'Saturday',
      checked: false,
      slots: [],
    },
  ],
}

export const customLabels = {
  second: {
    past: {
      one: '{0}s ago',
      other: '{0}s ago',
    },
    future: {},
  },
  minute: {
    past: {
      other: '{0}m ago',
    },
    future: {},
  },
  hour: {
    past: {
      one: '{0} hour ago',
      other: '{0} hours ago',
    },
    future: {},
  },
  day: {
    past: {
      one: '{0} day ago',
      other: '{0} days ago',
    },
    future: {},
  },
  week: {
    past: {
      one: '{0} week ago',
      other: '{0} weeks ago',
    },
    future: {},
  },
  month: {
    past: {
      one: '{0} month ago',
      other: '{0} months ago',
    },
    future: {},
  },
  year: {
    past: {
      one: '{0} year ago',
      other: '{0} years ago',
    },
    future: {},
  },
}
export const defaultDuration = [{ label: '60 minutes', duration: 60 }]

export enum CALENDAR {
  MICROSOFT_OFFICE_365 = 'MICROSOFT OFFICE 365',
  GOOGLE_CALENDAR = 'GOOGLE CALENDAR',
  INTERNAL = 'INTERNAL',
}
export const CalendarColorsMap = new Map<CALENDAR, string>([
  [CALENDAR.GOOGLE_CALENDAR, 'rgb(255, 244, 204)'],
  [CALENDAR.MICROSOFT_OFFICE_365, 'rgb(220, 240, 254)'],
  [CALENDAR.INTERNAL, 'rgb(223, 205, 241)'],
])

export const reschedule = '# meetings re-scheduled'
