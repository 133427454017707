import React, { useEffect, useMemo, useState } from 'react'

import CLIENTS_API from 'api/clients'

import ModalBox from 'components/Common/ModalBox'
import ProfileSection from 'components/Dashboard/ProfileSection'
import NavigationCards from 'components/Dashboard/NavigationCards'
import UpcomingEvents from 'components/Dashboard/UpcomingEvents'
import SmsSubscriptionNotification from 'components/NotificationPanel/SmsSubscriptionNotification'
import StatCards from 'components/Dashboard/StatCards'
import { useTenantId } from 'utils/useTenantId'
import { useSelector } from 'react-redux'
import { RootState } from 'App'
import { USER_ROLES } from 'config'

const useActiveClients = (): [any[], boolean, boolean] => {
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(false)
  const [clients, setClients] = useState<any[]>([])
  const tenantId = useTenantId()

  useEffect(() => {
    setLoaded(false)
    setError(false)
    const getActiveClients = async () => {
      try {
        // @ts-ignore
        const response = await CLIENTS_API.getAdvisorDashboard({ tenantId })
        const { accounts } = JSON.parse(response?.getAdvisorDashboard?.data)
        setClients(accounts || [])
      } catch (error) {
        setClients([])
        setError(true)
      } finally {
        setLoaded(true)
      }
    }
    getActiveClients()
  }, [tenantId])

  return [clients, loaded, error]
}

const Dashboard = ({
  setShowSubscriptionPromptAction,
  getUserCalendarEventsAction,
  getClientsSummaryAction,
  updateUserProfileAction,
  chatUnreadMessageCount,
  isUpdateProfileLoading,
  showSubscriptionPrompt,
  isLoading,
  summary,
  events,
  user,
}) => {
  useEffect(() => {
    getClientsSummaryAction()
    getUserCalendarEventsAction()
  }, [getClientsSummaryAction, getUserCalendarEventsAction, user])

  const bsoProfile = useSelector((state: RootState) => state.bso.bsoProfile)

  const [clients, clientsLoaded, errorFetchingClients] = useActiveClients()
  const [showSmsSubscribeModal, setShowSmsSubscribeModal] = useState(true)
  const showNoActiveClientsMessage = useMemo(() => {
    return clients?.length === 0 && clientsLoaded && !errorFetchingClients
  }, [clients, clientsLoaded, errorFetchingClients])

  /** These are events parsed into the interface required by the UpcomingEvents component */
  const eventSummaries = useMemo(() => {
    return events ? events.map((event) => ({ description: event.title, date: event.start })) : []
  }, [events])

  // check if user has admin role
  let firstName = user?.firstName || ''
  let lastName = user?.lastName || ''
  let name = firstName + lastName
  let logoUrl = ''
  if (
    user?.roles?.includes(USER_ROLES.BSO_ADMIN) ||
    user?.roles?.includes(USER_ROLES.BSO_ADVISOR)
  ) {
    name = bsoProfile.name
    logoUrl = bsoProfile.logoUrl
  }

  return (
    <div className="w-full max-w-full">
      <ProfileSection name={name} profileImgSrc={logoUrl} />
      <NavigationCards chatUnreadMessageCount={chatUnreadMessageCount} />
      <div className="flex flex-col gap-8 mt-11 sm:mt-[81px] w-full max-w-full lg:flex-row">
        <StatCards
          showNoActiveClientsMessage={showNoActiveClientsMessage}
          loading={isLoading}
          stats={summary}
        />
        <UpcomingEvents
          className="lg:order-first"
          noEventsMessage="Get started on your Calendar"
          events={eventSummaries}
          loading={isLoading}
        />
      </div>
      {user?.isSmsOptedIn === null && showSmsSubscribeModal && showSubscriptionPrompt && (
        <ModalBox
          padding={'px-4 py-5'}
          width="sm:!w-[560px]"
          height="h-auto"
          bgColor="bg-[rgba(0,0,0,0.65)]"
          onClose={() => {
            setShowSubscriptionPromptAction(false)
            setShowSmsSubscribeModal(false)
          }}
        >
          <SmsSubscriptionNotification
            user={user}
            isLoading={isUpdateProfileLoading}
            onSubmit={updateUserProfileAction}
          />
        </ModalBox>
      )}
    </div>
  )
}

export default Dashboard
