import React from 'react'

import { AppFC } from 'types'
import useNavigateTo from 'hooks/useNavigateTo'

import Heading from 'components/Common/Heading'
import ProfilePicture from 'components/Dashboard/ProfilePicture'

interface IProfileContentProps {
  name?: string
  profileImgSrc?: string
}

/** Displays the user's name, profile picture and a link to edit their account details. */
const ProfileSection: AppFC<IProfileContentProps> = ({ name = '', profileImgSrc = '' }) => {
  const navigate = useNavigateTo('account')
  return (
    <section className="flex items-center gap-3 xs:justify-center lg:justify-start mt-2 sm:mt-auto ">
      <ProfilePicture src={profileImgSrc} />
      <div className="flex flex-col gap-2 text-white">
        <Heading variant="secondary" className="text-white text-2xl xs:text-[32px] leading-7">
          {name}
        </Heading>
        <div>
          <span
            onClick={navigate}
            className="text-sm font-semibold text-white hover:text-sky hover:cursor-pointer"
          >
            Edit Account Profile
          </span>
        </div>
      </div>
    </section>
  )
}

export default ProfileSection
