import React, { useEffect } from 'react'

import InputField from 'components/Common/InputField'

const EditUserProfile = ({ checkErrors, register, errors }) => {
  useEffect(() => {
    checkErrors(errors)
  })

  return (
    <>
      <div className="xs:col-span-12 sm:col-span-6">
        <InputField
          errors={errors.firstName}
          type={'text'}
          label="First Name"
          defaultValue={''}
          {...register('firstName')}
        />
      </div>
      <div className="xs:col-span-12 sm:col-span-6">
        <InputField
          errors={errors.lastName}
          type={'text'}
          label="Last Name"
          {...register('lastName')}
        />
      </div>
      <div className="xs:col-span-12 sm:col-span-6">
        <InputField
          errors={errors.email}
          type={'email'}
          label="Email"
          defaultValue={''}
          {...register('email')}
          disabled={true}
          className={'border-primary-outline hover:!border-primary-outline'}
        />
      </div>
      <div className="col-span-12 sm:col-span-6">
        <InputField
          errors={errors.mobileContactNumber}
          type="text"
          placeholder="(XXX) XXX-XXXX"
          mask="(999) 999-9999"
          label="Phone Number"
          defaultValue={''}
          {...register('mobileContactNumber')}
        />
      </div>
    </>
  )
}
export default EditUserProfile
