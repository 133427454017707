import {
  ACTIVESTEP,
  GET_ASSESSMENT_QUESTION,
  GET_USER_ASSESSMENT_RESPONSE,
  GET_USER_GOAL,
  UPDATE_USER_GOAL,
  ADD_EDIT_ASSESSMENT,
  SET_ACTIVE_QUESTION,
  INITIALIZE_OWNER_STEPPER,
  GET_ASSESSMENT_RESPONSE_BY_QUESTION,
  GET_RECOMMENDED_PLAYS_ASSSESSMENTS,
  GET_USER_ASSESSMENT_RESPONSE_BY_QUESTION,
  GET_BIG_QUERY,
  GET_SALES_DATA,
  GET_PRODUCT_DATA,
  RESET_ASSESSMENT,
  GET_FINANCE_DATA,
  GET_MARKETING_DATA,
  SAVE_ASSESSMENT_RESPONSE,
} from 'store/types'

const initialState = {
  steps: [],
  activeStep: '',
  questions: [],
  goals: [],
  assessmentResponse: {},
  activeQuestion: {},
  assessmentQuestion: {},
  usersAssessmentResponseByQuestion: {},
  play: {},
  recommendations: [],
  bigQuery: [],
  salesChartData: [],
  productsData: [],
  isLoadingAssessmentResponse: false,
  marketingData: {},
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTIVESTEP:
      return {
        ...state,
        activeStep: action?.payload,
      }
    case RESET_ASSESSMENT:
      return {
        ...state,
        steps: [],
        activeStep: '',
        questions: [],
        assessmentResponse: {},
        activeQuestion: {},
        assessmentQuestion: {},
        usersAssessmentResponseByQuestion: {},
        isLoadingAssessmentResponse: false,
      }
    case GET_RECOMMENDED_PLAYS_ASSSESSMENTS.STARTED:
    case SAVE_ASSESSMENT_RESPONSE.STARTED:
      return {
        ...state,
        isLoadingAssessmentResponse: true,
      }
    case ADD_EDIT_ASSESSMENT.FULLFILLED:
      return {
        ...state,
        questions: action?.payload?.questions,
        assessmentResponse: action?.payload?.assessmentResponse,
      }
    case GET_ASSESSMENT_QUESTION.FULLFILLED:
      return {
        ...state,
        questions: action?.payload,
      }
    case UPDATE_USER_GOAL.FULLFILLED:
    case GET_USER_GOAL.FULLFILLED:
      return {
        ...state,
        goals: action.payload,
      }
    case GET_USER_ASSESSMENT_RESPONSE.FULLFILLED:
      return {
        ...state,
        assessmentResponse: action?.payload,
        isLoadingAssessmentResponse: false,
      }
    case SET_ACTIVE_QUESTION:
      return {
        ...state,
        activeQuestion: state.questions.find((q: { id: string }) => q.id === action.payload),
      }
    case INITIALIZE_OWNER_STEPPER:
      return {
        ...state,
        steps: action?.payload,
      }
    case GET_ASSESSMENT_RESPONSE_BY_QUESTION.FULLFILLED:
      return {
        ...state,
        assessmentQuestion: action.payload,
      }
    case GET_USER_ASSESSMENT_RESPONSE_BY_QUESTION.FULLFILLED:
      return {
        ...state,
        usersAssessmentResponseByQuestion: action.payload,
      }

    case GET_RECOMMENDED_PLAYS_ASSSESSMENTS.FULLFILLED:
      return {
        ...state,
        isLoadingAssessmentResponse: false,
        recommendations: action.payload,
      }

    case GET_RECOMMENDED_PLAYS_ASSSESSMENTS.RESET:
      return {
        ...state,
        recommendations: [],
      }

    case GET_BIG_QUERY.FULLFILLED:
      let { payload } = action
      return {
        ...state,
        bigQuery: payload,
      }
    case GET_SALES_DATA.FULLFILLED:
      return {
        ...state,
        salesChartData: action.payload,
      }
    case GET_PRODUCT_DATA.FULLFILLED:
      return {
        ...state,
        productsData: action.payload,
      }
    case GET_FINANCE_DATA.FULLFILLED:
      return {
        ...state,
        financeData: action.payload,
      }
    case GET_MARKETING_DATA.FULLFILLED:
      return {
        ...state,
        marketingData: action.payload,
      }
    default:
  }
  return state
}

export default reducer
