import React, { useMemo } from 'react'
import { compact, join } from 'lodash'
import { timeDuration } from 'utils/helper'
import { IUSER_NOTIFICATION_TYPES } from 'components/NotificationPanel/types'

const Message = ({ notification }) => {
  const userName = useMemo(() => {
    return join(compact([notification?.firstName, notification?.lastName]), ' ')
  }, [notification])
  return (
    <div className="font-primary text-primary-text text-base font-semibold ">
      {notification.notificationType === IUSER_NOTIFICATION_TYPES.CALENDAR_MEETING && (
        <>
          <span className="text-base font-semibold">
            {userName} {notification.businessName ? `of ${notification.businessName} ` : ''}
          </span>
          has scheduled a meeting
        </>
      )}
      {notification.notificationType === IUSER_NOTIFICATION_TYPES.CALENDAR_MEETING_RESCHEDULED && (
        <>
          <span className="text-base font-semibold">
            {userName} {notification.businessName ? `of ${notification.businessName} ` : ''}
          </span>
          rescheduled a meeting
        </>
      )}
      {notification.notificationType === IUSER_NOTIFICATION_TYPES.CALENDAR_MEETING_CANCELLED && (
        <>
          <span className="text-base font-semibold">
            {userName} {notification.businessName ? `of ${notification.businessName} ` : ''}
          </span>
          cancelled a meeting
        </>
      )}
      {notification.notificationType === IUSER_NOTIFICATION_TYPES.CHAT && (
        <>
          <span className="text-base font-semibold">
            {userName} {notification.businessName ? `of ${notification.businessName} ` : ''}
          </span>
          sent a chat
        </>
      )}
      {notification.notificationType === IUSER_NOTIFICATION_TYPES.CLIENT_USER_PROFILE_UPDATE && (
        <>
          <span className="text-base font-semibold">
            {userName} {notification.businessName ? `of ${notification.businessName} ` : ''}
          </span>
          has updated their user profile details
        </>
      )}
      {notification.notificationType ===
        IUSER_NOTIFICATION_TYPES.CLIENT_BUSINESS_PROFILE_UPDATE && (
        <>
          <span className="text-base font-semibold">
            {userName} {notification.businessName ? `of ${notification.businessName} ` : ''}
          </span>
          has updated their business profile details
        </>
      )}
      {notification.notificationType === IUSER_NOTIFICATION_TYPES.NEW_CLIENT_ADDED && (
        <>
          <span className="text-base font-semibold">
            {userName} {notification.businessName ? `of ${notification.businessName} ` : ''}
          </span>
          has been assigned to you.
        </>
      )}
      {notification.notificationType === IUSER_NOTIFICATION_TYPES.CLIENT_REMOVED && (
        <>
          <span className="text-base	font-semibold">
            {userName} {notification.businessName ? `of ${notification.businessName} ` : ''}
          </span>
          has been removed.
        </>
      )}
      {notification.notificationType === IUSER_NOTIFICATION_TYPES.MONTHLY_ATTESTATION_TIME && (
        <>
          <span className="text-base font-semibold"> {userName}</span> {notification.meta.text}
        </>
      )}
      {notification.createdAt && (
        <div className="font-normal mt-1 leading-3 text-xs">
          {timeDuration(notification.createdAt)}
        </div>
      )}
    </div>
  )
}

export default Message
