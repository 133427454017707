import ValidationSchemaBuilder from 'utils/ValidationSchemaBuilder'
import * as yup from 'yup'

const schemaBuilder = new ValidationSchemaBuilder()

export const myProfileSchema = schemaBuilder
  .firstName()
  .lastName()
  .email()
  .mobileContactNumber()
  .getSchema()

export const profileSchema = schemaBuilder
  .firstName()
  .lastName()
  .email()
  .mobileContactNumber()
  .getSchema()

export const businessSchema = schemaBuilder.businessName().getSchema()
export const businessOtherDetailSchema = schemaBuilder
  .custom({
    businessVision: yup.string().required('You must Enter business Vision'),
    challenges: yup.string().required('You must Enter challenges'),
  })
  .getSchema()

export const availabilityHoursSchema = schemaBuilder
  .custom({
    duration: yup.number().required('Please select duration of the meeting'),
    timeZone: yup.string().required(),
    timeSlots: yup.array().of(
      yup.object({
        lable: yup.string(),
        value: yup.string(),
        slots: yup.array().of(
          yup.object({
            startTime: yup.string().required('Please select the starting Time'),
            endTime: yup.string().required('Please select the end time'),
          })
        ),
      })
    ),
  })
  .getSchema()

export const monthlyAttestationSchema = schemaBuilder
  .fullTimeEmployees()
  .partTimeEmployees()
  .contractors()
  .volunteers()
  .jobsCreated()
  .revenue()
  .wages()
  .debtFinancing()
  .businessBankAccount()
  .newCustomer()
  .productSale()
  .serviceSale()
  .targetAudience()
  .physicalLocation()
  .financialGrowth()
  .getSchema()
export const subscribeToSmsSchema = schemaBuilder.isSmsOptedIn().getSchema()
