import {
  GET_USER_FILES,
  INPUT_FIELD_BG_COLOR,
  RESET_IMAGE_URL,
  RESET_PLAY,
  TOTAL_UPCOMING_MEETINGS,
  UPLOAD_FILE,
} from 'store/types'

const initialState = {
  uploadedImage: '',
  uploadFileLoading: false,
  userFiles: [],
  uploadFileErrors: '',
  totalUpComingMeeting: 0,
  inputFieldBgColor: 'bg-grey-light',
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case UPLOAD_FILE.STARTED:
      return {
        ...state,
        uploadedImage: '',
        uploadFileErrors: '',
        uploadFileLoading: true,
      }
    case UPLOAD_FILE.FULLFILLED:
      return {
        ...state,
        ...action.payload,
        uploadFileLoading: false,
      }
    case GET_USER_FILES.STARTED:
      return {
        ...state,
        userFiles: [],
      }
    case GET_USER_FILES.FULLFILLED:
      return {
        ...state,
        userFiles: action.payload,
      }
    case TOTAL_UPCOMING_MEETINGS:
      return {
        ...state,
        totalUpComingMeeting: action.payload,
      }
    case RESET_IMAGE_URL:
      return {
        ...state,
        uploadedImage: '',
      }
    case INPUT_FIELD_BG_COLOR:
      return {
        ...state,
        inputFieldBgColor: action.payload,
      }
    case RESET_PLAY:
      return {
        ...state,
        uploadedImage: '',
      }
    default:
  }
  return state
}

export default reducer
