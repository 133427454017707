import * as yup from 'yup'
import Lazy from 'yup/lib/Lazy'

type Schema = Lazy<any, unknown> | yup.AnyObjectSchema

/**
 * A set of common validations.
 * Don't add to this class unless a given validation is needed more than once.
 * For one-time validations use the "custom" method of ValidationSchemaBuilder
 */
export abstract class CommonValidations {
  abstract firstName()
  abstract lastName()
  abstract email()
  abstract mobileContactNumber()
  abstract ein()
  abstract yearsInBusiness()
  abstract businessName()
}

/** Responsible for defining validation rules with the "yup" library */
export class ValidationsProvider extends CommonValidations {
  firstName() {
    return yup
      .string()
      .matches(/^(?!\s)[a-zA-Z''"”’ -]+$/, 'First name cannot be empty')
      .required('Please enter first name')
  }

  lastName() {
    return yup
      .string()
      .matches(/^(?!\s)[a-zA-Z''"”’ -]+$/, 'Last name cannot be empty')
      .required('Please enter last name')
  }

  email() {
    return yup
      .string()
      .email('Please enter a valid email address')
      .required('Please enter a valid email address')
  }
  isSmsOptedIn() {
    return yup.boolean().oneOf([true], 'Agreement is required to subscribe.')
  }

  mobileContactNumber() {
    return yup
      .string()
      .required('Phone number is required')
      .matches(
        /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})$/,
        'Please enter valid phone number'
      )
      .transform((value) => {
        return value ? value.replace(/\D/g, '') : ''
      })
      .test('contactNumberLength', 'All 10 numbers required', (value) => {
        if (!value) {
          return true
        }
        return value.length === 0 || value.length === 10
      })
  }

  ein() {
    return yup
      .string()
      .matches(/^[0-9]{2}-[0-9]{7}$/i, 'Please enter a valid EIN number')
      .required('Please enter a valid ein number')
  }

  yearsInBusiness() {
    return yup
      .string()
      .matches(/^(?!00)[0-9]{1,4}$/i, 'Year must be between 0 to 1000')
      .required('You must enter year')
  }

  businessName() {
    return yup.string().required('You must enter Business Name')
  }

  fullTimeEmployees() {
    return yup
      .string()
      .required('Must be a number')
      .matches(/^(?!00)[0-9,]{1,}$/i, 'must be a number')
  }
  partTimeEmployees() {
    return yup
      .string()
      .required('Must be a number')
      .matches(/^(?!00)[0-9,]{1,}$/i, 'must be a number')
  }
  contractors() {
    return yup
      .string()
      .required('Must be a number')
      .matches(/^(?!00)[0-9,]{1,}$/i, 'must be a number')
  }
  volunteers() {
    return yup
      .string()
      .required('Must be a number')
      .matches(/^(?!00)[0-9,]{1,}$/i, 'must be a number')
  }
  jobsCreated() {
    return yup
      .string()
      .required('Must be a number')
      .matches(/^(?!00)[0-9,]{1,}$/i, 'Must be a number')
  }
  revenue() {
    return yup
      .string()
      .required('Please enter 0 or dollars and cents (eg. $15.50)')
      .matches(
        /^([-]?(?!00)[0-9*$,+]*(\.[0-9*$,+]+)?)$/i,
        'Please enter 0 or dollars and cents (eg. $15.50)'
      )
  }
  wages() {
    return yup
      .string()
      .required('Please enter 0 or dollars and cents (eg. $15.50)')
      .matches(
        /^(?!00)[0-9*$,+]*(\.[0-9*$,+]+)?$/i,
        'Please enter 0 or dollars and cents (eg. $15.50)'
      )
  }
  debtFinancing() {
    return yup
      .string()
      .required('Please enter 0 or dollars and cents (eg. $15.50)')
      .matches(
        /^(?!00)[0-9*$,+]*(\.[0-9*$,+]+)?$/i,
        'Please enter 0 or dollars and cents (eg. $15.50)'
      )
  }
  businessBankAccount() {
    return yup
      .string()
      .matches(/^(?!00)[0-9,]{1,}$/i, 'Must be a number')
      .required('Must be a number')
  }
  newCustomer() {
    return yup
      .string()
      .required('Must be a number')
      .matches(/^(?!00)[0-9,]{1,}$/i, 'Must be a number')
  }
  productSale() {
    return yup
      .string()
      .required('Must be a number')
      .matches(/^(?!00)[0-9,]{1,}$/i, 'Must be a number')
  }
  serviceSale() {
    return yup
      .string()
      .required('Must be a number')
      .matches(/^(?!00)[0-9,]{1,}$/i, 'Must be a number')
  }
  targetAudience() {
    return yup
      .string()
      .required('Must be a number')
      .matches(/^(?!00)[0-9,]{1,}$/i, 'Must be a number')
  }
  physicalLocation() {
    return yup
      .string()
      .required('Must be a number')
      .matches(/^(?!00)[0-9,]{1,}$/i, 'Must be a number')
  }
  financialGrowth() {
    return yup.string().required('Choose profit or loss for last month').nullable()
  }
}

/** Builder class for creating yup schemas */
class ValidationSchemaBuilder extends CommonValidations {
  provider = new ValidationsProvider()
  validations: { [key: string]: any } = {}
  _schema: Schema | null = null

  updateSchema() {
    this._schema = yup.object(this.validations) as Schema
  }

  /** Returns the finished schema and clears itself to make another schema */
  getSchema() {
    const schema = this._schema
    this.validations = {}
    this._schema = null
    return schema as Schema
  }

  /** Add custom, one-off properties to the schema */
  custom(obj: { [key: string]: any }) {
    Object.assign(this.validations, obj)
    this.updateSchema()
    return this
  }

  firstName() {
    this.validations.firstName = this.provider.firstName()
    this.updateSchema()
    return this
  }

  lastName() {
    this.validations.lastName = this.provider.lastName()
    this.updateSchema()
    return this
  }

  email() {
    this.validations.email = this.provider.email()
    this.updateSchema()
    return this
  }

  mobileContactNumber() {
    this.validations.mobileContactNumber = this.provider.mobileContactNumber()
    this.updateSchema()
    return this
  }
  isSmsOptedIn() {
    this.validations.isSmsOptedIn = this.provider.isSmsOptedIn()
    this.updateSchema()
    return this
  }

  ein() {
    this.validations.ein = this.provider.ein()
    this.updateSchema()
    return this
  }

  yearsInBusiness() {
    this.validations.yearsInBusiness = this.provider.yearsInBusiness()
    this.updateSchema()
    return this
  }

  businessName() {
    this.validations.businessName = this.provider.businessName()
    this.updateSchema()
    return this
  }

  fullTimeEmployees() {
    this.validations.fullTimeEmployees = this.provider.fullTimeEmployees()
    this.updateSchema()
    return this
  }
  partTimeEmployees() {
    this.validations.partTimeEmployees = this.provider.partTimeEmployees()
    this.updateSchema()
    return this
  }
  contractors() {
    this.validations.contractors = this.provider.contractors()
    this.updateSchema()
    return this
  }
  volunteers() {
    this.validations.volunteers = this.provider.volunteers()
    this.updateSchema()
    return this
  }
  jobsCreated() {
    this.validations.jobsCreated = this.provider.jobsCreated()
    this.updateSchema()
    return this
  }
  revenue() {
    this.validations.revenue = this.provider.revenue()
    this.updateSchema()
    return this
  }
  wages() {
    this.validations.wages = this.provider.wages()
    this.updateSchema()
    return this
  }
  debtFinancing() {
    this.validations.debtFinancing = this.provider.debtFinancing()
    this.updateSchema()
    return this
  }
  businessBankAccount() {
    this.validations.businessBankAccount = this.provider.businessBankAccount()
    this.updateSchema()
    return this
  }
  newCustomer() {
    this.validations.newCustomer = this.provider.newCustomer()
    this.updateSchema()
    return this
  }
  productSale() {
    this.validations.productSale = this.provider.productSale()
    this.updateSchema()
    return this
  }
  serviceSale() {
    this.validations.serviceSale = this.provider.serviceSale()
    this.updateSchema()
    return this
  }
  targetAudience() {
    this.validations.targetAudience = this.provider.targetAudience()
    this.updateSchema()
    return this
  }
  physicalLocation() {
    this.validations.physicalLocation = this.provider.physicalLocation()
    this.updateSchema()
    return this
  }
  financialGrowth() {
    this.validations.financialGrowth = this.provider.financialGrowth()
    this.updateSchema()
    return this
  }
}

export default ValidationSchemaBuilder
