import API from 'api'

import * as api from '__generated__/api-types-and-hooks'

class USER_API {
  getCognitoUsers = (role): Promise<any> => {
    return API.fetch(api['ListCognitoUsersDocument'], { input: role })
  }
  getUserProfile = (userId = undefined, tenantId = undefined): Promise<any> => {
    return API.fetch(api['GetMyProfileDocument'], { userId, tenantId })
  }
  getBSOProfile = (tenantId): Promise<any> => {
    return API.fetch(api['GetBusinessSupportOrganizationDocument'], { input: { tenantId } })
  }
  updateUserProfile = (user): Promise<any> => {
    return API.fetch(api['UpdateMyProfileDocument'], { input: user })
  }
  addUserToGroup = (user): Promise<any> => {
    return API.fetch(api['AddUserToGroupDocument'], { input: user })
  }

  updatePassword = (password): Promise<any> => {
    return API.fetch(api['UpdateUserPasswordDocument'], { input: password })
  }
  createUser = (user): Promise<any> => {
    return API.fetch(api['CreateUserDocument'], { input: user })
  }
  getBusinessProfile = (id): Promise<any> => {
    return API.fetch(api['GetBusinessProfileDocument'], { id })
  }
  updateBusinessProfile = (user): Promise<any> => {
    return API.fetch(api['UpdateBusinessProfileDocument'], { input: user })
  }
  getBigQuery = (query): Promise<any> => {
    return API.fetch(api['GetBigQueryDocument'], { query: query })
  }
  logActivity = (activityLogInput): Promise<any> => {
    return API.fetch(api['LogActivityDocument'], { activityLogInput: activityLogInput })
  }
  getUserNotifications = (payload): Promise<any> => {
    return API.fetch(api['GetUserNotificationsDocument'], { input: payload })
  }
  updateUserNotifications = (payload): Promise<any> => {
    return API.fetch(api['UpdateUserNotificationsDocument'], { input: payload })
  }
  deleteUserNotifications = (payload): Promise<any> => {
    return API.fetch(api['DeleteUserNotificationsDocument'], { input: payload })
  }
  getClientsSummary = ({ tenantId }): Promise<any> => {
    return API.fetch(api['GetClientsSummaryDocument'], { tenantId })
  }
  resendUserInvite = (payload): Promise<any> => {
    return API.fetch(api['ResendUserInviteDocument'], { input: payload })
  }
  saveAttestation = (payload): Promise<api.AttestationResponse | undefined> => {
    return API.fetch(api['SaveAttestationDocument'], { input: payload })
  }
}

// eslint-disable-next-line
export default new USER_API()
