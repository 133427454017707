import { IButtonProps } from 'components/Common/Button/index'
import { useMemo } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'

export const ButtonWrapper = styled.button((props: IButtonProps) => {
  const classes = useMemo(() => {
    const variants = {
      primary: tw`font-secondary rounded-full font-black bg-primary hover:bg-primary-brand active:bg-primary-active focus:bg-primary border-0 text-white tracking-wide`,
      secondary: tw`font-secondary rounded-full font-black bg-transparent text-primary hover:text-primary-brand active:text-primary-active border border-primary hover:border-primary-brand active:border-primary-brand focus:bg-primary-brandLight`,
      text: tw`font-primary bg-transparent font-primary font-[650] text-primary hover:text-primary-brand focus:text-primary focus:border-b focus:border-primary py-0 px-0 leading-6`,
    }

    const sizes = {
      small: tw`py-2 px-4 gap-1`,
      base: tw`py-4 px-8 gap-2`,
      large: tw`py-4 px-8 gap-2`,
    }

    const size = props.variant === 'text' ? '' : sizes[props.size!]

    const fontSize = {
      xs: tw`text-xs`,
      sm: tw`text-sm`,
      base: tw`text-base`,
      lg: tw`text-lg`,
      xl: tw`text-xl`,
      xxl: tw`text-2xl`,
      '3xl': tw`text-3xl`,
    }[props.fontSize!]

    const disabledClassesByVariant = props.disabled
      ? {
          primary: tw`!bg-primary-disabled !cursor-not-allowed`,
          secondary: tw`!text-primary-disabledText !border-primary-disabledText !cursor-not-allowed`,
          text: tw`!text-primary-disabledText !cursor-not-allowed`,
        }[props.variant!]
      : ''

    return [
      tw`flex justify-center items-center gap-2 focus-visible:outline-none cursor-pointer`,
      variants[props.variant!],
      fontSize,
      size,
      disabledClassesByVariant,
    ]
  }, [props.disabled, props.fontSize, props.size, props.variant])

  return classes
})
