import React from 'react'

import useDragElement from 'hooks/useDragElement'

import StatCard from 'components/Dashboard/StatCard'
import SectionTitle from 'components/Dashboard/SectionTitle'
import DraggableContentArea from 'components/Common/DraggableContentArea'

import { AppFC } from 'types'

interface IStatsCardProps {
  showNoActiveClientsMessage: boolean
  loading: boolean
  stats: any
}

/** A series of cards displaying important statistics about BSO clients, draggable on smaller screens */
const StatCards: AppFC<IStatsCardProps> = ({ loading, stats, showNoActiveClientsMessage }) => {
  const total = stats?.totalUsers
  useDragElement('#drag-area')
  /* use of overflow hidden here is duct-tape to prevent stat cards from overreaching the side of the screen until drag/scrolling is implemented */
  return (
    <section className="flex flex-col gap-3 lg:overflow-hidden">
      {/* negative padding helps prevent box shadows from forming a sort of "box" with outlines */}
      <div className="-pb-3 flex flex-col gap-2 sm:flex-row sm:gap-9 items-baseline">
        <SectionTitle>Client Stats</SectionTitle>
        {showNoActiveClientsMessage && (
          <p className="italic text-black-light text-lg sm:text-xl">You have no active clients.</p>
        )}
      </div>
      <DraggableContentArea>
        <StatCard
          loading={loading}
          title="Signed In"
          description="Percentage of clients who have signed in at least 1 time."
          value={stats?.userSignIns}
          total={total}
        />
        <StatCard
          loading={loading}
          title="Welcome Assessment"
          description="Percentage of clients who have completed the welcome assessment."
          value={stats?.completedInitialAssessmentCount}
          total={total}
        />
        {/* <StatCard
          // NEED THIS FROM API
          loading={loading}
          title="Business Baseline"
          description="Percentage of clients who have completed the business baseline assessment."
          value={0}
          total={total}
        /> */}
        <StatCard
          loading={loading}
          title="First Play"
          description="Percentage of clients who have completed at least 1 play."
          value={stats?.completedPlayCount}
          total={total}
        />
      </DraggableContentArea>
    </section>
  )
}

export default StatCards
