import { SplitFactory } from '@splitsoftware/splitio'
export class FeatureFlagManager {
  private factory: SplitIO.ISDK
  private client: SplitIO.IClient

  constructor(userId?: string) {
    if (!process.env.REACT_APP_SPLIT_IO_CLIENT_KEY)
      throw new Error('Feature flag configuration not set.')
    this.factory = SplitFactory({
      core: {
        authorizationKey: process.env.REACT_APP_SPLIT_IO_CLIENT_KEY,
        key: userId ?? 'key',
      },
      startup: {
        readyTimeout: 10, // seconds
      },
      streamingEnabled: true,
    })

    this.client = this.factory.client()
  }

  public async checkToggleFlag(flagName: string): Promise<boolean> {
    await this.client.ready().catch((e) => console.error(e))

    const treatment: SplitIO.Treatment = this.client.getTreatment(flagName)

    return treatment === 'on'
  }

  public async checkFlag(flagName: string): Promise<string> {
    await this.client.ready().catch((e) => console.error(e))

    const treatment: SplitIO.Treatment = this.client.getTreatment(flagName)

    return treatment
  }
}
