import { ampli } from 'ampli'
import history from 'utils/history'

/** "item" is a nav item defined in config data - todo: type it */
export default function navigateTo(item: any) {
  if (item?.path) {
    ampli.topNavViewed({ tabName: item.label })
    history.push(item.path)
  }
}
