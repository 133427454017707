import styled from 'styled-components'
import tw from 'twin.macro'

export const FileWrapper = styled.div`
  input[type='file'] {
    display: block !important;
    min-height: 100px !important;
  }
`

export const DropFileWrapper = styled.div`
  ${tw`flex flex-col justify-center items-center xs:pt-0 sm:px-4 sm:pt-6 sm:pb-11 relative bg-contain bg-no-repeat`} {
  }
`
