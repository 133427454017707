module.exports = {
  /** PURPLE */
  primary: {
    DEFAULT: '#5E06B9',
    brand: '#7600ED',
    brandDark: '#BB80F6',
    brandLight: '#F1E6FD',
    brandBlueLight: '#52B4F9',
    active: '#470C84',
    disabled: '#D4D4D8',
    text: '#3F3F46',
    focus: '#5E06B9',
    outline: '#D4D4D8',
    hover: '#7600ED',
    disabledText: '#B0B0B0',
    error: '#DC2626',
    card: '#E2E8F0',
    loading: '#5E06B9',
  },
  secondary: {
    DEFAULT: '#FAFAFA',
    text: '#D4D4D8',
  },
  /** Light blue */
  tertiary: {
    light: '#1E7ABB',
    DEFAULT: '#52B4F9',
    hover: '#BAE2FF',
  },
  alert: {
    light: '#ffebeb',
    DEFAULT: '#DC2626',
  },
  info: {
    DEFAULT: '#3B82F6',
    light: '#ebf3fe',
  },
  background: {
    DEFAULT: '#E5E5E5',
  },
  black: {
    DEFAULT: '#000000',
    light: '#18181B',
  },
  white: {
    DEFAULT: '#FFFFFF',
  },
  transparent: {
    DEFAULT: 'transparent',
  },
  zinc: {
    DEFAULT: '#3F3F46',
  },
  gerberared: {
    DEFAULT: '#F45E13',
  },
  silver: {
    darkest: '#7c92a9',
    darker: '#888888',
    dark: '#A1A1AA',
    DEFAULT: '#71717A',
    light: '#585757',
    lighter: '#D9D9D9',
  },
  grey: {
    darkest: '#969696',
    darker: '#C4C4C4',
    dark: '#52525B',
    DEFAULT: '#27272A',
    light: '#F7F7F7',
    lighter: '#e8e8e8',
    lightest: '#dddddd',
    lightest2x: '#F1E6FD',
    lighter3x: '#F4F4F5',
    lighter4x: '#E6E6E6',
  },
  blue: {
    darkest: '#2F035D',
    darker: '#0073C6',
    dark: '#0085ff',
    DEFAULT: '#3B82F6',
    light: '#03a1ff',
    lighter: '#12b8f1',
    lightest: '#d0f1fc',
    curious: '#52B4F9',
  },
  cream: {
    dark: '#F4F4F5',
    darker: '#f3f3f3',
    DEFAULT: '#E5F3FF',
    light: '#e6ebee',
    lighter: '#E5E5E5',
  },
  purple: {
    dark: '#111827',
    DEFAULT: '#7B61FF',
    light: '#22457D',
    lighter: '#5E06B9',
    indigo: '#470C84',
  },
  sky: {
    light: '#E4FEF8',
    DEFAULT: '#DCF0FE',
    dark: '#CDD6E5',
    darker: '#FFE5DE',
  },
  brown: {
    light: '#FADEDE',
    DEFAULT: '#B0B0B0',
    dark: '#83402E',
  },
  violet: {
    DEFAULT: '#1B1A57',
    light: '#A0AAD7',
  },
  green: {
    light: '#D9EFE9',
    lighter: '#e6f5f0',
    DEFAULT: '#059669',
    cyan: '#059669',
  },
  zumthor: {
    DEFAULT: '#EAF2FF',
  },
  bleachedlilac: {
    DEFAULT: '#F3E7FF',
  },
  aqua: {
    DEFAULT: '#77FADB',
  },
  yellow: {
    lighter: '#FFFF8A',
    light: '#fff6e6',
    DEFAULT: '#FFF4CC',
    dark: '#FFA500',
    darker: '#FFC900',
    darkest: '#B6910D',
  },
  calander: {
    intro: {
      bg: '#E5F3FF',
      text: '#3B82F6',
    },
    review: {
      bg: '#7B61FF2B',
      text: '#7B61FF',
    },
    playbook: {
      bg: '#F4F4F5',
      text: '#52525B',
    },
  },
}
