import { STATUS } from 'config'
import { CHANGE_PASSWORD } from 'store/types'

const initialState = {
  uploadedImage: '',
  status: '',
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case CHANGE_PASSWORD.STARTED:
      return {
        ...state,
        status: STATUS.LOADING,
      }
    case CHANGE_PASSWORD.FULLFILLED:
      return {
        ...state,
        status: STATUS.COMPLETED,
      }
    case CHANGE_PASSWORD.REJECTED:
      return {
        ...state,
        status: STATUS.COMPLETED,
      }
    default:
  }
  return state
}

export default reducer
