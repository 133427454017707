import { createSelector } from 'reselect'
import moment from 'moment-timezone'
import { CYCLR_CALENDAR } from 'config'
import sortBy from 'lodash/sortBy'
import { get, isEmpty } from 'lodash'

const getCalendarEvents = (state) => state.calendar.calendarEvents
const getUserAvailabilityList = (state) => state.calendar.userAvailabilityList
const getTotalUpComingMeetings = (state) => state.common.totalUpComingMeeting
const getCalendarEvent = (state) => state.calendar.calendarEvent
const getUserProfile = (state) => state.user?.user

export const getUserAvailabilityListSelector = createSelector(
  [getUserAvailabilityList],
  (userAvailabilityList) => {
    const availableSlots = userAvailabilityList?.availability
    let availabilityList: any = {
      availability: [],
      duration: '',
    }
    if (!isEmpty(availableSlots)) {
      availableSlots.forEach((availableSlot) => {
        availableSlot.slots = sortBy(
          availableSlot.slots.filter(
            (slot) => slot.isAvailable && moment.utc(slot.slotStartTime).isAfter(moment.utc())
          )
        ).map((slot) => {
          return {
            ...slot,
            slotStartDate: moment(slot.slotStartTime),
            slotEndDate: moment(slot.slotEndTime),
            slotStartTime: moment(slot.slotStartTime).format('h:mm A'),
            slotEndTime: moment(slot.slotEndTime).format('h:mm A'),
          }
        })

        if (!isEmpty(availableSlot.slots)) {
          availabilityList.availability.push(availableSlot)
        }
      })
      availabilityList.duration = userAvailabilityList.duration
    }
    return availabilityList
  }
)

const getUpcomingEvents = (futureMeetings, totalUpComingMeeting) => {
  const myObj = {}
  try {
    for (let i = 0; i < totalUpComingMeeting && futureMeetings[i]; i++) {
      const meDate = moment(futureMeetings[i].start).format('YYYY-MM-DD')

      if (myObj[meDate]) {
        myObj[meDate].push(futureMeetings[i])
      } else {
        myObj[meDate] = [futureMeetings[i]]
      }
    }
  } catch (err) {
    console.log(err)
  }
  return myObj
}

export const getCalendarEventsSelector = createSelector(
  [getCalendarEvents, getTotalUpComingMeetings, getUserProfile],
  (events, totalUpComingMeeting, user) => {
    const calendarEvents: any = []
    let upComingEvents: any = []
    let futureMeetings: any[] = []
    try {
      for (let i = 0; i < events?.length; i++) {
        let start: Date
        let end: Date
        if (events[i]?.start?.dateTime) {
          start = moment.tz(events[i]?.start?.dateTime, events[i]?.start?.timeZone).toDate()
          end = moment.tz(events[i]?.end?.dateTime, events[i]?.start?.timeZone).toDate()
        } else {
          start = moment(events[i]?.start?.date)?.toDate()
          end = moment(events[i]?.end?.date)?.toDate()
        }
        if (events[i]?.meta && typeof events[i].meta === 'string') {
          events[i].meta = JSON.parse(events[i]?.meta)
        }
        if (typeof events[i].attendees === 'string') {
          events[i].attendees = JSON.parse(events[i]?.attendees)
        }
        if (events[i].attendees) {
          let attendeesEmail = events[i].attendees.map((attendee) => {
            return attendee?.email
          })
          events[i].attendeesEmail = attendeesEmail
        }

        if (events[i]?.profile?.length > 0) {
          events[i].profile = JSON.parse(events[i].profile)
        }
        if (events[i].description) {
          var html = events[i].description
          var div = document.createElement('div')
          div.innerHTML = html
          events[i].text = div.innerText
          events[i].text = events[i].text.replace(/\n/g, ' ')
        }

        calendarEvents.push({
          calendar: CYCLR_CALENDAR.GOOGLE_CALENDAR,
          title: events[i]?.summary,
          start,
          end,
          attendeesEmail: events[i].attendeesEmail,
          text: events[i]?.text,
          description: events[i].description,
          location: events[i].location,
          textToShow: events[i].textToShow,
          timezone: events[i]?.start?.timeZone,
          attendees: events[i].attendees,
          eventType: events[i]?.eventType,
          organizer: events[i]?.meta?.organizer,
          inviteeId: events[i]?.inviteeId,
          eventId: events[i]?.eventId || '',
          advisorId: events[i]?.advisorId,
          advisor: events[i]?.profile
            ? get(events[i], 'profile.firstName', '') + ' ' + get(events[i], 'profile.lastName', '')
            : '',
          id: events[i]?.id,
          tackleId: events[i].meta?.tackleId || '',
          availability: events[i]?.availability,
          startDateTime: events[i]?.start?.dateTime,
          endDateTime: events[i]?.end?.dateTime,
          videoLinkDescription: events[i]?.videoLinkDescription,
        })
      }

      futureMeetings = calendarEvents.filter((event) => new Date(event.start) >= new Date())
      //futureMeetings = sortBy(copy(futureMeetings), 'start')
      upComingEvents = getUpcomingEvents(futureMeetings, totalUpComingMeeting)
    } catch (err) {
      console.log('error: ', err)
    }
    return { upComingEvents, calendarEvents, futureMeetings }
  }
)

export const getUserMeetingByEventIdSelector = createSelector(
  [getCalendarEvent, getUserProfile],
  (calendarEvent, user) => {
    interface Event {
      start?: Date
      end?: Date
      attendeesEmail?: []
      title: String
      location: String
      textToShow: String
      timezone: String
      eventId: String
      advisorId: String
      advisor: String
      id: String
      attendees: []
      tackleId: string
      description: string
      eventType: string
      videoLinkDescription: string
    }

    let event: Event = {
      title: '',
      location: '',
      textToShow: '',
      timezone: '',
      attendees: [],
      eventId: '',
      advisorId: '',
      advisor: '',
      tackleId: '',
      id: '',
      description: '',
      eventType: '',
      videoLinkDescription: '',
    }

    try {
      if (calendarEvent?.summary) {
        if (!isEmpty(calendarEvent?.start)) {
          event.start = moment
            .tz(calendarEvent?.start?.dateTime, calendarEvent?.start?.timeZone)
            .toDate()
          event.end = moment.tz(calendarEvent?.end?.dateTime, calendarEvent?.end?.timeZone).toDate()
        } else {
          event.start = calendarEvent?.start?.date
            ? moment(calendarEvent?.start?.date).toDate()
            : undefined
          event.end = calendarEvent?.end?.date
            ? moment(calendarEvent?.end?.date).toDate()
            : undefined
        }

        if (calendarEvent?.meta) {
          calendarEvent.meta = JSON.parse(calendarEvent?.meta)
        }

        if (calendarEvent?.attendees) {
          let attendeesEmail = calendarEvent.attendees.map((attendee) => {
            return attendee?.email
          })
          event.attendeesEmail = attendeesEmail
        }

        if (calendarEvent?.profile?.length > 0) {
          calendarEvent.profile = JSON.parse(calendarEvent?.profile)
        }

        event.title = calendarEvent?.summary
        event.location = calendarEvent?.location
        event.textToShow = calendarEvent?.textToShow
        event.timezone = calendarEvent.start?.timeZone
        event.attendees = calendarEvent?.attendees
        event.eventId = calendarEvent?.id
        event.advisorId = calendarEvent?.advisorId
        event.advisor = calendarEvent?.profile
          ? get(calendarEvent, 'profile.firstName', '') +
            ' ' +
            get(calendarEvent, 'profile.lastName', '')
          : ''

        event.description = calendarEvent.description
        event.tackleId = calendarEvent.meta?.tackleId || ''
        event.id = calendarEvent?.id
        event.eventType = calendarEvent?.eventType
        event.videoLinkDescription = calendarEvent?.videoLinkDescription
      }
    } catch (err) {
      console.log('error: ', err)
    }
    return event
  }
)
