import React, { FC } from 'react'
import { COMPANY_NAME } from 'config'
import history from 'utils/history'

import Heading from 'components/Common/Heading'
import PolicyOverlayPage from 'components/Common/PolicyOverlayPage'
import TermsOfServiceBannerImage from 'assets/svgs/terms-of-service-banner-image.svg'
import styled from 'styled-components'
import { AppFC } from 'types'

export const TITLE = 'Terms Of Service'
export const JAMS_URL = 'https://www.jamsadr.com'
export const JAMS_LINK_TEST_ID = 'JAMS_LINK_TEST_ID'

export default function TermsAndConditions({ user, setShow }) {
  return (
    <PolicyOverlayPage
      bannerImageSrc={TermsOfServiceBannerImage}
      title={TITLE}
      lastRevised="December 27, 2022"
      user={user}
      setShow={setShow}
    >
      <Introduction />
      <TableOfContents>
        <Section title="WHO MAY USE THE SERVICES">
          You must be 18 years of age or older and reside in the United States or any of its
          territories to use the Services. By using the Services, you represent and warrant that you
          meet these requirements
        </Section>

        <Section title="USER ACCOUNTS">
          <Subsection identifier="2.1" title="Creating and Safeguarding your Account">
            To use the Services, you may need to create an account (“
            <strong>Account</strong>
            ”). You agree to provide us with accurate, complete and updated information for your
            Account. You can access, edit and update your Account via the following means [ DESCRIBE
            HOW WEBSITE ALLOWS USERS TO ADJUST ACCOUNT SETTINGS ]. You are solely responsible for
            any activity on your Account and for maintaining the confidentiality and security of
            your password. We are not liable for any acts or omissions by you in connection with
            your Account. You must immediately notify us at support@gologicsolutions.com if you know
            or have any reason to suspect that your Account or password have been stolen,
            misappropriated or otherwise compromised, or in case of any actual or suspected
            unauthorized use of your Account.
          </Subsection>
          <Subsection identifier="2.2" title="Access to Services">
            Your access to the Services is determined and governed by the small business development
            center that has authorized your access and use of the services. In the event that you no
            longer have access to the Services, please contact the small business development center
            that authorized your access and use of the services.
          </Subsection>
        </Section>
        <Section title="LOCATION OF OUR PRIVACY POLICY">
          <Subsection identifier="3.1" title="Privacy Policy">
            Our Privacy Policy describes how we handle the information you provide to us when you
            use the Services. For an explanation of our privacy practices, please visit our
            <PrivacyPolicyLink>Privacy Policy</PrivacyPolicyLink>
          </Subsection>
        </Section>

        <Section title="RESTRICTIONS">
          <Subsection identifier="4.1" title="Access">
            Your access and use of the Services may be interrupted from time to time for any of
            several reasons, including, without limitation, the malfunction of equipment, periodic
            updating, maintenance or repair of the Service or other actions that Company, in its
            sole discretion, may elect to take
          </Subsection>

          <Subsection identifier="4.2" title="Restrictions On Your Use of the Services">
            You may not do any of the following, unless applicable laws or regulations prohibit
            these restrictions or you have our written permission to do so:
            <SubsectionItems>
              <SubsectionItem>
                download, modify, copy, distribute, transmit, display, perform, reproduce,
                duplicate, publish, license, create derivative works from, or offer for sale any
                information contained on, or obtained from or through, the Services;
              </SubsectionItem>
              <SubsectionItem>
                duplicate, decompile, reverse engineer, disassemble or decode the Services
                (including any underlying idea or algorithm), or attempt to do any of the same;
              </SubsectionItem>
              <SubsectionItem>
                use, reproduce or remove any copyright, trademark, service mark, trade name, slogan,
                logo, image, or other proprietary notation displayed on or through the Services;
              </SubsectionItem>
              <SubsectionItem>
                use automation software (bots), hacks, modifications (mods) or any other
                unauthorized third-party software designed to modify the Services;
              </SubsectionItem>
              <SubsectionItem>
                exploit the Services for any commercial purpose, including without limitation
                communicating or facilitating any commercial advertisement or solicitation;
              </SubsectionItem>
              <SubsectionItem>
                access or use the Services in any manner that could disable, overburden, damage,
                disrupt or impair the Services or interfere with any other party's access to or use
                of the Services or use any device, software or routine that causes the same;
              </SubsectionItem>
              <SubsectionItem>
                attempt to gain unauthorized access to, interfere with, damage or disrupt the
                Services, accounts registered to other users, or the computer systems or networks
                connected to the Services;
              </SubsectionItem>
              <SubsectionItem>
                circumvent, remove, alter, deactivate, degrade or thwart any technological measure
                or content protections of the Services;
              </SubsectionItem>
              <SubsectionItem>
                use any robot, spider, crawlers or other automatic device, process, software or
                queries that intercepts, “mines,” scrapes or otherwise accesses the Services to
                monitor, extract, copy or collect information or data from or through the Services,
                or engage in any manual process to do the same;
              </SubsectionItem>
              <SubsectionItem>
                introduce any viruses, trojan horses, worms, logic bombs or other materials that are
                malicious or technologically harmful into our systems;
              </SubsectionItem>
              <SubsectionItem>
                use the Services for illegal, harassing, unethical, or disruptive purposes;
              </SubsectionItem>
              <SubsectionItem>
                violate any applicable law or regulation in connection with your access to or use of
                the Services; or
              </SubsectionItem>
              <SubsectionItem>
                access or use the Services in any way not expressly permitted by these Terms.
              </SubsectionItem>
            </SubsectionItems>
          </Subsection>
        </Section>

        <Section title="OWNERSHIP AND CONTENT">
          <Subsection identifier="5.1" title="Ownership of the Services">
            The Services, including their "look and feel" (e.g., text, graphics, images, logos),
            proprietary content, information and other materials, are protected under copyright,
            trademark and other intellectual property laws. You agree that the Company and/or its
            licensors own all right, title and interest in and to the Services (including any and
            all intellectual property rights therein) and you agree not to take any action(s)
            inconsistent with such ownership interests. We and our licensors reserve all rights in
            connection with the Services and its content (other than Your Content), including,
            without limitation, the exclusive right to create derivative works.
          </Subsection>
          <Subsection identifier="5.2" title="Ownership of Trademarks">
            The Company’s name, the Company’s logo and all related names, logos, product and service
            names, designs and slogans are trademarks of the Company or its affiliates or licensors.
            Other names, logos, product and service names, designs and slogans that appear on the
            Services are the property of their respective owners, who may or may not be affiliated
            with, connected to, or sponsored by us.
          </Subsection>
          <Subsection identifier="5.3" title="Ownership of the Feedback">
            We welcome feedback, comments & suggestions for improvements to the Services
            (“Feedback”). You acknowledge and expressly agree that any contribution of Feedback does
            not and will not give or grant you any right, title or interest in the Services or in
            any such Feedback. All Feedback becomes the sole and exclusive property of the Company,
            and the Company may use and disclose Feedback in any manner and for any purpose
            whatsoever without further notice or compensation to you and without retention by you of
            any proprietary or other right or claim. You hereby assign to the Company any and all
            right, title and interest (including, but not limited to, any patent, copyright, trade
            secret, trademark, show-how, know- how, moral rights and any and all other intellectual
            property right) that you may have in and to any and all Feedback.
          </Subsection>
          <Subsection identifier="5.4" title="Your Content License Grant">
            In connection with your use of the Services, you may be able to post, upload, or submit
            content to be made available through the Services (“
            <strong>Your Content</strong>”). In order to operate the Service, we must obtain from
            you certain license rights in Your Content so that actions we take in operating the
            Service are not considered legal violations. Accordingly, by using the Service and
            uploading Your Content, you grant us a license to access, use, host, cache, store,
            reproduce, transmit, display, publish, distribute, and modify (for technical purposes,
            e.g., making sure content is viewable on smartphones as well as computers and other
            devices) Your Content but solely as required to be able to operate and provide the
            Services. You agree that these rights and licenses are royalty-free, transferable,
            sub-licensable, worldwide and irrevocable (for so long as Your Content is stored with
            us), and include a right for us to make Your Content available to, and pass these rights
            along to, others with whom we have contractual relationships related to the provision of
            the Services, solely for the purpose of providing such Services, and to otherwise permit
            access to or disclose Your Content to third parties if we determine such access is
            necessary to comply with our legal obligations. As part of the foregoing license grant
            you agree that the other users of the Services shall have the right to comment on and/or
            tag Your Content and/or to use, publish, display, modify or include a copy of Your
            Content as part of their own use of the Services; except that the foregoing shall not
            apply to any of Your Content that you post privately for non-public display on the
            Services. By posting or submitting Your Content through the Services, you represent and
            warrant that you have, or have obtained, all rights, licenses, consents, permissions,
            power and/or authority necessary to grant the rights granted herein for Your Content.
            You agree that Your Content will not contain material subject to copyright or other
            proprietary rights, unless you have the necessary permission or are otherwise legally
            entitled to post the material and to grant us the license described above.
          </Subsection>
        </Section>
        <Section title="THIRD PARTY SERVICES AND MATERIALS">
          <Subsection identifier="6.1" title="Use of Third Party Materials in the Services">
            Certain Services may display, include or make available content, data, information,
            applications or materials from third parties (“
            <strong>Third Party Materials</strong>
            ”) or provide links to certain third party websites or tools. By using the Services, you
            acknowledge and agree that the Company is not responsible for examining or evaluating
            the content, accuracy, completeness, availability, timeliness, validity, copyright
            compliance, legality, decency, quality or any other aspect of such Third Party Materials
            or websites. We do not warrant or endorse and do not assume and will not have any
            liability or responsibility to you or any other person for any third-party services,
            Third Party Materials or third-party websites, or for any other materials, products, or
            services of third parties. Third Party Materials and links to other websites are
            provided solely as a convenience to you.
          </Subsection>
        </Section>
        <Section title="DISCLAIMERS, LIMITATIONS OF LIABILITY AND INDEMNIFICATION">
          <Subsection identifier="7.1" title="Disclaimers">
            YOUR ACCESS TO AND USE OF THE SERVICES ARE AT YOUR OWN RISK. YOU UNDERSTAND AND AGREE
            THAT THE SERVICES ARE PROVIDED TO YOU ON AN “AS IS” AND “AS AVAILABLE” BASIS. WITHOUT
            LIMITING THE FOREGOING, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, THE
            COMPANY, ITS PARENTS, AFFILIATES, RELATED COMPANIES, OFFICERS, DIRECTORS, EMPLOYEES,
            AGENTS, REPRESENTATIVES, PARTNERS AND LICENSORS (THE “
            <strong>THE COMPANY ENTITIES</strong>
            ”) DISCLAIM ALL WARRANTIES AND CONDITIONS, WHETHER EXPRESS OR IMPLIED, OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. THE COMPANY
            ENTITIES MAKE NO WARRANTY OR REPRESENTATION AND DISCLAIM ALL RESPONSIBILITY AND
            LIABILITY FOR: (A) THE COMPLETENESS, ACCURACY, AVAILABILITY, TIMELINESS, SECURITY OR
            RELIABILITY OF THE SERVICES; (B) ANY HARM TO YOUR COMPUTER SYSTEM, LOSS OF DATA, OR
            OTHER HARM THAT RESULTS FROM YOUR ACCESS TO OR USE OF THE SERVICES; (C) THE OPERATION OR
            COMPATIBILITY WITH ANY OTHER APPLICATION OR ANY PARTICULAR SYSTEM OR DEVICE; (D) WHETHER
            THE SERVICES WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE OR
            ERROR-FREE BASIS; AND (E) THE DELETION OF, OR THE FAILURE TO STORE OR TRANSMIT, YOUR
            CONTENT AND OTHER COMMUNICATIONS MAINTAINED BY THE SERVICES. NO ADVICE OR INFORMATION,
            WHETHER ORAL OR WRITTEN, OBTAINED FROM THE COMPANY ENTITIES OR THROUGH THE SERVICES,
            WILL CREATE ANY WARRANTY OR REPRESENTATION NOT EXPRESSLY MADE HEREIN.
          </Subsection>
          <Subsection identifier="7.2" title="Limitations of Liability">
            TO THE EXTENT NOT PROHIBITED BY LAW, YOU AGREE THAT IN NO EVENT WILL THE COMPANY
            ENTITIES BE LIABLE (A) FOR DAMAGES OF ANY KIND, INCLUDING DIRECT, INDIRECT, SPECIAL,
            EXEMPLARY, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING, BUT NOT LIMITED TO,
            PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, LOSS OF USE, DATA OR PROFITS, BUSINESS
            INTERRUPTION OR ANY OTHER DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR USE OR
            INABILITY TO USE THE SERVICES), HOWEVER CAUSED AND UNDER ANY THEORY OF LIABILITY,
            WHETHER UNDER THESE TERMS OR OTHERWISE ARISING IN ANY WAY IN CONNECTION WITH THE
            SERVICES OR THESE TERMS AND WHETHER IN CONTRACT, STRICT LIABILITY OR TORT (INCLUDING
            NEGLIGENCE OR OTHERWISE) EVEN IF THE COMPANY ENTITIES HAVE BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGE, OR (B) FOR ANY OTHER CLAIM, DEMAND OR DAMAGES WHATSOEVER
            RESULTING FROM OR ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR THE DELIVERY, USE
            OR PERFORMANCE OF THE SERVICES. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
            LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE EXCLUSION OR LIMITATION
            MAY NOT APPLY TO YOU. THE COMPANY ENTITIES’ TOTAL LIABILITY TO YOU FOR ANY DAMAGES
            FINALLY AWARDED SHALL NOT EXCEED ONE HUNDRED DOLLARS ($100.00). THE FOREGOING
            LIMITATIONS WILL APPLY EVEN IF THE ABOVE STATED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
          </Subsection>
          <Subsection identifier="7.3" title="Indemnification">
            By entering into these Terms and accessing or using the Services, you agree that you
            shall defend, indemnify and hold the Company Entities harmless from and against any and
            all claims, costs, damages, losses, liabilities and expenses (including attorneys’ fees
            and costs) incurred by the Company Entities arising out of or in connection with:
            <SubsectionItems>
              <SubsectionItem>
                your violation or breach of any term of these Terms or any applicable law or
                regulation;
              </SubsectionItem>
              <SubsectionItem>your violation of any rights of any third party;</SubsectionItem>
              <SubsectionItem>your access to or use of the Services;</SubsectionItem>
              <SubsectionItem>Your Content, or</SubsectionItem>
              <SubsectionItem>your negligence or wilful misconduct.</SubsectionItem>
            </SubsectionItems>
          </Subsection>
        </Section>
        <Section title="ARBITRATION AND CLASS ACTION WAIVER">
          <Subsection identifier="8.1" title="Informal Process First">
            You agree that, in the event of any dispute between you and the Company Entities, you
            will first contact the Company in writing and make a good faith sustained effort to
            resolve the dispute before resorting to more formal means of resolution, including
            without limitation, any arbitration or court action.
          </Subsection>
          <Subsection identifier="8.2" title="Arbitration Agreement and Class Action Waiver">
            <SubsectionItems>
              <SubsectionItem>
                If the dispute remains unresolved thirty (30) days after you first contact the
                Company in writing under Section 8.1, any remaining dispute, controversy, or claim
                (collectively, “<strong>Claim</strong>”) relating in any way to your purchase or use
                of the Products, will be resolved by arbitration, including threshold questions of
                arbitrability of the Claim. You and the Company agree that any Claim will be settled
                by final and binding arbitration, using the English language, administered by JAMS
                under its Comprehensive Arbitration Rules and Procedures (the{' '}
                <strong>“JAMS Rules</strong>”) then in effect (those rules ae deemed to be
                incorporated by reference into this section, and as of the date of these Terms). The
                JAMS Rules and the JAMS Consumer Arbitration Minimum Standards, the latter effective
                July 15, 2009, are available at &nbsp;
                <JamsLink />.
              </SubsectionItem>
              <SubsectionItem>
                {' '}
                <span className="font-bold md:text-xl">
                  Arbitration will be handled by a sole, neutral arbitrator in accordance with the
                  JAMS Rules. Judgment on the arbitration award may be entered in any court that has
                  jurisdiction. Any arbitration under these Terms will take place on an individual
                  basis – class, consolidated or representative arbitrations and class, consolidated
                  or representative actions are not permitted. You understand that by agreeing to
                  these Terms, you and the Company are each waiving the right to trial by jury or to
                  participate in a class, consolidated or representative action or class,
                  consolidated or representative arbitration. Court review of an arbitration award
                  is limited. The arbitrator, however, can award on an individual basis the same
                  damages and relief as a court (including injunctive and declaratory relief, or
                  statutory damages).
                </span>
                &nbsp;Notwithstanding the foregoing, you and the Company will have the right to
                bring an action in a court of proper jurisdiction for injunctive or other equitable
                or conservatory relief, pending a final decision by the arbitrator. You may instead
                assert your claim in “small claims” court, but only if your claim qualifies, your
                claim remains in such court and your claim remains on an individual,
                non-representative and non-class basis.
              </SubsectionItem>
              <SubsectionItem>
                {' '}
                The place of arbitration will be Chicago, Illinois, but if you prefer a hearing in
                or closer to your hometown area, you and the Company will confer in good faith in
                order to identify a reasonable alternative location.
              </SubsectionItem>
              <SubsectionItem>
                {' '}
                Each of the Parties shall cooperate in good faith in the discovery or exchange of
                nonprivileged information relevant to the dispute as necessary in accordance with
                the JAMS Rules.
              </SubsectionItem>
              <SubsectionItem>
                {' '}
                The arbitrator’s award will consist of a written statement stating the disposition
                of each claim, and the award will also provide a concise written statement of the
                essential findings and conclusions on which the award is based.
              </SubsectionItem>
              <SubsectionItem>
                {' '}
                You and the Company shall maintain the confidential nature of the arbitration
                proceeding and the award, including the hearing, except as may be necessary to
                prepare for or conduct the arbitration hearing on the merits, or except as may be
                necessary in connection with a court application for a preliminary remedy, a
                judicial challenge to an award or its enforcement or unless otherwise required by
                law or judicial decision.
              </SubsectionItem>
            </SubsectionItems>
          </Subsection>
          <Subsection identifier="8.3" title="Costs of Arbitration">
            Payment for any and all reasonable JAMS filing, administrative and arbitrator fees will
            be in accordance with the JAMS Rules and the JAMS Consumer Arbitration Minimum
            Standards. If the value of your claim does not exceed $10,000, the Company will pay for
            the reasonable filing, administrative and arbitrator fees associated with the
            arbitration, unless the arbitrator finds that either the substance of your claim or the
            relief sought was frivolous or brought for an improper purpose.
          </Subsection>
          <Subsection identifier="8.4" title="Opt-Out" bold={true}>
            Payment for any and all reasonable JAMS filing, administrative and arbitrator fees will
            be in accordance with the JAMS Rules and the JAMS Consumer Arbitration Minimum
            Standards. If the value of your claim does not exceed $10,000, the Company will pay for
            the reasonable filing, administrative and arbitrator fees associated with the
            arbitration, unless the arbitrator finds that either the substance of your claim or the
            relief sought was frivolous or brought for an improper purpose.
          </Subsection>
        </Section>
        <Section title="ADDITIONAL PROVISIONS">
          <Subsection identifier="9.1" title="Updating These Terms">
            We may modify these Terms from time to time in which case we will update the “Last
            Revised” date at the top of these Terms. If we make changes that are material, we will
            use reasonable efforts to attempt to notify you, such as by e-mail and/or by placing a
            prominent notice on the first page of the Platform. However, it is your sole
            responsibility to review these Terms from time to time to view any such changes. The
            updated Terms will be effective as of the time of posting, or such later date as may be
            specified in the updated Terms. Your continued access or use of the Services after the
            modifications have become effective will be deemed your acceptance of the modified
            Terms.
          </Subsection>
          <Subsection identifier="9.2" title="Termination of License and Your Account">
            If you breach any of the provisions of these Terms, all access to the Services granted
            by the Company will terminate automatically. Additionally, the Company may suspend,
            disable, or delete your Account and/or the Services (or any part of the foregoing) with
            or without notice, for any or no reason. If the Company deletes your Account for any
            suspected breach of these Terms by you, you are prohibited from re-registering for the
            Services under a different name. In the event of Account deletion for any reason, the
            Company may, but is not obligated to, delete any of Your Content. the Company shall not
            be responsible for the failure to delete or deletion of Your Content. All sections which
            by their nature should survive the termination of these Terms shall continue in full
            force and effect subsequent to and notwithstanding any termination of this Agreement by
            the Company or you. Termination will not limit any of the Company’s other rights or
            remedies at law or in equity.
          </Subsection>
          <Subsection identifier="9.3" title="Injunctive Relief">
            You agree that a breach of these Terms will cause irreparable injury to the Company for
            which monetary damages would not be an adequate remedy and the Company shall be entitled
            to equitable relief in addition to any remedies it may have hereunder or at law without
            a bond, other security or proof of damages
          </Subsection>
          <Subsection identifier="9.4" title="California Residents">
            If you are a California resident, in accordance with Cal. Civ. Code § 1789.3, you may
            report complaints to the Complaint Assistance Unit of the Division of Consumer Services
            of the California Department of Consumer Affairs by contacting them in writing at 1625
            North Market Blvd., Suite N 112 Sacramento, CA 95834, or by telephone at (800) 952-5210.
          </Subsection>
          <Subsection identifier="9.5" title="Miscellaneous">
            If any provision of these Terms shall be unlawful, void or for any reason unenforceable,
            then that provision shall be deemed severable from these Terms and shall not affect the
            validity and enforceability of any remaining provisions. These Terms and the licenses
            granted hereunder may be assigned by the Company but may not be assigned by you without
            the prior express written consent of the Company. No waiver by either party of any
            breach or default hereunder shall be deemed to be a waiver of any preceding or
            subsequent breach or default. The section headings used herein are for reference only
            and shall not be read to have any legal effect. The Services are operated by us in the
            United States. Those who choose to access the Services from locations outside the United
            States do so at their own initiative and are responsible for compliance with applicable
            local laws. These Terms are governed by the laws of the State of Illinois, without
            regard to conflict of laws rules, and the proper venue for any disputes arising out of
            or relating to any of the same will be the arbitration venue set forth in Section 8, or
            if arbitration does not apply, then the state and federal courts located in Chicago,
            Illinois, which shall have exclusive jurisdiction in such instance.
          </Subsection>
          <Subsection identifier="9.6" title="How to Contact Us">
            You may contact us regarding the Services or these Terms at: 222 West Merchandise Plaza
            #1212, Chicago, IL 60654, or by e-mail at legal@gologicsolutions.com.
          </Subsection>
        </Section>
      </TableOfContents>
    </PolicyOverlayPage>
  )
}
TermsAndConditions.defaultProps = {
  user: {},
  setShow: () => {},
}

const Text: AppFC = ({ children, className = '', ...props }) => (
  <p {...props} className={`md:text-xl ${className}`}>
    {children}
  </p>
)

export const PrivacyPolicyLink: AppFC = ({ children }) => (
  <Text
    id="privacy-policy-link"
    className="text-primary underline cursor-pointer ml-1 inline"
    onClick={() => history.push(`/privacy-policy`)}
  >
    {children}
  </Text>
)

/** This component can be expanded or extracted to it's own component if desired. */
export const JamsLink: FC<{}> = () => (
  <a className="text-blue" href={JAMS_URL} tabIndex={-1} data-testid={JAMS_LINK_TEST_ID}>
    {JAMS_URL}
  </a>
)

const TableOfContents: AppFC = ({ children }) => {
  return (
    <>
      <Heading className="text-xl md:text-2xl">TABLE OF CONTENTS</Heading>
      <ol className="list-decimal list-inside flex flex-col gap-6">{children}</ol>
    </>
  )
}

const Introduction = () => {
  return (
    <>
      <Text>
        Welcome to the Terms of Service (these “Terms”) for the {COMPANY_NAME} Platform,
        gotackle.app (the “Platform”), operated on behalf of {COMPANY_NAME}, Inc. (“ Company”, “we”
        or “us”), and together with any content, tools, features and functionality offered on or
        through our Platform (the “Services”).
      </Text>

      <Text>
        These Terms govern your access to and use of the Services. Please read these Terms
        carefully, as they include important information about your legal rights. By accessing
        and/or using the Services, you are agreeing to these Terms. If you do not understand or
        agree to these Terms, please do not use the Services.
      </Text>

      <Text>
        For purposes of these Terms, “you” and “your” means you as the user of the Services. If you
        use the Services on behalf of a company or other entity then “you” includes you and that
        entity, and you represent and warrant that (a) you are an authorized representative of the
        entity with the authority to bind the entity to these Terms, and (b) you agree to these
        Terms on the entity's behalf.
      </Text>

      <div className="bg-grey-lighter3x p-6">
        <Text>
          Please note that Section 8. contains an arbitration clause and class action waiver. By
          agreeing to these Terms, you agree (a) to resolve all disputes with us through binding
          individual arbitration, which means that you waive any right to have those disputes
          decided by a judge or jury, and (b) that you waive your right to participate in class
          actions, class arbitrations, or representative actions. You have the right to opt-out of
          arbitration as explained in Section 8.
        </Text>
      </div>
    </>
  )
}

interface ISectionProps {
  title: string
}
const Section: AppFC<ISectionProps> = ({ title, children = null }) => {
  return (
    <li className="flex flex-col gap-6">
      <strong className="tracking-wide md:text-lg">{`${title}`}</strong>
      <ol className="flex flex-col gap-6 md:text-xl">{children}</ol>
    </li>
  )
}

interface ISubsectionProps {
  identifier?: string
  title?: string
  bold?: boolean
}
const Subsection: AppFC<ISubsectionProps> = ({
  identifier = '',
  title = '',
  children = null,
  bold = false,
}) => {
  return (
    <li className={bold ? 'font-bold' : ''}>
      <div className="flex flex-column">
        <span>{identifier}</span>
        <div className="ml-2.5 md:ml-7">
          <Text className="underline inline">{title}.</Text> {children || null}
        </div>
      </div>
    </li>
  )
}

/** Creates a custom ordered list in which list items are introduced alphabetically with parentheses.
 * @example
 * ( a )  bla bla bla
 * ( b )  bla bla bla
 * ( c )  bla bla bla
 */
const AlphaOrderedList = styled.ol`
  counter-reset: list;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  li {
    display: flex;
    list-style: none;
    padding-right: 1.5rem;
    position: relative;
    left: 1.5rem;
  }
  li:before {
    counter-increment: list;
    content: '(' counter(list, lower-alpha) ') ';
    position: absolute;
    left: -1.5rem;
  }
  @media (min-width: 868px) {
    li {
      padding-right: 3rem;
      left: 3rem;
    }
    li:before {
      counter-increment: list;
      content: '( ' counter(list, lower-alpha) ' ) ';
      position: absolute;
      left: -3rem;
    }
  }
`
const SubsectionItems: AppFC = ({ children }) => {
  return <AlphaOrderedList>{children}</AlphaOrderedList>
}

const SubsectionItem: AppFC = ({ children }) => {
  return (
    <li>
      <Text>{children}</Text>
    </li>
  )
}
