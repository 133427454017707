import styled from 'styled-components'

export const TextLargeWrapper = styled.div`
  p {
    font-size: 18px;
  }
`

export const IpadViewPort = styled.div`
  @media (min-width: 821px) and (min-height: 1024px) {
    .hideInIpadView {
      display: none;
    }
  }

  @media (max-width: 1024px) and (max-height: 1365px) {
    .hideInIpadProView {
      display: none;
    }
    @media (max-width: 1366px) and (max-height: 1024px) {
      .hideInIpadProView {
        display: none;
      }
    }
  }
`

export const SelectFieldWrapper = styled.div`
  .select__indicator {
    display: none !important;
  }
  .select__indicator-separator {
    display: none !important;
  }
`
