import ModalBox from 'components/Common/ModalBox'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'

import Button from 'components/Common/Button'
import { UpdateMyProfileInput } from '../../../__generated__/api-types-and-hooks'

export interface ITimezoneModalProps {
  setShowTimezoneModal: (state: boolean) => void
  updateUserProfileAction: (payload: Partial<UpdateMyProfileInput>) => {
    type: 'UPDATE_USER_PROFILE_STARTED'
    payload: Partial<UpdateMyProfileInput>
  }
  defaultTimezone: string
}

const TimezoneModal = ({
  setShowTimezoneModal,
  updateUserProfileAction,
  defaultTimezone,
}: ITimezoneModalProps) => {
  const [currentTime, setCurrentTime] = useState(new Date())
  const timezone = moment.tz.guess()
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date())
    }, 10000)

    return () => {
      clearInterval(interval)
    }
  }, [])
  const formattedTime = timezone
    ? moment(currentTime).tz(timezone).format('hh:mm a')
    : moment().format('hh:mm a')

  const formattedTimeInDefaultTimezone = defaultTimezone
    ? moment(currentTime).tz(defaultTimezone).format('hh:mm a')
    : moment().format('hh:mm a')

  const handleSubmit = () => {
    updateUserProfileAction({
      timezone,
    })
    setShowTimezoneModal(false)
  }

  const handleRejection = () => {
    updateUserProfileAction({
      interimTimezone: timezone,
    })
    setShowTimezoneModal(false)
  }

  return (
    <ModalBox
      onClose={handleRejection}
      title={'Different Time Zone Detected'}
      classes="rounded-[10px]"
      height={'min-h-[32rem]'}
    >
      <div className="flex flex-col  h-full w-full">
        <div className="mb-12 sm:mb-12 ml-7">
          <p className="leading-7 text-lg lg:text-xl mt-5 mb-2">Default time zone:</p>
          <div className="mb-10">
            <span className="inline-block font-bold text-lg text-black-light font-futura mr-2">
              {defaultTimezone}
            </span>
            <span className="inline-block font-bold text-lg text-black-light font-futura">
              ({formattedTimeInDefaultTimezone})
            </span>
          </div>
          <p className="leading-7 text-lg lg:text-xl mt-5 mb-2">Current time zone:</p>
          <div className="mb-10">
            <span className="inline-block font-bold text-lg text-black-light font-futura mr-2">
              {timezone}
            </span>
            <span className="inline-block font-bold text-lg text-black-light font-futura">
              ({formattedTime})
            </span>
          </div>
          <p className="leading-7 text-lg lg:text-xl">
            Would you like to update your default time zone to match your current time zone?
          </p>
        </div>

        <div className="h-full flex flex-row justify-end items-end p-7 border-t border-primary-outline">
          <Button
            className="!px-3 bg-white smd:w-48 smd:text-xl"
            label={'No Thanks'}
            type="button"
            variant="secondary"
            onClick={handleRejection}
          />
          <Button
            className="!px-3 ml-[0.94rem] smd:w-64 smd:text-xl"
            label={'Update Time Zone'}
            type="button"
            variant="primary"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </ModalBox>
  )
}

export default TimezoneModal
