import React, { useEffect, useState, useMemo } from 'react'
import TableHeader from 'components/Common/TableHeader'
import TableBody from 'components/Common/TableBody'
import TablePagination from 'components/Common/TablePagination'
import { sortHandlerHelper } from 'utils/helper'
import { Columns } from 'types'
let emptyFunction = (obj) => {}
export interface IDataTableProps {
  columns: Columns
  rows: any[]
  /** todo: remove "pagination" prop. Instead, infer that pagination should be used if "pageSize" and/or "setPageSize" props are defined. */
  pagination: boolean
  setPageSize?: (value: any) => void
  getRecords?: (...args: any[]) => void
  pageSize: number
  ExpandedComponent: any
  /** The number of equally spaced grid columns used in conjuction with "display: grid" to style the table. */
  gridColumns?: number
  /** The name of the business concept / data type that populates each table row. (e.g. "user", "play", etc.) */
  tableType?: string
  hasMoreData?: boolean
  isApiDataPaginated?: boolean
  isLoading?: boolean
}
const DataTable = ({
  columns,
  rows,
  pagination,
  setPageSize = emptyFunction,
  getRecords = emptyFunction,
  pageSize,
  ExpandedComponent,
  gridColumns,
  tableType,
  hasMoreData = false,
  isApiDataPaginated,
  isLoading,
}: IDataTableProps) => {
  const size = useMemo(
    () => (rows?.length < pageSize && rows?.length > 0 ? rows?.length : pageSize),
    [pageSize, rows]
  )
  const [noOfItemsPerPage, setNoOfItemsPerPage] = useState(size)
  const [isInitialRender, setIsInitialRender] = useState(false)
  const [isMoveToNextButton, setIsMoveToNextButton] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const [totalItems, setTotalItems] = useState(0)
  const [startIndex, setStartIndex] = useState(0)
  const [endIndex, setEndIndex] = useState(0)
  const [tableData, setTableData] = useState<any[]>([])

  useEffect(() => {
    if (rows?.length > 0 && !isInitialRender) {
      setNoOfItemsPerPage(size)
      setIsInitialRender(true)
    }

    if (
      currentPage < totalPages &&
      rows.length !== totalItems &&
      isApiDataPaginated &&
      isMoveToNextButton
    ) {
      setCurrentPage(currentPage + 1)
    } else if (!isMoveToNextButton && rows.length !== totalItems && isInitialRender) {
      setNoOfItemsPerPage(noOfItemsPerPage + 1)
      setPageSize(noOfItemsPerPage + 1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows])

  useEffect(() => {
    getRecords({ filter: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (Array.isArray(rows)) {
      setTotalItems(rows.length)
      setTableData(rows)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      setCurrentPage(1)
    }
  }, [rows])

  const moveNextHandler = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1)
    } else {
      getRecords()
      setIsMoveToNextButton(true)
    }
  }
  const movePreviousHandler = () => {
    if (currentPage - 1 > 0) {
      setCurrentPage(currentPage - 1)
    }
  }
  const updateNoOfItemPerPageHandler = (value) => {
    if (isApiDataPaginated && hasMoreData && value > rows.length) {
      getRecords()
      setIsMoveToNextButton(false)
    }
    if (value > 0 && value <= rows.length) {
      setNoOfItemsPerPage(value)
      setPageSize(value)
    }
  }
  const sortHandler = ({ sortField, sortOrder, fieldType }) => {
    let sortedTableData = sortHandlerHelper({
      sortField,
      sortOrder,
      fieldType,
      data: tableData,
    })
    setTableData(sortedTableData)
  }
  const data = useMemo(() => {
    let computedData = tableData
    // Commenting it out as it is not getting used
    // if (startIndex +1 === rows.length) {
    //   let updatedCurrentPage = currentPage - 1
    //   if (currentPage > 1) {
    //     // startIndex & endIndex
    //     let start = (updatedCurrentPage - 1) * noOfItemsPerPage
    //     let end = (updatedCurrentPage - 1) * noOfItemsPerPage + noOfItemsPerPage
    //     setStartIndex(start)
    //     if (rows.length < end) {
    //       setEndIndex(rows.length)
    //     } else {
    //       setEndIndex(end)
    //     }
    //     // updateTotalPages
    //     let pages = Math.ceil(rows.length / noOfItemsPerPage)
    //     setCurrentPage(updatedCurrentPage)
    //     setTotalPages(pages)
    //     return computedData.slice(start, end)
    //   } else {
    //     // note: for one record
    //     setStartIndex(0)
    //     setEndIndex(1)
    //     setCurrentPage(1)
    //     setTotalPages(1)
    //     return computedData
    //   }
    // } else {
    // // startIndex & endIndex
    let start = (currentPage - 1) * noOfItemsPerPage
    let end = (currentPage - 1) * noOfItemsPerPage + noOfItemsPerPage
    setStartIndex(start)
    if (rows.length < end) {
      setEndIndex(rows.length)
    } else {
      setEndIndex(end)
    }
    // updateTotalPages
    let pages = Math.ceil(rows.length / noOfItemsPerPage)
    setTotalPages(pages)
    return computedData.slice(start, end)
    // }
    // eslint-disable-next-line
  }, [startIndex, tableData, rows.length, currentPage, noOfItemsPerPage])
  return (
    <div>
      <div className="overflow-hidden xs:w-full md:w-full lg:w-full overflow-x-auto">
        <table className="w-full">
          <TableHeader gridColumns={gridColumns} columns={columns} sortHandler={sortHandler} />
          <TableBody
            gridColumns={gridColumns}
            columns={columns}
            rows={data}
            ExpandedComponent={ExpandedComponent}
            isLoading={isLoading}
          />
        </table>
        {pagination && tableData.length > 0 && (
          <TablePagination
            noOfItemsPerPage={noOfItemsPerPage}
            currentPage={currentPage}
            totalPages={totalPages}
            totalItems={totalItems}
            startIndex={startIndex}
            endIndex={endIndex}
            hasMoreData={hasMoreData}
            moveNextHandler={moveNextHandler}
            movePreviousHandler={movePreviousHandler}
            updateNoOfItemPerPageHandler={updateNoOfItemPerPageHandler}
            type={tableType || columns[0].tableType || 'record'}
          />
        )}
      </div>
    </div>
  )
}
DataTable.defaultProps = {
  ExpandedComponent: React.Fragment,
  getRecords: () => {},
  isApiDataPaginated: false,
}
export default DataTable
