import { GET_BSO_PROFILE } from 'store/types'
import { BusinessSupportOrganization, Scalars } from '__generated__/api-types-and-hooks'

type Term = {
  term: string
  replacement: string
}

export interface BSOProfile extends BusinessSupportOrganization {
  logoUrl: Scalars['String']
  terminology: Array<Term>
}

interface BSOState {
  bsoProfile: BSOProfile
}

const initialState: BSOState = {
  bsoProfile: {
    id: '',
    adminId: '',
    name: '',
    logoUrl: '',
    terminology: [],
  },
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_BSO_PROFILE.FULLFILLED:
      return {
        ...state,
        bsoProfile: {
          ...action.payload,
          logoUrl: action.payload?.logoUrl || '',
          terminology: action.payload?.terminology || [],
        },
      }
    default:
  }
  return state as BSOState
}

export default reducer
