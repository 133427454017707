export enum IUSER_NOTIFICATION_STATUS {
  READ = 'read',
  UNREAD = 'unread',
}

export enum IUSER_NOTIFICATION_TYPES {
  CALENDAR_MEETING = 'Calendar Meeting',
  CALENDAR_MEETING_RESCHEDULED = 'Calendar Meeting Rescheduled',
  CALENDAR_MEETING_CANCELLED = 'Calendar Meeting Cancelled',
  CHAT = 'Chat',
  CLIENT_USER_PROFILE_UPDATE = 'Client User Profile Update',
  CLIENT_BUSINESS_PROFILE_UPDATE = 'Client Business Profile Update',
  NEW_CLIENT_ADDED = 'New Client Added',
  CLIENT_REMOVED = 'Client Removed',
  MONTHLY_ATTESTATION_TIME = 'Monthly Attestation Time',
}
