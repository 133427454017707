import React, { useCallback, useEffect, useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import type { Channel, UserResponse } from 'stream-chat'
import { ChannelPreviewUIComponentProps, ChatContextValue, useChatContext } from 'stream-chat-react'
import { useUnreadContext } from 'components/Chat/contexts/UnreadContext'
import { ArrowRightIcon, VerticalBarIcon, RedDotIcon } from 'components/Common/SvgIcons'
import type { StreamChatGenerics } from 'components/Chat/types'
import { getChannelName, getBusinessName } from 'components/Chat/helper'
import 'components/Chat/components/MessagingChannelPreview/MessagingChannelPreview.css'
import isEmpty from 'lodash/isEmpty'
import { isMobileOnly, isTablet } from 'mobile-device-detect'
import TackleAvatarLogo from 'components/Common/TackleAvatarLogo'

type Props = ChannelPreviewUIComponentProps & {
  channel: Channel
  searchChannel: string
  usersAssessmentResponseByQuestion?: any
  chatUnreadMessageCountAction: any
  setShowChannelPreview?: any
  showChannelCreate?: boolean
  setShowChannelCreate: Dispatch<SetStateAction<boolean>>
  setActiveChannel?: ChatContextValue['setActiveChannel']
}

type ExtendedResponse = UserResponse & {
  firstName?: string
  lastName?: string
}

type ExtendedUserResponse<T extends StreamChatGenerics> = UserResponse<T> & ExtendedResponse

const MessagingChannelPreview = (props: Props) => {
  const {
    channel,
    setActiveChannel,
    showChannelCreate,
    setShowChannelCreate,
    unread,
    searchChannel,
  } = props
  const { channel: activeChannel, client } = useChatContext<StreamChatGenerics>()
  const { channelMessageCount, setChatsUnreadCount, setChannelMessageCount } = useUnreadContext()
  const [members, setMembers]: any = useState([])

  const isActiveChannel = channel?.id === activeChannel?.id
  const [isChannelDisplay, setIsChannelDisplay] = useState(true)

  const channelMessageCounts = !isEmpty(channelMessageCount)
    ? channelMessageCount?.[channel.cid]
    : 0

  useEffect(() => {
    const findMembers = Object.values(channel.state.members).filter(
      ({ user }) => user?.id !== client.userID
    )
    if (findMembers) {
      setMembers(findMembers)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUnreadCounts = () => {
    if (unread) {
      setChatsUnreadCount((chatsUnreadCount) => chatsUnreadCount - unread)
    }
  }

  const removeUnreadMessage = () => {
    setChannelMessageCount((state) => ({
      ...state,
      [channel.cid]: 0,
    }))
  }

  const searchByUserId = (): boolean => {
    for (const userId in channel?.state?.members) {
      let searchQuery = searchChannel.toLowerCase()
      let name: string = ''

      const user: ExtendedUserResponse<StreamChatGenerics> | undefined =
        channel.state.members[userId]?.user
      if (user?.name) {
        name = user.name.toLowerCase()
        searchQuery = searchQuery.toLowerCase()

        if (name?.includes(searchQuery)) {
          return true
        }
      } else {
        let firstName = (user?.firstName || '').toLowerCase()
        let lastName = (user?.lastName || '').toLowerCase()
        name = firstName + lastName
        if (name?.includes(searchQuery)) {
          return true
        }
      }
    }
    return false
  }

  const searchByBusinessName = useCallback((): boolean => {
    const users = Object.values(channel?.state?.members).map((member) => member.user) || []
    const businessNames = users.map((user) => user?.businessName || '') as string[]

    for (const business of businessNames) {
      if (business.toLowerCase().includes(searchChannel.toLowerCase())) return true
    }
    return false
  }, [channel?.state?.members, searchChannel])

  useEffect(() => {
    if (channel) {
      const userIdFound = searchByUserId()
      const businessNameFound = searchByBusinessName()

      if (userIdFound || businessNameFound) {
        setIsChannelDisplay(true)
      } else {
        setIsChannelDisplay(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchChannel])

  return (
    <>
      {isChannelDisplay && (
        <div
          className={`${isActiveChannel && !showChannelCreate ? 'selected !bg-white' : ' '}
          border-t-[1px] border-secondary-text py-4 flex  cursor-pointer justify-between items-centerw-full  bg-cream-dark`}
          onClick={async () => {
            setShowChannelCreate(false)
            handleUnreadCounts()
            setActiveChannel?.(channel)
            removeUnreadMessage()

            await channel.markRead()
          }}
        >
          <div className="flex flex-row items-center">
            <span className="mdl:block lg:block xl:block hidden">
              <VerticalBarIcon className={`${isActiveChannel ? '' : 'hidden'}`} />
            </span>
            <span
              className={`${
                channel?.id === activeChannel?.id ? 'ml-[21px]' : 'xs:ml-[21px] mdl:ml-[27px]'
              } inline-flex `}
            >
              <span className={`${isActiveChannel ? 'ml-21' : 'ml-27'} flex items-center `}>
                <TackleAvatarLogo />

                {channelMessageCounts > 0 && (
                  <span className="ml-[-8px]">
                    <RedDotIcon />
                  </span>
                )}
                <div className="channel-preview__content-wrapper !ml-6 mr-4">
                  <div className="channel-preview__content-top">
                    {members && members.length > 0 && (
                      <p className="channel-preview__content-name">
                        {getChannelName(members.filter((user) => user?.user_id !== client.userID))}
                      </p>
                    )}
                  </div>
                  <div className="text-sm font-primary text-primary-text">
                    {getBusinessName(members)}
                  </div>
                </div>
              </span>
            </span>
          </div>
          {(isMobileOnly || isTablet) && (
            <div className="flex self-end mr-5 mb-2 mdl:hidden">
              <ArrowRightIcon className={'h-[25px] w-[12px] fill-silver'} />
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default MessagingChannelPreview
