import React, { useEffect, useState } from 'react'

import useSubIndustryContainer from 'hooks/useSubIndustryContainer'
import useIndustryContainer from 'hooks/useIndustryContainer/useIndustryContainer'
import useFormContainer from 'hooks/useFormContainer'
import { businessSchema } from 'pages/Owner/validation'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import hookForms from 'utils/hookForms'
import { FORM } from 'config'

import InputField from 'components/Common/InputField'
import SelectField from 'components/Common/SelectField'
import Loader from 'components/Loaders'
import useCheckHasSubIndustry from 'hooks/useCheckHasSubIndustry'
import { isQuestionHaveSubIndustry } from 'utils/helper'

const EditBusinessProfile = ({
  getBusinessAndAssessmentResponseAction,
  isBusinessProfileFormSet,
  setIsSubIndustrySelected,
  setHasSubIndustry,
  setIndustryChanged,
  setFormChanged,
  isIndustryChanged,
  checkErrors,
  assessment,
  onSubmit,
  userId,
}) => {
  const [assessmentList, setAssessmentList]: any = useState([])

  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(businessSchema),
  })

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    control,
    reset,
  } = methods
  useEffect(() => {
    getBusinessAndAssessmentResponseAction({
      setForms: [FORM.USER_MANAGEMENT_BUSINESS_FORM],
      userId,
      type: 'initial',
    })
  }, [getBusinessAndAssessmentResponseAction, userId])

  useEffect(() => {
    checkErrors(errors)
  }, [checkErrors, errors])

  useEffect(() => {
    setAssessmentList(assessment)
  }, [assessment])

  useFormContainer(FORM.USER_MANAGEMENT_BUSINESS_FORM, methods)

  useIndustryContainer({
    methods,
    assessment,
    setIndustryChanged,
    title: 'industry',
    titlePath: 'ownerDetails.title',
  })

  const {
    industryQuestion,
    foodIndustryQuestionOption,
    subIndustryQuestion,
    profileFormValues,
    servicesSubIndustryQuestion,
    serviceIndustryQuestionOption,
  } = useSubIndustryContainer({
    assessment,
    profileForm: hookForms.getForm(FORM.USER_MANAGEMENT_BUSINESS_FORM),
    reset,
    isIndustryChanged,
    setIsSubIndustrySelected,
    setAssessmentList,
  })
  const hasSubIndustry = useCheckHasSubIndustry(
    assessmentList,
    profileFormValues,
    subIndustryQuestion,
    foodIndustryQuestionOption,
    industryQuestion,
    servicesSubIndustryQuestion,
    serviceIndustryQuestionOption
  )
  useEffect(() => {
    const subIndustryCheck = isQuestionHaveSubIndustry(
      profileFormValues,
      foodIndustryQuestionOption,
      serviceIndustryQuestionOption,
      industryQuestion
    )
    if (subIndustryCheck) {
      setHasSubIndustry(true)
    } else {
      setHasSubIndustry(false)
    }
    // eslint-disable-next-line
  }, [
    profileFormValues,
    industryQuestion,
    foodIndustryQuestionOption,
    serviceIndustryQuestionOption,
  ])
  useEffect(() => {
    if (isDirty) {
      setFormChanged(true)
    } else {
      setFormChanged(false)
    }
    // eslint-disable-next-line
  }, [isDirty])
  return (
    <>
      {!isBusinessProfileFormSet ? (
        <Loader loader="EditBusinessProfile" />
      ) : (
        <div className="bg-cream-dark pb-6 pt-5 px-7">
          <label className="text-2xl font-primary text-primary-text inline-block pb-2">
            Business Profile
          </label>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="grid grid-cols-12 gap-3">
              <div className="col-span-12">
                <InputField
                  errors={errors.businessName}
                  type={'text'}
                  label={'Business Name'}
                  defaultValue={''}
                  {...register('businessName')}
                />
              </div>
              <div className="xs:col-span-12 sm:col-span-6 md:col-span-6 mdl:col-span-6 mt-3">
                <InputField
                  errors={errors.yearsInBusiness}
                  min={0}
                  type={'number'}
                  defaultValue={''}
                  label={'Years in Business'}
                  {...register('yearsInBusiness')}
                />
              </div>
              {assessmentList?.map((question, index) => {
                return (
                  hasSubIndustry[index] && (
                    <div
                      className="xs:col-span-12 sm:col-span-6 md:col-span-6 mdl:col-span-6 mt-3"
                      key={index}
                    >
                      <Controller
                        defaultValue={''}
                        name={`${question.id}`}
                        control={control}
                        render={({ field }) => (
                          <SelectField
                            placeholder="Select"
                            {...field}
                            applyStyle
                            isMulti={question?.meta?.allowMultiSelect}
                            label={question?.content?.businessProfile?.description}
                            options={question.options.map((opt) => ({
                              label: opt.value,
                              value: opt.id,
                            }))}
                            classes="font-primary text-silver-darker text-base font-normal"
                          />
                        )}
                      />
                    </div>
                  )
                )
              })}
            </div>
          </form>
        </div>
      )}
    </>
  )
}
export default EditBusinessProfile
