import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { actionOption, ADVISOR_STATUS, ROLES } from 'config'
import { isTablet } from 'mobile-device-detect'

import SelectField from 'components/Common/SelectField'
import Button from 'components/Common/Button'
import ModalBox from 'components/Common/ModalBox'
import ResetPasswordConfirmationModalContainer, {
  ConfirmationModalContainer,
} from 'pages/Common/container'
import { EditAccountContainer } from 'pages/Admin/container/UserManagement'
import { navigateTo } from 'utils/helper'
import { useSelector } from 'react-redux'
import { RootState } from 'App'
import { replaceTerm } from 'utils/helper'

const TableActions = ({
  email,
  userData,
  openConfirmationModal,
  resendUserInviteAction,
  openConfirmationModalAction,
}) => {
  let { isSelectedTab } = useParams()
  const bsoProfile = useSelector((state: RootState) => state.bso.bsoProfile)
  const replacedTerm = replaceTerm('advisor', bsoProfile.terminology)

  const [activatedStatus, setActivatedStatus] = useState('')
  const [actions, setActions] = useState('')
  const [openUserManagementModal, setOpenUserManagementModal] = useState(false)
  const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false)

  useEffect(() => {
    if (userData && userData?.activated) {
      setActivatedStatus(userData?.activated)
    }
  }, [userData])

  const title = useMemo(() => {
    return isSelectedTab === 'businesses' ? 'Edit Business Account' : `Edit ${replacedTerm} Account`
  }, [isSelectedTab, replacedTerm])

  return (
    <div className="flex flex-row-reverse items-center gap-3">
      <div>
        <Button
          label="Edit"
          variant="text"
          className="w-full"
          onClick={() => {
            setOpenUserManagementModal(true)
          }}
        />
      </div>
      {!isTablet && (
        <>
          {activatedStatus === ADVISOR_STATUS.INVITED && (
            <div>
              <Button
                label="Resend Invitation"
                variant="text"
                onClick={() =>
                  openConfirmationModalAction({
                    openConfirmationModal: true,
                    email: userData.email,
                  })
                }
              />
            </div>
          )}
          {activatedStatus === ADVISOR_STATUS.INACTIVE && (
            <div>
              {/* check-button -- clean up custom styles */}
              <Button label={'Send Invitation'} variant="secondary" />
            </div>
          )}

          {activatedStatus === ADVISOR_STATUS.ACTIVE && (
            <>
              <div>
                <Button
                  label="Chat"
                  variant="secondary"
                  onClick={() => {
                    navigateTo(ROLES.BSO_ADMIN, `chat/${userData.userId}`)
                  }}
                  className="w-full"
                />
              </div>
              <div>
                <Button
                  label="Reset Password"
                  variant="secondary"
                  onClick={() => setOpenResetPasswordModal(true)}
                  className="w-full"
                />
              </div>
            </>
          )}
        </>
      )}
      {isTablet && (
        <span>
          <SelectField
            classes={'min-w-[110px]'}
            name={'action'}
            placeholder="Actions"
            options={actionOption
              .filter((o) => o.action === activatedStatus)
              .map((opt) => {
                return { label: opt.label, value: opt.value }
              })}
            onChange={(value) => {
              setActions(value)
            }}
            value={actions}
          />
        </span>
      )}
      {openUserManagementModal && (
        <div className="pt-4">
          <ModalBox
            onClose={() => setOpenUserManagementModal(false)}
            title={title}
            classes="rounded-[10px]"
            height={'h-full'}
            margin="my-4"
          >
            <EditAccountContainer
              userId={userData.userId}
              isBusiness={isSelectedTab === 'businesses' ? true : false}
              setOpenUserManagementModal={setOpenUserManagementModal}
              openUserManagementModal={openUserManagementModal}
            />
          </ModalBox>
        </div>
      )}
      {openResetPasswordModal && (
        <ResetPasswordConfirmationModalContainer
          email={userData.email}
          onClose={() => setOpenResetPasswordModal(false)}
        />
      )}
      {openConfirmationModal && (
        <ConfirmationModalContainer
          title="Resend user invitation"
          description={`Resending the invitation will send an email to ${email}`}
          successDescription={`The invitation has been successfully resent to  ${email}`}
          onClose={() => openConfirmationModalAction({ openConfirmationModal: false })}
          onConfirm={() => resendUserInviteAction(email)}
        />
      )}
    </div>
  )
}

export default TableActions
