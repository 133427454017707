import { yupResolver } from '@hookform/resolvers/yup'
import { Divider } from 'components/Calendar/style'
import Button from 'components/Common/Button'
import CheckBoxField from 'components/Common/CheckBoxField'
import ErrorMessage from 'components/Common/ErrorMessage'
import { HeadingWrapper } from 'components/DataReports/ClientReportManagement/styles'
import { ParagraphWrapper } from 'components/Owner/PlayBooksCategoryDetails/TakePlaysToast/styles'
import { FORM } from 'config/enums'
import useFormContainer from 'hooks/useFormContainer'
import { isEmpty } from 'lodash'
import { subscribeToSmsSchema } from 'pages/Owner/validation'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import hookForms from 'utils/hookForms'
import { useTenantId } from 'utils/useTenantId'

const SmsSubscriptionNotification = ({ user, onSubmit, isLoading }) => {
  const tenantId = useTenantId()
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(subscribeToSmsSchema),
  })
  const {
    handleSubmit,
    control,
    clearErrors,
    formState: { errors },
  } = methods
  const form = hookForms?.getForm(FORM.SMS_ENABLED)
  const formValues = form?.getValues()
  const onSubscribe = () => {
    onSubmit({
      isSmsOptedIn: formValues?.isSmsOptedIn,
      tenantId,
    })
  }
  useFormContainer(FORM.SMS_ENABLED, methods)

  return (
    <div className="px-8 flex flex-col">
      <HeadingWrapper className="text-2xl !text-primary-text !pt-0 !leading-7 font-secondary">
        Stay connected with us and never miss out on the latest updates!
      </HeadingWrapper>
      <ParagraphWrapper className=" text-lg leading-6 !text-primary-text mt-3 font-semibold">
        Get valuable notifications sent straight to your phone, keeping you informed about essential
        due dates, meeting requests, chat messages, and product updates.
      </ParagraphWrapper>
      <ParagraphWrapper className="mt-8">
        <p className="!text-primary-text"> Mobile Phone Number</p>
        <p className="font-bold !text-primary-text">
          {user?.mobileContactNumber
            ? user?.mobileContactNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
            : 'XXX-XXX-XXXX'}
        </p>
      </ParagraphWrapper>
      <form onSubmit={handleSubmit(onSubscribe)}>
        <HeadingWrapper className="!pt-6 !gap-4">
          <Controller
            defaultValue={false}
            name="isSmsOptedIn"
            control={control}
            render={({ field }) => (
              <CheckBoxField
                {...field}
                id="isSmsOptedIn"
                className="!mr-0"
                label={'I agree to receive SMS'}
                onChange={(e) => {
                  field.onChange(e)
                  clearErrors('isSmsOptedIn')
                }}
              />
            )}
          />
        </HeadingWrapper>

        <ParagraphWrapper className="!text-primary-text text-xs pt-4 ">
          By subscribing, you agree to receive messages at the phone number provided. Reply STOP to
          cancel. Messaging rates may apply.
        </ParagraphWrapper>
        {!isEmpty(errors) && (
          <ErrorMessage id={'isSmsOptedIn'}>{errors.isSmsOptedIn?.message}</ErrorMessage>
        )}
        <Divider />
        <div className="!gap-10 !justify-end !px-0 !py-8 cursor-auto w-full flex">
          <Button
            onClick={() => {
              clearErrors('isSmsOptedIn')
              onSubmit({
                isSmsOptedIn: false,
                tenantId,
              })
            }}
            disabled={isLoading}
            variant="text"
            type="button"
            label="Don't Subscribe"
          ></Button>
          <Button
            type="submit"
            disabled={isLoading}
            variant="primary"
            label="Subscribe"
            className="!px-4 !py-3"
          ></Button>
        </div>
      </form>
    </div>
  )
}

export default SmsSubscriptionNotification
