import React, { useEffect, useState } from 'react'

import { USER_ROLES } from 'config'

import ToggleButton from 'components/Common/ToggleButton'

const AdminRightsToggle = ({ params, addUserToGroupAction }) => {
  const [enabled, setEnabled] = useState(false)

  const userPermissionHandler = () => {
    if (enabled) {
      addUserToGroupAction({ userId: params.userId, remove: true })
    } else {
      addUserToGroupAction({ userId: params.userId, remove: false })
    }
  }

  useEffect(() => {
    if (params && params.role) {
      let isAdmin = false
      params?.role?.some((role) => {
        if (role === USER_ROLES.BSO_ADMIN) {
          setEnabled(true)
          isAdmin = true
          return true
        }
        return false
      })

      if (!isAdmin) {
        setEnabled(false)
      }
    } else {
    }
  }, [params])

  return (
    <div className="col-span-2">
      <ToggleButton enabled={enabled} toggleButtonHandler={userPermissionHandler} />
    </div>
  )
}

export default AdminRightsToggle
