import React, { memo, useMemo } from 'react'

import { STATUS } from 'config/enums'
import { AppFC } from 'types'

import { ButtonWrapper } from 'components/Common/Button/style'
import CircleNotchIcon from 'components/Common/SvgIcons/CircleNotchIcon'
import ArrowRight from 'assets/svgs/Arrow.svg'
import PlusCircleIcon from 'components/Common/SvgIcons/PlusCircleIcon'
import SortIcon from 'components/Common/SvgIcons/SortIcon'
import FilterMenuIcon from 'components/Common/SvgIcons/FilterMenuIcon'
import CrossIcon from 'components/Common/SvgIcons/CrossIcon'
import TrashIcon from 'components/Common/SvgIcons/TrashIcon'

type ButtonIcon = 'arrow-right' | 'plus-circle' | 'sort' | 'filter-menu' | 'cross' | 'trash'

export interface IButtonProps {
  icon?: any
  type?: any
  label?: string
  status?: string
  loading?: boolean
  disabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLElement>) => void
  variant?: 'primary' | 'secondary' | 'text'
  fontSize?: 'xs' | 'sm' | 'base' | 'lg' | 'xl' | 'xxl' | '3xl'
  size?: 'small' | 'base' | 'large'
  /** A render function for any content placed right of the button label. Takes precedent over "rightIcon" */
  rightContent?: (...args: any[]) => JSX.Element
  rightIcon?: ButtonIcon
  /** A render function for any content placed left of the button label. Takes precedent over "leftIcon" */
  leftContent?: (...args: any[]) => JSX.Element
  leftIcon?: ButtonIcon
}

const getIcon = (iconName: ButtonIcon) => {
  switch (iconName) {
    case 'arrow-right': {
      return <img className="pl-2" src={ArrowRight} alt="icon" />
    }
    case 'cross': {
      return <CrossIcon className="cursor-pointer" />
    }
    case 'plus-circle': {
      return (
        <PlusCircleIcon className="h-7 w-7" iconClasses="bg-primary group-hover:bg-primary-brand" />
      )
    }
    case 'sort': {
      return <SortIcon className="group-hover:fill-primary-brand mx-1" />
    }
    case 'filter-menu': {
      return <FilterMenuIcon className="group-hover:fill-primary-brand" />
    }
    case 'trash': {
      return <TrashIcon className="bg-primary group-hover:fill-primary-brand" />
    }
    default: {
      return null
    }
  }
}
// CURRENT ISSUES:

/*
 * 1) There are 3 uses of the component "DeprecatedButton" that should be replaced by this component
 * 2) There are 8 uses of the component "InputTextButton" that should be replaced by this component with variant="text"
 * 3) Some classes that existed before this component was a styled component had to be removed because the twin.macro couldn't find them. They either need to be found + reintroduced or confirmed as invalid classes. See ButtonWrapper for details.
 * 4) Add support for any/all icons currently in use
 * 5) Remove support for "icon" prop when all icons are supported and buttons that use it are refactored
 * 6) Inspect all uses of "text" button to refactor lost default padding
 */

const Button: AppFC<IButtonProps> = ({
  label,
  type,
  icon,
  onClick,
  testId,
  status,
  disabled,
  loading,
  rightContent,
  rightIcon,
  leftContent,
  leftIcon,
  className,
  ...props
}) => {
  const LeftContent = useMemo(() => {
    if (leftContent) {
      return leftContent()
    } else if (leftIcon) {
      return getIcon(leftIcon)
    }
    return null
  }, [leftContent, leftIcon])

  const RightContent = useMemo(() => {
    if (rightContent) {
      return rightContent()
    } else if (rightIcon) {
      return getIcon(rightIcon)
    }
    return null
  }, [rightContent, rightIcon])

  return (
    <ButtonWrapper
      className={`group ${className}`}
      disabled={disabled || loading}
      data-testid={testId}
      name={label || ''}
      onClick={onClick}
      type={type}
      {...props}
    >
      {(status === STATUS.LOADING || loading) && (
        <CircleNotchIcon className={props.variant === 'primary' ? 'fill-white' : 'fill-primary'} />
      )}
      {/* {status === STATUS.COMPLETED && (
          <TickIconV2 className={variant === 'primary' ? 'fill-white' : 'fill-primary'} />
        )} */}

      {LeftContent}
      {label}
      {RightContent}
      {icon && <img className="pl-2" src={icon} alt="icon" />}
    </ButtonWrapper>
  )
}

Button.defaultProps = {
  size: 'base',
  className: '',
  variant: 'primary',
  disabled: false,
}

export default memo(Button)
