import { useQuery, useMutation, UseQueryOptions, UseMutationOptions } from 'react-query'
import { fetcher } from '../lib/api-client'
export type Maybe<T> = T | null | undefined
export type InputMaybe<T> = T | null | undefined
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  AWSDate: string
  AWSDateTime: string
  AWSEmail: string
  AWSIPAddress: string
  AWSJSON: string
  AWSPhone: string
  AWSTime: string
  AWSTimestamp: number
  AWSURL: string
}

export type ActivityLogInput = {
  accessToken?: InputMaybe<Scalars['String']>
  action: Scalars['String']
  logStatus?: InputMaybe<Scalars['String']>
  showClientActivity?: InputMaybe<Scalars['Boolean']>
  tenantId: Scalars['String']
  type?: InputMaybe<Scalars['String']>
}

export type AddUserNotificationInput = {
  meta?: InputMaybe<Scalars['AWSJSON']>
  notificationType: Scalars['String']
  senderId: Scalars['String']
  status?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
  userId: Scalars['String']
}

export type AddUserToGroupInput = {
  remove?: InputMaybe<Scalars['Boolean']>
  role: Scalars['String']
  userName: Scalars['String']
}

export type AdvisorProfile = {
  __typename?: 'AdvisorProfile'
  defaultVideoLinkDescription?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
}

export type Advisors = {
  __typename?: 'Advisors'
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  lastName?: Maybe<Scalars['String']>
  schedulingUrl?: Maybe<Scalars['String']>
  tenantId?: Maybe<Scalars['String']>
}

export type ApiResponse = {
  __typename?: 'ApiResponse'
  data?: Maybe<Scalars['AWSJSON']>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type Assessment = {
  __typename?: 'Assessment'
  assessmentType?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['AWSJSON']>
  createdAt?: Maybe<Scalars['AWSDateTime']>
  id: Scalars['ID']
  isVisible?: Maybe<Scalars['Boolean']>
  meta?: Maybe<Scalars['AWSJSON']>
  optionType?: Maybe<Scalars['String']>
  options?: Maybe<Scalars['AWSJSON']>
  prerequisites?: Maybe<Scalars['AWSJSON']>
  previous?: Maybe<Scalars['AWSJSON']>
  tenantId?: Maybe<Scalars['Int']>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
}

export type AssessmentResponse = {
  __typename?: 'AssessmentResponse'
  assessmentResponse?: Maybe<Scalars['AWSJSON']>
  completedAt?: Maybe<Scalars['AWSDateTime']>
  createdAt?: Maybe<Scalars['AWSDateTime']>
  currentStage?: Maybe<Scalars['ID']>
  id?: Maybe<Scalars['ID']>
  playStage?: Maybe<Scalars['AWSJSON']>
  totalAttemptedItems?: Maybe<Scalars['Int']>
  totalItems?: Maybe<Scalars['Int']>
  type?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
  userId?: Maybe<Scalars['ID']>
}

export type AttendeesType = {
  __typename?: 'AttendeesType'
  email?: Maybe<Scalars['String']>
  organizer?: Maybe<Scalars['Boolean']>
  responseStatus?: Maybe<Scalars['String']>
  self?: Maybe<Scalars['Boolean']>
}

export type Attestation = {
  __typename?: 'Attestation'
  businessBankAccount?: Maybe<Scalars['String']>
  businessName?: Maybe<Scalars['String']>
  contractors?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  debtFinancing?: Maybe<Scalars['String']>
  downloadUrl?: Maybe<Scalars['String']>
  education?: Maybe<Scalars['String']>
  error?: Maybe<Scalars['String']>
  financialGrowth?: Maybe<Scalars['String']>
  fullTimeEmployees?: Maybe<Scalars['String']>
  gender?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  jobsCreated?: Maybe<Scalars['String']>
  month?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  newCustomer?: Maybe<Scalars['String']>
  partTimeEmployees?: Maybe<Scalars['String']>
  physicalLocation?: Maybe<Scalars['String']>
  pk?: Maybe<Scalars['String']>
  productSale?: Maybe<Scalars['String']>
  race?: Maybe<Scalars['String']>
  registerBusinessStructure?: Maybe<Scalars['String']>
  revenue?: Maybe<Scalars['String']>
  serviceSale?: Maybe<Scalars['String']>
  signedCount?: Maybe<Scalars['String']>
  signedDocumentUrl?: Maybe<Scalars['String']>
  sk?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  targetAudience?: Maybe<Scalars['String']>
  tenantAdvisorStatus?: Maybe<Scalars['String']>
  tenantId?: Maybe<Scalars['String']>
  tenantStatus?: Maybe<Scalars['String']>
  unsignedCount?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  username?: Maybe<Scalars['String']>
  volunteers?: Maybe<Scalars['String']>
  wages?: Maybe<Scalars['String']>
  year?: Maybe<Scalars['String']>
}

export type AttestationInput = {
  additionalInfo?: InputMaybe<Scalars['String']>
  businessBankAccount?: InputMaybe<Scalars['String']>
  businessName?: InputMaybe<Scalars['String']>
  contractors?: InputMaybe<Scalars['String']>
  debtFinancing?: InputMaybe<Scalars['String']>
  education?: InputMaybe<Scalars['String']>
  financialGrowth?: InputMaybe<Scalars['String']>
  fullTimeEmployees?: InputMaybe<Scalars['String']>
  gender?: InputMaybe<Scalars['String']>
  generateDocuSignUrl?: InputMaybe<Scalars['Boolean']>
  jobsCreated?: InputMaybe<Scalars['String']>
  month?: InputMaybe<Scalars['String']>
  newCustomer?: InputMaybe<Scalars['String']>
  partTimeEmployees?: InputMaybe<Scalars['String']>
  physicalLocation?: InputMaybe<Scalars['String']>
  productSale?: InputMaybe<Scalars['String']>
  race?: InputMaybe<Scalars['String']>
  redirectUrl: Scalars['String']
  registerBusinessStructure?: InputMaybe<Scalars['String']>
  revenue?: InputMaybe<Scalars['String']>
  serviceSale?: InputMaybe<Scalars['String']>
  targetAudience?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
  volunteers?: InputMaybe<Scalars['String']>
  wages?: InputMaybe<Scalars['String']>
  year?: InputMaybe<Scalars['String']>
}

export type AttestationResponse = {
  __typename?: 'AttestationResponse'
  data?: Maybe<Attestation>
  error?: Maybe<Scalars['String']>
}

export enum AttestationStatus {
  Signed = 'SIGNED',
  Unsigned = 'UNSIGNED',
}

export type AttestationSummary = {
  __typename?: 'AttestationSummary'
  aggregationReportHistory?: Maybe<Array<Maybe<Attestation>>>
  newAggregatedReport?: Maybe<Attestation>
  signedCount?: Maybe<Scalars['Int']>
  submissionEndDate?: Maybe<Scalars['AWSDateTime']>
  submissionReminderDate?: Maybe<Scalars['AWSDateTime']>
  submissionReportDate?: Maybe<Scalars['AWSDateTime']>
  submissionStartDate?: Maybe<Scalars['AWSDateTime']>
  tenantId?: Maybe<Scalars['ID']>
  unsignedCount?: Maybe<Scalars['Int']>
}

export type AuthValue = {
  __typename?: 'AuthValue'
  AccessToken?: Maybe<Scalars['String']>
  Expires?: Maybe<Scalars['String']>
  RefreshToken?: Maybe<Scalars['String']>
}

export type AvailabilityHoursInput = {
  duration?: InputMaybe<Scalars['String']>
  timeSlots?: InputMaybe<Array<InputMaybe<TimeSlotsInput>>>
}

export type AvailabilityHoursObj = {
  __typename?: 'AvailabilityHoursObj'
  duration?: Maybe<Scalars['String']>
  timeSlots?: Maybe<Array<Maybe<TimeSlotsObj>>>
  timezone?: Maybe<Scalars['String']>
}

export type BoAdvisorsResponse = {
  __typename?: 'BOAdvisorsResponse'
  data?: Maybe<Array<Maybe<Advisors>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type BusinessProfile = {
  __typename?: 'BusinessProfile'
  additionalInfo?: Maybe<Scalars['String']>
  businessBankAccount?: Maybe<Scalars['Boolean']>
  businessName?: Maybe<Scalars['String']>
  businessVision?: Maybe<Scalars['String']>
  challenges?: Maybe<Scalars['String']>
  companyEmployees?: Maybe<Scalars['String']>
  contractors?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['AWSDateTime']>
  debtFinancing?: Maybe<Scalars['String']>
  education?: Maybe<Scalars['String']>
  financialGrowth?: Maybe<Scalars['String']>
  fullTimeEmployees?: Maybe<Scalars['String']>
  gender?: Maybe<Scalars['String']>
  id: Scalars['ID']
  jobsCreated?: Maybe<Scalars['String']>
  mobileContactNumber?: Maybe<Scalars['String']>
  month?: Maybe<Scalars['String']>
  newCustomer?: Maybe<Scalars['String']>
  otherGoals?: Maybe<Scalars['String']>
  partTimeEmployees?: Maybe<Scalars['String']>
  physicalLocation?: Maybe<Scalars['String']>
  productSale?: Maybe<Scalars['String']>
  race?: Maybe<Scalars['String']>
  registerBusinessStructure?: Maybe<Scalars['String']>
  revenue?: Maybe<Scalars['String']>
  serviceSale?: Maybe<Scalars['String']>
  targetAudience?: Maybe<Scalars['String']>
  tenantId?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
  userName?: Maybe<Scalars['String']>
  volunteers?: Maybe<Scalars['String']>
  wages?: Maybe<Scalars['String']>
  year?: Maybe<Scalars['String']>
  yearsInBusiness?: Maybe<Scalars['String']>
}

export type BusinessSupportOrganization = {
  __typename?: 'BusinessSupportOrganization'
  adminId: Scalars['String']
  id: Scalars['ID']
  logoUrl?: Maybe<Scalars['String']>
  name: Scalars['String']
  terminology?: Maybe<Array<Term>>
}

export type CalendarEvent = {
  __typename?: 'CalendarEvent'
  advisorId?: Maybe<Scalars['String']>
  advisorProfiles?: Maybe<Scalars['AWSJSON']>
  attendees?: Maybe<Array<Maybe<CalendarEventAttendee>>>
  availability?: Maybe<Scalars['String']>
  comment?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  end?: Maybe<CalendarEventDate>
  eventId?: Maybe<Scalars['String']>
  eventTime?: Maybe<Scalars['AWSDateTime']>
  eventType?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  inviteeId?: Maybe<Scalars['String']>
  location?: Maybe<Scalars['String']>
  meta?: Maybe<Scalars['AWSJSON']>
  profile?: Maybe<Scalars['AWSJSON']>
  start?: Maybe<CalendarEventDate>
  status?: Maybe<Scalars['String']>
  summary?: Maybe<Scalars['String']>
  timezone?: Maybe<Scalars['String']>
  videoLinkDescription?: Maybe<Scalars['String']>
}

export type CalendarEventAttendee = {
  __typename?: 'CalendarEventAttendee'
  email?: Maybe<Scalars['String']>
  optional?: Maybe<Scalars['Boolean']>
  responseStatus?: Maybe<Scalars['String']>
}

export type CalendarEventDate = {
  __typename?: 'CalendarEventDate'
  dateTime?: Maybe<Scalars['String']>
  timeZone?: Maybe<Scalars['String']>
}

export type CalendarEventResponse = {
  __typename?: 'CalendarEventResponse'
  data?: Maybe<Array<Maybe<CalendarEvent>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type CalendlyUser = {
  __typename?: 'CalendlyUser'
  calendlyEventsUrl?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  schedulingUrl?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

export type CalendlyUserResponse = {
  __typename?: 'CalendlyUserResponse'
  data?: Maybe<CalendlyUser>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type CancelUserCalendarEvent = {
  __typename?: 'CancelUserCalendarEvent'
  eventId?: Maybe<Scalars['String']>
  eventType?: Maybe<Scalars['String']>
  tackleId?: Maybe<Scalars['String']>
}

export type CancelUserCalendarEventInput = {
  eventId?: InputMaybe<Scalars['String']>
  eventType?: InputMaybe<Scalars['String']>
  tackleId?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
}

export type CancelUserCalendarEventResponse = {
  __typename?: 'CancelUserCalendarEventResponse'
  data?: Maybe<CancelUserCalendarEvent>
  error?: Maybe<Scalars['String']>
}

export type CognitoUser = {
  __typename?: 'CognitoUser'
  email: Scalars['String']
  enabled?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  userName: Scalars['String']
  userStatus?: Maybe<Scalars['String']>
}

export type CreateUserInput = {
  advisors?: InputMaybe<Scalars['AWSJSON']>
  email: Scalars['String']
  firstName: Scalars['String']
  lastName: Scalars['String']
  mobileContactNumber: Scalars['AWSPhone']
  owners?: InputMaybe<Scalars['AWSJSON']>
  password: Scalars['String']
  role: Scalars['String']
  suppressEmail?: InputMaybe<Scalars['Boolean']>
  tenantId?: InputMaybe<Scalars['String']>
}

export type CyclrConnector = {
  __typename?: 'CyclrConnector'
  authValue?: Maybe<AuthValue>
  authenticated?: Maybe<Scalars['Boolean']>
  connectorId?: Maybe<Scalars['Int']>
  id?: Maybe<Scalars['Int']>
  name?: Maybe<Scalars['String']>
}

export type CyclrConnectorResponse = {
  __typename?: 'CyclrConnectorResponse'
  data?: Maybe<Array<Maybe<CyclrConnector>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type DeauthenticateConnectorInput = {
  connectorName: Scalars['String']
  tenantId: Scalars['String']
}

export type DeleteCalendarEventInput = {
  meta?: InputMaybe<Scalars['AWSJSON']>
}

export type DeleteCalendarEventResponse = {
  __typename?: 'DeleteCalendarEventResponse'
  data?: Maybe<Scalars['AWSJSON']>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type DeleteUserNotificationsInput = {
  deleteAll?: InputMaybe<Scalars['Boolean']>
  id?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
}

export type GetAdvisorProfileInput = {
  advisorId: Scalars['String']
  tenantId?: InputMaybe<Scalars['String']>
}

export type GetAdvisorsInput = {
  advisorId?: InputMaybe<Scalars['String']>
  isPaginated?: InputMaybe<Scalars['Boolean']>
  lastEvaluatedKey?: InputMaybe<Scalars['String']>
  pageSize?: InputMaybe<Scalars['String']>
  searchQuery?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
}

export type GetAllToolsResponse = {
  __typename?: 'GetAllToolsResponse'
  data?: Maybe<Array<Maybe<Tool>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

export type GetAttestationReportsInput = {
  filter: AttestationStatus
  lastEvaluatedKey?: InputMaybe<Scalars['String']>
  month?: InputMaybe<Scalars['String']>
  pageSize: Scalars['Int']
  sort?: InputMaybe<GetAttestationReportsSort>
  tenantId: Scalars['String']
  year?: InputMaybe<Scalars['String']>
}

export type GetAttestationReportsResponse = {
  __typename?: 'GetAttestationReportsResponse'
  data?: Maybe<GetAttestationReportsResponseObj>
  error?: Maybe<Scalars['String']>
}

export type GetAttestationReportsResponseObj = {
  __typename?: 'GetAttestationReportsResponseObj'
  items?: Maybe<Array<Maybe<Attestation>>>
  lastEvaluatedKey?: Maybe<Scalars['String']>
}

export type GetAttestationReportsSort = {
  name: Scalars['Int']
}

export type GetAttestationSummaryInput = {
  advisorId: Scalars['ID']
  includeAggregationDetails?: InputMaybe<Scalars['Boolean']>
  tenantId: Scalars['ID']
  timePeriod: TimePeriod
}

export type GetBusinessSupportOrganizationInput = {
  tenantId: Scalars['String']
}

export type GetBusinessesInput = {
  advisor?: InputMaybe<Scalars['String']>
  allStatuses: Scalars['Boolean']
  isPaginated?: InputMaybe<Scalars['Boolean']>
  lastEvaluatedKey?: InputMaybe<Scalars['String']>
  pageSize?: InputMaybe<Scalars['String']>
  searchQuery?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
}

export type GetChartDataInput = {
  businessId?: InputMaybe<Scalars['String']>
  chartName: Scalars['String']
}

export type GetClientsInput = {
  advisorId?: InputMaybe<Scalars['String']>
  includeInvitedClients?: InputMaybe<Scalars['Boolean']>
  tenantId?: InputMaybe<Scalars['String']>
}

export type GetClientsResponse = {
  __typename?: 'GetClientsResponse'
  data?: Maybe<Array<Maybe<UserProfile>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type GetMeetingTypeResponse = {
  __typename?: 'GetMeetingTypeResponse'
  data?: Maybe<Array<Maybe<MeetingTypes>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

export type GetPlaysInput = {
  lastEvaluatedKey?: InputMaybe<Scalars['AWSJSON']>
  pageSize?: InputMaybe<Scalars['Int']>
}

export type GetPlaysResponse = {
  __typename?: 'GetPlaysResponse'
  data?: Maybe<Array<Maybe<Play>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type GetTackleMeetingResponse = {
  __typename?: 'GetTackleMeetingResponse'
  data?: Maybe<Array<Maybe<CalendarEvent>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  pagination?: Maybe<Scalars['AWSJSON']>
  success: Scalars['Boolean']
}

export type GetTackleMeetingsInput = {
  filter?: InputMaybe<Scalars['String']>
  isPaginated?: InputMaybe<Scalars['Boolean']>
  lastEvaluatedKey?: InputMaybe<Scalars['AWSJSON']>
  ownerId: Scalars['String']
  pageSize?: InputMaybe<Scalars['Int']>
  tenantId: Scalars['String']
}

export type GetTenantsResponse = {
  __typename?: 'GetTenantsResponse'
  data?: Maybe<Array<Maybe<Tenant>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type GetToolsByTypeInput = {
  tenantId: Scalars['String']
  toolType: ToolType
}

export type GetToolsByTypeResponse = {
  __typename?: 'GetToolsByTypeResponse'
  data?: Maybe<Array<Maybe<Tool>>>
  error?: Maybe<Scalars['String']>
}

export type GetToolsResponse = {
  __typename?: 'GetToolsResponse'
  data?: Maybe<Array<Maybe<Tool>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type GetTopActiveClients = {
  __typename?: 'GetTopActiveClients'
  businessName?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  industry?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  score?: Maybe<Scalars['Float']>
  tenantId?: Maybe<Scalars['String']>
  topGoal?: Maybe<Scalars['String']>
  totalTime?: Maybe<Scalars['Float']>
}

export type GetTopActiveClientsResponse = {
  __typename?: 'GetTopActiveClientsResponse'
  data?: Maybe<Array<Maybe<GetTopActiveClients>>>
  error?: Maybe<Scalars['String']>
}

export type GetUserAvailability = {
  __typename?: 'GetUserAvailability'
  availability?: Maybe<Array<Maybe<UserAvailability>>>
  duration?: Maybe<Scalars['String']>
}

export type GetUserAvailabilityHoursResponse = {
  __typename?: 'GetUserAvailabilityHoursResponse'
  data?: Maybe<SetUserAvailabilityObj>
  error?: Maybe<Scalars['String']>
}

export type GetUserAvailabilityInput = {
  endTime?: InputMaybe<Scalars['String']>
  participantId?: InputMaybe<Scalars['String']>
  role?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  startTime?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
  timeZone?: InputMaybe<Scalars['String']>
}

export type GetUserAvailabilityResponse = {
  __typename?: 'GetUserAvailabilityResponse'
  data?: Maybe<GetUserAvailability>
  error?: Maybe<Scalars['String']>
}

export type GetUserMeetingByIdInput = {
  id: Scalars['String']
  tenantId: Scalars['String']
}

export type GetUserMeetingByIdResponse = {
  __typename?: 'GetUserMeetingByIdResponse'
  data?: Maybe<CalendarEvent>
  error?: Maybe<Scalars['String']>
}

export type GetUserNotificationsInput = {
  countOnly?: InputMaybe<Scalars['Boolean']>
  filter?: InputMaybe<Scalars['String']>
  isPaginated?: InputMaybe<Scalars['Boolean']>
  lastEvaluatedKey?: InputMaybe<Scalars['String']>
  pageSize?: InputMaybe<Scalars['Int']>
  tenantId: Scalars['String']
}

export type GetUserNotificationsResponse = {
  __typename?: 'GetUserNotificationsResponse'
  data?: Maybe<UserNotificationData>
  error?: Maybe<Scalars['String']>
}

export type GetUserToolsResponse = {
  __typename?: 'GetUserToolsResponse'
  data?: Maybe<Array<Maybe<Tool>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success?: Maybe<Scalars['Boolean']>
}

export type Goal = {
  __typename?: 'Goal'
  assessments?: Maybe<Scalars['AWSJSON']>
  createdAt?: Maybe<Scalars['AWSDateTime']>
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  isActiveGoal?: Maybe<Scalars['Boolean']>
  isTopGoal?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  orderOfSequence?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
}

export type GoogleCalendarWebhookMetaData = {
  __typename?: 'GoogleCalendarWebhookMetaData'
  expiration?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  kind?: Maybe<Scalars['String']>
  resourceId?: Maybe<Scalars['String']>
  resourceUri?: Maybe<Scalars['String']>
  token?: Maybe<Scalars['String']>
}

export type ListAssessment = {
  __typename?: 'ListAssessment'
  completedAt?: Maybe<Scalars['AWSDateTime']>
  isCompleted?: Maybe<Scalars['Boolean']>
  isLocked?: Maybe<Scalars['Boolean']>
  order?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
}

export type ListAssessmentResponse = {
  __typename?: 'ListAssessmentResponse'
  data?: Maybe<Array<Maybe<ListAssessment>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type ListCognitoUsersInput = {
  role: Scalars['String']
}

export type MeetingTypes = {
  __typename?: 'MeetingTypes'
  duration?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  label?: Maybe<Scalars['String']>
  tenantId?: Maybe<Scalars['String']>
}

export type Mutation = {
  __typename?: 'Mutation'
  addUserNotification?: Maybe<ApiResponse>
  addUserToGroup: Scalars['Boolean']
  authorizeConnector?: Maybe<ApiResponse>
  cancelUserCalendarEvent?: Maybe<CancelUserCalendarEventResponse>
  createUser?: Maybe<UserProfile>
  deauthenticateConnector?: Maybe<ApiResponse>
  deleteCalendarEvent?: Maybe<DeleteCalendarEventResponse>
  deleteUserNotifications?: Maybe<ApiResponse>
  listCognitoUsers: Array<Maybe<CognitoUser>>
  logActivity?: Maybe<ApiResponse>
  resendUserInvite?: Maybe<Scalars['Boolean']>
  saveAssessmentResponse?: Maybe<AssessmentResponse>
  saveAttestation?: Maybe<AttestationResponse>
  saveUserFile?: Maybe<ApiResponse>
  setUserAvailability?: Maybe<SetUserAvailabilityResponse>
  setUserMeeting?: Maybe<SetUserMeetingResponse>
  subscribeGoogleCalendarWebhook?: Maybe<SubscribeGoogleCalendarWebhookResponse>
  toolConnectionSuccess?: Maybe<ApiResponse>
  updateBusinessProfile?: Maybe<BusinessProfile>
  updateMyProfile?: Maybe<UserProfile>
  updateOwner?: Maybe<ApiResponse>
  updateUserAvailability?: Maybe<UpdateUserAvailabilityResponse>
  updateUserCalendarEvent?: Maybe<UpdateUserCalendarEventResponse>
  updateUserNotifications?: Maybe<ApiResponse>
  updateUserPassword?: Maybe<Scalars['Boolean']>
}

export type MutationAddUserNotificationArgs = {
  input?: InputMaybe<AddUserNotificationInput>
}

export type MutationAddUserToGroupArgs = {
  input: AddUserToGroupInput
}

export type MutationAuthorizeConnectorArgs = {
  authValue: Scalars['String']
}

export type MutationCancelUserCalendarEventArgs = {
  input: CancelUserCalendarEventInput
}

export type MutationCreateUserArgs = {
  input: CreateUserInput
}

export type MutationDeauthenticateConnectorArgs = {
  input?: InputMaybe<DeauthenticateConnectorInput>
}

export type MutationDeleteCalendarEventArgs = {
  input: DeleteCalendarEventInput
}

export type MutationDeleteUserNotificationsArgs = {
  input?: InputMaybe<DeleteUserNotificationsInput>
}

export type MutationListCognitoUsersArgs = {
  input: ListCognitoUsersInput
}

export type MutationLogActivityArgs = {
  activityLogInput: ActivityLogInput
}

export type MutationResendUserInviteArgs = {
  input: ResendUserInviteInput
}

export type MutationSaveAssessmentResponseArgs = {
  input: SaveAssessmentResponseInput
}

export type MutationSaveAttestationArgs = {
  input: AttestationInput
}

export type MutationSaveUserFileArgs = {
  input: Array<SaveUserFileInput>
}

export type MutationSetUserAvailabilityArgs = {
  input?: InputMaybe<SetUserAvailabilityInput>
}

export type MutationSetUserMeetingArgs = {
  input: SetUserMeetingInput
}

export type MutationSubscribeGoogleCalendarWebhookArgs = {
  tenantId?: InputMaybe<Scalars['String']>
}

export type MutationToolConnectionSuccessArgs = {
  input?: InputMaybe<ToolConnectionSuccessInput>
}

export type MutationUpdateBusinessProfileArgs = {
  input: UpdateBusinessProfileInput
}

export type MutationUpdateMyProfileArgs = {
  input: UpdateMyProfileInput
}

export type MutationUpdateOwnerArgs = {
  input?: InputMaybe<UpdateOwnerInput>
}

export type MutationUpdateUserAvailabilityArgs = {
  input?: InputMaybe<UpdateUserAvailabilityInput>
}

export type MutationUpdateUserCalendarEventArgs = {
  input?: InputMaybe<UpdateUserCalendarEventInput>
}

export type MutationUpdateUserNotificationsArgs = {
  input?: InputMaybe<UpdateUserNotificationsInput>
}

export type MutationUpdateUserPasswordArgs = {
  input: UpdateUserPasswordInput
}

export type OAuthUrlResponse = {
  __typename?: 'OAuthUrlResponse'
  data?: Maybe<Scalars['String']>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type Play = {
  __typename?: 'Play'
  content?: Maybe<Scalars['AWSJSON']>
  description?: Maybe<Scalars['String']>
  guide?: Maybe<Scalars['AWSJSON']>
  guide1?: Maybe<Scalars['AWSJSON']>
  id: Scalars['ID']
  meta?: Maybe<Scalars['AWSJSON']>
  playTabs?: Maybe<Scalars['AWSJSON']>
  recommendedBy?: Maybe<Scalars['String']>
  setup?: Maybe<Scalars['AWSJSON']>
  title?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
  use?: Maybe<Scalars['AWSJSON']>
}

export type Query = {
  __typename?: 'Query'
  cancelOutlookEvent?: Maybe<ApiResponse>
  deleteGoogleCalendarEvent?: Maybe<ApiResponse>
  getActivityLogs?: Maybe<ApiResponse>
  getAdvisorDashboard?: Maybe<ApiResponse>
  getAdvisorProfile?: Maybe<AdvisorProfile>
  getAdvisors?: Maybe<ApiResponse>
  getAllTools?: Maybe<GetAllToolsResponse>
  getAssessmentPlayListing?: Maybe<ApiResponse>
  getAssessmentResponseByQuestion?: Maybe<UserResponse>
  getAssessments?: Maybe<Array<Maybe<Assessment>>>
  getAttestationReports?: Maybe<GetAttestationReportsResponse>
  getAttestationSummary?: Maybe<AttestationSummary>
  getBigQuery?: Maybe<ApiResponse>
  getBoAdvisors?: Maybe<BoAdvisorsResponse>
  getBusinessProfile?: Maybe<BusinessProfile>
  getBusinessSupportOrganization?: Maybe<BusinessSupportOrganization>
  getBusinesses?: Maybe<ApiResponse>
  getCalendlyUser?: Maybe<CalendlyUserResponse>
  getChartData?: Maybe<ApiResponse>
  getClients?: Maybe<GetClientsResponse>
  getClientsSummary?: Maybe<ApiResponse>
  getDashboardListing?: Maybe<ApiResponse>
  getFile?: Maybe<ApiResponse>
  getGoalDetails?: Maybe<Goal>
  getMeetingTypes?: Maybe<GetMeetingTypeResponse>
  getMyProfile: UserProfile
  getOAuthUrl?: Maybe<OAuthUrlResponse>
  getOwnerActivityDetails?: Maybe<ApiResponse>
  getOwnerBusinessData?: Maybe<ApiResponse>
  getPlay?: Maybe<ApiResponse>
  getPlays?: Maybe<GetPlaysResponse>
  getSignedUrl?: Maybe<SignedUrlResponse>
  getTackleMeetings?: Maybe<GetTackleMeetingResponse>
  getTenants?: Maybe<GetTenantsResponse>
  getTools?: Maybe<GetToolsResponse>
  getToolsByType?: Maybe<GetToolsByTypeResponse>
  getTopActiveClients?: Maybe<GetTopActiveClientsResponse>
  getUserAssessmentResponse?: Maybe<UserAssessmentResponse>
  getUserAvailability?: Maybe<GetUserAvailabilityResponse>
  getUserAvailabilityHours?: Maybe<GetUserAvailabilityHoursResponse>
  getUserCalendarEvents?: Maybe<CalendarEventResponse>
  getUserConnectors?: Maybe<CyclrConnectorResponse>
  getUserFiles?: Maybe<ApiResponse>
  getUserGoals?: Maybe<Array<Maybe<Goal>>>
  getUserIntegrations?: Maybe<ApiResponse>
  getUserMeeting?: Maybe<ApiResponse>
  getUserMeetingById?: Maybe<GetUserMeetingByIdResponse>
  getUserNotifications?: Maybe<GetUserNotificationsResponse>
  getUserRecommendations?: Maybe<UserRecommendationsResponse>
  getUserTools?: Maybe<GetUserToolsResponse>
  listAssessments?: Maybe<ListAssessmentResponse>
  subscribeCalendlyWebhook?: Maybe<ApiResponse>
  updateGoogleCalendarEvent?: Maybe<ApiResponse>
}

export type QueryCancelOutlookEventArgs = {
  input?: InputMaybe<CancelOutlookEventInput>
}

export type QueryDeleteGoogleCalendarEventArgs = {
  input?: InputMaybe<DeleteGoogleCalendarEventInput>
}

export type QueryGetActivityLogsArgs = {
  tenantId: Scalars['String']
  userId?: InputMaybe<Scalars['String']>
}

export type QueryGetAdvisorDashboardArgs = {
  filterType?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}

export type QueryGetAdvisorProfileArgs = {
  input: GetAdvisorProfileInput
}

export type QueryGetAdvisorsArgs = {
  getAdvisorsInput?: InputMaybe<GetAdvisorsInput>
}

export type QueryGetAllToolsArgs = {
  includeDetails?: InputMaybe<Scalars['Boolean']>
  tenantId?: InputMaybe<Scalars['String']>
}

export type QueryGetAssessmentPlayListingArgs = {
  tenantId: Scalars['String']
}

export type QueryGetAssessmentResponseByQuestionArgs = {
  question?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}

export type QueryGetAssessmentsArgs = {
  type?: InputMaybe<Scalars['String']>
}

export type QueryGetAttestationReportsArgs = {
  input: GetAttestationReportsInput
}

export type QueryGetAttestationSummaryArgs = {
  input?: InputMaybe<GetAttestationSummaryInput>
}

export type QueryGetBigQueryArgs = {
  query?: InputMaybe<Scalars['String']>
}

export type QueryGetBoAdvisorsArgs = {
  tenantId?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}

export type QueryGetBusinessProfileArgs = {
  id?: InputMaybe<Scalars['String']>
}

export type QueryGetBusinessSupportOrganizationArgs = {
  input: GetBusinessSupportOrganizationInput
}

export type QueryGetBusinessesArgs = {
  getBusinessesInput: GetBusinessesInput
}

export type QueryGetChartDataArgs = {
  input: GetChartDataInput
}

export type QueryGetClientsArgs = {
  input?: InputMaybe<GetClientsInput>
}

export type QueryGetClientsSummaryArgs = {
  tenantId?: InputMaybe<Scalars['String']>
}

export type QueryGetDashboardListingArgs = {
  category?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
}

export type QueryGetFileArgs = {
  id?: InputMaybe<Scalars['String']>
}

export type QueryGetGoalDetailsArgs = {
  id?: InputMaybe<Scalars['String']>
}

export type QueryGetMeetingTypesArgs = {
  tenantId: Scalars['String']
}

export type QueryGetMyProfileArgs = {
  tenantId?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}

export type QueryGetOAuthUrlArgs = {
  connectorName?: InputMaybe<Scalars['String']>
}

export type QueryGetOwnerActivityDetailsArgs = {
  ownerId?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
}

export type QueryGetOwnerBusinessDataArgs = {
  input?: InputMaybe<GetOwnerBusinessDataInput>
}

export type QueryGetPlayArgs = {
  id?: InputMaybe<Scalars['String']>
}

export type QueryGetPlaysArgs = {
  input?: InputMaybe<GetPlaysInput>
}

export type QueryGetSignedUrlArgs = {
  contentType?: InputMaybe<Scalars['String']>
  fileName?: InputMaybe<Scalars['String']>
}

export type QueryGetTackleMeetingsArgs = {
  input?: InputMaybe<GetTackleMeetingsInput>
}

export type QueryGetToolsByTypeArgs = {
  input: GetToolsByTypeInput
}

export type QueryGetTopActiveClientsArgs = {
  tenantId?: InputMaybe<Scalars['String']>
}

export type QueryGetUserAssessmentResponseArgs = {
  type?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}

export type QueryGetUserAvailabilityArgs = {
  input?: InputMaybe<GetUserAvailabilityInput>
}

export type QueryGetUserCalendarEventsArgs = {
  calendarName?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
  timeMax?: InputMaybe<Scalars['String']>
  timeMin?: InputMaybe<Scalars['String']>
}

export type QueryGetUserConnectorsArgs = {
  connectorName?: InputMaybe<Scalars['String']>
}

export type QueryGetUserFilesArgs = {
  tenantId: Scalars['String']
  userId?: InputMaybe<Scalars['String']>
}

export type QueryGetUserGoalsArgs = {
  tenantId?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}

export type QueryGetUserIntegrationsArgs = {
  tenantId: Scalars['String']
  userId?: InputMaybe<Scalars['String']>
}

export type QueryGetUserMeetingArgs = {
  advisorId?: InputMaybe<Scalars['String']>
}

export type QueryGetUserMeetingByIdArgs = {
  input: GetUserMeetingByIdInput
}

export type QueryGetUserNotificationsArgs = {
  input?: InputMaybe<GetUserNotificationsInput>
}

export type QueryGetUserToolsArgs = {
  tenantId: Scalars['String']
  userId?: InputMaybe<Scalars['String']>
}

export type QueryListAssessmentsArgs = {
  tenantId: Scalars['String']
  userId?: InputMaybe<Scalars['String']>
}

export type QuerySubscribeCalendlyWebhookArgs = {
  email?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
}

export type QueryUpdateGoogleCalendarEventArgs = {
  input?: InputMaybe<UpdateGoogleCalendarEventInput>
}

export type ResendUserInviteInput = {
  email: Scalars['String']
}

export type SaveAssessmentResponseInput = {
  assessmentResponse?: InputMaybe<Scalars['AWSJSON']>
  category?: InputMaybe<Scalars['String']>
  createdAt?: InputMaybe<Scalars['AWSDateTime']>
  currentStage?: InputMaybe<Scalars['ID']>
  isCompleted?: InputMaybe<Scalars['Boolean']>
  playStage?: InputMaybe<Scalars['AWSJSON']>
  tenantId: Scalars['String']
  type: Scalars['String']
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>
  userId: Scalars['ID']
}

export type SaveUserFileInput = {
  id: Scalars['String']
  name?: InputMaybe<Scalars['String']>
  size?: InputMaybe<Scalars['String']>
  streamMessageId?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
  type?: InputMaybe<Scalars['String']>
  url?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}

export type SetUserAvailabilityInput = {
  availabilityHours?: InputMaybe<AvailabilityHoursInput>
  tenantId?: InputMaybe<Scalars['String']>
}

export type SetUserAvailabilityObj = {
  __typename?: 'SetUserAvailabilityObj'
  availabilityHours?: Maybe<AvailabilityHoursObj>
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  tenantId?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

export type SetUserAvailabilityResponse = {
  __typename?: 'SetUserAvailabilityResponse'
  data?: Maybe<SetUserAvailabilityObj>
  error?: Maybe<Scalars['String']>
}

export type SetUserMeetingInput = {
  attendees?: InputMaybe<Array<Scalars['String']>>
  description?: InputMaybe<Scalars['String']>
  endTime: Scalars['String']
  location?: InputMaybe<Scalars['String']>
  participantId: Scalars['String']
  role: Array<Scalars['String']>
  startTime: Scalars['String']
  tenantId: Scalars['String']
  timezone: Scalars['String']
  title: Scalars['String']
  videoLinkDescription?: InputMaybe<Scalars['String']>
}

export type SetUserMeetingResponse = {
  __typename?: 'SetUserMeetingResponse'
  data?: Maybe<UserMeetingObj>
  error?: Maybe<Scalars['String']>
}

export type SignedUrlResponse = {
  __typename?: 'SignedUrlResponse'
  data?: Maybe<Scalars['AWSJSON']>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type SlotInput = {
  endTime?: InputMaybe<Scalars['String']>
  startTime?: InputMaybe<Scalars['String']>
}

export type SlotObj = {
  __typename?: 'SlotObj'
  isAvailable?: Maybe<Scalars['Boolean']>
  slotEndTime?: Maybe<Scalars['String']>
  slotStartTime?: Maybe<Scalars['String']>
}

export type SlotOutput = {
  __typename?: 'SlotOutput'
  endTime?: Maybe<Scalars['String']>
  startTime?: Maybe<Scalars['String']>
}

export type SubscribeGoogleCalendarWebhookResponse = {
  __typename?: 'SubscribeGoogleCalendarWebhookResponse'
  data?: Maybe<Scalars['String']>
  error?: Maybe<Scalars['String']>
}

export type SystemSetting = {
  __typename?: 'SystemSetting'
  category?: Maybe<Scalars['String']>
  key?: Maybe<Scalars['String']>
  value?: Maybe<Scalars['String']>
}

export type Tenant = {
  __typename?: 'Tenant'
  adminId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['AWSDateTime']>
  id?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
}

export type Term = {
  __typename?: 'Term'
  replacement: Scalars['String']
  term: Scalars['String']
}

export type TimePeriod = {
  day?: InputMaybe<Scalars['Int']>
  month: Scalars['Int']
  year: Scalars['Int']
}

export type TimeSlotsInput = {
  label?: InputMaybe<Scalars['String']>
  slots?: InputMaybe<Array<InputMaybe<SlotInput>>>
  value?: InputMaybe<Scalars['String']>
}

export type TimeSlotsObj = {
  __typename?: 'TimeSlotsObj'
  label?: Maybe<Scalars['String']>
  slots?: Maybe<Array<Maybe<SlotOutput>>>
  value?: Maybe<Scalars['String']>
}

export type Tool = {
  __typename?: 'Tool'
  category?: Maybe<Scalars['String']>
  cyclrToolId?: Maybe<Scalars['String']>
  group?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['ID']>
  imageUrl?: Maybe<Scalars['String']>
  isConnected?: Maybe<Scalars['Boolean']>
  isRecommended?: Maybe<Scalars['Boolean']>
  isSelected?: Maybe<Scalars['Boolean']>
  recommendedBy?: Maybe<Scalars['String']>
  toolDisplayName?: Maybe<Scalars['String']>
  toolName?: Maybe<Scalars['String']>
  toolType?: Maybe<ToolType>
  toolUrl?: Maybe<Scalars['String']>
  userToolsImageUrl?: Maybe<Scalars['String']>
  visible?: Maybe<Scalars['Boolean']>
}

export type ToolConnectionSuccessInput = {
  connectorName: Scalars['String']
  playId?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
}

export enum ToolType {
  Calendar = 'CALENDAR',
}

export type UpdateBusinessProfileInput = {
  businessName?: InputMaybe<Scalars['String']>
  businessVision?: InputMaybe<Scalars['String']>
  challenges?: InputMaybe<Scalars['String']>
  companyEmployees?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  mobileContactNumber?: InputMaybe<Scalars['String']>
  otherGoals?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
  userName?: InputMaybe<Scalars['String']>
  yearsInBusiness?: InputMaybe<Scalars['String']>
}

export type UpdateMyProfileInput = {
  advisors?: InputMaybe<Array<Scalars['String']>>
  assessmentResponse?: InputMaybe<Scalars['AWSJSON']>
  attestationStatus?: InputMaybe<Scalars['String']>
  bio?: InputMaybe<Scalars['String']>
  businessName?: InputMaybe<Scalars['String']>
  calendlyEventsUrl?: InputMaybe<Scalars['String']>
  companyAdvisor?: InputMaybe<Scalars['String']>
  companyEmployees?: InputMaybe<Scalars['Int']>
  defaultVideoLinkDescription?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['AWSEmail']>
  firstName?: InputMaybe<Scalars['String']>
  interimTimezone?: InputMaybe<Scalars['String']>
  isNewUser?: InputMaybe<Scalars['Boolean']>
  isSmsOptedIn?: InputMaybe<Scalars['Boolean']>
  lastName?: InputMaybe<Scalars['String']>
  mobileContactNumber?: InputMaybe<Scalars['AWSPhone']>
  name?: InputMaybe<Scalars['String']>
  owners?: InputMaybe<Array<Scalars['String']>>
  phone?: InputMaybe<Scalars['AWSPhone']>
  schedulingUrl?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
  streamAccessToken?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
  timezone?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
  websiteURL?: InputMaybe<Scalars['String']>
  yearsInBusiness?: InputMaybe<Scalars['String']>
}

export type UpdateOwnerInput = {
  ownerExperience?: InputMaybe<Scalars['String']>
  selectedTools?: InputMaybe<Scalars['AWSJSON']>
  tenantId: Scalars['String']
}

export type UpdateUserAvailabilityInput = {
  availabilityHours?: InputMaybe<AvailabilityHoursInput>
  tenantId?: InputMaybe<Scalars['String']>
}

export type UpdateUserAvailabilityResponse = {
  __typename?: 'UpdateUserAvailabilityResponse'
  data?: Maybe<SetUserAvailabilityObj>
  error?: Maybe<Scalars['String']>
}

export type UpdateUserCalendarEvent = {
  __typename?: 'UpdateUserCalendarEvent'
  attendees?: Maybe<Array<Maybe<AttendeesType>>>
  comment?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  eventId?: Maybe<Scalars['String']>
  eventType?: Maybe<Scalars['String']>
  location?: Maybe<Scalars['String']>
  tackleId?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  videoLinkDescription?: Maybe<Scalars['String']>
}

export type UpdateUserCalendarEventInput = {
  attendees?: InputMaybe<Array<InputMaybe<Scalars['String']>>>
  comment?: InputMaybe<Scalars['String']>
  description?: InputMaybe<Scalars['String']>
  eventId?: InputMaybe<Scalars['String']>
  eventType?: InputMaybe<Scalars['String']>
  location?: InputMaybe<Scalars['String']>
  tackleId?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  videoLinkDescription?: InputMaybe<Scalars['String']>
}

export type UpdateUserCalendarEventResponse = {
  __typename?: 'UpdateUserCalendarEventResponse'
  data?: Maybe<UpdateUserCalendarEvent>
  error?: Maybe<Scalars['String']>
}

export type UpdateUserNotificationsInput = {
  id?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
  updateAll?: InputMaybe<Scalars['Boolean']>
}

export type UpdateUserPasswordInput = {
  password?: InputMaybe<Scalars['String']>
}

export type UserAnswer = {
  __typename?: 'UserAnswer'
  id?: Maybe<Scalars['String']>
  meta?: Maybe<Scalars['AWSJSON']>
  optionId?: Maybe<Scalars['String']>
  question?: Maybe<Scalars['AWSJSON']>
  value?: Maybe<Scalars['String']>
}

export type UserAssessmentResponse = {
  __typename?: 'UserAssessmentResponse'
  data?: Maybe<AssessmentResponse>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type UserAvailability = {
  __typename?: 'UserAvailability'
  date?: Maybe<Scalars['String']>
  slots?: Maybe<Array<Maybe<SlotObj>>>
}

export type UserIntegrations = {
  __typename?: 'UserIntegrations'
  cyclrToolId?: Maybe<Scalars['String']>
  group?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  imageUrl?: Maybe<Scalars['String']>
  isConnected?: Maybe<Scalars['Boolean']>
  isRecommended?: Maybe<Scalars['Boolean']>
  isSelected?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  recommendedBy?: Maybe<Scalars['String']>
  toolUrl?: Maybe<Scalars['String']>
  userToolsImageUrl?: Maybe<Scalars['String']>
}

export type UserIntegrationsResponse = {
  __typename?: 'UserIntegrationsResponse'
  data?: Maybe<Array<Maybe<ListAssessment>>>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type UserMeetingObj = {
  __typename?: 'UserMeetingObj'
  attendees?: Maybe<Scalars['AWSJSON']>
  createdAt?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  endTime?: Maybe<Scalars['String']>
  eventId?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  inviteeId?: Maybe<Scalars['String']>
  location?: Maybe<Scalars['String']>
  ownerId?: Maybe<Scalars['String']>
  participantId?: Maybe<Scalars['String']>
  role?: Maybe<Array<Maybe<Scalars['String']>>>
  startTime?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  tenantId?: Maybe<Scalars['String']>
  timezone?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  videoLinkDescription?: Maybe<Scalars['String']>
}

export type UserNotification = {
  __typename?: 'UserNotification'
  businessName?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['AWSDateTime']>
  firstName?: Maybe<Scalars['String']>
  id: Scalars['String']
  lastName?: Maybe<Scalars['String']>
  meta?: Maybe<Scalars['AWSJSON']>
  notificationType: Scalars['String']
  sender?: Maybe<Scalars['AWSJSON']>
  status?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['AWSDateTime']>
}

export type UserNotificationData = {
  __typename?: 'UserNotificationData'
  lastEvaluatedKey?: Maybe<Scalars['String']>
  notifications?: Maybe<Array<Maybe<UserNotification>>>
  totalCount?: Maybe<Scalars['Int']>
}

export type UserProfile = {
  __typename?: 'UserProfile'
  assessmentResponse?: Maybe<Scalars['AWSJSON']>
  attestationStatus?: Maybe<Scalars['String']>
  bio?: Maybe<Scalars['String']>
  businessName?: Maybe<Scalars['String']>
  calendlyEventsUrl?: Maybe<Scalars['String']>
  companyAdvisor?: Maybe<Scalars['String']>
  companyEmployees?: Maybe<Scalars['Int']>
  createdAt: Scalars['AWSDateTime']
  defaultVideoLinkDescription?: Maybe<Scalars['String']>
  email: Scalars['AWSEmail']
  firstName?: Maybe<Scalars['String']>
  firstSigninDate?: Maybe<Scalars['String']>
  googleCalendarWebhookMetaData?: Maybe<GoogleCalendarWebhookMetaData>
  id: Scalars['ID']
  industry?: Maybe<Scalars['String']>
  interimTimezone?: Maybe<Scalars['String']>
  isNewUser?: Maybe<Scalars['Boolean']>
  isSmsEnabled?: Maybe<Scalars['Boolean']>
  isSmsOptedIn?: Maybe<Scalars['Boolean']>
  lastName?: Maybe<Scalars['String']>
  mobileContactNumber?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  organizationName?: Maybe<Scalars['String']>
  ownerExperience?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['AWSPhone']>
  roles?: Maybe<Array<Maybe<Scalars['String']>>>
  schedulingUrl?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  streamAccessToken?: Maybe<Scalars['String']>
  submissionEndDay?: Maybe<Scalars['Int']>
  submissionReminderDay?: Maybe<Scalars['Int']>
  submissionStartDay?: Maybe<Scalars['Int']>
  tenantId?: Maybe<Array<Maybe<Scalars['String']>>>
  timezone?: Maybe<Scalars['String']>
  topGoal?: Maybe<Scalars['String']>
  updatedAt: Scalars['AWSDateTime']
  websiteURL?: Maybe<Scalars['String']>
  yearsInBusiness?: Maybe<Scalars['String']>
}

export type UserRecommendationsResponse = {
  __typename?: 'UserRecommendationsResponse'
  data?: Maybe<Scalars['AWSJSON']>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type UserResponse = {
  __typename?: 'UserResponse'
  data?: Maybe<UserAnswer>
  error?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
  success: Scalars['Boolean']
}

export type Attendee = {
  __typename?: 'attendee'
  email: Scalars['String']
  responseStatus?: Maybe<Scalars['String']>
}

export type AttendeesInterface = {
  email: Scalars['String']
  organizer?: InputMaybe<Scalars['Boolean']>
  responseStatus?: InputMaybe<Scalars['String']>
  self?: InputMaybe<Scalars['Boolean']>
}

export type BusinessAdvisor = {
  __typename?: 'businessAdvisor'
  advisor?: Maybe<Scalars['String']>
  businessId?: Maybe<Scalars['String']>
  createdAt?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['String']>
  tenantId?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['String']>
}

export type CancelOutlookEventInput = {
  eventId: Scalars['String']
}

export type DeleteGoogleCalendarEventInput = {
  eventId?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  tenantId?: InputMaybe<Scalars['String']>
}

export type GetOwnerBusinessDataInput = {
  endTime: Scalars['String']
  startTime: Scalars['String']
}

export type TimeObjInterface = {
  dateTime?: InputMaybe<Scalars['String']>
  timeZone?: InputMaybe<Scalars['String']>
}

export type UpdateGoogleCalendarEventInput = {
  attendees: Array<InputMaybe<AttendeesInterface>>
  description?: InputMaybe<Scalars['String']>
  end: TimeObjInterface
  eventId?: InputMaybe<Scalars['String']>
  id: Scalars['String']
  start: TimeObjInterface
  tenantId?: InputMaybe<Scalars['String']>
}

export type UserMeeting = {
  __typename?: 'userMeeting'
  advisorId: Scalars['String']
  attendees: Array<Maybe<Attendee>>
  createdAt?: Maybe<Scalars['String']>
  endTime: Scalars['String']
  eventId: Scalars['String']
  id: Scalars['String']
  inviteeId?: Maybe<Scalars['String']>
  ownerId: Scalars['String']
  role?: Maybe<Array<Maybe<Scalars['String']>>>
  startTime: Scalars['String']
  status?: Maybe<Scalars['String']>
  tenantId: Scalars['String']
  timezone?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['String']>
}

export type AssessmentResponseFieldsFragment = {
  __typename?: 'AssessmentResponse'
  id?: string | null | undefined
  currentStage?: string | null | undefined
  playStage?: string | null | undefined
  type?: string | null | undefined
  userId?: string | null | undefined
  assessmentResponse?: string | null | undefined
  createdAt?: string | null | undefined
  updatedAt?: string | null | undefined
}

export type AssessmentQuestionFieldsFragment = {
  __typename?: 'Assessment'
  id: string
  tenantId?: number | null | undefined
  previous?: string | null | undefined
  assessmentType?: string | null | undefined
  meta?: string | null | undefined
  content?: string | null | undefined
  optionType?: string | null | undefined
  options?: string | null | undefined
  createdAt?: string | null | undefined
  updatedAt?: string | null | undefined
  isVisible?: boolean | null | undefined
}

export type GetAssessmentResponseByQuestionFieldsFragment = {
  __typename?: 'UserResponse'
  success: boolean
  message?: string | null | undefined
  error?: string | null | undefined
  data?:
    | {
        __typename?: 'UserAnswer'
        id?: string | null | undefined
        value?: string | null | undefined
        question?: string | null | undefined
        meta?: string | null | undefined
      }
    | null
    | undefined
}

export type UserAssessmentResponseFieldsFragment = {
  __typename?: 'UserAssessmentResponse'
  success: boolean
  message?: string | null | undefined
  error?: string | null | undefined
  data?:
    | {
        __typename?: 'AssessmentResponse'
        id?: string | null | undefined
        currentStage?: string | null | undefined
        playStage?: string | null | undefined
        type?: string | null | undefined
        userId?: string | null | undefined
        assessmentResponse?: string | null | undefined
        totalItems?: number | null | undefined
        totalAttemptedItems?: number | null | undefined
        createdAt?: string | null | undefined
        updatedAt?: string | null | undefined
        completedAt?: string | null | undefined
      }
    | null
    | undefined
}

export type GetUserRecommendationsFieldsFragment = {
  __typename?: 'UserRecommendationsResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type GetUserAssessmentResponseQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>
  type?: InputMaybe<Scalars['String']>
}>

export type GetUserAssessmentResponseQuery = {
  __typename?: 'Query'
  getUserAssessmentResponse?:
    | {
        __typename?: 'UserAssessmentResponse'
        success: boolean
        message?: string | null | undefined
        error?: string | null | undefined
        data?:
          | {
              __typename?: 'AssessmentResponse'
              id?: string | null | undefined
              currentStage?: string | null | undefined
              playStage?: string | null | undefined
              type?: string | null | undefined
              userId?: string | null | undefined
              assessmentResponse?: string | null | undefined
              totalItems?: number | null | undefined
              totalAttemptedItems?: number | null | undefined
              createdAt?: string | null | undefined
              updatedAt?: string | null | undefined
              completedAt?: string | null | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetAssessmentsQueryVariables = Exact<{
  type?: InputMaybe<Scalars['String']>
}>

export type GetAssessmentsQuery = {
  __typename?: 'Query'
  getAssessments?:
    | Array<
        | {
            __typename?: 'Assessment'
            id: string
            tenantId?: number | null | undefined
            previous?: string | null | undefined
            assessmentType?: string | null | undefined
            meta?: string | null | undefined
            content?: string | null | undefined
            optionType?: string | null | undefined
            options?: string | null | undefined
            createdAt?: string | null | undefined
            updatedAt?: string | null | undefined
            isVisible?: boolean | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type SaveAssessmentResponseMutationVariables = Exact<{
  input: SaveAssessmentResponseInput
}>

export type SaveAssessmentResponseMutation = {
  __typename?: 'Mutation'
  saveAssessmentResponse?:
    | {
        __typename?: 'AssessmentResponse'
        id?: string | null | undefined
        currentStage?: string | null | undefined
        playStage?: string | null | undefined
        type?: string | null | undefined
        userId?: string | null | undefined
        assessmentResponse?: string | null | undefined
        createdAt?: string | null | undefined
        updatedAt?: string | null | undefined
      }
    | null
    | undefined
}

export type GetAssessmentResponseByQuestionQueryVariables = Exact<{
  type?: InputMaybe<Scalars['String']>
  question?: InputMaybe<Scalars['String']>
  userId?: InputMaybe<Scalars['String']>
}>

export type GetAssessmentResponseByQuestionQuery = {
  __typename?: 'Query'
  getAssessmentResponseByQuestion?:
    | {
        __typename?: 'UserResponse'
        success: boolean
        message?: string | null | undefined
        error?: string | null | undefined
        data?:
          | {
              __typename?: 'UserAnswer'
              id?: string | null | undefined
              value?: string | null | undefined
              question?: string | null | undefined
              meta?: string | null | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetUserRecommendationsQueryVariables = Exact<{ [key: string]: never }>

export type GetUserRecommendationsQuery = {
  __typename?: 'Query'
  getUserRecommendations?:
    | {
        __typename?: 'UserRecommendationsResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type ListAssessmentsFieldsFragment = {
  __typename?: 'ListAssessmentResponse'
  success: boolean
  message?: string | null | undefined
  error?: string | null | undefined
  data?:
    | Array<
        | {
            __typename?: 'ListAssessment'
            type?: string | null | undefined
            order?: string | null | undefined
            isCompleted?: boolean | null | undefined
            isLocked?: boolean | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type ListAssessmentsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
}>

export type ListAssessmentsQuery = {
  __typename?: 'Query'
  listAssessments?:
    | {
        __typename?: 'ListAssessmentResponse'
        success: boolean
        message?: string | null | undefined
        error?: string | null | undefined
        data?:
          | Array<
              | {
                  __typename?: 'ListAssessment'
                  type?: string | null | undefined
                  order?: string | null | undefined
                  isCompleted?: boolean | null | undefined
                  isLocked?: boolean | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetAttestationSummaryFieldsFragment = {
  __typename?: 'AttestationSummary'
  tenantId?: string | null | undefined
  unsignedCount?: number | null | undefined
  signedCount?: number | null | undefined
  submissionStartDate?: string | null | undefined
  submissionEndDate?: string | null | undefined
  submissionReminderDate?: string | null | undefined
  submissionReportDate?: string | null | undefined
  newAggregatedReport?:
    | {
        __typename?: 'Attestation'
        registerBusinessStructure?: string | null | undefined
        month?: string | null | undefined
        year?: string | null | undefined
        contractors?: string | null | undefined
        businessBankAccount?: string | null | undefined
        jobsCreated?: string | null | undefined
        volunteers?: string | null | undefined
        partTimeEmployees?: string | null | undefined
        fullTimeEmployees?: string | null | undefined
        gender?: string | null | undefined
        race?: string | null | undefined
        education?: string | null | undefined
        wages?: string | null | undefined
        revenue?: string | null | undefined
        debtFinancing?: string | null | undefined
        financialGrowth?: string | null | undefined
        newCustomer?: string | null | undefined
        productSale?: string | null | undefined
        serviceSale?: string | null | undefined
        targetAudience?: string | null | undefined
        physicalLocation?: string | null | undefined
        businessName?: string | null | undefined
        name?: string | null | undefined
        signedDocumentUrl?: string | null | undefined
        error?: string | null | undefined
        createdAt?: string | null | undefined
        downloadUrl?: string | null | undefined
      }
    | null
    | undefined
  aggregationReportHistory?:
    | Array<
        | {
            __typename?: 'Attestation'
            registerBusinessStructure?: string | null | undefined
            month?: string | null | undefined
            year?: string | null | undefined
            contractors?: string | null | undefined
            businessBankAccount?: string | null | undefined
            jobsCreated?: string | null | undefined
            volunteers?: string | null | undefined
            partTimeEmployees?: string | null | undefined
            fullTimeEmployees?: string | null | undefined
            gender?: string | null | undefined
            race?: string | null | undefined
            education?: string | null | undefined
            wages?: string | null | undefined
            revenue?: string | null | undefined
            debtFinancing?: string | null | undefined
            financialGrowth?: string | null | undefined
            newCustomer?: string | null | undefined
            productSale?: string | null | undefined
            serviceSale?: string | null | undefined
            targetAudience?: string | null | undefined
            physicalLocation?: string | null | undefined
            businessName?: string | null | undefined
            name?: string | null | undefined
            signedDocumentUrl?: string | null | undefined
            error?: string | null | undefined
            createdAt?: string | null | undefined
            downloadUrl?: string | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type GetAttestationSummaryQueryVariables = Exact<{
  input?: InputMaybe<GetAttestationSummaryInput>
}>

export type GetAttestationSummaryQuery = {
  __typename?: 'Query'
  getAttestationSummary?:
    | {
        __typename?: 'AttestationSummary'
        tenantId?: string | null | undefined
        unsignedCount?: number | null | undefined
        signedCount?: number | null | undefined
        submissionStartDate?: string | null | undefined
        submissionEndDate?: string | null | undefined
        submissionReminderDate?: string | null | undefined
        submissionReportDate?: string | null | undefined
        newAggregatedReport?:
          | {
              __typename?: 'Attestation'
              registerBusinessStructure?: string | null | undefined
              month?: string | null | undefined
              year?: string | null | undefined
              contractors?: string | null | undefined
              businessBankAccount?: string | null | undefined
              jobsCreated?: string | null | undefined
              volunteers?: string | null | undefined
              partTimeEmployees?: string | null | undefined
              fullTimeEmployees?: string | null | undefined
              gender?: string | null | undefined
              race?: string | null | undefined
              education?: string | null | undefined
              wages?: string | null | undefined
              revenue?: string | null | undefined
              debtFinancing?: string | null | undefined
              financialGrowth?: string | null | undefined
              newCustomer?: string | null | undefined
              productSale?: string | null | undefined
              serviceSale?: string | null | undefined
              targetAudience?: string | null | undefined
              physicalLocation?: string | null | undefined
              businessName?: string | null | undefined
              name?: string | null | undefined
              signedDocumentUrl?: string | null | undefined
              error?: string | null | undefined
              createdAt?: string | null | undefined
              downloadUrl?: string | null | undefined
            }
          | null
          | undefined
        aggregationReportHistory?:
          | Array<
              | {
                  __typename?: 'Attestation'
                  registerBusinessStructure?: string | null | undefined
                  month?: string | null | undefined
                  year?: string | null | undefined
                  contractors?: string | null | undefined
                  businessBankAccount?: string | null | undefined
                  jobsCreated?: string | null | undefined
                  volunteers?: string | null | undefined
                  partTimeEmployees?: string | null | undefined
                  fullTimeEmployees?: string | null | undefined
                  gender?: string | null | undefined
                  race?: string | null | undefined
                  education?: string | null | undefined
                  wages?: string | null | undefined
                  revenue?: string | null | undefined
                  debtFinancing?: string | null | undefined
                  financialGrowth?: string | null | undefined
                  newCustomer?: string | null | undefined
                  productSale?: string | null | undefined
                  serviceSale?: string | null | undefined
                  targetAudience?: string | null | undefined
                  physicalLocation?: string | null | undefined
                  businessName?: string | null | undefined
                  name?: string | null | undefined
                  signedDocumentUrl?: string | null | undefined
                  error?: string | null | undefined
                  createdAt?: string | null | undefined
                  downloadUrl?: string | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type BusinessSupportOrganizationFieldsFragment = {
  __typename?: 'BusinessSupportOrganization'
  id: string
  adminId: string
  name: string
  logoUrl?: string | null | undefined
  terminology?: Array<{ __typename?: 'Term'; term: string; replacement: string }> | null | undefined
}

export type GetBusinessSupportOrganizationQueryVariables = Exact<{
  input: GetBusinessSupportOrganizationInput
}>

export type GetBusinessSupportOrganizationQuery = {
  __typename?: 'Query'
  getBusinessSupportOrganization?:
    | {
        __typename?: 'BusinessSupportOrganization'
        id: string
        adminId: string
        name: string
        logoUrl?: string | null | undefined
        terminology?:
          | Array<{ __typename?: 'Term'; term: string; replacement: string }>
          | null
          | undefined
      }
    | null
    | undefined
}

export type BusinessProfileFieldsFragment = {
  __typename?: 'BusinessProfile'
  id: string
  userName?: string | null | undefined
  businessName?: string | null | undefined
  mobileContactNumber?: string | null | undefined
  yearsInBusiness?: string | null | undefined
  companyEmployees?: string | null | undefined
  createdAt?: string | null | undefined
  updatedAt?: string | null | undefined
  tenantId?: string | null | undefined
  businessVision?: string | null | undefined
  challenges?: string | null | undefined
  otherGoals?: string | null | undefined
  registerBusinessStructure?: string | null | undefined
  month?: string | null | undefined
  year?: string | null | undefined
  contractors?: string | null | undefined
  businessBankAccount?: boolean | null | undefined
  jobsCreated?: string | null | undefined
  volunteers?: string | null | undefined
  partTimeEmployees?: string | null | undefined
  fullTimeEmployees?: string | null | undefined
  gender?: string | null | undefined
  race?: string | null | undefined
  education?: string | null | undefined
  wages?: string | null | undefined
  revenue?: string | null | undefined
  debtFinancing?: string | null | undefined
  financialGrowth?: string | null | undefined
  newCustomer?: string | null | undefined
  productSale?: string | null | undefined
  serviceSale?: string | null | undefined
  targetAudience?: string | null | undefined
  physicalLocation?: string | null | undefined
  additionalInfo?: string | null | undefined
}

export type GetBusinessProfileQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>
}>

export type GetBusinessProfileQuery = {
  __typename?: 'Query'
  getBusinessProfile?:
    | {
        __typename?: 'BusinessProfile'
        id: string
        userName?: string | null | undefined
        businessName?: string | null | undefined
        mobileContactNumber?: string | null | undefined
        yearsInBusiness?: string | null | undefined
        companyEmployees?: string | null | undefined
        createdAt?: string | null | undefined
        updatedAt?: string | null | undefined
        tenantId?: string | null | undefined
        businessVision?: string | null | undefined
        challenges?: string | null | undefined
        otherGoals?: string | null | undefined
        registerBusinessStructure?: string | null | undefined
        month?: string | null | undefined
        year?: string | null | undefined
        contractors?: string | null | undefined
        businessBankAccount?: boolean | null | undefined
        jobsCreated?: string | null | undefined
        volunteers?: string | null | undefined
        partTimeEmployees?: string | null | undefined
        fullTimeEmployees?: string | null | undefined
        gender?: string | null | undefined
        race?: string | null | undefined
        education?: string | null | undefined
        wages?: string | null | undefined
        revenue?: string | null | undefined
        debtFinancing?: string | null | undefined
        financialGrowth?: string | null | undefined
        newCustomer?: string | null | undefined
        productSale?: string | null | undefined
        serviceSale?: string | null | undefined
        targetAudience?: string | null | undefined
        physicalLocation?: string | null | undefined
        additionalInfo?: string | null | undefined
      }
    | null
    | undefined
}

export type UpdateBusinessProfileMutationVariables = Exact<{
  input: UpdateBusinessProfileInput
}>

export type UpdateBusinessProfileMutation = {
  __typename?: 'Mutation'
  updateBusinessProfile?:
    | {
        __typename?: 'BusinessProfile'
        id: string
        userName?: string | null | undefined
        businessName?: string | null | undefined
        mobileContactNumber?: string | null | undefined
        yearsInBusiness?: string | null | undefined
        companyEmployees?: string | null | undefined
        createdAt?: string | null | undefined
        updatedAt?: string | null | undefined
        tenantId?: string | null | undefined
        businessVision?: string | null | undefined
        challenges?: string | null | undefined
        otherGoals?: string | null | undefined
        registerBusinessStructure?: string | null | undefined
        month?: string | null | undefined
        year?: string | null | undefined
        contractors?: string | null | undefined
        businessBankAccount?: boolean | null | undefined
        jobsCreated?: string | null | undefined
        volunteers?: string | null | undefined
        partTimeEmployees?: string | null | undefined
        fullTimeEmployees?: string | null | undefined
        gender?: string | null | undefined
        race?: string | null | undefined
        education?: string | null | undefined
        wages?: string | null | undefined
        revenue?: string | null | undefined
        debtFinancing?: string | null | undefined
        financialGrowth?: string | null | undefined
        newCustomer?: string | null | undefined
        productSale?: string | null | undefined
        serviceSale?: string | null | undefined
        targetAudience?: string | null | undefined
        physicalLocation?: string | null | undefined
        additionalInfo?: string | null | undefined
      }
    | null
    | undefined
}

export type CyclrConnectorFieldsFragment = {
  __typename?: 'CyclrConnectorResponse'
  success: boolean
  message?: string | null | undefined
  error?: string | null | undefined
  data?:
    | Array<
        | {
            __typename?: 'CyclrConnector'
            id?: number | null | undefined
            name?: string | null | undefined
            authenticated?: boolean | null | undefined
            connectorId?: number | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type CalenderEventFieldsFragment = {
  __typename?: 'CalendarEventResponse'
  success: boolean
  message?: string | null | undefined
  error?: string | null | undefined
  data?:
    | Array<
        | {
            __typename?: 'CalendarEvent'
            summary?: string | null | undefined
            description?: string | null | undefined
            status?: string | null | undefined
            eventTime?: string | null | undefined
            meta?: string | null | undefined
            location?: string | null | undefined
            eventType?: string | null | undefined
            advisorProfiles?: string | null | undefined
            profile?: string | null | undefined
            timezone?: string | null | undefined
            eventId?: string | null | undefined
            inviteeId?: string | null | undefined
            advisorId?: string | null | undefined
            availability?: string | null | undefined
            videoLinkDescription?: string | null | undefined
            id?: string | null | undefined
            start?:
              | {
                  __typename?: 'CalendarEventDate'
                  dateTime?: string | null | undefined
                  timeZone?: string | null | undefined
                }
              | null
              | undefined
            end?:
              | {
                  __typename?: 'CalendarEventDate'
                  dateTime?: string | null | undefined
                  timeZone?: string | null | undefined
                }
              | null
              | undefined
            attendees?:
              | Array<
                  | {
                      __typename?: 'CalendarEventAttendee'
                      email?: string | null | undefined
                      responseStatus?: string | null | undefined
                      optional?: boolean | null | undefined
                    }
                  | null
                  | undefined
                >
              | null
              | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type OAuthUrlFieldsFragment = {
  __typename?: 'OAuthUrlResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type UserMeetingFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type CalendlyUserFieldsFragment = {
  __typename?: 'CalendlyUserResponse'
  success: boolean
  message?: string | null | undefined
  error?: string | null | undefined
  data?:
    | {
        __typename?: 'CalendlyUser'
        email?: string | null | undefined
        name?: string | null | undefined
        schedulingUrl?: string | null | undefined
        calendlyEventsUrl?: string | null | undefined
      }
    | null
    | undefined
}

export type DeleteCalendarFieldsFragment = {
  __typename?: 'DeleteCalendarEventResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type DeleteGoogleCalendarEventFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type CancelOutlookEventFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type UpdateGoogleCalendarEventFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type GetMeetingTypesFieldsFragment = {
  __typename?: 'GetMeetingTypeResponse'
  success?: boolean | null | undefined
  message?: string | null | undefined
  error?: string | null | undefined
  data?:
    | Array<
        | {
            __typename?: 'MeetingTypes'
            id?: string | null | undefined
            tenantId?: string | null | undefined
            duration?: string | null | undefined
            label?: string | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type SetUserAvailabilityFieldsFragment = {
  __typename?: 'SetUserAvailabilityResponse'
  error?: string | null | undefined
  data?:
    | {
        __typename?: 'SetUserAvailabilityObj'
        createdAt?: string | null | undefined
        updatedAt?: string | null | undefined
        userId?: string | null | undefined
        tenantId?: string | null | undefined
        id?: string | null | undefined
        availabilityHours?:
          | {
              __typename?: 'AvailabilityHoursObj'
              duration?: string | null | undefined
              timezone?: string | null | undefined
              timeSlots?:
                | Array<
                    | {
                        __typename?: 'TimeSlotsObj'
                        label?: string | null | undefined
                        value?: string | null | undefined
                        slots?:
                          | Array<
                              | {
                                  __typename?: 'SlotOutput'
                                  startTime?: string | null | undefined
                                  endTime?: string | null | undefined
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetUserAvailabilityFieldsFragment = {
  __typename?: 'GetUserAvailabilityResponse'
  error?: string | null | undefined
  data?:
    | {
        __typename?: 'GetUserAvailability'
        duration?: string | null | undefined
        availability?:
          | Array<
              | {
                  __typename?: 'UserAvailability'
                  date?: string | null | undefined
                  slots?:
                    | Array<
                        | {
                            __typename?: 'SlotObj'
                            isAvailable?: boolean | null | undefined
                            slotStartTime?: string | null | undefined
                            slotEndTime?: string | null | undefined
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type SetUserMeetingResponseFieldsFragment = {
  __typename?: 'SetUserMeetingResponse'
  error?: string | null | undefined
  data?:
    | {
        __typename?: 'UserMeetingObj'
        id?: string | null | undefined
        eventId?: string | null | undefined
        updatedAt?: string | null | undefined
        createdAt?: string | null | undefined
        participantId?: string | null | undefined
        tenantId?: string | null | undefined
        startTime?: string | null | undefined
        endTime?: string | null | undefined
        description?: string | null | undefined
        ownerId?: string | null | undefined
        inviteeId?: string | null | undefined
        title?: string | null | undefined
        timezone?: string | null | undefined
        attendees?: string | null | undefined
        role?: Array<string | null | undefined> | null | undefined
        status?: string | null | undefined
        location?: string | null | undefined
        videoLinkDescription?: string | null | undefined
      }
    | null
    | undefined
}

export type GetUserConnectorsQueryVariables = Exact<{
  connectorName?: InputMaybe<Scalars['String']>
}>

export type GetUserConnectorsQuery = {
  __typename?: 'Query'
  getUserConnectors?:
    | {
        __typename?: 'CyclrConnectorResponse'
        success: boolean
        message?: string | null | undefined
        error?: string | null | undefined
        data?:
          | Array<
              | {
                  __typename?: 'CyclrConnector'
                  id?: number | null | undefined
                  name?: string | null | undefined
                  authenticated?: boolean | null | undefined
                  connectorId?: number | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetUserCalendarEventsQueryVariables = Exact<{
  calendarName?: InputMaybe<Scalars['String']>
  timeMin?: InputMaybe<Scalars['String']>
  timeMax?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
}>

export type GetUserCalendarEventsQuery = {
  __typename?: 'Query'
  getUserCalendarEvents?:
    | {
        __typename?: 'CalendarEventResponse'
        success: boolean
        message?: string | null | undefined
        error?: string | null | undefined
        data?:
          | Array<
              | {
                  __typename?: 'CalendarEvent'
                  summary?: string | null | undefined
                  description?: string | null | undefined
                  status?: string | null | undefined
                  eventTime?: string | null | undefined
                  meta?: string | null | undefined
                  location?: string | null | undefined
                  eventType?: string | null | undefined
                  advisorProfiles?: string | null | undefined
                  profile?: string | null | undefined
                  timezone?: string | null | undefined
                  eventId?: string | null | undefined
                  inviteeId?: string | null | undefined
                  advisorId?: string | null | undefined
                  availability?: string | null | undefined
                  videoLinkDescription?: string | null | undefined
                  id?: string | null | undefined
                  start?:
                    | {
                        __typename?: 'CalendarEventDate'
                        dateTime?: string | null | undefined
                        timeZone?: string | null | undefined
                      }
                    | null
                    | undefined
                  end?:
                    | {
                        __typename?: 'CalendarEventDate'
                        dateTime?: string | null | undefined
                        timeZone?: string | null | undefined
                      }
                    | null
                    | undefined
                  attendees?:
                    | Array<
                        | {
                            __typename?: 'CalendarEventAttendee'
                            email?: string | null | undefined
                            responseStatus?: string | null | undefined
                            optional?: boolean | null | undefined
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type UpdateUserCalendarFieldsFragment = {
  __typename?: 'UpdateUserCalendarEventResponse'
  error?: string | null | undefined
  data?:
    | {
        __typename?: 'UpdateUserCalendarEvent'
        title?: string | null | undefined
        eventId?: string | null | undefined
        location?: string | null | undefined
        tackleId?: string | null | undefined
        eventType?: string | null | undefined
        description?: string | null | undefined
        comment?: string | null | undefined
        videoLinkDescription?: string | null | undefined
        attendees?:
          | Array<
              | {
                  __typename?: 'AttendeesType'
                  email?: string | null | undefined
                  organizer?: boolean | null | undefined
                  self?: boolean | null | undefined
                  responseStatus?: string | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type SubscribeGoogleCalendarWebhookFieldsFragment = {
  __typename?: 'SubscribeGoogleCalendarWebhookResponse'
  data?: string | null | undefined
  error?: string | null | undefined
}

export type CancelUserCalendarFieldsFragment = {
  __typename?: 'CancelUserCalendarEventResponse'
  error?: string | null | undefined
  data?:
    | {
        __typename?: 'CancelUserCalendarEvent'
        tackleId?: string | null | undefined
        eventId?: string | null | undefined
        eventType?: string | null | undefined
      }
    | null
    | undefined
}

export type UpdateUserAvailabilityFieldsFragment = {
  __typename?: 'UpdateUserAvailabilityResponse'
  error?: string | null | undefined
  data?:
    | {
        __typename?: 'SetUserAvailabilityObj'
        createdAt?: string | null | undefined
        updatedAt?: string | null | undefined
        userId?: string | null | undefined
        tenantId?: string | null | undefined
        id?: string | null | undefined
        availabilityHours?:
          | {
              __typename?: 'AvailabilityHoursObj'
              duration?: string | null | undefined
              timezone?: string | null | undefined
              timeSlots?:
                | Array<
                    | {
                        __typename?: 'TimeSlotsObj'
                        label?: string | null | undefined
                        value?: string | null | undefined
                        slots?:
                          | Array<
                              | {
                                  __typename?: 'SlotOutput'
                                  startTime?: string | null | undefined
                                  endTime?: string | null | undefined
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetUserAvailabilityHoursFieldsFragment = {
  __typename?: 'GetUserAvailabilityHoursResponse'
  error?: string | null | undefined
  data?:
    | {
        __typename?: 'SetUserAvailabilityObj'
        createdAt?: string | null | undefined
        updatedAt?: string | null | undefined
        userId?: string | null | undefined
        tenantId?: string | null | undefined
        id?: string | null | undefined
        availabilityHours?:
          | {
              __typename?: 'AvailabilityHoursObj'
              duration?: string | null | undefined
              timezone?: string | null | undefined
              timeSlots?:
                | Array<
                    | {
                        __typename?: 'TimeSlotsObj'
                        label?: string | null | undefined
                        value?: string | null | undefined
                        slots?:
                          | Array<
                              | {
                                  __typename?: 'SlotOutput'
                                  startTime?: string | null | undefined
                                  endTime?: string | null | undefined
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetUserMeetingByIdFieldsFragment = {
  __typename?: 'GetUserMeetingByIdResponse'
  error?: string | null | undefined
  data?:
    | {
        __typename?: 'CalendarEvent'
        summary?: string | null | undefined
        description?: string | null | undefined
        status?: string | null | undefined
        eventTime?: string | null | undefined
        meta?: string | null | undefined
        location?: string | null | undefined
        eventType?: string | null | undefined
        advisorProfiles?: string | null | undefined
        profile?: string | null | undefined
        timezone?: string | null | undefined
        eventId?: string | null | undefined
        inviteeId?: string | null | undefined
        advisorId?: string | null | undefined
        availability?: string | null | undefined
        comment?: string | null | undefined
        videoLinkDescription?: string | null | undefined
        id?: string | null | undefined
        start?:
          | {
              __typename?: 'CalendarEventDate'
              dateTime?: string | null | undefined
              timeZone?: string | null | undefined
            }
          | null
          | undefined
        end?:
          | {
              __typename?: 'CalendarEventDate'
              dateTime?: string | null | undefined
              timeZone?: string | null | undefined
            }
          | null
          | undefined
        attendees?:
          | Array<
              | {
                  __typename?: 'CalendarEventAttendee'
                  email?: string | null | undefined
                  responseStatus?: string | null | undefined
                  optional?: boolean | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetOAuthUrlQueryVariables = Exact<{
  connectorName?: InputMaybe<Scalars['String']>
}>

export type GetOAuthUrlQuery = {
  __typename?: 'Query'
  getOAuthUrl?:
    | {
        __typename?: 'OAuthUrlResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetCalendlyUserQueryVariables = Exact<{ [key: string]: never }>

export type GetCalendlyUserQuery = {
  __typename?: 'Query'
  getCalendlyUser?:
    | {
        __typename?: 'CalendlyUserResponse'
        success: boolean
        message?: string | null | undefined
        error?: string | null | undefined
        data?:
          | {
              __typename?: 'CalendlyUser'
              email?: string | null | undefined
              name?: string | null | undefined
              schedulingUrl?: string | null | undefined
              calendlyEventsUrl?: string | null | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type SubscribeCalendlyWebhookQueryVariables = Exact<{
  tenantId?: InputMaybe<Scalars['String']>
  email?: InputMaybe<Scalars['String']>
}>

export type SubscribeCalendlyWebhookQuery = {
  __typename?: 'Query'
  subscribeCalendlyWebhook?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type SubscribeGoogleCalendarWebhookMutationVariables = Exact<{
  tenantId?: InputMaybe<Scalars['String']>
}>

export type SubscribeGoogleCalendarWebhookMutation = {
  __typename?: 'Mutation'
  subscribeGoogleCalendarWebhook?:
    | {
        __typename?: 'SubscribeGoogleCalendarWebhookResponse'
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetUserMeetingQueryVariables = Exact<{
  advisorId?: InputMaybe<Scalars['String']>
}>

export type GetUserMeetingQuery = {
  __typename?: 'Query'
  getUserMeeting?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type DeleteCalendarEventMutationVariables = Exact<{
  input: DeleteCalendarEventInput
}>

export type DeleteCalendarEventMutation = {
  __typename?: 'Mutation'
  deleteCalendarEvent?:
    | {
        __typename?: 'DeleteCalendarEventResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type DeleteGoogleCalendarEventQueryVariables = Exact<{
  input?: InputMaybe<DeleteGoogleCalendarEventInput>
}>

export type DeleteGoogleCalendarEventQuery = {
  __typename?: 'Query'
  deleteGoogleCalendarEvent?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type UpdateGoogleCalendarEventQueryVariables = Exact<{
  input?: InputMaybe<UpdateGoogleCalendarEventInput>
}>

export type UpdateGoogleCalendarEventQuery = {
  __typename?: 'Query'
  updateGoogleCalendarEvent?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type CancelOutlookEventQueryVariables = Exact<{
  input?: InputMaybe<CancelOutlookEventInput>
}>

export type CancelOutlookEventQuery = {
  __typename?: 'Query'
  cancelOutlookEvent?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetMeetingTypesQueryVariables = Exact<{
  tenantId: Scalars['String']
}>

export type GetMeetingTypesQuery = {
  __typename?: 'Query'
  getMeetingTypes?:
    | {
        __typename?: 'GetMeetingTypeResponse'
        success?: boolean | null | undefined
        message?: string | null | undefined
        error?: string | null | undefined
        data?:
          | Array<
              | {
                  __typename?: 'MeetingTypes'
                  id?: string | null | undefined
                  tenantId?: string | null | undefined
                  duration?: string | null | undefined
                  label?: string | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type SetUserAvailabilityMutationVariables = Exact<{
  input?: InputMaybe<SetUserAvailabilityInput>
}>

export type SetUserAvailabilityMutation = {
  __typename?: 'Mutation'
  setUserAvailability?:
    | {
        __typename?: 'SetUserAvailabilityResponse'
        error?: string | null | undefined
        data?:
          | {
              __typename?: 'SetUserAvailabilityObj'
              createdAt?: string | null | undefined
              updatedAt?: string | null | undefined
              userId?: string | null | undefined
              tenantId?: string | null | undefined
              id?: string | null | undefined
              availabilityHours?:
                | {
                    __typename?: 'AvailabilityHoursObj'
                    duration?: string | null | undefined
                    timezone?: string | null | undefined
                    timeSlots?:
                      | Array<
                          | {
                              __typename?: 'TimeSlotsObj'
                              label?: string | null | undefined
                              value?: string | null | undefined
                              slots?:
                                | Array<
                                    | {
                                        __typename?: 'SlotOutput'
                                        startTime?: string | null | undefined
                                        endTime?: string | null | undefined
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined
                  }
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetUserAvailabilityQueryVariables = Exact<{
  input?: InputMaybe<GetUserAvailabilityInput>
}>

export type GetUserAvailabilityQuery = {
  __typename?: 'Query'
  getUserAvailability?:
    | {
        __typename?: 'GetUserAvailabilityResponse'
        error?: string | null | undefined
        data?:
          | {
              __typename?: 'GetUserAvailability'
              duration?: string | null | undefined
              availability?:
                | Array<
                    | {
                        __typename?: 'UserAvailability'
                        date?: string | null | undefined
                        slots?:
                          | Array<
                              | {
                                  __typename?: 'SlotObj'
                                  isAvailable?: boolean | null | undefined
                                  slotStartTime?: string | null | undefined
                                  slotEndTime?: string | null | undefined
                                }
                              | null
                              | undefined
                            >
                          | null
                          | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type SetUserMeetingMutationVariables = Exact<{
  input: SetUserMeetingInput
}>

export type SetUserMeetingMutation = {
  __typename?: 'Mutation'
  setUserMeeting?:
    | {
        __typename?: 'SetUserMeetingResponse'
        error?: string | null | undefined
        data?:
          | {
              __typename?: 'UserMeetingObj'
              id?: string | null | undefined
              eventId?: string | null | undefined
              updatedAt?: string | null | undefined
              createdAt?: string | null | undefined
              participantId?: string | null | undefined
              tenantId?: string | null | undefined
              startTime?: string | null | undefined
              endTime?: string | null | undefined
              description?: string | null | undefined
              ownerId?: string | null | undefined
              inviteeId?: string | null | undefined
              title?: string | null | undefined
              timezone?: string | null | undefined
              attendees?: string | null | undefined
              role?: Array<string | null | undefined> | null | undefined
              status?: string | null | undefined
              location?: string | null | undefined
              videoLinkDescription?: string | null | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type UpdateUserCalendarEventMutationVariables = Exact<{
  input?: InputMaybe<UpdateUserCalendarEventInput>
}>

export type UpdateUserCalendarEventMutation = {
  __typename?: 'Mutation'
  updateUserCalendarEvent?:
    | {
        __typename?: 'UpdateUserCalendarEventResponse'
        error?: string | null | undefined
        data?:
          | {
              __typename?: 'UpdateUserCalendarEvent'
              title?: string | null | undefined
              eventId?: string | null | undefined
              location?: string | null | undefined
              tackleId?: string | null | undefined
              eventType?: string | null | undefined
              description?: string | null | undefined
              comment?: string | null | undefined
              videoLinkDescription?: string | null | undefined
              attendees?:
                | Array<
                    | {
                        __typename?: 'AttendeesType'
                        email?: string | null | undefined
                        organizer?: boolean | null | undefined
                        self?: boolean | null | undefined
                        responseStatus?: string | null | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type CancelUserCalendarEventMutationVariables = Exact<{
  input: CancelUserCalendarEventInput
}>

export type CancelUserCalendarEventMutation = {
  __typename?: 'Mutation'
  cancelUserCalendarEvent?:
    | {
        __typename?: 'CancelUserCalendarEventResponse'
        error?: string | null | undefined
        data?:
          | {
              __typename?: 'CancelUserCalendarEvent'
              tackleId?: string | null | undefined
              eventId?: string | null | undefined
              eventType?: string | null | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetUserAvailabilityHoursQueryVariables = Exact<{ [key: string]: never }>

export type GetUserAvailabilityHoursQuery = {
  __typename?: 'Query'
  getUserAvailabilityHours?:
    | {
        __typename?: 'GetUserAvailabilityHoursResponse'
        error?: string | null | undefined
        data?:
          | {
              __typename?: 'SetUserAvailabilityObj'
              createdAt?: string | null | undefined
              updatedAt?: string | null | undefined
              userId?: string | null | undefined
              tenantId?: string | null | undefined
              id?: string | null | undefined
              availabilityHours?:
                | {
                    __typename?: 'AvailabilityHoursObj'
                    duration?: string | null | undefined
                    timezone?: string | null | undefined
                    timeSlots?:
                      | Array<
                          | {
                              __typename?: 'TimeSlotsObj'
                              label?: string | null | undefined
                              value?: string | null | undefined
                              slots?:
                                | Array<
                                    | {
                                        __typename?: 'SlotOutput'
                                        startTime?: string | null | undefined
                                        endTime?: string | null | undefined
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined
                  }
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type UpdateUserAvailabilityMutationVariables = Exact<{
  input?: InputMaybe<UpdateUserAvailabilityInput>
}>

export type UpdateUserAvailabilityMutation = {
  __typename?: 'Mutation'
  updateUserAvailability?:
    | {
        __typename?: 'UpdateUserAvailabilityResponse'
        error?: string | null | undefined
        data?:
          | {
              __typename?: 'SetUserAvailabilityObj'
              createdAt?: string | null | undefined
              updatedAt?: string | null | undefined
              userId?: string | null | undefined
              tenantId?: string | null | undefined
              id?: string | null | undefined
              availabilityHours?:
                | {
                    __typename?: 'AvailabilityHoursObj'
                    duration?: string | null | undefined
                    timezone?: string | null | undefined
                    timeSlots?:
                      | Array<
                          | {
                              __typename?: 'TimeSlotsObj'
                              label?: string | null | undefined
                              value?: string | null | undefined
                              slots?:
                                | Array<
                                    | {
                                        __typename?: 'SlotOutput'
                                        startTime?: string | null | undefined
                                        endTime?: string | null | undefined
                                      }
                                    | null
                                    | undefined
                                  >
                                | null
                                | undefined
                            }
                          | null
                          | undefined
                        >
                      | null
                      | undefined
                  }
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetUserMeetingByIdQueryVariables = Exact<{
  input: GetUserMeetingByIdInput
}>

export type GetUserMeetingByIdQuery = {
  __typename?: 'Query'
  getUserMeetingById?:
    | {
        __typename?: 'GetUserMeetingByIdResponse'
        error?: string | null | undefined
        data?:
          | {
              __typename?: 'CalendarEvent'
              summary?: string | null | undefined
              description?: string | null | undefined
              status?: string | null | undefined
              eventTime?: string | null | undefined
              meta?: string | null | undefined
              location?: string | null | undefined
              eventType?: string | null | undefined
              advisorProfiles?: string | null | undefined
              profile?: string | null | undefined
              timezone?: string | null | undefined
              eventId?: string | null | undefined
              inviteeId?: string | null | undefined
              advisorId?: string | null | undefined
              availability?: string | null | undefined
              comment?: string | null | undefined
              videoLinkDescription?: string | null | undefined
              id?: string | null | undefined
              start?:
                | {
                    __typename?: 'CalendarEventDate'
                    dateTime?: string | null | undefined
                    timeZone?: string | null | undefined
                  }
                | null
                | undefined
              end?:
                | {
                    __typename?: 'CalendarEventDate'
                    dateTime?: string | null | undefined
                    timeZone?: string | null | undefined
                  }
                | null
                | undefined
              attendees?:
                | Array<
                    | {
                        __typename?: 'CalendarEventAttendee'
                        email?: string | null | undefined
                        responseStatus?: string | null | undefined
                        optional?: boolean | null | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type AdvisorDashboardFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type TopActiveClientsFieldsFragment = {
  __typename?: 'GetTopActiveClientsResponse'
  error?: string | null | undefined
  data?:
    | Array<
        | {
            __typename?: 'GetTopActiveClients'
            id?: string | null | undefined
            name?: string | null | undefined
            tenantId?: string | null | undefined
            email?: string | null | undefined
            score?: number | null | undefined
            totalTime?: number | null | undefined
            topGoal?: string | null | undefined
            industry?: string | null | undefined
            businessName?: string | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type GetUserToolsFieldsFragment = {
  __typename?: 'GetUserToolsResponse'
  success?: boolean | null | undefined
  message?: string | null | undefined
  error?: string | null | undefined
  data?:
    | Array<
        | {
            __typename?: 'Tool'
            id?: string | null | undefined
            cyclrToolId?: string | null | undefined
            imageUrl?: string | null | undefined
            toolName?: string | null | undefined
            userToolsImageUrl?: string | null | undefined
            toolUrl?: string | null | undefined
            isConnected?: boolean | null | undefined
            isRecommended?: boolean | null | undefined
            recommendedBy?: string | null | undefined
            isSelected?: boolean | null | undefined
            group?: string | null | undefined
            category?: string | null | undefined
            visible?: boolean | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type ClientsFieldsFragment = {
  __typename?: 'GetClientsResponse'
  success: boolean
  message?: string | null | undefined
  error?: string | null | undefined
  data?:
    | Array<
        | {
            __typename?: 'UserProfile'
            id: string
            name?: string | null | undefined
            firstName?: string | null | undefined
            lastName?: string | null | undefined
            email: string
            createdAt: string
            updatedAt: string
            websiteURL?: string | null | undefined
            bio?: string | null | undefined
            roles?: Array<string | null | undefined> | null | undefined
            phone?: string | null | undefined
            isNewUser?: boolean | null | undefined
            businessName?: string | null | undefined
            mobileContactNumber?: string | null | undefined
            yearsInBusiness?: string | null | undefined
            assessmentResponse?: string | null | undefined
            companyAdvisor?: string | null | undefined
            schedulingUrl?: string | null | undefined
            calendlyEventsUrl?: string | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type TackleMeetingFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type ActivityLogFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type OwnerActivityDetailsFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type UserIntegrationFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type TackleMeetingsFieldsFragment = {
  __typename?: 'GetTackleMeetingResponse'
  success: boolean
  message?: string | null | undefined
  pagination?: string | null | undefined
  error?: string | null | undefined
  data?:
    | Array<
        | {
            __typename?: 'CalendarEvent'
            summary?: string | null | undefined
            description?: string | null | undefined
            status?: string | null | undefined
            eventTime?: string | null | undefined
            meta?: string | null | undefined
            location?: string | null | undefined
            eventType?: string | null | undefined
            advisorProfiles?: string | null | undefined
            profile?: string | null | undefined
            timezone?: string | null | undefined
            eventId?: string | null | undefined
            inviteeId?: string | null | undefined
            advisorId?: string | null | undefined
            availability?: string | null | undefined
            comment?: string | null | undefined
            start?:
              | {
                  __typename?: 'CalendarEventDate'
                  dateTime?: string | null | undefined
                  timeZone?: string | null | undefined
                }
              | null
              | undefined
            end?:
              | {
                  __typename?: 'CalendarEventDate'
                  dateTime?: string | null | undefined
                  timeZone?: string | null | undefined
                }
              | null
              | undefined
            attendees?:
              | Array<
                  | {
                      __typename?: 'CalendarEventAttendee'
                      email?: string | null | undefined
                      responseStatus?: string | null | undefined
                      optional?: boolean | null | undefined
                    }
                  | null
                  | undefined
                >
              | null
              | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type DeauthenticateConnectorFieldsFragment = {
  __typename?: 'ApiResponse'
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type GetAllToolsFieldsFragment = {
  __typename?: 'GetAllToolsResponse'
  success?: boolean | null | undefined
  message?: string | null | undefined
  error?: string | null | undefined
  data?:
    | Array<
        | {
            __typename?: 'Tool'
            id?: string | null | undefined
            cyclrToolId?: string | null | undefined
            imageUrl?: string | null | undefined
            toolName?: string | null | undefined
            userToolsImageUrl?: string | null | undefined
            toolUrl?: string | null | undefined
            isConnected?: boolean | null | undefined
            isRecommended?: boolean | null | undefined
            recommendedBy?: string | null | undefined
            isSelected?: boolean | null | undefined
            group?: string | null | undefined
            category?: string | null | undefined
            visible?: boolean | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type GetAttestationReportsFieldsFragment = {
  __typename?: 'GetAttestationReportsResponse'
  error?: string | null | undefined
  data?:
    | {
        __typename?: 'GetAttestationReportsResponseObj'
        lastEvaluatedKey?: string | null | undefined
        items?:
          | Array<
              | {
                  __typename?: 'Attestation'
                  registerBusinessStructure?: string | null | undefined
                  month?: string | null | undefined
                  year?: string | null | undefined
                  contractors?: string | null | undefined
                  businessBankAccount?: string | null | undefined
                  jobsCreated?: string | null | undefined
                  volunteers?: string | null | undefined
                  partTimeEmployees?: string | null | undefined
                  fullTimeEmployees?: string | null | undefined
                  gender?: string | null | undefined
                  race?: string | null | undefined
                  education?: string | null | undefined
                  wages?: string | null | undefined
                  revenue?: string | null | undefined
                  debtFinancing?: string | null | undefined
                  financialGrowth?: string | null | undefined
                  newCustomer?: string | null | undefined
                  productSale?: string | null | undefined
                  serviceSale?: string | null | undefined
                  targetAudience?: string | null | undefined
                  physicalLocation?: string | null | undefined
                  businessName?: string | null | undefined
                  name?: string | null | undefined
                  signedDocumentUrl?: string | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type UpdateOwnerFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type GetAdvisorDashboardQueryVariables = Exact<{
  filterType?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
}>

export type GetAdvisorDashboardQuery = {
  __typename?: 'Query'
  getAdvisorDashboard?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetTopActiveClientsQueryVariables = Exact<{
  tenantId?: InputMaybe<Scalars['String']>
}>

export type GetTopActiveClientsQuery = {
  __typename?: 'Query'
  getTopActiveClients?:
    | {
        __typename?: 'GetTopActiveClientsResponse'
        error?: string | null | undefined
        data?:
          | Array<
              | {
                  __typename?: 'GetTopActiveClients'
                  id?: string | null | undefined
                  name?: string | null | undefined
                  tenantId?: string | null | undefined
                  email?: string | null | undefined
                  score?: number | null | undefined
                  totalTime?: number | null | undefined
                  topGoal?: string | null | undefined
                  industry?: string | null | undefined
                  businessName?: string | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetClientsQueryVariables = Exact<{
  input?: InputMaybe<GetClientsInput>
}>

export type GetClientsQuery = {
  __typename?: 'Query'
  getClients?:
    | {
        __typename?: 'GetClientsResponse'
        success: boolean
        message?: string | null | undefined
        error?: string | null | undefined
        data?:
          | Array<
              | {
                  __typename?: 'UserProfile'
                  id: string
                  name?: string | null | undefined
                  firstName?: string | null | undefined
                  lastName?: string | null | undefined
                  email: string
                  createdAt: string
                  updatedAt: string
                  websiteURL?: string | null | undefined
                  bio?: string | null | undefined
                  roles?: Array<string | null | undefined> | null | undefined
                  phone?: string | null | undefined
                  isNewUser?: boolean | null | undefined
                  businessName?: string | null | undefined
                  mobileContactNumber?: string | null | undefined
                  yearsInBusiness?: string | null | undefined
                  assessmentResponse?: string | null | undefined
                  companyAdvisor?: string | null | undefined
                  schedulingUrl?: string | null | undefined
                  calendlyEventsUrl?: string | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetOwnerActivityDetailsQueryVariables = Exact<{
  ownerId?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
}>

export type GetOwnerActivityDetailsQuery = {
  __typename?: 'Query'
  getOwnerActivityDetails?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetActivityLogsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
}>

export type GetActivityLogsQuery = {
  __typename?: 'Query'
  getActivityLogs?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetUserIntegrationsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
}>

export type GetUserIntegrationsQuery = {
  __typename?: 'Query'
  getUserIntegrations?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetTackleMeetingsQueryVariables = Exact<{
  input?: InputMaybe<GetTackleMeetingsInput>
}>

export type GetTackleMeetingsQuery = {
  __typename?: 'Query'
  getTackleMeetings?:
    | {
        __typename?: 'GetTackleMeetingResponse'
        success: boolean
        message?: string | null | undefined
        pagination?: string | null | undefined
        error?: string | null | undefined
        data?:
          | Array<
              | {
                  __typename?: 'CalendarEvent'
                  summary?: string | null | undefined
                  description?: string | null | undefined
                  status?: string | null | undefined
                  eventTime?: string | null | undefined
                  meta?: string | null | undefined
                  location?: string | null | undefined
                  eventType?: string | null | undefined
                  advisorProfiles?: string | null | undefined
                  profile?: string | null | undefined
                  timezone?: string | null | undefined
                  eventId?: string | null | undefined
                  inviteeId?: string | null | undefined
                  advisorId?: string | null | undefined
                  availability?: string | null | undefined
                  comment?: string | null | undefined
                  start?:
                    | {
                        __typename?: 'CalendarEventDate'
                        dateTime?: string | null | undefined
                        timeZone?: string | null | undefined
                      }
                    | null
                    | undefined
                  end?:
                    | {
                        __typename?: 'CalendarEventDate'
                        dateTime?: string | null | undefined
                        timeZone?: string | null | undefined
                      }
                    | null
                    | undefined
                  attendees?:
                    | Array<
                        | {
                            __typename?: 'CalendarEventAttendee'
                            email?: string | null | undefined
                            responseStatus?: string | null | undefined
                            optional?: boolean | null | undefined
                          }
                        | null
                        | undefined
                      >
                    | null
                    | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type DeauthenticateConnectorMutationVariables = Exact<{
  input?: InputMaybe<DeauthenticateConnectorInput>
}>

export type DeauthenticateConnectorMutation = {
  __typename?: 'Mutation'
  deauthenticateConnector?:
    | {
        __typename?: 'ApiResponse'
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetAllToolsQueryVariables = Exact<{
  tenantId?: InputMaybe<Scalars['String']>
  includeDetails?: InputMaybe<Scalars['Boolean']>
}>

export type GetAllToolsQuery = {
  __typename?: 'Query'
  getAllTools?:
    | {
        __typename?: 'GetAllToolsResponse'
        success?: boolean | null | undefined
        message?: string | null | undefined
        error?: string | null | undefined
        data?:
          | Array<
              | {
                  __typename?: 'Tool'
                  id?: string | null | undefined
                  cyclrToolId?: string | null | undefined
                  imageUrl?: string | null | undefined
                  toolName?: string | null | undefined
                  userToolsImageUrl?: string | null | undefined
                  toolUrl?: string | null | undefined
                  isConnected?: boolean | null | undefined
                  isRecommended?: boolean | null | undefined
                  recommendedBy?: string | null | undefined
                  isSelected?: boolean | null | undefined
                  group?: string | null | undefined
                  category?: string | null | undefined
                  visible?: boolean | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type UpdateOwnerMutationVariables = Exact<{
  input?: InputMaybe<UpdateOwnerInput>
}>

export type UpdateOwnerMutation = {
  __typename?: 'Mutation'
  updateOwner?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetUserToolsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
}>

export type GetUserToolsQuery = {
  __typename?: 'Query'
  getUserTools?:
    | {
        __typename?: 'GetUserToolsResponse'
        success?: boolean | null | undefined
        message?: string | null | undefined
        error?: string | null | undefined
        data?:
          | Array<
              | {
                  __typename?: 'Tool'
                  id?: string | null | undefined
                  cyclrToolId?: string | null | undefined
                  imageUrl?: string | null | undefined
                  toolName?: string | null | undefined
                  userToolsImageUrl?: string | null | undefined
                  toolUrl?: string | null | undefined
                  isConnected?: boolean | null | undefined
                  isRecommended?: boolean | null | undefined
                  recommendedBy?: string | null | undefined
                  isSelected?: boolean | null | undefined
                  group?: string | null | undefined
                  category?: string | null | undefined
                  visible?: boolean | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetAttestationReportsQueryVariables = Exact<{
  input: GetAttestationReportsInput
}>

export type GetAttestationReportsQuery = {
  __typename?: 'Query'
  getAttestationReports?:
    | {
        __typename?: 'GetAttestationReportsResponse'
        error?: string | null | undefined
        data?:
          | {
              __typename?: 'GetAttestationReportsResponseObj'
              lastEvaluatedKey?: string | null | undefined
              items?:
                | Array<
                    | {
                        __typename?: 'Attestation'
                        registerBusinessStructure?: string | null | undefined
                        month?: string | null | undefined
                        year?: string | null | undefined
                        contractors?: string | null | undefined
                        businessBankAccount?: string | null | undefined
                        jobsCreated?: string | null | undefined
                        volunteers?: string | null | undefined
                        partTimeEmployees?: string | null | undefined
                        fullTimeEmployees?: string | null | undefined
                        gender?: string | null | undefined
                        race?: string | null | undefined
                        education?: string | null | undefined
                        wages?: string | null | undefined
                        revenue?: string | null | undefined
                        debtFinancing?: string | null | undefined
                        financialGrowth?: string | null | undefined
                        newCustomer?: string | null | undefined
                        productSale?: string | null | undefined
                        serviceSale?: string | null | undefined
                        targetAudience?: string | null | undefined
                        physicalLocation?: string | null | undefined
                        businessName?: string | null | undefined
                        name?: string | null | undefined
                        signedDocumentUrl?: string | null | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type ToolConnectionSuccessMutationVariables = Exact<{
  input?: InputMaybe<ToolConnectionSuccessInput>
}>

export type ToolConnectionSuccessMutation = {
  __typename?: 'Mutation'
  toolConnectionSuccess?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GoalDetailsFieldsFragment = {
  __typename?: 'Goal'
  id: string
  name?: string | null | undefined
  description?: string | null | undefined
  isTopGoal?: boolean | null | undefined
  isActiveGoal?: boolean | null | undefined
  assessments?: string | null | undefined
  createdAt?: string | null | undefined
  orderOfSequence?: string | null | undefined
}

export type GetGoalDetailsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>
}>

export type GetGoalDetailsQuery = {
  __typename?: 'Query'
  getGoalDetails?:
    | {
        __typename?: 'Goal'
        id: string
        name?: string | null | undefined
        description?: string | null | undefined
        isTopGoal?: boolean | null | undefined
        isActiveGoal?: boolean | null | undefined
        assessments?: string | null | undefined
        createdAt?: string | null | undefined
        orderOfSequence?: string | null | undefined
      }
    | null
    | undefined
}

export type GetUserGoalsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
}>

export type GetUserGoalsQuery = {
  __typename?: 'Query'
  getUserGoals?:
    | Array<
        | {
            __typename?: 'Goal'
            id: string
            name?: string | null | undefined
            description?: string | null | undefined
            isTopGoal?: boolean | null | undefined
            isActiveGoal?: boolean | null | undefined
            assessments?: string | null | undefined
            createdAt?: string | null | undefined
            orderOfSequence?: string | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type ChartDataFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type GetChartDataQueryVariables = Exact<{
  input: GetChartDataInput
}>

export type GetChartDataQuery = {
  __typename?: 'Query'
  getChartData?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type OwnerBusinessDataFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type GetOwnerBusinessDataQueryVariables = Exact<{
  input?: InputMaybe<GetOwnerBusinessDataInput>
}>

export type GetOwnerBusinessDataQuery = {
  __typename?: 'Query'
  getOwnerBusinessData?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetPlayFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type GetPlaysFieldsFragment = {
  __typename?: 'GetPlaysResponse'
  success: boolean
  message?: string | null | undefined
  error?: string | null | undefined
  data?:
    | Array<
        | {
            __typename?: 'Play'
            id: string
            content?: string | null | undefined
            title?: string | null | undefined
            type?: string | null | undefined
            recommendedBy?: string | null | undefined
            meta?: string | null | undefined
            guide?: string | null | undefined
            guide1?: string | null | undefined
            setup?: string | null | undefined
            use?: string | null | undefined
            description?: string | null | undefined
            playTabs?: string | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type AuthorizeConnectorFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type GetPlayQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>
}>

export type GetPlayQuery = {
  __typename?: 'Query'
  getPlay?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetPlaysQueryVariables = Exact<{
  input?: InputMaybe<GetPlaysInput>
}>

export type GetPlaysQuery = {
  __typename?: 'Query'
  getPlays?:
    | {
        __typename?: 'GetPlaysResponse'
        success: boolean
        message?: string | null | undefined
        error?: string | null | undefined
        data?:
          | Array<
              | {
                  __typename?: 'Play'
                  id: string
                  content?: string | null | undefined
                  title?: string | null | undefined
                  type?: string | null | undefined
                  recommendedBy?: string | null | undefined
                  meta?: string | null | undefined
                  guide?: string | null | undefined
                  guide1?: string | null | undefined
                  setup?: string | null | undefined
                  use?: string | null | undefined
                  description?: string | null | undefined
                  playTabs?: string | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type AuthorizeConnectorMutationVariables = Exact<{
  authValue: Scalars['String']
}>

export type AuthorizeConnectorMutation = {
  __typename?: 'Mutation'
  authorizeConnector?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type DashboardListingFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type AssessmentPlayListingFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type GetDashboardListingQueryVariables = Exact<{
  tenantId: Scalars['String']
  category?: InputMaybe<Scalars['String']>
}>

export type GetDashboardListingQuery = {
  __typename?: 'Query'
  getDashboardListing?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetAssessmentPlayListingQueryVariables = Exact<{
  tenantId: Scalars['String']
}>

export type GetAssessmentPlayListingQuery = {
  __typename?: 'Query'
  getAssessmentPlayListing?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetToolsFieldsFragment = {
  __typename?: 'GetToolsResponse'
  success: boolean
  message?: string | null | undefined
  error?: string | null | undefined
  data?:
    | Array<
        | {
            __typename?: 'Tool'
            id?: string | null | undefined
            toolName?: string | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type GetToolsQueryVariables = Exact<{ [key: string]: never }>

export type GetToolsQuery = {
  __typename?: 'Query'
  getTools?:
    | {
        __typename?: 'GetToolsResponse'
        success: boolean
        message?: string | null | undefined
        error?: string | null | undefined
        data?:
          | Array<
              | {
                  __typename?: 'Tool'
                  id?: string | null | undefined
                  toolName?: string | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type ToolFieldsFragment = {
  __typename?: 'GetToolsByTypeResponse'
  error?: string | null | undefined
  data?:
    | Array<
        | {
            __typename?: 'Tool'
            id?: string | null | undefined
            cyclrToolId?: string | null | undefined
            imageUrl?: string | null | undefined
            toolName?: string | null | undefined
            userToolsImageUrl?: string | null | undefined
            toolUrl?: string | null | undefined
            isConnected?: boolean | null | undefined
            isRecommended?: boolean | null | undefined
            recommendedBy?: string | null | undefined
            isSelected?: boolean | null | undefined
            group?: string | null | undefined
            category?: string | null | undefined
            visible?: boolean | null | undefined
            toolType?: ToolType | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type GetToolsByTypeQueryVariables = Exact<{
  input: GetToolsByTypeInput
}>

export type GetToolsByTypeQuery = {
  __typename?: 'Query'
  getToolsByType?:
    | {
        __typename?: 'GetToolsByTypeResponse'
        error?: string | null | undefined
        data?:
          | Array<
              | {
                  __typename?: 'Tool'
                  id?: string | null | undefined
                  cyclrToolId?: string | null | undefined
                  imageUrl?: string | null | undefined
                  toolName?: string | null | undefined
                  userToolsImageUrl?: string | null | undefined
                  toolUrl?: string | null | undefined
                  isConnected?: boolean | null | undefined
                  isRecommended?: boolean | null | undefined
                  recommendedBy?: string | null | undefined
                  isSelected?: boolean | null | undefined
                  group?: string | null | undefined
                  category?: string | null | undefined
                  visible?: boolean | null | undefined
                  toolType?: ToolType | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type GoalSignedUrlFieldsFragment = {
  __typename?: 'SignedUrlResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type CognitoUserFieldsFragment = {
  __typename?: 'CognitoUser'
  email: string
  userName: string
  enabled?: boolean | null | undefined
  userStatus?: string | null | undefined
  name?: string | null | undefined
}

export type GetUserFilesFiledsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type SaveUserFileFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type GetSignedUrlQueryVariables = Exact<{
  contentType?: InputMaybe<Scalars['String']>
  fileName?: InputMaybe<Scalars['String']>
}>

export type GetSignedUrlQuery = {
  __typename?: 'Query'
  getSignedUrl?:
    | {
        __typename?: 'SignedUrlResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetUserFilesQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>
  tenantId: Scalars['String']
}>

export type GetUserFilesQuery = {
  __typename?: 'Query'
  getUserFiles?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetFileQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>
}>

export type GetFileQuery = {
  __typename?: 'Query'
  getFile?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type SaveUserFileMutationVariables = Exact<{
  input: Array<SaveUserFileInput> | SaveUserFileInput
}>

export type SaveUserFileMutation = {
  __typename?: 'Mutation'
  saveUserFile?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type BigQueryFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type BusinessesFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type AdvisorsFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type LogActivityFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type UserNotificationsFieldsFragment = {
  __typename?: 'GetUserNotificationsResponse'
  error?: string | null | undefined
  data?:
    | {
        __typename?: 'UserNotificationData'
        lastEvaluatedKey?: string | null | undefined
        totalCount?: number | null | undefined
        notifications?:
          | Array<
              | {
                  __typename?: 'UserNotification'
                  id: string
                  createdAt?: string | null | undefined
                  meta?: string | null | undefined
                  notificationType: string
                  sender?: string | null | undefined
                  status?: string | null | undefined
                  firstName?: string | null | undefined
                  lastName?: string | null | undefined
                  updatedAt?: string | null | undefined
                  businessName?: string | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type UserNotificationMutateFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type GetClientsSummaryFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type ResendUserInviteFieldsFragment = {
  __typename?: 'ApiResponse'
  success: boolean
  message?: string | null | undefined
  data?: string | null | undefined
  error?: string | null | undefined
}

export type SaveAttestationFieldsFragment = {
  __typename?: 'AttestationResponse'
  error?: string | null | undefined
  data?:
    | {
        __typename?: 'Attestation'
        pk?: string | null | undefined
        sk?: string | null | undefined
        id?: string | null | undefined
        tenantId?: string | null | undefined
        registerBusinessStructure?: string | null | undefined
        month?: string | null | undefined
        year?: string | null | undefined
        contractors?: string | null | undefined
        businessBankAccount?: string | null | undefined
        jobsCreated?: string | null | undefined
        volunteers?: string | null | undefined
        partTimeEmployees?: string | null | undefined
        fullTimeEmployees?: string | null | undefined
        gender?: string | null | undefined
        race?: string | null | undefined
        education?: string | null | undefined
        wages?: string | null | undefined
        revenue?: string | null | undefined
        debtFinancing?: string | null | undefined
        financialGrowth?: string | null | undefined
        newCustomer?: string | null | undefined
        productSale?: string | null | undefined
        serviceSale?: string | null | undefined
        targetAudience?: string | null | undefined
        physicalLocation?: string | null | undefined
        url?: string | null | undefined
      }
    | null
    | undefined
}

export type GetBusinessesQueryVariables = Exact<{
  getBusinessesInput: GetBusinessesInput
}>

export type GetBusinessesQuery = {
  __typename?: 'Query'
  getBusinesses?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type ListCognitoUsersMutationVariables = Exact<{
  input: ListCognitoUsersInput
}>

export type ListCognitoUsersMutation = {
  __typename?: 'Mutation'
  listCognitoUsers: Array<
    | {
        __typename?: 'CognitoUser'
        email: string
        userName: string
        enabled?: boolean | null | undefined
        userStatus?: string | null | undefined
        name?: string | null | undefined
      }
    | null
    | undefined
  >
}

export type AddUserToGroupMutationVariables = Exact<{
  input: AddUserToGroupInput
}>

export type AddUserToGroupMutation = { __typename?: 'Mutation'; addUserToGroup: boolean }

export type UpdateUserPasswordMutationVariables = Exact<{
  input: UpdateUserPasswordInput
}>

export type UpdateUserPasswordMutation = {
  __typename?: 'Mutation'
  updateUserPassword?: boolean | null | undefined
}

export type GetBigQueryQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>
}>

export type GetBigQueryQuery = {
  __typename?: 'Query'
  getBigQuery?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetAdvisorsQueryVariables = Exact<{
  getAdvisorsInput?: InputMaybe<GetAdvisorsInput>
}>

export type GetAdvisorsQuery = {
  __typename?: 'Query'
  getAdvisors?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type LogActivityMutationVariables = Exact<{
  activityLogInput: ActivityLogInput
}>

export type LogActivityMutation = {
  __typename?: 'Mutation'
  logActivity?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type GetUserNotificationsQueryVariables = Exact<{
  input?: InputMaybe<GetUserNotificationsInput>
}>

export type GetUserNotificationsQuery = {
  __typename?: 'Query'
  getUserNotifications?:
    | {
        __typename?: 'GetUserNotificationsResponse'
        error?: string | null | undefined
        data?:
          | {
              __typename?: 'UserNotificationData'
              lastEvaluatedKey?: string | null | undefined
              totalCount?: number | null | undefined
              notifications?:
                | Array<
                    | {
                        __typename?: 'UserNotification'
                        id: string
                        createdAt?: string | null | undefined
                        meta?: string | null | undefined
                        notificationType: string
                        sender?: string | null | undefined
                        status?: string | null | undefined
                        firstName?: string | null | undefined
                        lastName?: string | null | undefined
                        updatedAt?: string | null | undefined
                        businessName?: string | null | undefined
                      }
                    | null
                    | undefined
                  >
                | null
                | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type GetClientsSummaryQueryVariables = Exact<{
  tenantId?: InputMaybe<Scalars['String']>
}>

export type GetClientsSummaryQuery = {
  __typename?: 'Query'
  getClientsSummary?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type UpdateUserNotificationsMutationVariables = Exact<{
  input?: InputMaybe<UpdateUserNotificationsInput>
}>

export type UpdateUserNotificationsMutation = {
  __typename?: 'Mutation'
  updateUserNotifications?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type DeleteUserNotificationsMutationVariables = Exact<{
  input?: InputMaybe<DeleteUserNotificationsInput>
}>

export type DeleteUserNotificationsMutation = {
  __typename?: 'Mutation'
  deleteUserNotifications?:
    | {
        __typename?: 'ApiResponse'
        success: boolean
        message?: string | null | undefined
        data?: string | null | undefined
        error?: string | null | undefined
      }
    | null
    | undefined
}

export type ResendUserInviteMutationVariables = Exact<{
  input: ResendUserInviteInput
}>

export type ResendUserInviteMutation = {
  __typename?: 'Mutation'
  resendUserInvite?: boolean | null | undefined
}

export type SaveAttestationMutationVariables = Exact<{
  input: AttestationInput
}>

export type SaveAttestationMutation = {
  __typename?: 'Mutation'
  saveAttestation?:
    | {
        __typename?: 'AttestationResponse'
        error?: string | null | undefined
        data?:
          | {
              __typename?: 'Attestation'
              pk?: string | null | undefined
              sk?: string | null | undefined
              id?: string | null | undefined
              tenantId?: string | null | undefined
              registerBusinessStructure?: string | null | undefined
              month?: string | null | undefined
              year?: string | null | undefined
              contractors?: string | null | undefined
              businessBankAccount?: string | null | undefined
              jobsCreated?: string | null | undefined
              volunteers?: string | null | undefined
              partTimeEmployees?: string | null | undefined
              fullTimeEmployees?: string | null | undefined
              gender?: string | null | undefined
              race?: string | null | undefined
              education?: string | null | undefined
              wages?: string | null | undefined
              revenue?: string | null | undefined
              debtFinancing?: string | null | undefined
              financialGrowth?: string | null | undefined
              newCustomer?: string | null | undefined
              productSale?: string | null | undefined
              serviceSale?: string | null | undefined
              targetAudience?: string | null | undefined
              physicalLocation?: string | null | undefined
              url?: string | null | undefined
            }
          | null
          | undefined
      }
    | null
    | undefined
}

export type UserProfileFieldsFragment = {
  __typename?: 'UserProfile'
  id: string
  name?: string | null | undefined
  firstName?: string | null | undefined
  lastName?: string | null | undefined
  email: string
  createdAt: string
  updatedAt: string
  websiteURL?: string | null | undefined
  bio?: string | null | undefined
  roles?: Array<string | null | undefined> | null | undefined
  isNewUser?: boolean | null | undefined
  phone?: string | null | undefined
  businessName?: string | null | undefined
  mobileContactNumber?: string | null | undefined
  yearsInBusiness?: string | null | undefined
  assessmentResponse?: string | null | undefined
  companyEmployees?: number | null | undefined
  companyAdvisor?: string | null | undefined
  schedulingUrl?: string | null | undefined
  calendlyEventsUrl?: string | null | undefined
  tenantId?: Array<string | null | undefined> | null | undefined
  streamAccessToken?: string | null | undefined
  industry?: string | null | undefined
  topGoal?: string | null | undefined
  firstSigninDate?: string | null | undefined
  status?: string | null | undefined
  ownerExperience?: string | null | undefined
  submissionStartDay?: number | null | undefined
  submissionEndDay?: number | null | undefined
  submissionReminderDay?: number | null | undefined
  attestationStatus?: string | null | undefined
  isSmsEnabled?: boolean | null | undefined
  defaultVideoLinkDescription?: string | null | undefined
  organizationName?: string | null | undefined
  timezone?: string | null | undefined
  isSmsOptedIn?: boolean | null | undefined
  interimTimezone?: string | null | undefined
}

export type BoAdvisorsFieldsFragment = {
  __typename?: 'BOAdvisorsResponse'
  success: boolean
  message?: string | null | undefined
  error?: string | null | undefined
  data?:
    | Array<
        | {
            __typename?: 'Advisors'
            id: string
            firstName?: string | null | undefined
            lastName?: string | null | undefined
            email?: string | null | undefined
            schedulingUrl?: string | null | undefined
          }
        | null
        | undefined
      >
    | null
    | undefined
}

export type SaveAssessmentResponseInputFragment = {
  __typename?: 'AssessmentResponse'
  currentStage?: string | null | undefined
  type?: string | null | undefined
  assessmentResponse?: string | null | undefined
  userId?: string | null | undefined
  createdAt?: string | null | undefined
  updatedAt?: string | null | undefined
}

export type AdvisorProfileFieldsFragment = {
  __typename?: 'AdvisorProfile'
  id?: string | null | undefined
  firstName?: string | null | undefined
  lastName?: string | null | undefined
  defaultVideoLinkDescription?: string | null | undefined
}

export type GetMyProfileQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
}>

export type GetMyProfileQuery = {
  __typename?: 'Query'
  getMyProfile: {
    __typename?: 'UserProfile'
    id: string
    name?: string | null | undefined
    firstName?: string | null | undefined
    lastName?: string | null | undefined
    email: string
    createdAt: string
    updatedAt: string
    websiteURL?: string | null | undefined
    bio?: string | null | undefined
    roles?: Array<string | null | undefined> | null | undefined
    isNewUser?: boolean | null | undefined
    phone?: string | null | undefined
    businessName?: string | null | undefined
    mobileContactNumber?: string | null | undefined
    yearsInBusiness?: string | null | undefined
    assessmentResponse?: string | null | undefined
    companyEmployees?: number | null | undefined
    companyAdvisor?: string | null | undefined
    schedulingUrl?: string | null | undefined
    calendlyEventsUrl?: string | null | undefined
    tenantId?: Array<string | null | undefined> | null | undefined
    streamAccessToken?: string | null | undefined
    industry?: string | null | undefined
    topGoal?: string | null | undefined
    firstSigninDate?: string | null | undefined
    status?: string | null | undefined
    ownerExperience?: string | null | undefined
    submissionStartDay?: number | null | undefined
    submissionEndDay?: number | null | undefined
    submissionReminderDay?: number | null | undefined
    attestationStatus?: string | null | undefined
    isSmsEnabled?: boolean | null | undefined
    defaultVideoLinkDescription?: string | null | undefined
    organizationName?: string | null | undefined
    timezone?: string | null | undefined
    isSmsOptedIn?: boolean | null | undefined
    interimTimezone?: string | null | undefined
  }
}

export type GetBoAdvisorsQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['String']>
  tenantId?: InputMaybe<Scalars['String']>
}>

export type GetBoAdvisorsQuery = {
  __typename?: 'Query'
  getBoAdvisors?:
    | {
        __typename?: 'BOAdvisorsResponse'
        success: boolean
        message?: string | null | undefined
        error?: string | null | undefined
        data?:
          | Array<
              | {
                  __typename?: 'Advisors'
                  id: string
                  firstName?: string | null | undefined
                  lastName?: string | null | undefined
                  email?: string | null | undefined
                  schedulingUrl?: string | null | undefined
                }
              | null
              | undefined
            >
          | null
          | undefined
      }
    | null
    | undefined
}

export type UpdateMyProfileMutationVariables = Exact<{
  input: UpdateMyProfileInput
}>

export type UpdateMyProfileMutation = {
  __typename?: 'Mutation'
  updateMyProfile?:
    | {
        __typename?: 'UserProfile'
        id: string
        name?: string | null | undefined
        firstName?: string | null | undefined
        lastName?: string | null | undefined
        email: string
        createdAt: string
        updatedAt: string
        websiteURL?: string | null | undefined
        bio?: string | null | undefined
        roles?: Array<string | null | undefined> | null | undefined
        isNewUser?: boolean | null | undefined
        phone?: string | null | undefined
        businessName?: string | null | undefined
        mobileContactNumber?: string | null | undefined
        yearsInBusiness?: string | null | undefined
        assessmentResponse?: string | null | undefined
        companyEmployees?: number | null | undefined
        companyAdvisor?: string | null | undefined
        schedulingUrl?: string | null | undefined
        calendlyEventsUrl?: string | null | undefined
        tenantId?: Array<string | null | undefined> | null | undefined
        streamAccessToken?: string | null | undefined
        industry?: string | null | undefined
        topGoal?: string | null | undefined
        firstSigninDate?: string | null | undefined
        status?: string | null | undefined
        ownerExperience?: string | null | undefined
        submissionStartDay?: number | null | undefined
        submissionEndDay?: number | null | undefined
        submissionReminderDay?: number | null | undefined
        attestationStatus?: string | null | undefined
        isSmsEnabled?: boolean | null | undefined
        defaultVideoLinkDescription?: string | null | undefined
        organizationName?: string | null | undefined
        timezone?: string | null | undefined
        isSmsOptedIn?: boolean | null | undefined
        interimTimezone?: string | null | undefined
      }
    | null
    | undefined
}

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput
}>

export type CreateUserMutation = {
  __typename?: 'Mutation'
  createUser?:
    | {
        __typename?: 'UserProfile'
        id: string
        name?: string | null | undefined
        firstName?: string | null | undefined
        lastName?: string | null | undefined
        email: string
        createdAt: string
        updatedAt: string
        websiteURL?: string | null | undefined
        bio?: string | null | undefined
        roles?: Array<string | null | undefined> | null | undefined
        isNewUser?: boolean | null | undefined
        phone?: string | null | undefined
        businessName?: string | null | undefined
        mobileContactNumber?: string | null | undefined
        yearsInBusiness?: string | null | undefined
        assessmentResponse?: string | null | undefined
        companyEmployees?: number | null | undefined
        companyAdvisor?: string | null | undefined
        schedulingUrl?: string | null | undefined
        calendlyEventsUrl?: string | null | undefined
        tenantId?: Array<string | null | undefined> | null | undefined
        streamAccessToken?: string | null | undefined
        industry?: string | null | undefined
        topGoal?: string | null | undefined
        firstSigninDate?: string | null | undefined
        status?: string | null | undefined
        ownerExperience?: string | null | undefined
        submissionStartDay?: number | null | undefined
        submissionEndDay?: number | null | undefined
        submissionReminderDay?: number | null | undefined
        attestationStatus?: string | null | undefined
        isSmsEnabled?: boolean | null | undefined
        defaultVideoLinkDescription?: string | null | undefined
        organizationName?: string | null | undefined
        timezone?: string | null | undefined
        isSmsOptedIn?: boolean | null | undefined
        interimTimezone?: string | null | undefined
      }
    | null
    | undefined
}

export type GetAdvisorProfileQueryVariables = Exact<{
  input: GetAdvisorProfileInput
}>

export type GetAdvisorProfileQuery = {
  __typename?: 'Query'
  getAdvisorProfile?:
    | {
        __typename?: 'AdvisorProfile'
        id?: string | null | undefined
        firstName?: string | null | undefined
        lastName?: string | null | undefined
        defaultVideoLinkDescription?: string | null | undefined
      }
    | null
    | undefined
}

export const AssessmentResponseFieldsFragmentDoc = `
    fragment assessmentResponseFields on AssessmentResponse {
  id
  currentStage
  playStage
  type
  userId
  assessmentResponse
  createdAt
  updatedAt
}
    `
export const AssessmentQuestionFieldsFragmentDoc = `
    fragment assessmentQuestionFields on Assessment {
  id
  tenantId
  previous
  assessmentType
  meta
  content
  optionType
  options
  createdAt
  updatedAt
  isVisible
}
    `
export const GetAssessmentResponseByQuestionFieldsFragmentDoc = `
    fragment getAssessmentResponseByQuestionFields on UserResponse {
  success
  message
  data {
    id
    value
    question
    meta
  }
  error
}
    `
export const UserAssessmentResponseFieldsFragmentDoc = `
    fragment userAssessmentResponseFields on UserAssessmentResponse {
  success
  message
  data {
    id
    currentStage
    playStage
    type
    userId
    assessmentResponse
    totalItems
    totalAttemptedItems
    createdAt
    updatedAt
    completedAt
  }
  error
}
    `
export const GetUserRecommendationsFieldsFragmentDoc = `
    fragment getUserRecommendationsFields on UserRecommendationsResponse {
  success
  message
  data
  error
}
    `
export const ListAssessmentsFieldsFragmentDoc = `
    fragment listAssessmentsFields on ListAssessmentResponse {
  success
  message
  data {
    type
    order
    isCompleted
    isLocked
  }
  error
}
    `
export const GetAttestationSummaryFieldsFragmentDoc = `
    fragment getAttestationSummaryFields on AttestationSummary {
  tenantId
  unsignedCount
  signedCount
  submissionStartDate
  submissionEndDate
  submissionReminderDate
  submissionReportDate
  newAggregatedReport {
    registerBusinessStructure
    month
    year
    contractors
    businessBankAccount
    jobsCreated
    volunteers
    partTimeEmployees
    fullTimeEmployees
    gender
    race
    education
    wages
    revenue
    debtFinancing
    financialGrowth
    newCustomer
    productSale
    serviceSale
    targetAudience
    physicalLocation
    businessName
    name
    signedDocumentUrl
    error
    createdAt
    downloadUrl
  }
  aggregationReportHistory {
    registerBusinessStructure
    month
    year
    contractors
    businessBankAccount
    jobsCreated
    volunteers
    partTimeEmployees
    fullTimeEmployees
    gender
    race
    education
    wages
    revenue
    debtFinancing
    financialGrowth
    newCustomer
    productSale
    serviceSale
    targetAudience
    physicalLocation
    businessName
    name
    signedDocumentUrl
    error
    createdAt
    downloadUrl
  }
}
    `
export const BusinessSupportOrganizationFieldsFragmentDoc = `
    fragment businessSupportOrganizationFields on BusinessSupportOrganization {
  id
  adminId
  name
  logoUrl
  terminology {
    term
    replacement
  }
}
    `
export const BusinessProfileFieldsFragmentDoc = `
    fragment businessProfileFields on BusinessProfile {
  id
  userName
  businessName
  mobileContactNumber
  yearsInBusiness
  companyEmployees
  createdAt
  updatedAt
  tenantId
  businessVision
  challenges
  otherGoals
  registerBusinessStructure
  month
  year
  contractors
  businessBankAccount
  jobsCreated
  volunteers
  partTimeEmployees
  fullTimeEmployees
  gender
  race
  education
  wages
  revenue
  debtFinancing
  financialGrowth
  newCustomer
  productSale
  serviceSale
  targetAudience
  physicalLocation
  additionalInfo
}
    `
export const CyclrConnectorFieldsFragmentDoc = `
    fragment cyclrConnectorFields on CyclrConnectorResponse {
  success
  message
  data {
    id
    name
    authenticated
    connectorId
  }
  error
}
    `
export const CalenderEventFieldsFragmentDoc = `
    fragment calenderEventFields on CalendarEventResponse {
  success
  message
  data {
    summary
    description
    start {
      dateTime
      timeZone
    }
    end {
      dateTime
      timeZone
    }
    status
    eventTime
    meta
    location
    eventType
    attendees {
      email
      responseStatus
      optional
    }
    advisorProfiles
    profile
    timezone
    eventId
    inviteeId
    advisorId
    availability
    videoLinkDescription
    id
  }
  error
}
    `
export const OAuthUrlFieldsFragmentDoc = `
    fragment oAuthUrlFields on OAuthUrlResponse {
  success
  message
  data
  error
}
    `
export const UserMeetingFieldsFragmentDoc = `
    fragment userMeetingFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const CalendlyUserFieldsFragmentDoc = `
    fragment calendlyUserFields on CalendlyUserResponse {
  success
  message
  data {
    email
    name
    schedulingUrl
    calendlyEventsUrl
  }
  error
}
    `
export const DeleteCalendarFieldsFragmentDoc = `
    fragment deleteCalendarFields on DeleteCalendarEventResponse {
  success
  message
  data
  error
}
    `
export const DeleteGoogleCalendarEventFieldsFragmentDoc = `
    fragment deleteGoogleCalendarEventFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const CancelOutlookEventFieldsFragmentDoc = `
    fragment cancelOutlookEventFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const UpdateGoogleCalendarEventFieldsFragmentDoc = `
    fragment updateGoogleCalendarEventFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const GetMeetingTypesFieldsFragmentDoc = `
    fragment getMeetingTypesFields on GetMeetingTypeResponse {
  success
  message
  data {
    id
    tenantId
    duration
    label
  }
  error
}
    `
export const SetUserAvailabilityFieldsFragmentDoc = `
    fragment setUserAvailabilityFields on SetUserAvailabilityResponse {
  data {
    createdAt
    updatedAt
    userId
    tenantId
    id
    availabilityHours {
      duration
      timezone
      timeSlots {
        label
        value
        slots {
          startTime
          endTime
        }
      }
    }
  }
  error
}
    `
export const GetUserAvailabilityFieldsFragmentDoc = `
    fragment getUserAvailabilityFields on GetUserAvailabilityResponse {
  data {
    duration
    availability {
      date
      slots {
        isAvailable
        slotStartTime
        slotEndTime
      }
    }
  }
  error
}
    `
export const SetUserMeetingResponseFieldsFragmentDoc = `
    fragment setUserMeetingResponseFields on SetUserMeetingResponse {
  error
  data {
    id
    eventId
    updatedAt
    createdAt
    participantId
    tenantId
    startTime
    endTime
    description
    ownerId
    inviteeId
    title
    timezone
    attendees
    role
    status
    location
    videoLinkDescription
  }
}
    `
export const UpdateUserCalendarFieldsFragmentDoc = `
    fragment UpdateUserCalendarFields on UpdateUserCalendarEventResponse {
  data {
    title
    eventId
    location
    tackleId
    eventType
    description
    comment
    attendees {
      email
      organizer
      self
      responseStatus
    }
    videoLinkDescription
  }
  error
}
    `
export const SubscribeGoogleCalendarWebhookFieldsFragmentDoc = `
    fragment subscribeGoogleCalendarWebhookFields on SubscribeGoogleCalendarWebhookResponse {
  data
  error
}
    `
export const CancelUserCalendarFieldsFragmentDoc = `
    fragment CancelUserCalendarFields on CancelUserCalendarEventResponse {
  data {
    tackleId
    eventId
    eventType
  }
  error
}
    `
export const UpdateUserAvailabilityFieldsFragmentDoc = `
    fragment updateUserAvailabilityFields on UpdateUserAvailabilityResponse {
  data {
    createdAt
    updatedAt
    userId
    tenantId
    id
    availabilityHours {
      duration
      timezone
      timeSlots {
        label
        value
        slots {
          startTime
          endTime
        }
      }
    }
  }
  error
}
    `
export const GetUserAvailabilityHoursFieldsFragmentDoc = `
    fragment getUserAvailabilityHoursFields on GetUserAvailabilityHoursResponse {
  data {
    createdAt
    updatedAt
    userId
    tenantId
    id
    availabilityHours {
      duration
      timezone
      timeSlots {
        label
        value
        slots {
          startTime
          endTime
        }
      }
    }
  }
  error
}
    `
export const GetUserMeetingByIdFieldsFragmentDoc = `
    fragment getUserMeetingByIdFields on GetUserMeetingByIdResponse {
  data {
    summary
    description
    start {
      dateTime
      timeZone
    }
    end {
      dateTime
      timeZone
    }
    status
    eventTime
    meta
    location
    eventType
    attendees {
      email
      responseStatus
      optional
    }
    advisorProfiles
    profile
    timezone
    eventId
    inviteeId
    advisorId
    availability
    comment
    videoLinkDescription
    id
  }
  error
}
    `
export const AdvisorDashboardFieldsFragmentDoc = `
    fragment AdvisorDashboardFields on ApiResponse {
  success
  message
  data
  data
  error
}
    `
export const TopActiveClientsFieldsFragmentDoc = `
    fragment TopActiveClientsFields on GetTopActiveClientsResponse {
  error
  data {
    id
    name
    tenantId
    email
    score
    totalTime
    topGoal
    industry
    businessName
  }
}
    `
export const GetUserToolsFieldsFragmentDoc = `
    fragment getUserToolsFields on GetUserToolsResponse {
  success
  message
  data {
    id
    cyclrToolId
    imageUrl
    toolName
    userToolsImageUrl
    toolUrl
    isConnected
    isRecommended
    recommendedBy
    isSelected
    group
    category
    visible
  }
  error
}
    `
export const ClientsFieldsFragmentDoc = `
    fragment ClientsFields on GetClientsResponse {
  success
  message
  data {
    id
    name
    firstName
    lastName
    email
    createdAt
    updatedAt
    websiteURL
    bio
    roles
    phone
    isNewUser
    businessName
    mobileContactNumber
    yearsInBusiness
    assessmentResponse
    companyAdvisor
    schedulingUrl
    calendlyEventsUrl
  }
  error
}
    `
export const TackleMeetingFieldsFragmentDoc = `
    fragment tackleMeetingFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const ActivityLogFieldsFragmentDoc = `
    fragment activityLogFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const OwnerActivityDetailsFieldsFragmentDoc = `
    fragment ownerActivityDetailsFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const UserIntegrationFieldsFragmentDoc = `
    fragment userIntegrationFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const TackleMeetingsFieldsFragmentDoc = `
    fragment tackleMeetingsFields on GetTackleMeetingResponse {
  success
  message
  data {
    summary
    description
    start {
      dateTime
      timeZone
    }
    end {
      dateTime
      timeZone
    }
    status
    eventTime
    meta
    location
    eventType
    attendees {
      email
      responseStatus
      optional
    }
    advisorProfiles
    profile
    timezone
    eventId
    inviteeId
    advisorId
    availability
    comment
  }
  pagination
  error
}
    `
export const DeauthenticateConnectorFieldsFragmentDoc = `
    fragment deauthenticateConnectorFields on ApiResponse {
  message
  data
  error
}
    `
export const GetAllToolsFieldsFragmentDoc = `
    fragment getAllToolsFields on GetAllToolsResponse {
  success
  message
  data {
    id
    cyclrToolId
    imageUrl
    toolName
    userToolsImageUrl
    toolUrl
    isConnected
    isRecommended
    recommendedBy
    isSelected
    group
    category
    visible
  }
  error
}
    `
export const GetAttestationReportsFieldsFragmentDoc = `
    fragment getAttestationReportsFields on GetAttestationReportsResponse {
  error
  data {
    lastEvaluatedKey
    items {
      registerBusinessStructure
      month
      year
      contractors
      businessBankAccount
      jobsCreated
      volunteers
      partTimeEmployees
      fullTimeEmployees
      gender
      race
      education
      wages
      revenue
      debtFinancing
      financialGrowth
      newCustomer
      productSale
      serviceSale
      targetAudience
      physicalLocation
      businessName
      name
      signedDocumentUrl
    }
  }
}
    `
export const UpdateOwnerFieldsFragmentDoc = `
    fragment updateOwnerFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const GoalDetailsFieldsFragmentDoc = `
    fragment goalDetailsFields on Goal {
  id
  name
  description
  isTopGoal
  isActiveGoal
  assessments
  createdAt
  orderOfSequence
}
    `
export const ChartDataFieldsFragmentDoc = `
    fragment chartDataFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const OwnerBusinessDataFieldsFragmentDoc = `
    fragment ownerBusinessDataFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const GetPlayFieldsFragmentDoc = `
    fragment getPlayFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const GetPlaysFieldsFragmentDoc = `
    fragment getPlaysFields on GetPlaysResponse {
  success
  message
  data {
    id
    content
    title
    type
    recommendedBy
    meta
    guide
    guide1
    setup
    use
    description
    playTabs
  }
  error
}
    `
export const AuthorizeConnectorFieldsFragmentDoc = `
    fragment authorizeConnectorFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const DashboardListingFieldsFragmentDoc = `
    fragment dashboardListingFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const AssessmentPlayListingFieldsFragmentDoc = `
    fragment assessmentPlayListingFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const GetToolsFieldsFragmentDoc = `
    fragment getToolsFields on GetToolsResponse {
  success
  message
  data {
    id
    toolName
  }
  error
}
    `
export const ToolFieldsFragmentDoc = `
    fragment toolFields on GetToolsByTypeResponse {
  error
  data {
    id
    cyclrToolId
    imageUrl
    toolName
    userToolsImageUrl
    toolUrl
    isConnected
    isRecommended
    recommendedBy
    isSelected
    group
    category
    visible
    toolType
  }
}
    `
export const GoalSignedUrlFieldsFragmentDoc = `
    fragment goalSignedUrlFields on SignedUrlResponse {
  success
  message
  data
  error
}
    `
export const CognitoUserFieldsFragmentDoc = `
    fragment cognitoUserFields on CognitoUser {
  email
  userName
  enabled
  userStatus
  name
}
    `
export const GetUserFilesFiledsFragmentDoc = `
    fragment getUserFilesFileds on ApiResponse {
  success
  message
  data
  error
}
    `
export const SaveUserFileFieldsFragmentDoc = `
    fragment saveUserFileFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const BigQueryFieldsFragmentDoc = `
    fragment bigQueryFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const BusinessesFieldsFragmentDoc = `
    fragment BusinessesFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const AdvisorsFieldsFragmentDoc = `
    fragment AdvisorsFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const LogActivityFieldsFragmentDoc = `
    fragment logActivityFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const UserNotificationsFieldsFragmentDoc = `
    fragment UserNotificationsFields on GetUserNotificationsResponse {
  error
  data {
    lastEvaluatedKey
    totalCount
    notifications {
      id
      createdAt
      meta
      notificationType
      sender
      status
      firstName
      lastName
      updatedAt
      businessName
    }
  }
}
    `
export const UserNotificationMutateFieldsFragmentDoc = `
    fragment UserNotificationMutateFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const GetClientsSummaryFieldsFragmentDoc = `
    fragment GetClientsSummaryFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const ResendUserInviteFieldsFragmentDoc = `
    fragment resendUserInviteFields on ApiResponse {
  success
  message
  data
  error
}
    `
export const SaveAttestationFieldsFragmentDoc = `
    fragment saveAttestationFields on AttestationResponse {
  error
  data {
    pk
    sk
    id
    tenantId
    registerBusinessStructure
    month
    year
    contractors
    businessBankAccount
    jobsCreated
    volunteers
    partTimeEmployees
    fullTimeEmployees
    gender
    race
    education
    wages
    revenue
    debtFinancing
    financialGrowth
    newCustomer
    productSale
    serviceSale
    targetAudience
    physicalLocation
    url
  }
}
    `
export const UserProfileFieldsFragmentDoc = `
    fragment userProfileFields on UserProfile {
  id
  name
  firstName
  lastName
  email
  createdAt
  updatedAt
  websiteURL
  bio
  roles
  isNewUser
  phone
  businessName
  mobileContactNumber
  yearsInBusiness
  assessmentResponse
  companyEmployees
  companyAdvisor
  schedulingUrl
  calendlyEventsUrl
  tenantId
  streamAccessToken
  industry
  topGoal
  firstSigninDate
  status
  ownerExperience
  submissionStartDay
  submissionEndDay
  submissionReminderDay
  attestationStatus
  isSmsEnabled
  defaultVideoLinkDescription
  organizationName
  timezone
  isSmsOptedIn
  interimTimezone
}
    `
export const BoAdvisorsFieldsFragmentDoc = `
    fragment BoAdvisorsFields on BOAdvisorsResponse {
  success
  message
  data {
    id
    firstName
    lastName
    email
    schedulingUrl
  }
  error
}
    `
export const SaveAssessmentResponseInputFragmentDoc = `
    fragment SaveAssessmentResponseInput on AssessmentResponse {
  currentStage
  type
  assessmentResponse
  userId
  createdAt
  updatedAt
}
    `
export const AdvisorProfileFieldsFragmentDoc = `
    fragment advisorProfileFields on AdvisorProfile {
  id
  firstName
  lastName
  defaultVideoLinkDescription
}
    `
export const GetUserAssessmentResponseDocument = `
    query getUserAssessmentResponse($userId: String, $type: String) {
  getUserAssessmentResponse(type: $type, userId: $userId) {
    ...userAssessmentResponseFields
  }
}
    ${UserAssessmentResponseFieldsFragmentDoc}`
export const useGetUserAssessmentResponseQuery = <
  TData = GetUserAssessmentResponseQuery,
  TError = any
>(
  variables?: GetUserAssessmentResponseQueryVariables,
  options?: UseQueryOptions<GetUserAssessmentResponseQuery, TError, TData>
) =>
  useQuery<GetUserAssessmentResponseQuery, TError, TData>(
    variables === undefined
      ? ['getUserAssessmentResponse']
      : ['getUserAssessmentResponse', variables],
    fetcher<GetUserAssessmentResponseQuery, GetUserAssessmentResponseQueryVariables>(
      GetUserAssessmentResponseDocument
    ).bind(null, variables),
    options
  )
export const GetAssessmentsDocument = `
    query getAssessments($type: String) {
  getAssessments(type: $type) {
    ...assessmentQuestionFields
  }
}
    ${AssessmentQuestionFieldsFragmentDoc}`
export const useGetAssessmentsQuery = <TData = GetAssessmentsQuery, TError = any>(
  variables?: GetAssessmentsQueryVariables,
  options?: UseQueryOptions<GetAssessmentsQuery, TError, TData>
) =>
  useQuery<GetAssessmentsQuery, TError, TData>(
    variables === undefined ? ['getAssessments'] : ['getAssessments', variables],
    fetcher<GetAssessmentsQuery, GetAssessmentsQueryVariables>(GetAssessmentsDocument).bind(
      null,
      variables
    ),
    options
  )
export const SaveAssessmentResponseDocument = `
    mutation saveAssessmentResponse($input: SaveAssessmentResponseInput!) {
  saveAssessmentResponse(input: $input) {
    ...assessmentResponseFields
  }
}
    ${AssessmentResponseFieldsFragmentDoc}`
export const useSaveAssessmentResponseMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    SaveAssessmentResponseMutation,
    TError,
    SaveAssessmentResponseMutationVariables,
    TContext
  >
) =>
  useMutation<
    SaveAssessmentResponseMutation,
    TError,
    SaveAssessmentResponseMutationVariables,
    TContext
  >(
    ['saveAssessmentResponse'],
    fetcher<SaveAssessmentResponseMutation, SaveAssessmentResponseMutationVariables>(
      SaveAssessmentResponseDocument
    ),
    options
  )
export const GetAssessmentResponseByQuestionDocument = `
    query getAssessmentResponseByQuestion($type: String, $question: String, $userId: String) {
  getAssessmentResponseByQuestion(
    type: $type
    question: $question
    userId: $userId
  ) {
    ...getAssessmentResponseByQuestionFields
  }
}
    ${GetAssessmentResponseByQuestionFieldsFragmentDoc}`
export const useGetAssessmentResponseByQuestionQuery = <
  TData = GetAssessmentResponseByQuestionQuery,
  TError = any
>(
  variables?: GetAssessmentResponseByQuestionQueryVariables,
  options?: UseQueryOptions<GetAssessmentResponseByQuestionQuery, TError, TData>
) =>
  useQuery<GetAssessmentResponseByQuestionQuery, TError, TData>(
    variables === undefined
      ? ['getAssessmentResponseByQuestion']
      : ['getAssessmentResponseByQuestion', variables],
    fetcher<GetAssessmentResponseByQuestionQuery, GetAssessmentResponseByQuestionQueryVariables>(
      GetAssessmentResponseByQuestionDocument
    ).bind(null, variables),
    options
  )
export const GetUserRecommendationsDocument = `
    query getUserRecommendations {
  getUserRecommendations {
    ...getUserRecommendationsFields
  }
}
    ${GetUserRecommendationsFieldsFragmentDoc}`
export const useGetUserRecommendationsQuery = <TData = GetUserRecommendationsQuery, TError = any>(
  variables?: GetUserRecommendationsQueryVariables,
  options?: UseQueryOptions<GetUserRecommendationsQuery, TError, TData>
) =>
  useQuery<GetUserRecommendationsQuery, TError, TData>(
    variables === undefined ? ['getUserRecommendations'] : ['getUserRecommendations', variables],
    fetcher<GetUserRecommendationsQuery, GetUserRecommendationsQueryVariables>(
      GetUserRecommendationsDocument
    ).bind(null, variables),
    options
  )
export const ListAssessmentsDocument = `
    query listAssessments($userId: String, $tenantId: String!) {
  listAssessments(userId: $userId, tenantId: $tenantId) {
    ...listAssessmentsFields
  }
}
    ${ListAssessmentsFieldsFragmentDoc}`
export const useListAssessmentsQuery = <TData = ListAssessmentsQuery, TError = any>(
  variables: ListAssessmentsQueryVariables,
  options?: UseQueryOptions<ListAssessmentsQuery, TError, TData>
) =>
  useQuery<ListAssessmentsQuery, TError, TData>(
    ['listAssessments', variables],
    fetcher<ListAssessmentsQuery, ListAssessmentsQueryVariables>(ListAssessmentsDocument).bind(
      null,
      variables
    ),
    options
  )
export const GetAttestationSummaryDocument = `
    query getAttestationSummary($input: GetAttestationSummaryInput) {
  getAttestationSummary(input: $input) {
    ...getAttestationSummaryFields
  }
}
    ${GetAttestationSummaryFieldsFragmentDoc}`
export const useGetAttestationSummaryQuery = <TData = GetAttestationSummaryQuery, TError = any>(
  variables?: GetAttestationSummaryQueryVariables,
  options?: UseQueryOptions<GetAttestationSummaryQuery, TError, TData>
) =>
  useQuery<GetAttestationSummaryQuery, TError, TData>(
    variables === undefined ? ['getAttestationSummary'] : ['getAttestationSummary', variables],
    fetcher<GetAttestationSummaryQuery, GetAttestationSummaryQueryVariables>(
      GetAttestationSummaryDocument
    ).bind(null, variables),
    options
  )
export const GetBusinessSupportOrganizationDocument = `
    query getBusinessSupportOrganization($input: GetBusinessSupportOrganizationInput!) {
  getBusinessSupportOrganization(input: $input) {
    ...businessSupportOrganizationFields
  }
}
    ${BusinessSupportOrganizationFieldsFragmentDoc}`
export const useGetBusinessSupportOrganizationQuery = <
  TData = GetBusinessSupportOrganizationQuery,
  TError = any
>(
  variables: GetBusinessSupportOrganizationQueryVariables,
  options?: UseQueryOptions<GetBusinessSupportOrganizationQuery, TError, TData>
) =>
  useQuery<GetBusinessSupportOrganizationQuery, TError, TData>(
    ['getBusinessSupportOrganization', variables],
    fetcher<GetBusinessSupportOrganizationQuery, GetBusinessSupportOrganizationQueryVariables>(
      GetBusinessSupportOrganizationDocument
    ).bind(null, variables),
    options
  )
export const GetBusinessProfileDocument = `
    query getBusinessProfile($id: String) {
  getBusinessProfile(id: $id) {
    ...businessProfileFields
  }
}
    ${BusinessProfileFieldsFragmentDoc}`
export const useGetBusinessProfileQuery = <TData = GetBusinessProfileQuery, TError = any>(
  variables?: GetBusinessProfileQueryVariables,
  options?: UseQueryOptions<GetBusinessProfileQuery, TError, TData>
) =>
  useQuery<GetBusinessProfileQuery, TError, TData>(
    variables === undefined ? ['getBusinessProfile'] : ['getBusinessProfile', variables],
    fetcher<GetBusinessProfileQuery, GetBusinessProfileQueryVariables>(
      GetBusinessProfileDocument
    ).bind(null, variables),
    options
  )
export const UpdateBusinessProfileDocument = `
    mutation updateBusinessProfile($input: UpdateBusinessProfileInput!) {
  updateBusinessProfile(input: $input) {
    ...businessProfileFields
  }
}
    ${BusinessProfileFieldsFragmentDoc}`
export const useUpdateBusinessProfileMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateBusinessProfileMutation,
    TError,
    UpdateBusinessProfileMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateBusinessProfileMutation,
    TError,
    UpdateBusinessProfileMutationVariables,
    TContext
  >(
    ['updateBusinessProfile'],
    fetcher<UpdateBusinessProfileMutation, UpdateBusinessProfileMutationVariables>(
      UpdateBusinessProfileDocument
    ),
    options
  )
export const GetUserConnectorsDocument = `
    query getUserConnectors($connectorName: String) {
  getUserConnectors(connectorName: $connectorName) {
    ...cyclrConnectorFields
  }
}
    ${CyclrConnectorFieldsFragmentDoc}`
export const useGetUserConnectorsQuery = <TData = GetUserConnectorsQuery, TError = any>(
  variables?: GetUserConnectorsQueryVariables,
  options?: UseQueryOptions<GetUserConnectorsQuery, TError, TData>
) =>
  useQuery<GetUserConnectorsQuery, TError, TData>(
    variables === undefined ? ['getUserConnectors'] : ['getUserConnectors', variables],
    fetcher<GetUserConnectorsQuery, GetUserConnectorsQueryVariables>(
      GetUserConnectorsDocument
    ).bind(null, variables),
    options
  )
export const GetUserCalendarEventsDocument = `
    query getUserCalendarEvents($calendarName: String, $timeMin: String, $timeMax: String, $tenantId: String) {
  getUserCalendarEvents(
    calendarName: $calendarName
    timeMin: $timeMin
    timeMax: $timeMax
    tenantId: $tenantId
  ) {
    ...calenderEventFields
  }
}
    ${CalenderEventFieldsFragmentDoc}`
export const useGetUserCalendarEventsQuery = <TData = GetUserCalendarEventsQuery, TError = any>(
  variables?: GetUserCalendarEventsQueryVariables,
  options?: UseQueryOptions<GetUserCalendarEventsQuery, TError, TData>
) =>
  useQuery<GetUserCalendarEventsQuery, TError, TData>(
    variables === undefined ? ['getUserCalendarEvents'] : ['getUserCalendarEvents', variables],
    fetcher<GetUserCalendarEventsQuery, GetUserCalendarEventsQueryVariables>(
      GetUserCalendarEventsDocument
    ).bind(null, variables),
    options
  )
export const GetOAuthUrlDocument = `
    query getOAuthUrl($connectorName: String) {
  getOAuthUrl(connectorName: $connectorName) {
    ...oAuthUrlFields
  }
}
    ${OAuthUrlFieldsFragmentDoc}`
export const useGetOAuthUrlQuery = <TData = GetOAuthUrlQuery, TError = any>(
  variables?: GetOAuthUrlQueryVariables,
  options?: UseQueryOptions<GetOAuthUrlQuery, TError, TData>
) =>
  useQuery<GetOAuthUrlQuery, TError, TData>(
    variables === undefined ? ['getOAuthUrl'] : ['getOAuthUrl', variables],
    fetcher<GetOAuthUrlQuery, GetOAuthUrlQueryVariables>(GetOAuthUrlDocument).bind(null, variables),
    options
  )
export const GetCalendlyUserDocument = `
    query getCalendlyUser {
  getCalendlyUser {
    ...calendlyUserFields
  }
}
    ${CalendlyUserFieldsFragmentDoc}`
export const useGetCalendlyUserQuery = <TData = GetCalendlyUserQuery, TError = any>(
  variables?: GetCalendlyUserQueryVariables,
  options?: UseQueryOptions<GetCalendlyUserQuery, TError, TData>
) =>
  useQuery<GetCalendlyUserQuery, TError, TData>(
    variables === undefined ? ['getCalendlyUser'] : ['getCalendlyUser', variables],
    fetcher<GetCalendlyUserQuery, GetCalendlyUserQueryVariables>(GetCalendlyUserDocument).bind(
      null,
      variables
    ),
    options
  )
export const SubscribeCalendlyWebhookDocument = `
    query subscribeCalendlyWebhook($tenantId: String, $email: String) {
  subscribeCalendlyWebhook(tenantId: $tenantId, email: $email) {
    ...userMeetingFields
  }
}
    ${UserMeetingFieldsFragmentDoc}`
export const useSubscribeCalendlyWebhookQuery = <
  TData = SubscribeCalendlyWebhookQuery,
  TError = any
>(
  variables?: SubscribeCalendlyWebhookQueryVariables,
  options?: UseQueryOptions<SubscribeCalendlyWebhookQuery, TError, TData>
) =>
  useQuery<SubscribeCalendlyWebhookQuery, TError, TData>(
    variables === undefined
      ? ['subscribeCalendlyWebhook']
      : ['subscribeCalendlyWebhook', variables],
    fetcher<SubscribeCalendlyWebhookQuery, SubscribeCalendlyWebhookQueryVariables>(
      SubscribeCalendlyWebhookDocument
    ).bind(null, variables),
    options
  )
export const SubscribeGoogleCalendarWebhookDocument = `
    mutation subscribeGoogleCalendarWebhook($tenantId: String) {
  subscribeGoogleCalendarWebhook(tenantId: $tenantId) {
    ...subscribeGoogleCalendarWebhookFields
  }
}
    ${SubscribeGoogleCalendarWebhookFieldsFragmentDoc}`
export const useSubscribeGoogleCalendarWebhookMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    SubscribeGoogleCalendarWebhookMutation,
    TError,
    SubscribeGoogleCalendarWebhookMutationVariables,
    TContext
  >
) =>
  useMutation<
    SubscribeGoogleCalendarWebhookMutation,
    TError,
    SubscribeGoogleCalendarWebhookMutationVariables,
    TContext
  >(
    ['subscribeGoogleCalendarWebhook'],
    fetcher<
      SubscribeGoogleCalendarWebhookMutation,
      SubscribeGoogleCalendarWebhookMutationVariables
    >(SubscribeGoogleCalendarWebhookDocument),
    options
  )
export const GetUserMeetingDocument = `
    query getUserMeeting($advisorId: String) {
  getUserMeeting(advisorId: $advisorId) {
    ...userMeetingFields
  }
}
    ${UserMeetingFieldsFragmentDoc}`
export const useGetUserMeetingQuery = <TData = GetUserMeetingQuery, TError = any>(
  variables?: GetUserMeetingQueryVariables,
  options?: UseQueryOptions<GetUserMeetingQuery, TError, TData>
) =>
  useQuery<GetUserMeetingQuery, TError, TData>(
    variables === undefined ? ['getUserMeeting'] : ['getUserMeeting', variables],
    fetcher<GetUserMeetingQuery, GetUserMeetingQueryVariables>(GetUserMeetingDocument).bind(
      null,
      variables
    ),
    options
  )
export const DeleteCalendarEventDocument = `
    mutation deleteCalendarEvent($input: DeleteCalendarEventInput!) {
  deleteCalendarEvent(input: $input) {
    ...deleteCalendarFields
  }
}
    ${DeleteCalendarFieldsFragmentDoc}`
export const useDeleteCalendarEventMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteCalendarEventMutation,
    TError,
    DeleteCalendarEventMutationVariables,
    TContext
  >
) =>
  useMutation<DeleteCalendarEventMutation, TError, DeleteCalendarEventMutationVariables, TContext>(
    ['deleteCalendarEvent'],
    fetcher<DeleteCalendarEventMutation, DeleteCalendarEventMutationVariables>(
      DeleteCalendarEventDocument
    ),
    options
  )
export const DeleteGoogleCalendarEventDocument = `
    query deleteGoogleCalendarEvent($input: deleteGoogleCalendarEventInput) {
  deleteGoogleCalendarEvent(input: $input) {
    ...deleteGoogleCalendarEventFields
  }
}
    ${DeleteGoogleCalendarEventFieldsFragmentDoc}`
export const useDeleteGoogleCalendarEventQuery = <
  TData = DeleteGoogleCalendarEventQuery,
  TError = any
>(
  variables?: DeleteGoogleCalendarEventQueryVariables,
  options?: UseQueryOptions<DeleteGoogleCalendarEventQuery, TError, TData>
) =>
  useQuery<DeleteGoogleCalendarEventQuery, TError, TData>(
    variables === undefined
      ? ['deleteGoogleCalendarEvent']
      : ['deleteGoogleCalendarEvent', variables],
    fetcher<DeleteGoogleCalendarEventQuery, DeleteGoogleCalendarEventQueryVariables>(
      DeleteGoogleCalendarEventDocument
    ).bind(null, variables),
    options
  )
export const UpdateGoogleCalendarEventDocument = `
    query updateGoogleCalendarEvent($input: updateGoogleCalendarEventInput) {
  updateGoogleCalendarEvent(input: $input) {
    ...updateGoogleCalendarEventFields
  }
}
    ${UpdateGoogleCalendarEventFieldsFragmentDoc}`
export const useUpdateGoogleCalendarEventQuery = <
  TData = UpdateGoogleCalendarEventQuery,
  TError = any
>(
  variables?: UpdateGoogleCalendarEventQueryVariables,
  options?: UseQueryOptions<UpdateGoogleCalendarEventQuery, TError, TData>
) =>
  useQuery<UpdateGoogleCalendarEventQuery, TError, TData>(
    variables === undefined
      ? ['updateGoogleCalendarEvent']
      : ['updateGoogleCalendarEvent', variables],
    fetcher<UpdateGoogleCalendarEventQuery, UpdateGoogleCalendarEventQueryVariables>(
      UpdateGoogleCalendarEventDocument
    ).bind(null, variables),
    options
  )
export const CancelOutlookEventDocument = `
    query cancelOutlookEvent($input: cancelOutlookEventInput) {
  cancelOutlookEvent(input: $input) {
    ...cancelOutlookEventFields
  }
}
    ${CancelOutlookEventFieldsFragmentDoc}`
export const useCancelOutlookEventQuery = <TData = CancelOutlookEventQuery, TError = any>(
  variables?: CancelOutlookEventQueryVariables,
  options?: UseQueryOptions<CancelOutlookEventQuery, TError, TData>
) =>
  useQuery<CancelOutlookEventQuery, TError, TData>(
    variables === undefined ? ['cancelOutlookEvent'] : ['cancelOutlookEvent', variables],
    fetcher<CancelOutlookEventQuery, CancelOutlookEventQueryVariables>(
      CancelOutlookEventDocument
    ).bind(null, variables),
    options
  )
export const GetMeetingTypesDocument = `
    query getMeetingTypes($tenantId: String!) {
  getMeetingTypes(tenantId: $tenantId) {
    ...getMeetingTypesFields
  }
}
    ${GetMeetingTypesFieldsFragmentDoc}`
export const useGetMeetingTypesQuery = <TData = GetMeetingTypesQuery, TError = any>(
  variables: GetMeetingTypesQueryVariables,
  options?: UseQueryOptions<GetMeetingTypesQuery, TError, TData>
) =>
  useQuery<GetMeetingTypesQuery, TError, TData>(
    ['getMeetingTypes', variables],
    fetcher<GetMeetingTypesQuery, GetMeetingTypesQueryVariables>(GetMeetingTypesDocument).bind(
      null,
      variables
    ),
    options
  )
export const SetUserAvailabilityDocument = `
    mutation setUserAvailability($input: SetUserAvailabilityInput) {
  setUserAvailability(input: $input) {
    ...setUserAvailabilityFields
  }
}
    ${SetUserAvailabilityFieldsFragmentDoc}`
export const useSetUserAvailabilityMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    SetUserAvailabilityMutation,
    TError,
    SetUserAvailabilityMutationVariables,
    TContext
  >
) =>
  useMutation<SetUserAvailabilityMutation, TError, SetUserAvailabilityMutationVariables, TContext>(
    ['setUserAvailability'],
    fetcher<SetUserAvailabilityMutation, SetUserAvailabilityMutationVariables>(
      SetUserAvailabilityDocument
    ),
    options
  )
export const GetUserAvailabilityDocument = `
    query getUserAvailability($input: GetUserAvailabilityInput) {
  getUserAvailability(input: $input) {
    ...getUserAvailabilityFields
  }
}
    ${GetUserAvailabilityFieldsFragmentDoc}`
export const useGetUserAvailabilityQuery = <TData = GetUserAvailabilityQuery, TError = any>(
  variables?: GetUserAvailabilityQueryVariables,
  options?: UseQueryOptions<GetUserAvailabilityQuery, TError, TData>
) =>
  useQuery<GetUserAvailabilityQuery, TError, TData>(
    variables === undefined ? ['getUserAvailability'] : ['getUserAvailability', variables],
    fetcher<GetUserAvailabilityQuery, GetUserAvailabilityQueryVariables>(
      GetUserAvailabilityDocument
    ).bind(null, variables),
    options
  )
export const SetUserMeetingDocument = `
    mutation setUserMeeting($input: SetUserMeetingInput!) {
  setUserMeeting(input: $input) {
    ...setUserMeetingResponseFields
  }
}
    ${SetUserMeetingResponseFieldsFragmentDoc}`
export const useSetUserMeetingMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    SetUserMeetingMutation,
    TError,
    SetUserMeetingMutationVariables,
    TContext
  >
) =>
  useMutation<SetUserMeetingMutation, TError, SetUserMeetingMutationVariables, TContext>(
    ['setUserMeeting'],
    fetcher<SetUserMeetingMutation, SetUserMeetingMutationVariables>(SetUserMeetingDocument),
    options
  )
export const UpdateUserCalendarEventDocument = `
    mutation updateUserCalendarEvent($input: UpdateUserCalendarEventInput) {
  updateUserCalendarEvent(input: $input) {
    ...UpdateUserCalendarFields
  }
}
    ${UpdateUserCalendarFieldsFragmentDoc}`
export const useUpdateUserCalendarEventMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateUserCalendarEventMutation,
    TError,
    UpdateUserCalendarEventMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateUserCalendarEventMutation,
    TError,
    UpdateUserCalendarEventMutationVariables,
    TContext
  >(
    ['updateUserCalendarEvent'],
    fetcher<UpdateUserCalendarEventMutation, UpdateUserCalendarEventMutationVariables>(
      UpdateUserCalendarEventDocument
    ),
    options
  )
export const CancelUserCalendarEventDocument = `
    mutation cancelUserCalendarEvent($input: CancelUserCalendarEventInput!) {
  cancelUserCalendarEvent(input: $input) {
    ...CancelUserCalendarFields
  }
}
    ${CancelUserCalendarFieldsFragmentDoc}`
export const useCancelUserCalendarEventMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    CancelUserCalendarEventMutation,
    TError,
    CancelUserCalendarEventMutationVariables,
    TContext
  >
) =>
  useMutation<
    CancelUserCalendarEventMutation,
    TError,
    CancelUserCalendarEventMutationVariables,
    TContext
  >(
    ['cancelUserCalendarEvent'],
    fetcher<CancelUserCalendarEventMutation, CancelUserCalendarEventMutationVariables>(
      CancelUserCalendarEventDocument
    ),
    options
  )
export const GetUserAvailabilityHoursDocument = `
    query getUserAvailabilityHours {
  getUserAvailabilityHours {
    ...getUserAvailabilityHoursFields
  }
}
    ${GetUserAvailabilityHoursFieldsFragmentDoc}`
export const useGetUserAvailabilityHoursQuery = <
  TData = GetUserAvailabilityHoursQuery,
  TError = any
>(
  variables?: GetUserAvailabilityHoursQueryVariables,
  options?: UseQueryOptions<GetUserAvailabilityHoursQuery, TError, TData>
) =>
  useQuery<GetUserAvailabilityHoursQuery, TError, TData>(
    variables === undefined
      ? ['getUserAvailabilityHours']
      : ['getUserAvailabilityHours', variables],
    fetcher<GetUserAvailabilityHoursQuery, GetUserAvailabilityHoursQueryVariables>(
      GetUserAvailabilityHoursDocument
    ).bind(null, variables),
    options
  )
export const UpdateUserAvailabilityDocument = `
    mutation updateUserAvailability($input: UpdateUserAvailabilityInput) {
  updateUserAvailability(input: $input) {
    ...updateUserAvailabilityFields
  }
}
    ${UpdateUserAvailabilityFieldsFragmentDoc}`
export const useUpdateUserAvailabilityMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateUserAvailabilityMutation,
    TError,
    UpdateUserAvailabilityMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateUserAvailabilityMutation,
    TError,
    UpdateUserAvailabilityMutationVariables,
    TContext
  >(
    ['updateUserAvailability'],
    fetcher<UpdateUserAvailabilityMutation, UpdateUserAvailabilityMutationVariables>(
      UpdateUserAvailabilityDocument
    ),
    options
  )
export const GetUserMeetingByIdDocument = `
    query getUserMeetingById($input: GetUserMeetingByIdInput!) {
  getUserMeetingById(input: $input) {
    ...getUserMeetingByIdFields
  }
}
    ${GetUserMeetingByIdFieldsFragmentDoc}`
export const useGetUserMeetingByIdQuery = <TData = GetUserMeetingByIdQuery, TError = any>(
  variables: GetUserMeetingByIdQueryVariables,
  options?: UseQueryOptions<GetUserMeetingByIdQuery, TError, TData>
) =>
  useQuery<GetUserMeetingByIdQuery, TError, TData>(
    ['getUserMeetingById', variables],
    fetcher<GetUserMeetingByIdQuery, GetUserMeetingByIdQueryVariables>(
      GetUserMeetingByIdDocument
    ).bind(null, variables),
    options
  )
export const GetAdvisorDashboardDocument = `
    query getAdvisorDashboard($filterType: String, $tenantId: String) {
  getAdvisorDashboard(filterType: $filterType, tenantId: $tenantId) {
    ...AdvisorDashboardFields
  }
}
    ${AdvisorDashboardFieldsFragmentDoc}`
export const useGetAdvisorDashboardQuery = <TData = GetAdvisorDashboardQuery, TError = any>(
  variables?: GetAdvisorDashboardQueryVariables,
  options?: UseQueryOptions<GetAdvisorDashboardQuery, TError, TData>
) =>
  useQuery<GetAdvisorDashboardQuery, TError, TData>(
    variables === undefined ? ['getAdvisorDashboard'] : ['getAdvisorDashboard', variables],
    fetcher<GetAdvisorDashboardQuery, GetAdvisorDashboardQueryVariables>(
      GetAdvisorDashboardDocument
    ).bind(null, variables),
    options
  )
export const GetTopActiveClientsDocument = `
    query getTopActiveClients($tenantId: String) {
  getTopActiveClients(tenantId: $tenantId) {
    ...TopActiveClientsFields
  }
}
    ${TopActiveClientsFieldsFragmentDoc}`
export const useGetTopActiveClientsQuery = <TData = GetTopActiveClientsQuery, TError = any>(
  variables?: GetTopActiveClientsQueryVariables,
  options?: UseQueryOptions<GetTopActiveClientsQuery, TError, TData>
) =>
  useQuery<GetTopActiveClientsQuery, TError, TData>(
    variables === undefined ? ['getTopActiveClients'] : ['getTopActiveClients', variables],
    fetcher<GetTopActiveClientsQuery, GetTopActiveClientsQueryVariables>(
      GetTopActiveClientsDocument
    ).bind(null, variables),
    options
  )
export const GetClientsDocument = `
    query getClients($input: GetClientsInput) {
  getClients(input: $input) {
    ...ClientsFields
  }
}
    ${ClientsFieldsFragmentDoc}`
export const useGetClientsQuery = <TData = GetClientsQuery, TError = any>(
  variables?: GetClientsQueryVariables,
  options?: UseQueryOptions<GetClientsQuery, TError, TData>
) =>
  useQuery<GetClientsQuery, TError, TData>(
    variables === undefined ? ['getClients'] : ['getClients', variables],
    fetcher<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument).bind(null, variables),
    options
  )
export const GetOwnerActivityDetailsDocument = `
    query getOwnerActivityDetails($ownerId: String, $tenantId: String!) {
  getOwnerActivityDetails(ownerId: $ownerId, tenantId: $tenantId) {
    ...ownerActivityDetailsFields
  }
}
    ${OwnerActivityDetailsFieldsFragmentDoc}`
export const useGetOwnerActivityDetailsQuery = <TData = GetOwnerActivityDetailsQuery, TError = any>(
  variables: GetOwnerActivityDetailsQueryVariables,
  options?: UseQueryOptions<GetOwnerActivityDetailsQuery, TError, TData>
) =>
  useQuery<GetOwnerActivityDetailsQuery, TError, TData>(
    ['getOwnerActivityDetails', variables],
    fetcher<GetOwnerActivityDetailsQuery, GetOwnerActivityDetailsQueryVariables>(
      GetOwnerActivityDetailsDocument
    ).bind(null, variables),
    options
  )
export const GetActivityLogsDocument = `
    query getActivityLogs($userId: String, $tenantId: String!) {
  getActivityLogs(userId: $userId, tenantId: $tenantId) {
    ...activityLogFields
  }
}
    ${ActivityLogFieldsFragmentDoc}`
export const useGetActivityLogsQuery = <TData = GetActivityLogsQuery, TError = any>(
  variables: GetActivityLogsQueryVariables,
  options?: UseQueryOptions<GetActivityLogsQuery, TError, TData>
) =>
  useQuery<GetActivityLogsQuery, TError, TData>(
    ['getActivityLogs', variables],
    fetcher<GetActivityLogsQuery, GetActivityLogsQueryVariables>(GetActivityLogsDocument).bind(
      null,
      variables
    ),
    options
  )
export const GetUserIntegrationsDocument = `
    query getUserIntegrations($userId: String, $tenantId: String!) {
  getUserIntegrations(userId: $userId, tenantId: $tenantId) {
    ...userIntegrationFields
  }
}
    ${UserIntegrationFieldsFragmentDoc}`
export const useGetUserIntegrationsQuery = <TData = GetUserIntegrationsQuery, TError = any>(
  variables: GetUserIntegrationsQueryVariables,
  options?: UseQueryOptions<GetUserIntegrationsQuery, TError, TData>
) =>
  useQuery<GetUserIntegrationsQuery, TError, TData>(
    ['getUserIntegrations', variables],
    fetcher<GetUserIntegrationsQuery, GetUserIntegrationsQueryVariables>(
      GetUserIntegrationsDocument
    ).bind(null, variables),
    options
  )
export const GetTackleMeetingsDocument = `
    query getTackleMeetings($input: GetTackleMeetingsInput) {
  getTackleMeetings(input: $input) {
    ...tackleMeetingsFields
  }
}
    ${TackleMeetingsFieldsFragmentDoc}`
export const useGetTackleMeetingsQuery = <TData = GetTackleMeetingsQuery, TError = any>(
  variables?: GetTackleMeetingsQueryVariables,
  options?: UseQueryOptions<GetTackleMeetingsQuery, TError, TData>
) =>
  useQuery<GetTackleMeetingsQuery, TError, TData>(
    variables === undefined ? ['getTackleMeetings'] : ['getTackleMeetings', variables],
    fetcher<GetTackleMeetingsQuery, GetTackleMeetingsQueryVariables>(
      GetTackleMeetingsDocument
    ).bind(null, variables),
    options
  )
export const DeauthenticateConnectorDocument = `
    mutation deauthenticateConnector($input: DeauthenticateConnectorInput) {
  deauthenticateConnector(input: $input) {
    ...deauthenticateConnectorFields
  }
}
    ${DeauthenticateConnectorFieldsFragmentDoc}`
export const useDeauthenticateConnectorMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    DeauthenticateConnectorMutation,
    TError,
    DeauthenticateConnectorMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeauthenticateConnectorMutation,
    TError,
    DeauthenticateConnectorMutationVariables,
    TContext
  >(
    ['deauthenticateConnector'],
    fetcher<DeauthenticateConnectorMutation, DeauthenticateConnectorMutationVariables>(
      DeauthenticateConnectorDocument
    ),
    options
  )
export const GetAllToolsDocument = `
    query getAllTools($tenantId: String, $includeDetails: Boolean) {
  getAllTools(tenantId: $tenantId, includeDetails: $includeDetails) {
    ...getAllToolsFields
  }
}
    ${GetAllToolsFieldsFragmentDoc}`
export const useGetAllToolsQuery = <TData = GetAllToolsQuery, TError = any>(
  variables?: GetAllToolsQueryVariables,
  options?: UseQueryOptions<GetAllToolsQuery, TError, TData>
) =>
  useQuery<GetAllToolsQuery, TError, TData>(
    variables === undefined ? ['getAllTools'] : ['getAllTools', variables],
    fetcher<GetAllToolsQuery, GetAllToolsQueryVariables>(GetAllToolsDocument).bind(null, variables),
    options
  )
export const UpdateOwnerDocument = `
    mutation updateOwner($input: UpdateOwnerInput) {
  updateOwner(input: $input) {
    ...updateOwnerFields
  }
}
    ${UpdateOwnerFieldsFragmentDoc}`
export const useUpdateOwnerMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<UpdateOwnerMutation, TError, UpdateOwnerMutationVariables, TContext>
) =>
  useMutation<UpdateOwnerMutation, TError, UpdateOwnerMutationVariables, TContext>(
    ['updateOwner'],
    fetcher<UpdateOwnerMutation, UpdateOwnerMutationVariables>(UpdateOwnerDocument),
    options
  )
export const GetUserToolsDocument = `
    query getUserTools($userId: String, $tenantId: String!) {
  getUserTools(userId: $userId, tenantId: $tenantId) {
    ...getUserToolsFields
  }
}
    ${GetUserToolsFieldsFragmentDoc}`
export const useGetUserToolsQuery = <TData = GetUserToolsQuery, TError = any>(
  variables: GetUserToolsQueryVariables,
  options?: UseQueryOptions<GetUserToolsQuery, TError, TData>
) =>
  useQuery<GetUserToolsQuery, TError, TData>(
    ['getUserTools', variables],
    fetcher<GetUserToolsQuery, GetUserToolsQueryVariables>(GetUserToolsDocument).bind(
      null,
      variables
    ),
    options
  )
export const GetAttestationReportsDocument = `
    query getAttestationReports($input: GetAttestationReportsInput!) {
  getAttestationReports(input: $input) {
    ...getAttestationReportsFields
  }
}
    ${GetAttestationReportsFieldsFragmentDoc}`
export const useGetAttestationReportsQuery = <TData = GetAttestationReportsQuery, TError = any>(
  variables: GetAttestationReportsQueryVariables,
  options?: UseQueryOptions<GetAttestationReportsQuery, TError, TData>
) =>
  useQuery<GetAttestationReportsQuery, TError, TData>(
    ['getAttestationReports', variables],
    fetcher<GetAttestationReportsQuery, GetAttestationReportsQueryVariables>(
      GetAttestationReportsDocument
    ).bind(null, variables),
    options
  )
export const ToolConnectionSuccessDocument = `
    mutation toolConnectionSuccess($input: ToolConnectionSuccessInput) {
  toolConnectionSuccess(input: $input) {
    ...updateOwnerFields
  }
}
    ${UpdateOwnerFieldsFragmentDoc}`
export const useToolConnectionSuccessMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    ToolConnectionSuccessMutation,
    TError,
    ToolConnectionSuccessMutationVariables,
    TContext
  >
) =>
  useMutation<
    ToolConnectionSuccessMutation,
    TError,
    ToolConnectionSuccessMutationVariables,
    TContext
  >(
    ['toolConnectionSuccess'],
    fetcher<ToolConnectionSuccessMutation, ToolConnectionSuccessMutationVariables>(
      ToolConnectionSuccessDocument
    ),
    options
  )
export const GetGoalDetailsDocument = `
    query getGoalDetails($id: String) {
  getGoalDetails(id: $id) {
    ...goalDetailsFields
  }
}
    ${GoalDetailsFieldsFragmentDoc}`
export const useGetGoalDetailsQuery = <TData = GetGoalDetailsQuery, TError = any>(
  variables?: GetGoalDetailsQueryVariables,
  options?: UseQueryOptions<GetGoalDetailsQuery, TError, TData>
) =>
  useQuery<GetGoalDetailsQuery, TError, TData>(
    variables === undefined ? ['getGoalDetails'] : ['getGoalDetails', variables],
    fetcher<GetGoalDetailsQuery, GetGoalDetailsQueryVariables>(GetGoalDetailsDocument).bind(
      null,
      variables
    ),
    options
  )
export const GetUserGoalsDocument = `
    query getUserGoals($userId: String, $tenantId: String) {
  getUserGoals(userId: $userId, tenantId: $tenantId) {
    ...goalDetailsFields
  }
}
    ${GoalDetailsFieldsFragmentDoc}`
export const useGetUserGoalsQuery = <TData = GetUserGoalsQuery, TError = any>(
  variables?: GetUserGoalsQueryVariables,
  options?: UseQueryOptions<GetUserGoalsQuery, TError, TData>
) =>
  useQuery<GetUserGoalsQuery, TError, TData>(
    variables === undefined ? ['getUserGoals'] : ['getUserGoals', variables],
    fetcher<GetUserGoalsQuery, GetUserGoalsQueryVariables>(GetUserGoalsDocument).bind(
      null,
      variables
    ),
    options
  )
export const GetChartDataDocument = `
    query getChartData($input: GetChartDataInput!) {
  getChartData(input: $input) {
    ...chartDataFields
  }
}
    ${ChartDataFieldsFragmentDoc}`
export const useGetChartDataQuery = <TData = GetChartDataQuery, TError = any>(
  variables: GetChartDataQueryVariables,
  options?: UseQueryOptions<GetChartDataQuery, TError, TData>
) =>
  useQuery<GetChartDataQuery, TError, TData>(
    ['getChartData', variables],
    fetcher<GetChartDataQuery, GetChartDataQueryVariables>(GetChartDataDocument).bind(
      null,
      variables
    ),
    options
  )
export const GetOwnerBusinessDataDocument = `
    query getOwnerBusinessData($input: getOwnerBusinessDataInput) {
  getOwnerBusinessData(input: $input) {
    ...ownerBusinessDataFields
  }
}
    ${OwnerBusinessDataFieldsFragmentDoc}`
export const useGetOwnerBusinessDataQuery = <TData = GetOwnerBusinessDataQuery, TError = any>(
  variables?: GetOwnerBusinessDataQueryVariables,
  options?: UseQueryOptions<GetOwnerBusinessDataQuery, TError, TData>
) =>
  useQuery<GetOwnerBusinessDataQuery, TError, TData>(
    variables === undefined ? ['getOwnerBusinessData'] : ['getOwnerBusinessData', variables],
    fetcher<GetOwnerBusinessDataQuery, GetOwnerBusinessDataQueryVariables>(
      GetOwnerBusinessDataDocument
    ).bind(null, variables),
    options
  )
export const GetPlayDocument = `
    query getPlay($id: String) {
  getPlay(id: $id) {
    ...getPlayFields
  }
}
    ${GetPlayFieldsFragmentDoc}`
export const useGetPlayQuery = <TData = GetPlayQuery, TError = any>(
  variables?: GetPlayQueryVariables,
  options?: UseQueryOptions<GetPlayQuery, TError, TData>
) =>
  useQuery<GetPlayQuery, TError, TData>(
    variables === undefined ? ['getPlay'] : ['getPlay', variables],
    fetcher<GetPlayQuery, GetPlayQueryVariables>(GetPlayDocument).bind(null, variables),
    options
  )
export const GetPlaysDocument = `
    query getPlays($input: GetPlaysInput) {
  getPlays(input: $input) {
    ...getPlaysFields
  }
}
    ${GetPlaysFieldsFragmentDoc}`
export const useGetPlaysQuery = <TData = GetPlaysQuery, TError = any>(
  variables?: GetPlaysQueryVariables,
  options?: UseQueryOptions<GetPlaysQuery, TError, TData>
) =>
  useQuery<GetPlaysQuery, TError, TData>(
    variables === undefined ? ['getPlays'] : ['getPlays', variables],
    fetcher<GetPlaysQuery, GetPlaysQueryVariables>(GetPlaysDocument).bind(null, variables),
    options
  )
export const AuthorizeConnectorDocument = `
    mutation authorizeConnector($authValue: String!) {
  authorizeConnector(authValue: $authValue) {
    ...authorizeConnectorFields
  }
}
    ${AuthorizeConnectorFieldsFragmentDoc}`
export const useAuthorizeConnectorMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    AuthorizeConnectorMutation,
    TError,
    AuthorizeConnectorMutationVariables,
    TContext
  >
) =>
  useMutation<AuthorizeConnectorMutation, TError, AuthorizeConnectorMutationVariables, TContext>(
    ['authorizeConnector'],
    fetcher<AuthorizeConnectorMutation, AuthorizeConnectorMutationVariables>(
      AuthorizeConnectorDocument
    ),
    options
  )
export const GetDashboardListingDocument = `
    query getDashboardListing($tenantId: String!, $category: String) {
  getDashboardListing(tenantId: $tenantId, category: $category) {
    ...dashboardListingFields
  }
}
    ${DashboardListingFieldsFragmentDoc}`
export const useGetDashboardListingQuery = <TData = GetDashboardListingQuery, TError = any>(
  variables: GetDashboardListingQueryVariables,
  options?: UseQueryOptions<GetDashboardListingQuery, TError, TData>
) =>
  useQuery<GetDashboardListingQuery, TError, TData>(
    ['getDashboardListing', variables],
    fetcher<GetDashboardListingQuery, GetDashboardListingQueryVariables>(
      GetDashboardListingDocument
    ).bind(null, variables),
    options
  )
export const GetAssessmentPlayListingDocument = `
    query getAssessmentPlayListing($tenantId: String!) {
  getAssessmentPlayListing(tenantId: $tenantId) {
    ...assessmentPlayListingFields
  }
}
    ${AssessmentPlayListingFieldsFragmentDoc}`
export const useGetAssessmentPlayListingQuery = <
  TData = GetAssessmentPlayListingQuery,
  TError = any
>(
  variables: GetAssessmentPlayListingQueryVariables,
  options?: UseQueryOptions<GetAssessmentPlayListingQuery, TError, TData>
) =>
  useQuery<GetAssessmentPlayListingQuery, TError, TData>(
    ['getAssessmentPlayListing', variables],
    fetcher<GetAssessmentPlayListingQuery, GetAssessmentPlayListingQueryVariables>(
      GetAssessmentPlayListingDocument
    ).bind(null, variables),
    options
  )
export const GetToolsDocument = `
    query getTools {
  getTools {
    ...getToolsFields
  }
}
    ${GetToolsFieldsFragmentDoc}`
export const useGetToolsQuery = <TData = GetToolsQuery, TError = any>(
  variables?: GetToolsQueryVariables,
  options?: UseQueryOptions<GetToolsQuery, TError, TData>
) =>
  useQuery<GetToolsQuery, TError, TData>(
    variables === undefined ? ['getTools'] : ['getTools', variables],
    fetcher<GetToolsQuery, GetToolsQueryVariables>(GetToolsDocument).bind(null, variables),
    options
  )
export const GetToolsByTypeDocument = `
    query getToolsByType($input: GetToolsByTypeInput!) {
  getToolsByType(input: $input) {
    ...toolFields
  }
}
    ${ToolFieldsFragmentDoc}`
export const useGetToolsByTypeQuery = <TData = GetToolsByTypeQuery, TError = any>(
  variables: GetToolsByTypeQueryVariables,
  options?: UseQueryOptions<GetToolsByTypeQuery, TError, TData>
) =>
  useQuery<GetToolsByTypeQuery, TError, TData>(
    ['getToolsByType', variables],
    fetcher<GetToolsByTypeQuery, GetToolsByTypeQueryVariables>(GetToolsByTypeDocument).bind(
      null,
      variables
    ),
    options
  )
export const GetSignedUrlDocument = `
    query getSignedUrl($contentType: String, $fileName: String) {
  getSignedUrl(contentType: $contentType, fileName: $fileName) {
    ...goalSignedUrlFields
  }
}
    ${GoalSignedUrlFieldsFragmentDoc}`
export const useGetSignedUrlQuery = <TData = GetSignedUrlQuery, TError = any>(
  variables?: GetSignedUrlQueryVariables,
  options?: UseQueryOptions<GetSignedUrlQuery, TError, TData>
) =>
  useQuery<GetSignedUrlQuery, TError, TData>(
    variables === undefined ? ['getSignedUrl'] : ['getSignedUrl', variables],
    fetcher<GetSignedUrlQuery, GetSignedUrlQueryVariables>(GetSignedUrlDocument).bind(
      null,
      variables
    ),
    options
  )
export const GetUserFilesDocument = `
    query getUserFiles($userId: String, $tenantId: String!) {
  getUserFiles(userId: $userId, tenantId: $tenantId) {
    ...getUserFilesFileds
  }
}
    ${GetUserFilesFiledsFragmentDoc}`
export const useGetUserFilesQuery = <TData = GetUserFilesQuery, TError = any>(
  variables: GetUserFilesQueryVariables,
  options?: UseQueryOptions<GetUserFilesQuery, TError, TData>
) =>
  useQuery<GetUserFilesQuery, TError, TData>(
    ['getUserFiles', variables],
    fetcher<GetUserFilesQuery, GetUserFilesQueryVariables>(GetUserFilesDocument).bind(
      null,
      variables
    ),
    options
  )
export const GetFileDocument = `
    query getFile($id: String) {
  getFile(id: $id) {
    ...getUserFilesFileds
  }
}
    ${GetUserFilesFiledsFragmentDoc}`
export const useGetFileQuery = <TData = GetFileQuery, TError = any>(
  variables?: GetFileQueryVariables,
  options?: UseQueryOptions<GetFileQuery, TError, TData>
) =>
  useQuery<GetFileQuery, TError, TData>(
    variables === undefined ? ['getFile'] : ['getFile', variables],
    fetcher<GetFileQuery, GetFileQueryVariables>(GetFileDocument).bind(null, variables),
    options
  )
export const SaveUserFileDocument = `
    mutation saveUserFile($input: [SaveUserFileInput!]!) {
  saveUserFile(input: $input) {
    ...saveUserFileFields
  }
}
    ${SaveUserFileFieldsFragmentDoc}`
export const useSaveUserFileMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    SaveUserFileMutation,
    TError,
    SaveUserFileMutationVariables,
    TContext
  >
) =>
  useMutation<SaveUserFileMutation, TError, SaveUserFileMutationVariables, TContext>(
    ['saveUserFile'],
    fetcher<SaveUserFileMutation, SaveUserFileMutationVariables>(SaveUserFileDocument),
    options
  )
export const GetBusinessesDocument = `
    query getBusinesses($getBusinessesInput: GetBusinessesInput!) {
  getBusinesses(getBusinessesInput: $getBusinessesInput) {
    ...BusinessesFields
  }
}
    ${BusinessesFieldsFragmentDoc}`
export const useGetBusinessesQuery = <TData = GetBusinessesQuery, TError = any>(
  variables: GetBusinessesQueryVariables,
  options?: UseQueryOptions<GetBusinessesQuery, TError, TData>
) =>
  useQuery<GetBusinessesQuery, TError, TData>(
    ['getBusinesses', variables],
    fetcher<GetBusinessesQuery, GetBusinessesQueryVariables>(GetBusinessesDocument).bind(
      null,
      variables
    ),
    options
  )
export const ListCognitoUsersDocument = `
    mutation listCognitoUsers($input: ListCognitoUsersInput!) {
  listCognitoUsers(input: $input) {
    ...cognitoUserFields
  }
}
    ${CognitoUserFieldsFragmentDoc}`
export const useListCognitoUsersMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    ListCognitoUsersMutation,
    TError,
    ListCognitoUsersMutationVariables,
    TContext
  >
) =>
  useMutation<ListCognitoUsersMutation, TError, ListCognitoUsersMutationVariables, TContext>(
    ['listCognitoUsers'],
    fetcher<ListCognitoUsersMutation, ListCognitoUsersMutationVariables>(ListCognitoUsersDocument),
    options
  )
export const AddUserToGroupDocument = `
    mutation addUserToGroup($input: AddUserToGroupInput!) {
  addUserToGroup(input: $input)
}
    `
export const useAddUserToGroupMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    AddUserToGroupMutation,
    TError,
    AddUserToGroupMutationVariables,
    TContext
  >
) =>
  useMutation<AddUserToGroupMutation, TError, AddUserToGroupMutationVariables, TContext>(
    ['addUserToGroup'],
    fetcher<AddUserToGroupMutation, AddUserToGroupMutationVariables>(AddUserToGroupDocument),
    options
  )
export const UpdateUserPasswordDocument = `
    mutation updateUserPassword($input: UpdateUserPasswordInput!) {
  updateUserPassword(input: $input)
}
    `
export const useUpdateUserPasswordMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateUserPasswordMutation,
    TError,
    UpdateUserPasswordMutationVariables,
    TContext
  >
) =>
  useMutation<UpdateUserPasswordMutation, TError, UpdateUserPasswordMutationVariables, TContext>(
    ['updateUserPassword'],
    fetcher<UpdateUserPasswordMutation, UpdateUserPasswordMutationVariables>(
      UpdateUserPasswordDocument
    ),
    options
  )
export const GetBigQueryDocument = `
    query getBigQuery($query: String) {
  getBigQuery(query: $query) {
    ...bigQueryFields
  }
}
    ${BigQueryFieldsFragmentDoc}`
export const useGetBigQueryQuery = <TData = GetBigQueryQuery, TError = any>(
  variables?: GetBigQueryQueryVariables,
  options?: UseQueryOptions<GetBigQueryQuery, TError, TData>
) =>
  useQuery<GetBigQueryQuery, TError, TData>(
    variables === undefined ? ['getBigQuery'] : ['getBigQuery', variables],
    fetcher<GetBigQueryQuery, GetBigQueryQueryVariables>(GetBigQueryDocument).bind(null, variables),
    options
  )
export const GetAdvisorsDocument = `
    query getAdvisors($getAdvisorsInput: GetAdvisorsInput) {
  getAdvisors(getAdvisorsInput: $getAdvisorsInput) {
    ...AdvisorsFields
  }
}
    ${AdvisorsFieldsFragmentDoc}`
export const useGetAdvisorsQuery = <TData = GetAdvisorsQuery, TError = any>(
  variables?: GetAdvisorsQueryVariables,
  options?: UseQueryOptions<GetAdvisorsQuery, TError, TData>
) =>
  useQuery<GetAdvisorsQuery, TError, TData>(
    variables === undefined ? ['getAdvisors'] : ['getAdvisors', variables],
    fetcher<GetAdvisorsQuery, GetAdvisorsQueryVariables>(GetAdvisorsDocument).bind(null, variables),
    options
  )
export const LogActivityDocument = `
    mutation logActivity($activityLogInput: ActivityLogInput!) {
  logActivity(activityLogInput: $activityLogInput) {
    ...logActivityFields
  }
}
    ${LogActivityFieldsFragmentDoc}`
export const useLogActivityMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<LogActivityMutation, TError, LogActivityMutationVariables, TContext>
) =>
  useMutation<LogActivityMutation, TError, LogActivityMutationVariables, TContext>(
    ['logActivity'],
    fetcher<LogActivityMutation, LogActivityMutationVariables>(LogActivityDocument),
    options
  )
export const GetUserNotificationsDocument = `
    query getUserNotifications($input: GetUserNotificationsInput) {
  getUserNotifications(input: $input) {
    ...UserNotificationsFields
  }
}
    ${UserNotificationsFieldsFragmentDoc}`
export const useGetUserNotificationsQuery = <TData = GetUserNotificationsQuery, TError = any>(
  variables?: GetUserNotificationsQueryVariables,
  options?: UseQueryOptions<GetUserNotificationsQuery, TError, TData>
) =>
  useQuery<GetUserNotificationsQuery, TError, TData>(
    variables === undefined ? ['getUserNotifications'] : ['getUserNotifications', variables],
    fetcher<GetUserNotificationsQuery, GetUserNotificationsQueryVariables>(
      GetUserNotificationsDocument
    ).bind(null, variables),
    options
  )
export const GetClientsSummaryDocument = `
    query getClientsSummary($tenantId: String) {
  getClientsSummary(tenantId: $tenantId) {
    ...GetClientsSummaryFields
  }
}
    ${GetClientsSummaryFieldsFragmentDoc}`
export const useGetClientsSummaryQuery = <TData = GetClientsSummaryQuery, TError = any>(
  variables?: GetClientsSummaryQueryVariables,
  options?: UseQueryOptions<GetClientsSummaryQuery, TError, TData>
) =>
  useQuery<GetClientsSummaryQuery, TError, TData>(
    variables === undefined ? ['getClientsSummary'] : ['getClientsSummary', variables],
    fetcher<GetClientsSummaryQuery, GetClientsSummaryQueryVariables>(
      GetClientsSummaryDocument
    ).bind(null, variables),
    options
  )
export const UpdateUserNotificationsDocument = `
    mutation updateUserNotifications($input: UpdateUserNotificationsInput) {
  updateUserNotifications(input: $input) {
    ...UserNotificationMutateFields
  }
}
    ${UserNotificationMutateFieldsFragmentDoc}`
export const useUpdateUserNotificationsMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateUserNotificationsMutation,
    TError,
    UpdateUserNotificationsMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateUserNotificationsMutation,
    TError,
    UpdateUserNotificationsMutationVariables,
    TContext
  >(
    ['updateUserNotifications'],
    fetcher<UpdateUserNotificationsMutation, UpdateUserNotificationsMutationVariables>(
      UpdateUserNotificationsDocument
    ),
    options
  )
export const DeleteUserNotificationsDocument = `
    mutation deleteUserNotifications($input: DeleteUserNotificationsInput) {
  deleteUserNotifications(input: $input) {
    ...UserNotificationMutateFields
  }
}
    ${UserNotificationMutateFieldsFragmentDoc}`
export const useDeleteUserNotificationsMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteUserNotificationsMutation,
    TError,
    DeleteUserNotificationsMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteUserNotificationsMutation,
    TError,
    DeleteUserNotificationsMutationVariables,
    TContext
  >(
    ['deleteUserNotifications'],
    fetcher<DeleteUserNotificationsMutation, DeleteUserNotificationsMutationVariables>(
      DeleteUserNotificationsDocument
    ),
    options
  )
export const ResendUserInviteDocument = `
    mutation resendUserInvite($input: ResendUserInviteInput!) {
  resendUserInvite(input: $input)
}
    `
export const useResendUserInviteMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    ResendUserInviteMutation,
    TError,
    ResendUserInviteMutationVariables,
    TContext
  >
) =>
  useMutation<ResendUserInviteMutation, TError, ResendUserInviteMutationVariables, TContext>(
    ['resendUserInvite'],
    fetcher<ResendUserInviteMutation, ResendUserInviteMutationVariables>(ResendUserInviteDocument),
    options
  )
export const SaveAttestationDocument = `
    mutation saveAttestation($input: AttestationInput!) {
  saveAttestation(input: $input) {
    ...saveAttestationFields
  }
}
    ${SaveAttestationFieldsFragmentDoc}`
export const useSaveAttestationMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    SaveAttestationMutation,
    TError,
    SaveAttestationMutationVariables,
    TContext
  >
) =>
  useMutation<SaveAttestationMutation, TError, SaveAttestationMutationVariables, TContext>(
    ['saveAttestation'],
    fetcher<SaveAttestationMutation, SaveAttestationMutationVariables>(SaveAttestationDocument),
    options
  )
export const GetMyProfileDocument = `
    query getMyProfile($userId: String, $tenantId: String) {
  getMyProfile(userId: $userId, tenantId: $tenantId) {
    ...userProfileFields
  }
}
    ${UserProfileFieldsFragmentDoc}`
export const useGetMyProfileQuery = <TData = GetMyProfileQuery, TError = any>(
  variables?: GetMyProfileQueryVariables,
  options?: UseQueryOptions<GetMyProfileQuery, TError, TData>
) =>
  useQuery<GetMyProfileQuery, TError, TData>(
    variables === undefined ? ['getMyProfile'] : ['getMyProfile', variables],
    fetcher<GetMyProfileQuery, GetMyProfileQueryVariables>(GetMyProfileDocument).bind(
      null,
      variables
    ),
    options
  )
export const GetBoAdvisorsDocument = `
    query getBoAdvisors($userId: String, $tenantId: String) {
  getBoAdvisors(tenantId: $tenantId, userId: $userId) {
    ...BoAdvisorsFields
  }
}
    ${BoAdvisorsFieldsFragmentDoc}`
export const useGetBoAdvisorsQuery = <TData = GetBoAdvisorsQuery, TError = any>(
  variables?: GetBoAdvisorsQueryVariables,
  options?: UseQueryOptions<GetBoAdvisorsQuery, TError, TData>
) =>
  useQuery<GetBoAdvisorsQuery, TError, TData>(
    variables === undefined ? ['getBoAdvisors'] : ['getBoAdvisors', variables],
    fetcher<GetBoAdvisorsQuery, GetBoAdvisorsQueryVariables>(GetBoAdvisorsDocument).bind(
      null,
      variables
    ),
    options
  )
export const UpdateMyProfileDocument = `
    mutation updateMyProfile($input: UpdateMyProfileInput!) {
  updateMyProfile(input: $input) {
    ...userProfileFields
  }
}
    ${UserProfileFieldsFragmentDoc}`
export const useUpdateMyProfileMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateMyProfileMutation,
    TError,
    UpdateMyProfileMutationVariables,
    TContext
  >
) =>
  useMutation<UpdateMyProfileMutation, TError, UpdateMyProfileMutationVariables, TContext>(
    ['updateMyProfile'],
    fetcher<UpdateMyProfileMutation, UpdateMyProfileMutationVariables>(UpdateMyProfileDocument),
    options
  )
export const CreateUserDocument = `
    mutation createUser($input: CreateUserInput!) {
  createUser(input: $input) {
    ...userProfileFields
  }
}
    ${UserProfileFieldsFragmentDoc}`
export const useCreateUserMutation = <TError = any, TContext = unknown>(
  options?: UseMutationOptions<CreateUserMutation, TError, CreateUserMutationVariables, TContext>
) =>
  useMutation<CreateUserMutation, TError, CreateUserMutationVariables, TContext>(
    ['createUser'],
    fetcher<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument),
    options
  )
export const GetAdvisorProfileDocument = `
    query getAdvisorProfile($input: GetAdvisorProfileInput!) {
  getAdvisorProfile(input: $input) {
    ...advisorProfileFields
  }
}
    ${AdvisorProfileFieldsFragmentDoc}`
export const useGetAdvisorProfileQuery = <TData = GetAdvisorProfileQuery, TError = any>(
  variables: GetAdvisorProfileQueryVariables,
  options?: UseQueryOptions<GetAdvisorProfileQuery, TError, TData>
) =>
  useQuery<GetAdvisorProfileQuery, TError, TData>(
    ['getAdvisorProfile', variables],
    fetcher<GetAdvisorProfileQuery, GetAdvisorProfileQueryVariables>(
      GetAdvisorProfileDocument
    ).bind(null, variables),
    options
  )
