import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'

import { ConnectedProps, connect, useSelector } from 'react-redux'
import { AppDispatch, RootState } from 'App'
import { bindActionCreators } from 'redux'

import { getAdvisorsListSelector } from 'store/selectors/userManagement'

import { getAdvisorsAction } from 'store/actions/userManagement'

import { Columns } from 'types'
import { navigateTo, getRoleFromPath, sortHandlerHelper, replaceTerm } from 'utils/helper'
import { ROLES, statusOptions } from 'config'

import Button from 'components/Common/Button'
import DataTable from 'components/Common/DataTable'
import FilterButton from 'components/Common/FilterButton'
import FilterSettingsMenu from 'components/Common/FilterSettingsModal'
import InputField from 'components/Common/InputField'
import Loader from 'components/Loaders'
import RadioButtonField from 'components/Common/RadioButtonField'
import { SearchIcon } from 'components/Common/SvgIcons'
import SelectField from 'components/Common/SelectField'
import SortButton from 'components/Common/SortButton'
import UserManagementList from '../UserManagementList'
import { BSOProfile } from 'store/reducers/bso'

export const ADVISOR_SELECT_TEST_ID = 'ADVISOR_SELECT_TEST_ID'
export const STATUS_SELECT_TEST_ID = 'STATUS_SELECT_TEST_ID'

const connector = connect(
  (state: RootState) => ({
    advisors: getAdvisorsListSelector(state),
    bso: state.bso,
  }),
  (dispatch: AppDispatch) =>
    bindActionCreators(
      {
        getAdvisorsAction: getAdvisorsAction.STARTED,
      },
      dispatch
    )
)

interface BSO {
  bsoProfile: BSOProfile
}

interface IUserManagementLayoutProps extends ConnectedProps<typeof connector> {
  /** sortFields represent userAccount properties that can be used to sort userAccounts in the UI */
  sortFields: Array<{ label: string; value: string; fieldType?: string }>
  /** Properties on a userAccount to be used by the search bar to find matches */
  searchByProperties: string[] // todo - type these properties like "keyof UserAccount" when a common "UserAccount" interface exists
  showAdvisorFilter?: boolean
  userAccountType: 'Business' | 'Advisor'
  searchInputPlaceholder: string
  /** The URL path pointing to the page on which new users are created. */
  createAccountUrlPath: string
  /** Columns that determine how user account data is displayed in the table. See "Advisors" or "Businesses" component for examples. */
  tableColumns: Columns
  /** The user accounts that populate the table and/or "UserManagementList" */
  userAccounts: any[] // todo - create a common interface for the various user accounts based on needed attributes in this component
  loading: boolean
  /** A callback intended to wrap whatever redux dispatch actions needed to fetch & store whatever data when the page loads. */
  getData: () => void
  bso: BSO
}

const UserManagementLayout = connector<FC<IUserManagementLayoutProps>>(
  ({
    showAdvisorFilter = false,
    searchInputPlaceholder,
    searchByProperties,
    createAccountUrlPath,
    userAccountType,
    tableColumns,
    userAccounts,
    sortFields,
    advisors,
    bso,
    getData,
    loading,
  }) => {
    const [pageSize, setPageSize] = useState(20)

    useEffect(() => {
      getData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bso])

    const [displayedUserAccounts, setDisplayedUserAccounts] = useState(userAccounts)

    const userRole = getRoleFromPath()

    const createNewUserAccount = useCallback(() => {
      navigateTo(ROLES.BSO_ADMIN, createAccountUrlPath)
      // eslint-disable-next-line
    }, [userRole, createAccountUrlPath])

    const [searchbarText, setSearchbarText] = useState<string | undefined>()

    const [displayedFilterSettingsMenu, setDisplayedFilterSettingsMenu] = useState<
      'sort' | 'filter' | null
    >(null)

    const onSortButtonClicked = () => {
      setDisplayedFilterSettingsMenu('sort')
    }

    const onFilterButtonClicked = () => {
      setDisplayedFilterSettingsMenu('filter')
    }

    const filterSettingsMenuTitle = useMemo(() => {
      return displayedFilterSettingsMenu || ''
    }, [displayedFilterSettingsMenu])

    /** The property on a business ("name", "businessName", etc.) currently being used to sort the displayed data */
    const [sortField, setSortField] = useState(sortFields[0].value)

    /** The field that will sort user accounts once the "Apply" button is clicked. (small screens only) */
    const [pendingSortField, setPendingSortField] = useState(sortFields[0].value)

    const fieldType = useMemo(() => {
      return sortFields.find((sf) => sf.value === sortField)!?.fieldType || ''
    }, [sortField, sortFields])

    /** The currently filtered advisor. If set, only user accounts associated with this advisor appear in displayed data. */
    const [advisorFilter, setAdvisorFilter] = useState('')

    /** Becomes the advisor filter after user clicks "Apply" (small screens only) */
    const [pendingAdvisorFilter, setPendingAdvisorFilter] = useState('')

    /** The currently filtered status. If set, only user accounts with this status appear in displayed data. */
    const [statusFilter, setStatusFilter] = useState('')

    /** Becomes the status filter after user clicks "Apply" (small screens only) */
    const [pendingStatusFilter, setPendingStatusFilter] = useState('')

    const restoreDefaultSortAndFilters = () => {
      const advisor = ''
      const status = ''
      const sort = sortFields[0].value

      setSortField(sort)
      setPendingSortField(sort)
      setPendingAdvisorFilter(advisor)
      setAdvisorFilter(advisor)
      setPendingStatusFilter(status)
      setStatusFilter(status)
      setSearchbarText('')
      setDisplayedFilterSettingsMenu(null)
    }

    const closeFilterModalAndRevertPendingChanges = () => {
      if (displayedFilterSettingsMenu === 'sort') {
        setPendingSortField(sortField)
      } else if (displayedFilterSettingsMenu === 'filter') {
        setPendingAdvisorFilter(advisorFilter)
        setPendingStatusFilter(statusFilter)
      }
      setDisplayedFilterSettingsMenu(null)
    }

    /** Apply a set of pending changes to filters and update the displayed data */
    const applyPendingFilterChanges = () => {
      /* Use the "pending" filter values as the new filters... */
      setAdvisorFilter(pendingAdvisorFilter)
      setStatusFilter(pendingStatusFilter)
      sortAndFilterUserAccounts()
    }

    /** Immediately apply a status as a filter and recompute displayed user accounts (Does not require confirmation with "Apply" button click) */
    const applyStatusFilter = (status) => {
      setPendingStatusFilter(status)
      setStatusFilter(status)
      sortAndFilterUserAccounts()
    }

    /** Immediately apply an advisor as a filter and recompute displayed user accounts (Does not require confirmation with "Apply" button click) */
    const applyAdvisorFilter = (advisor) => {
      setPendingAdvisorFilter(advisor)
      setAdvisorFilter(advisor)
      sortAndFilterUserAccounts()
    }

    const applySortSettings = (field = pendingSortField) => {
      setSortField(field)
      sortAndFilterUserAccounts()
    }

    /** This is currently just another filter */
    const handleSearch = (e) => {
      const text = e.target.value
      setSearchbarText(text)
      sortAndFilterUserAccounts()
    }

    /** Sort a provided array of user accounts using the current sortField */
    const sort = useCallback(
      (data) => {
        const results = sortHandlerHelper({
          sortOrder: 'ASC',
          fieldType: fieldType,
          sortField: sortField,
          data: data,
        })
        return results
      },
      [fieldType, sortField]
    )

    /** Applies filtering and sorting to user accounts */
    const sortAndFilterUserAccounts = useCallback(() => {
      const normalizeText = (val: string) => val?.toLowerCase().replace(/\s/g, '')
      let _userAccounts = userAccounts.filter((userAccount) => {
        if (showAdvisorFilter) {
          if (
            advisorFilter &&
            advisorFilter !== 'all' &&
            !userAccount?.advisors?.includes(advisorFilter)
          )
            return false
        }
        if (statusFilter && statusFilter !== 'all' && userAccount?.status !== statusFilter) {
          return false
        }
        if (searchbarText) {
          const searchChars = normalizeText(searchbarText)

          const matchesSearchText = searchByProperties.some((prop) => {
            const text = userAccount?.[prop]
            if (!text || typeof text !== 'string') return false
            return normalizeText(text)?.includes(searchChars)
          })

          if (!matchesSearchText) return false
        }
        return true
      })

      _userAccounts = sort(_userAccounts)
      setDisplayedUserAccounts(_userAccounts)
    }, [
      userAccounts,
      sort,
      showAdvisorFilter,
      statusFilter,
      searchbarText,
      advisorFilter,
      searchByProperties,
    ])

    /** Automatically updates displayed data if "userAccounts" (underlying redux data) changes */
    useEffect(() => {
      sortAndFilterUserAccounts()
    }, [sortAndFilterUserAccounts])

    /** Closes the sort/filter menu and applies the pending changes. The associated button only appears in a modal on small screens. */
    const onAppliedClicked = () => {
      const menu = displayedFilterSettingsMenu
      if (!menu) return
      if (menu === 'sort') applySortSettings()
      if (menu === 'filter') applyPendingFilterChanges()
      setDisplayedFilterSettingsMenu(null)
    }

    // replace keywords
    const replacedTerm = replaceTerm(userAccountType, bso?.bsoProfile?.terminology)

    return (
      <div>
        <div className="w-full overflow-auto mdl:overflow-hidden space-y-5">
          <div className="text-xl sm:text-3xl font-secondary whitespace-nowrap">
            Manage {replacedTerm} Accounts
          </div>
          <Button
            label={`New ${replacedTerm} Account`}
            onClick={createNewUserAccount}
            variant="text"
            leftIcon="plus-circle"
          />
          <div className="flex flex-col smd:flex-row smd:justify-between smd:items-end gap-4 mt-4">
            <UserManagementSearchBar
              placeholder={searchInputPlaceholder}
              onSearchClicked={() => handleSearch(searchbarText)}
              onChange={handleSearch}
            />
            {/* SMALL-SCREEN FILTERS */}
            <div className="mdl:hidden grid grid-cols-2 gap-2">
              <SortButton onClick={onSortButtonClicked} />
              <FilterButton onClick={onFilterButtonClicked} />
              {displayedFilterSettingsMenu && (
                <FilterSettingsMenu
                  title={filterSettingsMenuTitle}
                  onClose={closeFilterModalAndRevertPendingChanges}
                  onReset={restoreDefaultSortAndFilters}
                  onSettingsApplied={onAppliedClicked}
                >
                  {/* Full-screen modal for choosing sort column for mobile users */}
                  {displayedFilterSettingsMenu === 'sort' && (
                    <div className="space-y-6">
                      <RadioButtonField
                        options={sortFields}
                        onChange={(v) => setPendingSortField(v)}
                        value={pendingSortField}
                      />
                    </div>
                  )}
                  {/* Full-screen modal for choosing filters for mobile users */}

                  {displayedFilterSettingsMenu === 'filter' && (
                    <>
                      <div className="w-[250px]">
                        <StatusSelect
                          onChange={setPendingStatusFilter}
                          value={pendingStatusFilter}
                        />
                      </div>
                      {showAdvisorFilter && (
                        <div className="w-full max-w-[500px]">
                          <AdvisorSelect
                            advisors={advisors}
                            onChange={setPendingAdvisorFilter}
                            value={pendingAdvisorFilter}
                          />
                        </div>
                      )}
                    </>
                  )}
                </FilterSettingsMenu>
              )}
            </div>
            {/* LARGE-SCREEN FILTERS */}
            <div className="hidden mdl:flex justify-end w-full gap-3 items-end">
              {/* filter by status */}
              <div className="min-w-[210px]">
                <StatusSelect onChange={applyStatusFilter} value={pendingStatusFilter} />
              </div>
              {showAdvisorFilter && (
                <div className="min-w-[290px]">
                  <AdvisorSelect
                    advisors={advisors}
                    onChange={applyAdvisorFilter}
                    value={pendingAdvisorFilter}
                  />
                </div>
              )}
              <div className="flex items-center justify-center pb-4 ml-2">
                <Button
                  onClick={() => restoreDefaultSortAndFilters()}
                  label="Reset"
                  variant="text"
                />
              </div>
            </div>
          </div>

          {/* data-table - displays on larger screens */}
          <section className="hidden mdl:block">
            {loading ? (
              <Loader loader="UserManagementLoader" />
            ) : (
              <div className="w-full min-w-[800px]">
                <DataTable
                  pageSize={pageSize}
                  columns={tableColumns}
                  rows={displayedUserAccounts}
                  pagination={true}
                  setPageSize={setPageSize}
                />
              </div>
            )}
          </section>
          <section className="mdl:hidden">
            <UserManagementList
              userAccounts={displayedUserAccounts}
              showAdminRightsToggle={userAccountType === 'Advisor'}
              nextPaginatedDataHandler={() => {}}
              pageSize={pageSize}
            />
          </section>
        </div>
      </div>
    )
  }
)

export default UserManagementLayout

interface IAdvisorSelectProps {
  advisors: { label: string; value: string }[]
  onChange: (value: string) => any
  value: string
}

const AdvisorSelect: FC<IAdvisorSelectProps> = ({ advisors, onChange, value }) => {
  const bsoProfile = useSelector((state: RootState) => state.bso.bsoProfile)
  const replacedTerm = replaceTerm('advisor', bsoProfile?.terminology)

  return (
    <SelectField
      label={`Filter By ${replacedTerm}`}
      name={'advisor'}
      placeholder="Select"
      options={advisors}
      onChange={(value) => onChange(value)}
      value={value === '' ? 'all' : value}
      id={ADVISOR_SELECT_TEST_ID}
    />
  )
}

interface IStatusSelectProps {
  onChange: (value: string) => any
  value: string
}

const StatusSelect: FC<IStatusSelectProps> = ({ onChange, value }) => {
  return (
    <SelectField
      label="Filter By Status"
      name="status"
      placeholder="Select"
      options={statusOptions}
      onChange={(value) => onChange(value)}
      value={value === '' ? 'all' : value}
      id={STATUS_SELECT_TEST_ID}
    />
  )
}

const UserManagementSearchBar = ({ onSearchClicked, onChange, placeholder = '' }) => {
  return (
    <div className="flex justify-between items-center gap-2 min-w-[335px]">
      <span className="hover:cursor-pointer" onClick={onSearchClicked}>
        <SearchIcon className={`fill-primary cursor-pointer`} />
      </span>
      <InputField
        type={'search'}
        placeholder={placeholder}
        onChange={onChange}
        className="w-full text-sm"
        placeholderFontSize="sm"
        fontSize="base"
        name="search"
      />
    </div>
  )
}
