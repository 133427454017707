import { CancelIcon, NotificationBellIcon } from 'components/Common/SvgIcons'
import styled from 'styled-components'
import tw from 'twin.macro'

export const NotficationPanel = styled.div.attrs({
  className:
    'xs:fixed sm:absolute sm:pl-5 sm:pr-1 xs:pl-0 top-16 xs:top-0 right-0 z-[100]  sm:w-[375px] shadow-md border border-primary-outline bg-sky xs:w-full xs-top-0 xs:right-0 z-[1025] xs:after:hidden xs:h-[100vh] sm:h-[95vh]   lg:h-[100vh] sm:top-[70px]  ',
})``

export const NotificationPanelWrapper = styled.div.attrs({
  className: '',
})``
export const NotificationBellIconWrapper = styled(NotificationBellIcon)`
  ${tw` xs:inline-block h-5 w-5 mr-2 inline-block `}
`
export const NotificationDesktopClearCount = styled.div`
  ${tw` xs:hidden sm:inline-block font-primary font-bold leading-5 text-primary text-base cursor-pointer hover:text-primary-brand `}
`
export const NotificationPanelCount = styled.div`
  ${tw` xs:inline-block font-primary font-light leading-8 text-primary-text text-base inline-flex items-center `}
`
export const NotificationPanelDesktopIcon = styled.div`
  ${tw` xs:hidden sm:block sm:max-w-fit cursor-pointer my-12 `}
`
export const NotificationPanelHeader = styled.div`
  ${tw` xs:flex flex-wrap justify-start gap-x-2 gap-y-8 items-baseline xs:px-5 sm:px-0 `}
`
export const NotificationPanelHeaderTitle = styled.div`
  ${tw` inline-flex items-center w-full `}
`
export const NotificationPanelHeaderLoading = styled.div`
  ${tw` font-primary font-black leading-8 text-black-light text-4xl `}
`

export const NotificationPanelMobileWrapper = styled.div.attrs({
  className: '',
})``
export const NotificationPanelMessage = styled.div`
  ${tw`text-xs font-primary`}
`

export const ListItem = styled.div.attrs({
  className:
    'flex flex-col  overflow-auto gap-2 pr-2 xs:pl-5 sm:pl-0 xs:pb-4 sm:pb-3  xs:h-3/4 md:h-4/5 lg:h-4/6',
})`
  & {
    .li-body {
      ${tw` flex items-end sm:items-end justify-between p-4 shadow-md rounded-[20px] xs:gap-6 sm:gap-8 xs:p-4 sm:p-4  bg-white  `}
    }
    .unread-icon-body {
      ${tw` relative flex xs:gap-4 sm:gap-5 gap-2 items-center `}
    }
    .unread-icon {
      ${tw` absolute top-0 right-0 w-3 h-3 rounded-full border border-white bg-gerberared `}
    }
    .clear-notification {
      ${tw`font-primary font-bold leading-5 text-primary text-sm cursor-pointer hover:text-primary-brand xs:pt-2 sm:pt-0`}
    }
  }
`
export const NotificationPanelCancelIcon = styled(CancelIcon)`
  ${tw`cursor-pointer`}
`
export const NotificationPanelMobileIcon = styled.div`
  ${tw`xs:flex sm:hidden bg-white cursor-pointer p-5 mb-12 shadow-md `}
`
