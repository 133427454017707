import {
  UPDATE_PLAY_STEP,
  GET_PLAY,
  AUTHORIZE_CONNECTOR,
  UPDATE_PLAY_STAGE,
  GET_PLAY_ASSESSMENT_RESPONSE,
  HANDLE_COMPLETE_PLAY,
  GET_CONNECTED_PLAY,
  SAVE_PLAY_DATA,
} from 'store/types'
import { get } from 'lodash'
import PlayApi from 'api/play'
import {
  updatePlayStepId,
  getPlayAction,
  authorizeConnectorAction,
  updatePlayStageAction,
  getPlayAssessmentResponseAction,
  resetPlayAction,
  savePlayDataAction,
} from 'store/actions/play'
import { handleCyclrOAuthAction, resetOAuthUrlAction } from 'store/actions/calendar'

import { LOG_STATUS } from 'config'
import UserApi from 'api/user'
import history from 'utils/history'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import AssessmentApi from 'api/assessment'
import { checkPlayCompletion, getPlayStageAndAssessmentResponse } from 'utils/helper'
import { disablePlayAction } from 'store/actions/PlayBook'
import {
  getAssessmentResponseByQuestionAction,
  saveAssessmentResponseAction,
} from 'store/actions/owner/initialAssessment'
import { ampli } from 'ampli'

function* updatePlayStep(action) {
  yield put(updatePlayStepId.FULLFILLED({ payload: 'payload' }))
  try {
  } catch (error) {
    console.log('update play step error ', error)
  }
}

function* getPlay(action) {
  try {
    yield put(resetPlayAction())

    const playRes = yield call(PlayApi.getPlay, action?.payload?.play_id)

    const response = JSON.parse(playRes.getPlay.data)
    let play = response.play
    play.recommendedBy = response.recommendedBy

    let res = yield call(AssessmentApi.getUserAssessmentResponse, play.id)
    const getAssessments = get(res, 'getUserAssessmentResponse.data', '')

    let { playStage, assessmentResponse } = getPlayStageAndAssessmentResponse({
      assessments: getAssessments,
      play,
    })
    let isPlayCompleted = false
    if (play.playTabs) {
      let { updatedPlayStage, playCompleted } = checkPlayCompletion({ playStage, play })
      if (playCompleted) {
        isPlayCompleted = playCompleted
        playStage = updatedPlayStage
      }
    }

    if (playStage.isCompleted) {
      playStage.guide.isCompleted = false
      playStage.setUp.isCompleted = false
      playStage.use.isCompleted = false
      yield put(disablePlayAction(true))
    }

    let payload = {
      play,
      playStage,
      assessmentResponse,
      isPlayCompleted,
    }
    yield put(getPlayAction.FULLFILLED(payload))
  } catch (error: any) {
    console.log('Error : ', error?.message)
  }
}

function* authorizeConnector(action) {
  try {
    let record = yield call(PlayApi.authorizeConnector, action?.payload)
    let payload = JSON.parse(record.authorizeConnector.data)
    yield put(authorizeConnectorAction.FULLFILLED(payload))
  } catch (error) {
    console.log('get play (saga: authorizeConnector) error : ', error)
  }
}

function* updatePlayState(action) {
  try {
    const playData = yield select((state) => state.play?.play?.playData)
    const { submitActivity, name, isCompleted, type } = action.payload
    delete action.payload.submitActivity
    delete action.payload.name
    const tenantId = yield select((state) => state.user.tenantId)

    const redirectToDashboard = action.payload?.redirectToDashboard
    delete action.payload?.redirectToDashboard
    let record = yield call(AssessmentApi.saveAssessmentResponse, { ...action.payload, tenantId })

    let playStage = JSON.parse(record.saveAssessmentResponse?.playStage)
    if (redirectToDashboard) {
      ampli.playFinished({
        id: type,
        name: playData?.title,
      })
      history.push(`/owner/${tenantId}/dashboard`)
    }

    yield put(updatePlayStageAction.FULLFILLED({ playStage }))

    let isStarted = false
    for (let i = 0; i < playStage.length; i++) {
      for (let j = 0; j < playStage[i].steps.length; j++) {
        if (playStage[i].steps[j].isCompleted) {
          if (i === 0 && j === 0) {
            isStarted = true
          } else {
            isStarted = false
          }
        }
      }
    }

    if ((isCompleted || isStarted) && submitActivity) {
      const sessionToken = localStorage.getItem('sessionToken')
      yield call(UserApi.logActivity, {
        action: `${isStarted ? LOG_STATUS.STARTED : LOG_STATUS.COMPLETED} ${name}`,
        logStatus: isStarted ? LOG_STATUS.STARTED : LOG_STATUS.COMPLETED,
        accessToken: sessionToken,
        tenantId,
        showClientActivity: true,
        type: get(action, 'payload.type', ''),
      })
    }
  } catch (error: any) {
    console.log('error: updatePlayStage: ', error.message)
  }
}

function* getPlayAssessmentResponse(action) {
  try {
    let { play_id } = action.payload

    let res = yield call(AssessmentApi.getUserAssessmentResponse, play_id)
    let getAssessments = get(res, 'getUserAssessmentResponse.data', '')

    if (getAssessments?.assessmentResponse) {
      getAssessments.assessmentResponse = getAssessments?.assessmentResponse
        ? JSON.parse(getAssessments.assessmentResponse)
        : []
    } else {
      getAssessments = { assessmentResponse: [] }
    }

    if (getAssessments) {
      yield put(getPlayAssessmentResponseAction.FULLFILLED({ assessmentResponse: getAssessments }))
    }
  } catch (error: any) {
    console.log('error: updatePlayStage: ', error.message)
  }
}

function* handleCompletePlay(action) {
  const tenantId = yield select((state) => state.user.tenantId)
  const play = yield select((state) => state.play.play)
  const playStage = yield select((state) => state.play.playStage)
  const user = yield select((state) => state.user.user)

  if (play?.meta?.playType === 'EIN') {
    history.push(`/owner/${tenantId}/dashboard`)
  } else if (play?.meta?.playType !== 'EIN') {
    playStage.use.isCompleted = true
    let assessmentResponseObj = {
      userId: user.id,
      type: play.id,
      isCompleted: true,
      tenantId,
      assessmentResponse: JSON.stringify([]),
      playStage: JSON.stringify(playStage),
    }
    try {
      let record = yield call(AssessmentApi.saveAssessmentResponse, assessmentResponseObj)

      let playStage = JSON.parse(record.saveAssessmentResponse?.playStage)

      yield put(updatePlayStageAction.FULLFILLED({ playStage }))
    } catch (error: any) {
      console.log('error: updatePlayStage: ', error.message)
    }
    history.push(`/owner/${tenantId}/dashboard`)
  }
}

function* getConnectedPlay(action) {
  const user = yield select((state) => state.user.user)

  const { type, question, url } = action.payload
  try {
    yield put(handleCyclrOAuthAction.FULLFILLED({ url: '', isConnected: false }))
    const res = yield call(AssessmentApi.getAssessmentResponseByQuestion, question, type, user.id)
    if (res?.getAssessmentResponseByQuestion?.data?.id.includes(question)) {
      yield put(handleCyclrOAuthAction.FULLFILLED({ url: '', isConnected: true }))
      yield put(
        getAssessmentResponseByQuestionAction.FULLFILLED(res?.getAssessmentResponseByQuestion?.data)
      )
    } else {
      yield put(handleCyclrOAuthAction.FULLFILLED({ url: url, isConnected: false }))
    }
  } catch (error) {
    console.log('get connected play error ', error)
  }
}

function* savePlayData(action) {
  const user = yield select((state) => state.user.user)
  const tenantId = yield select((state) => state.user.tenantId)

  const { question, assessmentResponseObj } = action.payload
  let assessmentPlayLoad = {
    ...assessmentResponseObj,
    tenantId,
  }
  try {
    const response = yield call(AssessmentApi.saveAssessmentResponse, assessmentPlayLoad)
    yield put(saveAssessmentResponseAction.FULLFILLED(response))
    const res = yield call(
      AssessmentApi.getAssessmentResponseByQuestion,
      question,
      assessmentResponseObj.type,
      user.id
    )
    yield put(handleCyclrOAuthAction.FULLFILLED({ url: '', isConnected: true }))
    resetOAuthUrlAction()
    yield put(
      getAssessmentResponseByQuestionAction.FULLFILLED(res?.getAssessmentResponseByQuestion?.data)
    )
    yield put(savePlayDataAction.FULLFILLED())
  } catch (error) {
    console.log('save play data error ', error)
    yield put(savePlayDataAction.REJECTED())
  }
}

/* -------------------------------- Watchers -------------------------------- */
export function* watcherPlay() {
  yield takeLatest(UPDATE_PLAY_STEP.STARTED, updatePlayStep)
  yield takeLatest(GET_PLAY.STARTED, getPlay)
  yield takeLatest(AUTHORIZE_CONNECTOR.STARTED, authorizeConnector)
  yield takeLatest(UPDATE_PLAY_STAGE.STARTED, updatePlayState)
  yield takeLatest(GET_PLAY_ASSESSMENT_RESPONSE.STARTED, getPlayAssessmentResponse)
  yield takeLatest(HANDLE_COMPLETE_PLAY.STARTED, handleCompletePlay)
  yield takeLatest(GET_CONNECTED_PLAY.STARTED, getConnectedPlay)
  yield takeLatest(SAVE_PLAY_DATA.STARTED, savePlayData)
}
