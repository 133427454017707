import {
  OPEN_USER_MANAGEMENT_EDIT_MODAL,
  GET_ADVISORS,
  ADD_USER_TO_GROUP,
  ENABLE_DISABLE_USER,
  UPDATE_OWNER_PROFILE,
  UPDATE_ADVISOR_PROFILE,
  GET_ADVISORS_ACCOUNT_DETAILS,
  GET_BUSINESSES,
  CHECK_USER_PROFILE,
  CHECK_BUSINESS_PROFILE,
} from 'store/types'

const initialState = {
  businesses: { list: [], lastEvaluatedKey: '' },
  advisorsAccountDetails: { list: [], lastEvaluatedKey: '' },
  advisors: [], // this is likely set to an object with list & lastEvaluatedKey properties..
  disableButton: false,
  disableUserButton: false,
  openUserManagementEditModal: true,
  isUserProfileFormSet: false,
  isBusinessProfileFormSet: false,
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_BUSINESSES.STARTED:
      return {
        ...state,
      }
    case GET_BUSINESSES.FULLFILLED:
      return {
        ...state,
        businesses: action.payload,
      }
    case GET_ADVISORS_ACCOUNT_DETAILS.FULLFILLED:
      return {
        ...state,
        advisorsAccountDetails: action.payload,
      }
    case ADD_USER_TO_GROUP.FULLFILLED:
      return {
        ...state,
        addUserToAdminGroup: action.payload,
      }
    case GET_ADVISORS.FULLFILLED:
      return {
        ...state,
        advisors: action.payload,
      }
    case UPDATE_OWNER_PROFILE.STARTED:
      return {
        ...state,
        disableButton: true,
      }
    case UPDATE_OWNER_PROFILE.FULLFILLED:
      return {
        ...state,
        disableButton: false,
        openUserManagementEditModal: true,
      }
    case UPDATE_OWNER_PROFILE.REJECTED:
      return {
        ...state,
        disableButton: false,
        openUserManagementEditModal: true,
      }
    case ENABLE_DISABLE_USER.STARTED:
      return {
        ...state,
        disableUserButton: true,
      }
    case ENABLE_DISABLE_USER.FULLFILLED:
      return {
        ...state,
        disableUserButton: false,
      }
    case ENABLE_DISABLE_USER.REJECTED:
      return {
        ...state,
        disableUserButton: false,
      }
    case UPDATE_ADVISOR_PROFILE.STARTED:
      return {
        ...state,
        disableButton: true,
      }
    case UPDATE_ADVISOR_PROFILE.FULLFILLED:
      return {
        ...state,
        disableButton: false,
        openUserManagementEditModal: true,
      }
    case UPDATE_ADVISOR_PROFILE.REJECTED:
      return {
        ...state,
        disableButton: false,
        openUserManagementEditModal: true,
      }

    case OPEN_USER_MANAGEMENT_EDIT_MODAL:
      return {
        ...state,
        openUserManagementEditModal: action.payload,
      }
    case CHECK_USER_PROFILE:
      return {
        ...state,
        isUserProfileFormSet: action.payload,
      }
    case CHECK_BUSINESS_PROFILE:
      return {
        ...state,
        isBusinessProfileFormSet: action.payload,
      }
    default:
  }
  return state
}

export default reducer
