import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import {
  getBusinessAndAssessmentResponseAction,
  openUserManagementEditModalAction,
  getUserProfileAndAdvisorsAction,
  updateAdvisorProfileAction,
  updateOwnerProfileAction,
  enableDisableUserAction,
  addUserToGroupAction,
} from 'store/actions/userManagement'

import {
  getAssessmentsSelector,
  getRemainingClients,
  getSelectedClients,
  getAllClient,
} from 'store/selectors/userManagement'

import { getAdvisorsList } from 'store/selectors/admin'
import { getClientsAction } from 'store/actions/clients'

import AdminRightsToggle from 'components/Admin/UserManagement/AdminRightsToggle'
import AssignOwnerToAdvisor from 'components/Admin/UserManagement/AssignOwnerToAdvisor'
import AssignOwnerToAdvisorMobileView from 'components/Admin/UserManagement/AssignOwnerToAdvisorMobileView'
import EditAccount from 'components/Admin/UserManagement/EditAccount'
import EditBusinessProfile from 'components/Admin/UserManagement/EditBusinessProfile'
import EditUserProfile from 'components/Admin/UserManagement/EditUserProfile'
import UserManagement from 'components/Admin/UserManagement'

/* --------------------------- User Management  --------------------------- */
const userManagementProps = (state: any) => {
  const { openUserManagementEditModal } = state.userManagement
  return {
    openUserManagementEditModal,
  }
}
function userManagementDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      openUserManagementEditModalAction: openUserManagementEditModalAction,
    },
    dispatch
  )
}
export const UserManagementContainer = connect(
  userManagementProps,
  userManagementDispatch
)(UserManagement)

/* --------------------------- AdminRightsToggle --------------------------- */
const adminRightsProps = (state: any) => {
  return {}
}
function adminRightsDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      addUserToGroupAction: addUserToGroupAction.STARTED,
    },
    dispatch
  )
}
export const AdminRightsContainer = connect(
  adminRightsProps,
  adminRightsDispatch
)(AdminRightsToggle)

/* --------------------------- EditAccount --------------------------- */
const editAccountProps = (state: any) => {
  const { disableButton, disableUserButton, openUserManagementEditModal, isUserProfileFormSet } =
    state.userManagement
  const { userProfile, user } = state.user
  return {
    user,
    userProfile,
    disableButton,
    disableUserButton,
    isUserProfileFormSet,
    openUserManagementEditModal,
    advisors: getAdvisorsList(state),
  }
}
function editAccountDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      updateOwnerProfileAction: updateOwnerProfileAction.STARTED,
      enableDisableUserAction: enableDisableUserAction.STARTED,
      getUserProfileAndAdvisorsAction: getUserProfileAndAdvisorsAction.STARTED,
      getClientsAction: getClientsAction.STARTED,
      updateAdvisorProfileAction: updateAdvisorProfileAction.STARTED,
      openUserManagementEditModalAction: openUserManagementEditModalAction,
    },
    dispatch
  )
}
export const EditAccountContainer = connect(editAccountProps, editAccountDispatch)(EditAccount)
/* --------------------------- EditUserProfile --------------------------- */
const editUserProfileProps = (state: any) => {
  return {}
}
function editUserProfileDispatch(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch)
}
export const EditUserProfileContainer = connect(
  editUserProfileProps,
  editUserProfileDispatch
)(EditUserProfile)

/* --------------------------- EditBusinessProfile --------------------------- */
const editBusinessProfileProps = (state: any) => {
  const { isBusinessProfileFormSet } = state.userManagement

  return {
    isBusinessProfileFormSet,
    assessment: getAssessmentsSelector(state),
  }
}
function editBusinessProfileDispatch(dispatch: Dispatch) {
  return bindActionCreators(
    {
      getBusinessAndAssessmentResponseAction: getBusinessAndAssessmentResponseAction.STARTED,
    },
    dispatch
  )
}
export const EditBusinessProfileContainer = connect(
  editBusinessProfileProps,
  editBusinessProfileDispatch
)(EditBusinessProfile)

/* --------------------------- AssignOwnerToAdvisor --------------------------- */
const assignOwnerToAdvisortProps = (state: any) => {
  const { userProfile } = state.user
  const { isUserProfileFormSet } = state.userManagement

  return {
    isUserProfileFormSet,
    firstName: userProfile?.firstName,
    owners: getAllClient(state),
    assignedOwnerList: getSelectedClients(state),
  }
}
function assignOwnerToAdvisorDispatch(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch)
}
export const AssignOwnerToAdvisorContainer = connect(
  assignOwnerToAdvisortProps,
  assignOwnerToAdvisorDispatch
)(AssignOwnerToAdvisor)

/* --------------------------- AssignOwnerToAdvisor Mobile View --------------------------- */
const assignOwnerToAdvisorMobileViewProps = (state: any) => {
  const { userProfile } = state.user

  return {
    firstName: userProfile?.firstName,
    owners: getAllClient(state),
    assignedOwnerList: getSelectedClients(state),
    remainingOwnerList: getRemainingClients(state),
  }
}
function assignOwnerToAdvisorMobileViewDispatch(dispatch: Dispatch) {
  return bindActionCreators({}, dispatch)
}
export const AssignOwnerToAdvisorMobileViewContainer = connect(
  assignOwnerToAdvisorMobileViewProps,
  assignOwnerToAdvisorMobileViewDispatch
)(AssignOwnerToAdvisorMobileView)
