export enum FORM {
  USER_PROFILE = 'USER_PROFILE',
  PROFILE_FORM = 'PROFILE_FORM',
  ADVISOR_PROFILE_FORM = 'ADVISOR_PROFILE_FORM',
  BUSINESS_PROFILE = 'BUSINESS_PROFILE',
  INITIAL_ASSESSMENT = 'INITIAL_ASSESSMENT',
  BUSINESS_ASSESSMENT_FORM = 'BUSINESS_ASSESSMENT_FORM',
  ADVISOR_FORM = 'ADVISOR_FORM',
  ADMIN_ACCESS = 'ADMIN_ACCESS',
  USER_PROFILE_FORM = 'USER_PROFILE_FORM',
  OWNERS = 'OWNERS',
  TOPGOAL = 'TOPGOAL',
  BUSINESS_OTHER_DETAILS = 'BUSINESS_OTHER_DETAILS',
  USER_MANAGEMENT_BUSINESS_FORM = 'USER_MANAGEMENT_BUSINESS_FORM',
  OTHER_GOALS = 'OTHER_GOALS',
  BUSINESS_DETAILS = 'BUSINESS_DETAILS',
  CALENDAR = 'CALENDAR',
  USER_AVAILABILITY = 'USER_AVAILABILITY',
  PLAY_FORM = 'PLAY_FORM',
  MONTHLY_ATTESTATION = 'MONTHLY_ATTESTATION',
  MEETING_SCHEDULED = ' MEETING_SCHEDULED',
  SMS_ENABLED = 'SMS_ENABLED',
}

export enum ROLES {
  BSO_ADMIN = 'admin',
  BUSINESS_OWNER = 'owner',
  BSO_ADVISOR = 'advisor',
  APP_ADMIN = 'app-admin',
}

export enum CREATE_BUSINESS_ACCOUNT_STEPS {
  PROFILE_FORM = 'Profile Form',
  ADDITIONAL_DETAILS = 'Additional Details',
  ASSIGN_AN_ADVISOR = 'Assign an Advisor',
  CREATE_ACCOUNT = 'Create Account',
  ACCOUNT_CREATED = 'Account Created',
}

export enum CREATE_ADVISOR_ACCOUNT_STEPS {
  PROFILE_FORM = 'Profile Form',
  ASSIGN_A_CLIENT = 'Assign a Client',
  ADMIN_ACCESS = 'Admin Access',
  CREATE_ACCOUNT = 'Create Account',
  ACCOUNT_CREATED = 'Account Created',
}

export enum ERROR_MESSAGE_MAPPING {
  'An account with the given email already exists' = 'An account with this email already exists',
}

export enum GOAL_ACTION {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  TOP_GOAL = 'topGoal',
}

export const ASSESSMENT_USER_PRIVACY = {
  admin: 'showToAdmin',
  owner: 'showToOwner',
}

export enum CYCLR_CALENDAR {
  GOOGLE_CALENDAR = 'Google Calendar',
  SQUARE_UP = 'Squareup',
  CALENDLY = 'Calendly',
  MICROSOFT_OFFICE_365 = 'Microsoft Office 365',
  QUICKBOOKS = 'QuickBooks',
}

export enum CYCLR_OAUTH {
  SQUARE_UP = 'Squareup',
  QUICKBOOKS = 'QuickBooks',
}
export enum CYCLR_CONNECTORS {
  SQUARE_UP = 'Squareup',
  CALENDLY = 'Calendly connector',
  GOOGLE_CALENDAR = 'Google connector',
  MICROSOFT_OFFICE_365 = 'Microsoft Office 365',
  QUICKBOOKS = 'QuickBooks',
}

export enum CYCLR_CONNECTOR_ID {
  SQUARE_UP = 19596,
  CALENDLY = 19634,
  GOOGLE_CALENDAR = 19546,
  MICROSOFT_OFFICE_365 = 19555,
  QUICKBOOKS = 19740,
}

export const PLAY_STEPS = {
  GUIDE: 'guide',
  SETUP: 'setup',
  USE: 'use',
}

export const PLAY_CARDS = {
  COLLAPSEABLE: 'collapseable',
}

export const PLAY = {
  MOVE_NEXT_BUTTON: 'next',
  INTEGRATION: 'connect',
  SUGGESTIONS: 'optional-plays',
  CALENDAR_MEETING: 'calandar-meeting-scheduling',
  COMPLETE_BUTTON: 'play-complete-button',
}

export const PLAY_CARD_BODY_DATA = {
  HTML: 'html',
  QUESTION: 'question',
}

export enum FILTER_TYPE {
  THIS_WEEK = 'this week',
  THIS_MONTH = 'this month',
  THIS_YEAR = 'this year',
  THIS_TIME = 'all time',
}

export enum USER_MANAGEMENT_TABS {
  LABEL_BUSINESSES = 'BUSINESSES',
  LINK_BUSINESSES = 'businesses',
  LABEL_ADVISERS = 'ADVISERS',
  LINK_ADVISORS = 'advisers',
}

export enum USER_MANAGEMENT_FILTER {
  ADVISOR = 'advisor',
  STATUS = 'status',
  SEARCH_QUERY = 'searchQuery',
}

export enum USER_ROLES {
  BSO_ADMIN = 'BSO_ADMIN',
  BSO_ADVISOR = 'BSO_ADVISOR',
  BUSINESS_OWNER = 'BUSINESS_OWNER',
}

export enum BIG_QUERY {
  BOTTOM_5_CUSTOMERS = 'BOTTOM_5_CUSTOMERS',
}

export enum USER_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PENDING = 'pending',
  DISABLED = 'disabled',
}

export enum LOG_STATUS {
  COMPLETED = 'Completed',
  STARTED = 'Started',
}

export enum PLAY_INTEGRATION_QUERY_PARAMS {
  SUCCESS = '?success=true',
}

export enum PLAY_TYPE {
  TOOL = 'Existing Tool - Usage',
  CONNECT = 'Recommend Tool - Account Set up & Connect',
  EDUCATION = 'Education',
  PROCESS = 'Process',
}

export enum UPLOAD_TYPE {
  IMAGE = 'image',
  DOCUMENT = 'doc',
}

export enum Industry_Type {
  RETAIL = 'Retail',
  ECOMMERCE = 'ECommerce',
  CHILDCARE = 'Childcare',
  SERVICES = 'Services',
  FOOD = 'Food',
}

export enum STATUS {
  LOADING = 'loading',
  COMPLETED = 'completed',
  REJECTED = 'rejected',
  NONE = 'none',
}
export enum MEETING_STEPPER {
  ADVISOR = 'Request Meeting',
  CALENDAR = 'Select a Date',
  SLOTS = 'Select a Time',
  DESCRIPTION = 'Confirm Meeting',
}
export enum TIME_SLOT {
  START_TIME = '12:00 AM',
  END_TIME = '12:00 AM',
  DURATION = 60,
}
export enum LOG_ACTIVITY_ACTION {
  SIGN_IN = 'signin',
  SIGN_OUT = 'signout',
}

export enum PLAY_ACTIONS {
  MEETING_SCHEDULED = 'Meeting Scheduled',
}
export enum REGISTER_BUSINESS {
  TELL_ME_MORE = 'I don’t know/Tell me more',
  DONT_HAVE_YET = 'I don’t have one yet',
}
export enum MEETING_LABEL {
  CANCEL_MEETING = 'CANCEL MEETING',
  SAVE = 'Save',
  SEND_REQUEST = 'Send Request',
  CANCEL = 'Cancel',
  LOCATION = 'Location',
  PARTICIPANT_EMAIL_ADDRESSES = 'Participant Email Addresses',
  EDIT = 'Edit',
}

export enum ADVISOR_ONBOARDING {
  ADVISOR_ONBOARD = 'advisor onboarding',
}
