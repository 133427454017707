import React, { forwardRef, memo, ForwardRefRenderFunction } from 'react'
import InputMask from 'react-input-mask'
import { useSelector } from 'react-redux'

import Label from 'components/Common/Label'
import ErrorMessage from 'components/Common/ErrorMessage'
import { FontSize } from 'types'

interface IInputFieldProps {
  name?: string
  label?: string
  mask?: string
  placeholder?: string
  rows?: string
  type?: string
  value?: string
  onChange?: any
  onChangeText?: any
  defaultValue?: any
  disabled?: boolean
  readonly?: boolean
  step?: string
  help?: string
  maxLength?: string | number
  autoFocus?: boolean
  hidden?: boolean
  className?: string
  isCheckBox?: boolean
  id?: any
  onMouseOut?: any
  onBlur?: any
  onFocus?: any
  errors?: any
  showPassword?: any
  onKeyPress?: any
  fontSize?: FontSize | ''
  placeholderFontSize?: FontSize | ''
  icon?: string
}

const InputField: ForwardRefRenderFunction<any, IInputFieldProps> = (
  {
    name,
    label,
    placeholder,
    type = 'text',
    onChange,
    value,
    defaultValue,
    disabled,
    readonly,
    maxLength,
    autoFocus,
    hidden,
    id,
    onMouseOut,
    className,
    onBlur,
    onFocus,
    errors,
    showPassword,
    mask = '',
    onKeyPress,
    fontSize = '',
    placeholderFontSize = 'base',
    icon,
  },
  ref
) => {
  const bgColor = useSelector((state: any) => state.common.inputFieldBgColor)
  const getClasses = () => {
    const _fontSize = fontSize ? `text-${fontSize}` : 'xs:text-base sm:text-xl'
    const _placeholderFontSize = `placeholder-text-${placeholderFontSize}`

    let classes = ` border border-grey-light ${bgColor} rounded-xl placeholder-text-silver font-primary outline-0
      ${_fontSize} mt-1.5 block w-full px-3 py-3 text-black font-bold placeholder-font-medium ${_placeholderFontSize} placeholder-font-primary
      ${
        icon
          ? 'pl-12 pr-3 placeholder:!font-medium placeholder:!text-base hover:border hover:border-primary-brandDark !mt-0'
          : readonly || disabled
          ? 'cursor-not-allowed px-3'
          : 'hover:border hover:border-primary-brandDark focus:ring focus:ring-primary-brandLight focus:border focus:border-primary-brandDark shadow-primary-brandDark px-3'
      }   ${className}`
    if (errors) {
      classes = `${classes} border border-primary-error hover:border hover:border-primary-error focus:border-primary-error focus:ring-0 focus:ring-transparent`
    } else {
      classes = `${classes}`
    }
    return classes
  }

  return (
    <div className={className}>
      <div className={`${hidden ? 'hidden' : ''}`}>
        {label && <Label title={label}>{label}</Label>}
        <div className={`${icon ? 'flex relative' : ''}`}>
          {icon && <img className="absolute top-4 left-4" src={icon} alt="icon" />}
          <InputMask
            aria-invalid={errors}
            autoFocus={autoFocus}
            className={getClasses()}
            data-testid={name}
            defaultValue={defaultValue}
            disabled={disabled}
            hidden={hidden}
            id={id}
            mask={mask}
            maxLength={maxLength as number}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            onKeyPress={onKeyPress}
            onMouseOut={onMouseOut}
            placeholder={placeholder ? placeholder : ''}
            readOnly={readonly}
            ref={ref}
            title={label}
            type={type !== 'password' ? type : showPassword ? 'text' : 'password'}
            value={value}
          />
        </div>
      </div>
      {errors && <ErrorMessage id={name}>{errors.message}</ErrorMessage>}
    </div>
  )
}

export default memo(forwardRef(InputField))
